import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { httpInterceptorProviders } from './auth/interceptor';
import { LocalStorageService,SessionStorageService } from 'ngx-webstorage';
import { missingTranslationHandler } from './config/translation.config';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import {PaginatorModule} from 'primeng/paginator';
import {DecimalPipe} from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

import { ChartModule } from 'primeng/chart';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { MessageService } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { BadgeModule } from 'primeng/badge';
import { TreeTableModule } from 'primeng/treetable';
import { SharedModule } from './shared/shared.module';
import { StepsModule } from 'primeng/steps';
// Services
import { AgencyService } from './module/agency/agency.service';
import { LevelTypeService } from './module/level-type/level-type.service';

import { PersonalVendorService } from './module/personal-vendors/personal-vendors.service';
import { ProfileService } from './module/profile/profie.service';
import { UserService } from './module/checker-project/checker.service';
import { ValidationService } from './shared/validation/validation.service';
import { ZonalService } from './module/zonal/zonal.service';
import { NotificationService } from './alert/notification.service';

// Components
import { AgencyComponent } from './module/agency/agency.component';
import { DashboardNewComponent } from './new-dashBoard/dashboard/dashboard.component';
import { DashboardOldComponent } from './old-dashboard/dashboard/dashboard.component';

import { FooterComponent } from './layout/footer/footer.component';
import { LevelTypeComponent } from './module/level-type/level-type.component';

import { NavbarComponent } from './layout/navbar/navbar.component';
import { TabViewModule } from 'primeng/tabview';
import { PersonalVendorsComponent } from './module/personal-vendors/personal-vendors.component';
import { ProfileComponent } from './module/profile/profile.component';
import { SigninComponent } from './auth/signin/signin.component';
import { CheckerProjectComponent } from './module/checker-project/checker-project.component';
import { AlertComponent } from './alert/alert.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { UppercaseInputDirective } from './shared/directives/uppercase-input.directive';
//devExtreme
import { DxChartModule,DxButtonModule } from 'devextreme-angular';
import { DxPieChartModule } from 'devextreme-angular';
import { DxSankeyModule } from 'devextreme-angular';

import { ProjectComponent } from './project/project.component';

import { PaymentComponent } from './module/payment/payment.component';
import { PaymentGenerationComponent } from './module/payment-generation/payment-generation.component';
import { PaymentStatusComponent } from './module/payment-status/payment-status.component';
import { AddProjectComponent } from './module/add-project/add-project.component';
import { AddAgencyComponent } from './module/add-agency/add-agency.component';
import { Stage1Component } from './module/stage1/stage1.component';
import { Stage2Component } from './module/stage2/stage2.component';
import { Stage3Component } from './module/stage3/stage3.component';
import { Stage4Component } from './module/stage4/stage4.component';
import { Stage5Component } from './module/stage5/stage5.component';
import { AddFundAllocationComponent } from './module/add-lao/add-lao.component';
import { ListFundAllocationComponent } from './module/lao-list/lao-list.component';

import { AgencyBasedReportComponent } from './module/agency-based-report/agency-based-report.component';
import { ProjectBasedReportComponent } from './module/project-based-report/project-based-report.component';
import { BeneficiaryBasedReportComponent } from './module/beneficiary-based-report/beneficiary-based-report.component';
import { ConsolidatedReportComponent } from './module/consolidated-report/consolidated-report.component';
import { Stage1ViewComponent } from './module/stage1-view/stage1-view.component';
import { Stage2ViewComponent } from './module/stage2-view/stage2-view.component';
import { Stage3ViewComponent } from './module/stage3-view/stage3-view.component';
import { Stage4ViewComponent } from './module/stage4-view/stage4-view.component';
import { AddProfileComponent } from './module/add-profile/add-profile.component';

import * as CanvasJSAngularChart from "../assets/js/canvasjs.angular.component";
import { RequistioningDepartmentReportComponent } from './module/requistioning-department-report/requistioning-department-report.component';
import { LaoAmountReportComponent } from './module/lao-amount-report/lao-amount-report.component';
import { RaiseRequestComponent } from './module/raise-request/raise-request.component';
import { StatusRequestComponent } from './module/status-request/status-request.component';
import { PendingRequestComponent } from './module/pending-request/pending-request.component';
import { ProceedingNoComponent } from './module/proceeding-no/proceeding-no.component';
import { AccountSnaComponent } from './module/account-sna/account-sna.component';
import { AccountSnaService } from './module/account-sna/account-sna.service';
import { DepartmentListService } from './module/account-sna/department-list.service';
import { SchemeListService } from './module/account-sna/scheme-list.service';
import { BiometricTestComponent } from './module/biometric-test/biometric-test-device.component';
import { DepartmentListComponent } from './module/department-list/department-list.component';
import { LevelMasterComponent } from './module/level-master/level-master.component';
import { DynamicReportTableComponent } from './dynamic-report-table/dynamic-report-table.component';
import { LevelMasterService } from './module/level-master/level-master.service';
import { ChangePasswordComponent } from './module/change-password/change-password.component';
import { NgSelectInputRestrictionDirective } from './shared/directives/ng-select-input-restriction.directive';
import { AccountStatementComponent } from './module/account-statement/account-statement.component';
import { SerachInputRestrictionDirective } from './shared/directives/serach-input-restriction.directive';
import { Stage3DDComponent } from './module/stage3-dd/stage3-dd.component';
import { RecommandDDComponent } from './module/recommand-dd/recommand-dd.component';
import { CourtPaymentComponent } from './module/court-payment/court-payment.component';
import { CourtPaymentStatusComponent } from './module/court-payment-status/court-payment-status.component';
import { NoProjectReportComponent } from './no-project-report/no-project-report.component';
import { ExpenditureReportComponent } from './expenditure-report/expenditure-report.component';
import { FundReceivedComponent } from './fund-received/fund-received.component';
import { CourtDdListComponent } from './module/court-dd-list/court-dd-list.component';
var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;


@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    AccountSnaComponent,
    DynamicReportTableComponent,
    DashboardNewComponent,
    DashboardOldComponent,
    NavbarComponent,
    Stage3DDComponent,
    LevelMasterComponent,
    FooterComponent,
    ProfileComponent,
    CheckerProjectComponent,
    AgencyComponent,
    PersonalVendorsComponent,
    DepartmentListComponent,
    LevelTypeComponent,  
    CanvasJSChart,
    AlertComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UppercaseInputDirective,
    NgSelectInputRestrictionDirective,
    SerachInputRestrictionDirective,
    ProjectComponent,
    PaymentComponent,
    PaymentGenerationComponent,
    PaymentStatusComponent,
    AddProjectComponent,
    AddAgencyComponent,
    Stage1Component,
    Stage2Component,
    Stage3Component,
    Stage4ViewComponent,
    Stage4Component,
    Stage5Component,
    AddFundAllocationComponent,
    ListFundAllocationComponent,
    AgencyBasedReportComponent,
    ProjectBasedReportComponent,
    BeneficiaryBasedReportComponent,
    ConsolidatedReportComponent,
    Stage1ViewComponent,
    Stage2ViewComponent,
    Stage3ViewComponent,
    AddProfileComponent,
    RequistioningDepartmentReportComponent,
    LaoAmountReportComponent,
    RaiseRequestComponent,
    StatusRequestComponent,
    PendingRequestComponent,
    ProceedingNoComponent,
    BiometricTestComponent,
    ChangePasswordComponent,
    AccountStatementComponent,
    RecommandDDComponent,
    CourtPaymentComponent,
    CourtPaymentStatusComponent,
    NoProjectReportComponent,
    ExpenditureReportComponent,
    FundReceivedComponent,
    CourtDdListComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot(),
    ButtonModule,
    PaginatorModule,
    MenubarModule,
    StepsModule,
    TieredMenuModule,
    RippleModule,
    TabViewModule,
    DxSankeyModule,
    TooltipModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    TreeTableModule,
    TimelineModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    ChartModule,
    DxButtonModule,
    DxChartModule,
    DxPieChartModule,
    NgSelectModule,
    BadgeModule,
    NgxSpinnerModule,
    NgxExtendedPdfViewerModule,
    CascadeSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: missingTranslationHandler,
      },
    }),
  ],

  providers: [
    httpInterceptorProviders,
    ProfileService,
    MessageService,
    SchemeListService,
    LevelMasterService,
    ConfirmationService,
    DecimalPipe,
    AgencyService,
    UserService,
    ZonalService,
    PersonalVendorService,
    LevelTypeService,
    ValidationService,
    NotificationService,
    AccountSnaService,
    DepartmentListService,
    DatePipe,
  ],
  bootstrap: [AppComponent]

})
export class AppModule {
  constructor(

    translateService: TranslateService,
    sessionStorageService: SessionStorageService
  ) {
    // registerLocaleData(locale);
    // translateService.setDefaultLang('en');
    // // if user have changed language and navigates away from the application and back to the application then use previously choosed language
    // const langKey = sessionStorageService.retrieve('locale') ?? 'en';
    // translateService.use('en');
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}



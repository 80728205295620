<div class="row justify-content-center">
    <div class="col-lg-12 mt-5">

    </div>
    <div class="col-sm-6 col-md-5 col-lg-6 mt-5">
        <div class="col-lg-8">
            <div class=" mx-auto login-form">

                <div class="font-login mb-4" style="color:#162a72">Change Password</div>
                <div class="login-content">
                    <form class="form" role="form" (ngSubmit)="submit()" [formGroup]="resetPwdForm">
                        <div class="form-group mb-4 eye-container">
                            <input id="oldPassword" name="oldPassword" autocomplete="off" formControlName="oldPassword" [type]="oldhide ? 'password' : 'text'" required class="form-control full" placeholder="OLD PASSWORD">
                            <i class="pi pi-eye" *ngIf="oldhide" (click)="oldhide=false"></i>
                            <i class="pi pi-eye-slash" *ngIf="!oldhide" (click)="oldhide=true"></i>
                        </div>
                        <div class="form-group mb-4 eye-container">
                            <input id="password" name="password" autocomplete="off" formControlName="password" [type]="phide ? 'password' : 'text'" required class="form-control full" placeholder="NEW PASSWORD">
                            <i class="pi pi-eye" *ngIf="phide" (click)="phide=false"></i>
                            <i class="pi pi-eye-slash" *ngIf="!phide" (click)="phide=true"></i>
                        </div>
                        <div class="form-group mb-4 eye-container">
                            <input id="confirmPassword" name="confirmPassword" autocomplete="off" formControlName="confirmPassword" class="form-control full" required placeholder="CONFIRM NEW PASSWORD" [type]="cphide ? 'password' : 'text'">
                            <i class="pi pi-eye" *ngIf="cphide" (click)="cphide=false"></i>
                            <i class="pi pi-eye-slash" *ngIf="!cphide" (click)="cphide=true"></i>
                        </div>
                        <small *ngIf="passwordError" style="font-size: 30px;" class="text-danger">{{passwordError}}</small>
                        <div *ngIf="pwdMismatch" class="text-danger mb-2">
                            <small>New Password and Confirm New Password should be same. Please re-enter</small>
                        </div>
                        <div class="mb-4">
                            <button type="submit" [disabled]="!resetPwdForm.valid" class="btn btn-color">Submit</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>

</div>
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { MenuItem } from 'primeng/api';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { TranslateService } from '@ngx-translate/core';
import screenfull from 'screenfull';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NavbarService } from './navbar.service';
import { HttpResponse } from '@angular/common/http';
import { AppComponent } from 'src/app/app.component';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'ib-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  inProduction?: boolean;
  @ViewChild('AppComponent') app: AppComponent;

  isNavbarCollapsed = true;
  openAPIEnabled?: boolean;
  version = '';
  isFullScreenEnabled: boolean = false;
  hidden: boolean = true;
  headerItems: MenuItem[] = [
    {
      // label: 'usermenu.myAccount',
      label: this.translateService.instant('usermenu.myAccount'),
      icon: 'pi pi-user',
      routerLink: '/',
    },
    {
      // label: 'usermenu.settings',
      label: this.translateService.instant('usermenu.settings'),
      icon: 'pi pi-cog',
      routerLink: '/',
    },
    // {
    //   label: 'Logout',
    //   icon: 'pi pi-sign-out',
    //   routerLink: '/'
    // }
  ];

  items = [
    { routerLink: 'home', jhiTranslate: 'dashboard.name' },
    {
      label: 'Profile',
      routerLink: 'profile',
      jhiTranslate: 'breadcrumb.profile.name',
    },

    {
      label: 'User',
      jhiTranslate: 'breadcrumb.user.name',
      items: [
        {
          label: 'User List',
          routerLink: 'user',
          jhiTranslate: 'breadcrumb.user.list',
        },
        {
          label: 'Profile List',
          routerLink: 'profile',
          jhiTranslate: 'breadcrumb.profile.name',
        },
      ],
    },
  ];
  public selectedLanguage: string = 'English';
  showMenu = false;
  menu: any;
  isFullScreen = false;
  userName: any;
  disableMenu: boolean;
  role: any;
  indexLetter: string;
  roleId: boolean;
  roleIdForBenficiary: boolean;
  roleIdForProject: boolean;
  roleIdAgency: boolean;
  isMaker: boolean;
  isChecker: boolean;
  Id: any;
  roleCode: any;
  constructor(
    private loginService: SigninService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private renderer: Renderer2,
    private fontService: FontService,
    private navbarService: NavbarService,
    private AppComponent: AppComponent,
    private spinner: NgxSpinnerService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.fontService.setFontClass(this.translateService.currentLang);
    });
  }

  getMenu() {
    this.spinner.show()
    this.navbarService.getMenu().subscribe(
      (res: HttpResponse<any>) => {
        this.spinner.hide()
        this.menu = res.body;
      },
      (err) => { 
        this.spinner.hide()
      }
    );
  }

  async ngOnInit() {
    await this.loginService.preStatus().toPromise();
    await this.getMenu()
    await this.loginService.getuserDto().subscribe(
      (res) => {
        this.roleCode=res.body.role.code
        this.Id = res.body.role.id
        if (res.body.role.id == 5) {
          this.isMaker = true
        } else {
          this.isMaker = false
        }
        if (res.body.role.id == 4) {
          this.isChecker = true
        } else {
          this.isChecker = false
        }
        if (
          res.body.role.id != 1 &&
          res.body.role.id != 5 &&
          res.body.role.id != 4 &&
          res.body.role.id != 3 &&
          res.body.role.id != 8
        ) {
          this.roleIdForProject = true;
        } else {
          this.roleIdForProject = false;
        }
        if (res.body.role.id != 1 && res.body.role.id != 8 && res.body.role.id != 5 && res.body.role.id != 4 && res.body.role.id != 3) {
          this.roleIdAgency = true
        } else {
          this.roleIdAgency = false
        }
        this.userName = res.body.userName;
        localStorage.setItem('userID', res.body.id)
        localStorage.setItem('roleCode', res.body.role.code)
        if (res.body.role.code != 'SA') {
          localStorage.setItem('levelMasterId', res.body.levelMasterId.id)
        }
        this.indexLetter = String(res.body.userName).charAt(0);
        if (res.body.profileStatus === 'No' && res.body.role.code !== 'SA') {
          this.localStorageService.store('refresh', 'Yes');
          this.disableMenu = true;
          this.router.navigate(['/profile']);
        } else {
          this.disableMenu = false;
        }
        if (res.body.role.name.startsWith('District')) {
          this.hidden = false;
        }
      },
      (onError) => { }
    );
  }

  getData(data) {
    this.navbarService.updateSelectedReport(data)
  }

  getUserrole() {
    this.loginService.getuserDto().subscribe(
      (res) => {

        this.Id = res.body.role.id
        if (res.body.role.id == 5) {
          this.isMaker = true
        } else {
          this.isMaker = false
        }
        if (res.body.role.id == 4) {
          this.isChecker = true
        } else {
          this.isChecker = false
        }

        if (
          res.body.role.id != 1 &&
          res.body.role.id != 5 &&
          res.body.role.id != 4 &&
          res.body.role.id != 3 &&
          res.body.role.id != 8

        ) {
          this.roleIdForProject = true;
        } else {
          this.roleIdForProject = false;
        }

        if (res.body.role.id != 1 && res.body.role.id != 8 && res.body.role.id != 5 && res.body.role.id != 4 && res.body.role.id != 3) {
          this.roleIdAgency = true
        } else {
          this.roleIdAgency = false
        }



      },

      (onError) => { }
    );
  }
  login(): void {
    this.router.navigate(['/login']);
  }

  hideMenu(menuName) {
    const index = this.menu?.findIndex((_) => _.menuTab === menuName);
    if (index >= 0) {
      return false;
    } else {
      return true;
    }
  }

  logout(): void {
    this.navbarService.logout().subscribe(
      (res: HttpResponse<any>) => {
      },
      (err) => { }
    );
    this.localStorageService.clear();
    this.sessionStorageService.clear();
    this.router.navigate(['login']);
  }

  ngAferViewInit() {

    if (window.innerWidth < 992) {

      // close all inner dropdowns when parent is closed
      document.querySelectorAll('.navbar .dropdown').forEach(function (everydropdown) {
        everydropdown.addEventListener('hidden.bs.dropdown', function () {
          // after dropdown is hidden, then find all submenus
          this.querySelectorAll('.submenu').forEach(function (everysubmenu) {
            // hide every submenu as well
            everysubmenu.style.display = 'none';
          });
        })
      });

      document.querySelectorAll('.dropdown-menu a').forEach(function (element) {
        element.addEventListener('click', function (e) {
          let nextEl = this.nextElementSibling;
          if (nextEl && nextEl.classList.contains('submenu')) {
            // prevent opening link if link needs to open dropdown
            e.preventDefault();
            if (nextEl.style.display == 'block') {
              nextEl.style.display = 'none';
            } else {
              nextEl.style.display = 'block';
            }

          }
        });
      })
    }
  }

  showDialog() {
    this.AppComponent.showDialog()
  }
  // setLanguage(language: string): void {
  //   this.translateService.use(language);
  //   this.translateService.reloadLang(language);

  //   if (language === 'en') {
  //     this.selectedLanguage = 'nav.en';
  //     this.renderer.removeClass(document.body, 'tamil-font');
  //   } else if (language === 'ta') {
  //     this.selectedLanguage = 'nav.ta';
  //     this.renderer.addClass(document.body, 'tamil-font');
  //   }

  //   // Update the text content of the button using translation
  //   this.translateService
  //     .get(this.selectedLanguage)
  //     .subscribe((translation: string) => {
  //       const button = document.querySelector('.dropdown-toggle');
  //       if (button instanceof HTMLElement) {
  //         button.textContent = translation;
  //       }
  //     });

  //   // Update the body and dialog fonts
  //   const bodyElement = document.getElementsByTagName('body')[0];
  //   if (language === 'ta') {
  //     bodyElement.classList.add('tamil-font');
  //   } else {
  //     bodyElement.classList.remove('tamil-font');
  //   }

  //   const dialogElement = document.querySelectorAll('.p-dialog-title');
  //   if (dialogElement) {
  //     dialogElement.forEach((element) => {
  //       if (language === 'en') {
  //         element.classList.add('english-heading');
  //         element.classList.remove('tamil-heading');
  //       } else {
  //         element.classList.add('tamil-heading');
  //         element.classList.remove('english-heading');
  //       }
  //     });
  //   }
  //   this.cdr.detectChanges();
  // }

  // setLanguage(language: string): void {
  //   // this.clearTranslationCache();
  //   this.translateService.use(language);
  //   // this.translateService.reloadLang(language);
  //   if (language === "en") {
  //     this.selectedLanguage = "English";
  //     this.renderer.removeClass(document.body, 'tamil-font');

  //   }
  //   else if (language === "ta") {
  //     this.selectedLanguage = "Tamil";
  //     this.renderer.addClass(document.body, 'tamil-font');

  //   }

  //   const bodyElement = document.getElementsByTagName('body')[0];
  //   if (language === 'ta') {
  //     bodyElement.classList.add('tamil-font');
  //   } else {
  //     bodyElement.classList.remove('tamil-font');
  //   }

  //   const dialogElement = document.querySelector('.p-component-overlay');
  //   if (dialogElement) {
  //     if (language === 'ta') {
  //       dialogElement.classList.add('tamil-font');

  //     } else {
  //       dialogElement.classList.remove('tamil-font');
  //     }
  //   }
  //   // this.cdr.detectChanges();
  // }

  setLanguageOld(language: string): void {
    this.translateService.use(language);
    if (language === 'en') {
      this.selectedLanguage = 'English';
    } else if (language === 'ta') {
      this.selectedLanguage = 'Tamil';
    }
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  toggleFullScreen() {
    const sf = screenfull as any; // cast to any to avoid typescript errors
    if (sf.isEnabled) {
      sf.toggle();
      this.isFullScreen = !this.isFullScreen;
    }
  }
}

<div class="Main">
  <div class="container-fluid mt-3">
    <div class="row g-xl-2 card-content">
      <div class="col-lg-2 col-sm-6 card-sec">
        <div class="card card2">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <img src="../../../assets/img/Group 290.png" alt="" style="margin-right: 8px; width: 65px;" />
              </div>
              <div class="col-lg-8">
                <p>SNA Balance (In Crores)</p>
                <h5 class="funds" style="margin-top: 28%;">{{fundDetails?.totalFundReceived /10000000 | number:
                  '1.2-2'}} </h5>
              </div>
            </div>
          </div>
        </div>
      </div >
      <div class="col-lg-2 col-sm-6 card-sec">
        <div class="card card3">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <img src="../../../assets/img/Group 299.png" style="margin-right: 8px; width: 65px;" alt="" />
              </div>
              <div class="col-lg-8">
                <p> Total No of Projects</p>
                <h5 class="funds" style="margin-top: 28%;">{{fundDetails?.totalNoOfProjects}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6 card-sec">
        <div class="card card4">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <img src="../../../assets/img/Group 293.png" alt="" style="margin-right: 8px; width: 65px;" />
              </div>
              <div class="col-lg-8">
                <p>Total Project Value (In Crores)</p>
                <h5 class="funds" style="margin-top: 28%;">{{fundDetails?.projectValue /10000000 | number: '1.2-2'}}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6 card-sec">
        <div class="card card2">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <img src="../../../assets/img/Group 296.png" alt="" style="margin-right: 8px; width: 65px;" />
              </div>
              <div class="col-lg-8">
                <p>Total Amount Received (In Crores)</p>
                <h5 class="funds">{{(fundDetails?.totalFundReceived + fundDetails?.totalFundSpent) /10000000 | number:
                  '1.2-2'}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6 card-sec">
        <div class="card card3" (click)="getTotalAmount()" style="cursor: pointer;">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <img src="../../../assets/img/Group 400.png" alt="" style="margin-right: 8px; width: 65px;" />
              </div>
              <div class="col-lg-8">
                <p>Total Amount Spent (In Crores)</p>
                <h5 class="funds">{{fundDetails?.totalFundSpent /10000000 |indianRupee }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6 card-sec">
        <div class="card card4">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <img src="../../../assets/img/Group 402.png" alt="" style="margin-right: 8px; width: 65px;" />
              </div>
              <div class="col-lg-8">
                <p>Total Available Balance (In Crores)</p>
                <h5 class="funds">{{fundDetails?.totalFundReceived /10000000 | number: '1.2-2' }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body mt-2">
      <div class="row g-xl-2">
        <div class="col-lg-3">
          <div class="card px-2" style="width: 100%;">
            <div class="p-3">
              <h5 class="dataHeader">
                Project Value(In Crores)
              </h5>
              <h2>
                {{fundDetails?.projectValue /10000000 |indianRupee }}
              </h2>
            </div>
            <p-chart type="bar" [data]="dataProject" [options]="optionsProject" height="273px"></p-chart>
          </div>
        </div>
        <!-- <div class="col-lg-3">
          <div class="card px-2" style="width: 100%;">
            <h4 class="p-3 dataHeader">
              Total Project Value
            </h4>
            <p-chart type="bar" [data]="dataProjectValue" [options]="optionsProjectValue" height="273px"></p-chart>
          </div>
        </div> -->
        <div class="col-lg-3">
          <div class="card px-2" style="width: 100%;">
            <div class="p-3">
              <h5 class="dataHeader">
                Total Amount Received(In Crores)
              </h5>
              <h2>
                {{(fundDetails?.totalFundReceived + fundDetails?.totalFundSpent) /10000000 |indianRupee }}
              </h2>
            </div>
            <p-chart type="line" [data]="AmountReceivedata" [options]="optionsProject" height="273px"></p-chart>
          </div>
        </div>
        <div class="col-lg-3" >
          <div class="card px-2" style="width: 100%;" >
            <div class="p-3">
              <h5 class="dataHeader">
                Total Amount Spent(In Crores)
              </h5>
              <h2>
                {{fundDetails?.totalFundSpent /10000000 |indianRupee }}
              </h2>
            </div>
            <p-chart type="line" [data]="AmountSpentdata" [options]="AmountSpentOptions" height="273px"></p-chart>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card px-2" style="width: 100%;">
            <div class="p-3">
              <h5 class="dataHeader">
                Total Available Balance(In Crores)
              </h5>
              <h2>
                {{fundDetails?.totalFundReceived /10000000 |indianRupee }}
              </h2>
            </div>
            <p-chart type="line" [data]="AmountBalancedata" [options]="AmountBalanceOptions" height="273px"></p-chart>
          </div>
        </div>
        <!-- <div class="col-lg-3">
          <div class="card px-2" style="width: 100%;">
            <h4 class="p-3 dataHeader">
              Land Acquired(In Hectare)
            </h4>
            <p-chart type="bar" [data]="dataSideBar" [options]="optionsSideBar" height="273px"></p-chart>
          </div>
        </div> -->
        <div class="col-lg-3">
          <div class="card px-2" style="width: 100%;">
            <h4 class="p-3 dataHeader">
              District Wise
            </h4>
            <div>
              <canvasjs-chart [options]="options" (chartInstance)="getChartInstance($event)"
                [styles]="{width: '100%', height: '273px'}"></canvasjs-chart>
              <button class="backButton" *ngIf="isButtonVisible" (click)="handleClick($event)">Back</button>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card px-2" style="width: 100%;">
            <div class="p-3">
              <h5 class="dataHeader">
                Top Requisitioning Departments
              </h5>

            </div>
            <p-chart type="bar" [data]="dataBar" [options]="optionsBar" height="273px"></p-chart>
          </div>
        </div>
        <div class="col-lg-6 ">
          <div class="card px-2" style="width: 100%;">
            <div class="stripe">
              <table class="table table-striped ">
                <thead>
                  <tr>
                    <th scope="col" class="p-3">Name of Requisitioning Body</th>
                    <th scope="col" class="p-3">No of Projects Mapped</th>
                    <th scope="col" class="p-3">Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of departments">
                    <td>{{data.requistioningBody}}</td>
                    <td>{{data.noOfProjectMapped}}</td>
                    <td>{{data.totalAmount}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<p-dialog header="Amount Spent Details" [(visible)]="isShowAmount" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false"
  [resizable]="false">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Beneficiary Payment</th>
        <th scope="col">Court DD Payment</th>
        <th scope="col">Return Payment</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{getAmountSpentList?.beneficiaryPayments | indianRupee }}</td>
        <td>{{getAmountSpentList?.ddPayments | indianRupee}}</td>
        <td>{{getAmountSpentList?.returnAmount  ?? '' | indianRupee }}</td>
      </tr>
    </tbody>
  </table>
</p-dialog>
<div class="hamburger">
    <h2>{{heading}}</h2>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <p-toolbar styleClass="toolbar">
          <ng-template pTemplate="left">
          </ng-template>
          <ng-template pTemplate="right">
            <button class="btn btn-primary" styleClass="button" (click)="openNew()">
              Add +
            </button>
          </ng-template>
        </p-toolbar>
      </div>
    </div>
    </div>

    <p-dialog #dialog [(visible)]="limitAllocationDialog" [modal]="true" styleClass="ib-dialog" [style]="{ width: '750px' }"
    (onHide)="hideDialog()">
    <p-header [ngClass]="fontService.headingClass">
  Budget Allocation
    </p-header>
    <ng-template pTemplate="content">
      <div class="col-12">
        <div class="form-inner-panel">

          <div class="row gy-2">
            <div class="col-sm-6 col-md-6">
                <label>Department</label>
                <p style="font: bold;">LAND ACQUISITION AGENCY OF TAMILNADU</p>
             
            </div>
            <div class="col-sm-6 col-md-6">
                <label class="form-control-label" for="date">Fin Year</label>
                <ng-select id="gender" bindLabel="name" [items]="finYear" appearance="outline"
                    [closeOnSelect]="true" [clearable]="false" class="form-control">
                </ng-select>
            </div>
            <div class="col-sm-6 col-md-6">
                <label class="form-control-label req" [ngClass]="fontService.labelClass">Category</label>
                <ng-select id="department" [items]="categoryList" bindLabel="name" appearance="outline"  bindValue="name" [(ngModel)]="categoryData" (change)="onChangeCategory($event)"
                    [closeOnSelect]="true" [clearable]="true" class="form-control">
                </ng-select>
            </div>
            <div class="col-sm-6 col-md-6">
                <label>Limit Amount</label>
                <p class="mt-2">10000</p>
             
            </div>
            <div class="col-sm-6 col-md-6" *ngIf="isType">
                <label class="form-control-label req" [ngClass]="fontService.labelClass">Government
                    Type</label>
                <ng-select id="department" [items]="govermentType" bindLabel="name" appearance="outline"
                    [closeOnSelect]="true" [clearable]="true" class="form-control">
                </ng-select>
            </div>
            <div class="col-sm-6 col-md-6" *ngIf="isType">
                <label class="form-control-label req" [ngClass]="fontService.labelClass">Agency</label>
                <ng-select id="department" [items]="agencyList" bindLabel="name" appearance="outline"
                    [closeOnSelect]="true" [clearable]="true" class="form-control">
                </ng-select>
            </div>
            <div class="col-sm-6 col-md-6" *ngIf="isProject">
                <label class="form-control-label req" [ngClass]="fontService.labelClass">Project</label>
                <ng-select id="department" [items]="projectList" bindLabel="name" appearance="outline"
                    [closeOnSelect]="true" [clearable]="true" class="form-control">
                </ng-select>
            </div>
            <div class="col-sm-6 col-md-6">
                <label>Amount</label>
               <input class="form-control" />
             
            </div>
          </div>
        
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button icon="pi pi-times" class="btn btn-primary" (click)="hideDialog()">
        <span class="button-label" [ngClass]="fontService.buttonClass" custom-button >Cancel
        </span>
      </button>
      <button  icon="pi pi-check" class="btn btn-primary" (click)="saveLimit()">
        <span class="button-label" [ngClass]="fontService.buttonClass" > Save</span>
      </button>
    </ng-template>
  </p-dialog>
  <p-dialog #dialog [(visible)]="limitcorrectionDialog" [modal]="true" styleClass="ib-dialog" [style]="{ width: '750px' }"
  (onHide)="hideDialog()">
  <p-header [ngClass]="fontService.headingClass">
Budget Correction
  </p-header>
  <ng-template pTemplate="content">
    <div class="col-12">
      <div class="form-inner-panel">

        <div class="row gy-2">
          <div class="col-sm-6 col-md-6">
              <label>Department</label>
              <p style="font: bold;">LAND ACQUISITION AGENCY OF TAMILNADU</p>
           
          </div>
          <div class="col-sm-6 col-md-6">
              <label class="form-control-label" for="date">Fin Year</label>
              <ng-select id="gender" bindLabel="name" [items]="finYear" appearance="outline"
                  [closeOnSelect]="true" [clearable]="false" class="form-control">
              </ng-select>
          </div>
          <div class="col-sm-6 col-md-6">
              <label class="form-control-label req" [ngClass]="fontService.labelClass">Category</label>
              <ng-select id="department" [items]="categoryList" bindLabel="name" appearance="outline"  bindValue="name" [(ngModel)]="categoryData" (change)="onChangeCategory($event)"
                  [closeOnSelect]="true" [clearable]="true" class="form-control">
              </ng-select>
          </div>
          <div class="col-sm-6 col-md-6">
              <label>Limit Amount</label>
              <p class="mt-2">10000</p>
           
          </div>
          <div class="col-sm-6 col-md-6" *ngIf="isType">
              <label class="form-control-label req" [ngClass]="fontService.labelClass">Government
                  Type</label>
              <ng-select id="department" [items]="govermentType" bindLabel="name" appearance="outline"
                  [closeOnSelect]="true" [clearable]="true" class="form-control">
              </ng-select>
          </div>
          <div class="col-sm-6 col-md-6" *ngIf="isType">
              <label class="form-control-label req" [ngClass]="fontService.labelClass">Agency</label>
              <ng-select id="department" [items]="agencyList" bindLabel="name" appearance="outline"
                  [closeOnSelect]="true" [clearable]="true" class="form-control">
              </ng-select>
          </div>
          <div class="col-sm-6 col-md-6" *ngIf="isProject">
              <label class="form-control-label req" [ngClass]="fontService.labelClass">Project</label>
              <ng-select id="department" [items]="projectList" bindLabel="name" appearance="outline"
                  [closeOnSelect]="true" [clearable]="true" class="form-control">
              </ng-select>
          </div>
          <div class="col-sm-6 col-md-6">
            <label>Allocated Amount</label>
            <p class="mt-2">10000</p>
           <!-- <input class="form-control" /> -->
         
        </div>
          <div class="col-sm-6 col-md-6">
              <label>Amount</label>
             <input class="form-control" />
           
          </div>
        </div>
      
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button icon="pi pi-times" class="btn btn-primary" (click)="hideDialog()">
      <span class="button-label" [ngClass]="fontService.buttonClass" custom-button >Cancel
      </span>
    </button>
    <button  icon="pi pi-check" class="btn btn-primary" (click)="saveLimit()">
      <span class="button-label" [ngClass]="fontService.buttonClass" > Save</span>
    </button>
  </ng-template>
</p-dialog>
<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Payment Report</h2>
</div>
<div class=" bg">
    <div class="row">
        <div class="col-lg-12" style="overflow-x: scroll;">
            <table>
                <tr style="background: #017DC3;">
                    <th colspan="15" class="text-center">
                        Name of the LAO:{{designation}}
                    </th>
                </tr>
                <tr style="background: #017DC3;">
                   
                    <th colspan="3" style="width:100px;background: #017DC3;" class="text-center">
                        Details of the Project
                    </th>
                    <th colspan="4" class="text-center" style="width:100px;background: #017DC3;">
                        Details of the Amount Deposited
                    </th>
                    <th *ngIf="!roleId">
                    </th>
                  
                    <th rowspan="2" style="background: #017DC3;">
                        Balance of 10%(Not Allowed)
                    </th>
                    <th rowspan="2" style="background: #017DC3;">
                        Balance of 90%(Allowed)
                    </th>
                    <th rowspan="2" style="background: #017DC3;">
                        Total Balance Amount
                    </th>
                </tr>
                <tr>
                    <th style="background: #017DC3;width: 50px; ">
                        <span class="pro">PROJECTS</span>
                    </th>
                    <th style="background: #017DC3;width: 50px;">District Name
                    </th>
                    <th style="background: #017DC3; width: 50px;border-right: 1px solid white;">LAO Name</th>
                    <th style="background: #017DC3;width: 50px">
                        Amount
                    </th>
                    <th style="background: #017DC3;width: 50px">
                        Date of Deposit
                    </th>
                    <th style="background: #017DC3;width: 50px">
                        Deposit Mode
                    </th>
                    <th style="background: #017DC3;width: 50px">
                        Reference
                    </th>
                    <th *ngIf="!roleId" colspan="1" class="text-center"
                        style="width:100px;background: #017DC3;border-left: 1px solid white;">
                        Land Owner Particulars
                    </th>
                  
                </tr>

                <tbody>
                    <tr *ngFor='let data of projectList;let i =index'>
                        <!-- <td>{{generateIndex(i)}}</td> -->
                        <td *ngIf="roleId">{{data.departmentType == 'CENTRAL_GOVERNMENT'? "CENTRAL GOVERNMENT":"STATE
                            GOVERNMENT"}}</td>
                        <td *ngIf="roleId">{{data.requestingAgency.agencyName}}</td>
                        <td>{{data.projectName}}</td>
                        <td>{{data.projectLocation.district}}</td>
                        <td>{{data.projectLocation.lao}}</td>
                        <td>{{data.detailsOfAmountDeposited.amount}}</td>
                        <td>{{data.detailsOfAmountDeposited.dateOfDeposit | date}}</td>
                        <td>{{data.detailsOfAmountDeposited.transferMode}}</td>
                        <td>{{data.detailsOfAmountDeposited.reference}}</td>
                        <td *ngIf="!roleId" class="text-center">
                            <button class="btn btn-primary search" (click)="getLandOwner(data.beneficiaryLandOwner)">
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </button>
                        </td>
                     
                        <td>{{data.balanceOf10Per}}</td>
                        <td>{{data.balanceOf90Per}}</td>
                        <td>{{data.totalBalance}}</td>
                    </tr>
                </tbody>
            </table>
            <p-paginator [rows]="itemsPerPage" [totalRecords]="totalCount" (onPageChange)="onPageChange($event)"
                [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
        </div>
    </div>
</div>

<p-dialog #dialog [(visible)]="isShowpopUp" header="Beneficiary Details" [style]="{ width: '850px' }" [modal]="true"
    [closable]="true" styleClass="ib-dialog" appendTo="body">
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Amount</th>
                <th scope="col">Date of Transfer</th>
                <th scope="col">Reference</th>
                <th scope="col">Transfer Mode</th>
            </tr>
        </thead>
        <tbody *ngIf="selectBeneficiary !='' ">
            <tr *ngFor="let data of selectBeneficiary">
                <td>{{data.beneficiaryName}}</td>
                <td>{{data.amount}}</td>
                <td>{{data.dateOfTransfer | date}}</td>
                <td>{{data.reference}}</td>
                <td>{{data.transferMode}}</td>
            </tr>
        </tbody>
        
    </table>
    <div *ngIf="selectBeneficiary =='' ">
        <img src="../../../assets/img/notFound.png" width="300px" height="300px" alt="">
    </div>
</p-dialog>
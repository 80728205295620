<div class="mt-2">
    <button class="btn btn-primary" (click)="rdservice()">Get RDService Info</button>
    <pre>{{ response }}</pre>
</div>

<div *ngIf="scannerReady" class="mt-2">
    <div class="row">
        <div class="col-md-3"> <label class="form-control-label"
                jhiTranslate="vendor.aadhaarno" for="aadhaar">Aadhaar Number</label>
            <input type="text" class="form-control" name="aadhaar" autocomplete="off" id="aadhaar"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                [(ngModel)]="aadhaarNo" required autofocus maxlength="12" />
        </div>
    </div>
    <div *ngIf="returnPayment">
        <div  class="col-md-3 mt-4">
            <button class="btn btn-success p-2" (click)="registerFingerprintreturnPayment()">Return Payment</button>
        </div>
        <div class="mt-3">
            <h5>{{registrationStatusField}}</h5>
        </div>
    </div>
    <div *ngIf="!returnPayment">
        <div  class="col-md-3 mt-4">
        <button class="btn btn-success p-2" (click)="registerFingerprint()">Capture Biometric</button>
    </div>
    <div class="mt-3">
        <h5>{{registrationStatusField}}</h5>
    </div>
    </div>
</div>
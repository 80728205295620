<div class="hamburger">
    <h2>{{hamHeader}}</h2>
</div>
<div class="container-fluid bg">
    <div class="tableDesign">
        <div class="row px-4 bgFilter align-items-center">
            <div class="col-sm-4 col-md-3">
                <label class="form-control-label req">Project
                    Name</label>
                <ng-select id="department" bindLabel="projectName" (change)="selectProject($event)" appearance="outline"
                    [items]="projectList" [(ngModel)]="fundFilter" class="form-control" appNgSelectInputRestriction>
                </ng-select>
            </div>
        </div>
    </div>
    <div class="tableDesign" *ngIf="showTable">
        <div class="row mt-3">
            <div #customerTable id="entities" class="col-md-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">S.No
                            </th>
                            <!-- <th scope="col">Beneficiary Name <span
                                    (click)="loadPage(page,selecTProjectId,'benificiaryLandOwnerName')"><i
                                        class="fa fa-sort"></i> </span>
                            </th> -->
                            <th style="background-color: #017DC3;" scope="col">Project Name <span><i
                                        class="fa fa-sort"></i> </span>
                            </th>
                            <th style="background-color: #017DC3;" scope="col">Voucher Number <span><i
                                        class="fa fa-sort"></i> </span>
                            </th>
                            <th style="background-color: #017DC3;" scope="col">Total Extent <span><i
                                        class="fa fa-sort"></i> </span>
                            </th>

                            <!-- <th scope="col">Mobile
                                Number <span (click)="loadPage(page,selecTProjectId,'mobileNumber')"><i
                                        class="fa fa-sort"></i> </span>
                            </th>
                            <th scope="col">Bank Name <span (click)="loadPage(page,selecTProjectId,'nameOfBank')"><i
                                        class="fa fa-sort"></i> </span>
                            </th>
                            <th scope="col">Account Number <span
                                    (click)="loadPage(page,selecTProjectId,'accountNumber')"><i class="fa fa-sort"></i>
                                </span>
                            </th>
                            <th scope="col">Aadhaar Number <span
                                    (click)="loadPage(page,selecTProjectId,'aadharNumber')"><i class="fa fa-sort"></i>
                                </span>
                            </th>
                            <th scope="col">Amount <span (click)="loadPage(page,selecTProjectId,'totalAmount')"><i
                                        class="fa fa-sort"></i> </span>
                            </th> -->
                            <th style="background-color: #017DC3;" scope="col">Total Count <span><i
                                        class="fa fa-sort"></i> </span>
                            </th>
                            <th style="background-color: #017DC3;" scope="col">Total Amount <span><i
                                        class="fa fa-sort"></i> </span>
                            </th>
                            <th style="background-color: #017DC3;" scope="col">Status <span><i class="fa fa-sort"></i>
                                </span>
                            </th>
                            <th scope="col">Payment
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of paymentList;let i =index">
                            <td>{{generateIndex(i)}}</td>
                            <td>{{data.projectName}}</td>
                            <td>{{data.voucherNumber}}</td>
                            <td>{{data.totalExtentOwned}}</td>
                            <td>{{data.count}}</td>
                            <td>{{data.sumOfTotalAmount}}</td>
                            <td>{{data.status}}</td>
                            <td>
                                <p-checkbox [binary]="true" [(ngModel)]="checkData[i]" inputId="binary{{i}}"
                                    (onChange)="generateVoucher($event,data,i);updateVoucher(data);"></p-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center" *ngIf="totalCount == 0">
                    <img src="../../../assets/img/notFound.png" alt="">
                </div>
                <div *ngIf="totalCount != 0">
                    <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)" [totalRecords]="totalCount"
                        [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
                    <div class="row">
                        <div class="col-md-4">
                        </div>
                        <div class="col-md-4">
                            <button [disabled]="enableGenerateVoucher" class="btn btn-success p-2 mr-3"
                                (click)="submit('approve');">Approve</button>
                            <!-- <button [disabled]="enableGenerateVoucher" (click)="confirm1($event,'revert')"
                                class="btn btn-primary p-2 mx-3">Revert</button> -->
                            <button [disabled]="enableGenerateVoucher" (click)="confirm1($event,'rejected')"
                                class="btn btn-danger p-2 mx-2">Reject</button>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col">
                                    <!-- <button *ngIf="!disableBioMetric && enableAllBiometric"
                                        [disabled]="!enableGenerateVoucher" (click)="openNew()"
                                        class="btn btn-primary p-2 mx-3">Bio-metric Signature</button> -->
                                        <!-- *ngIf="enableAllBiometric && disableBioMetric" -->
                                    <button 
                                    *ngIf="enableEsign && !enableSignatureButton"
                                        [disabled]="!enableGenerateVoucher" (click)="enabledSignature()"
                                        class="btn btn-primary p-2 mx-3">Enable Signature</button>
                                </div>
                                <div class="col">
                                    <!-- action="https://demosignergateway.emsigner.com/eMsecure/V3_0/Index"  -->
                                    <form *ngIf='enableSignatureButton && enableAllBiometric && perform' ngNoForm
                                        action="https://emgateway.emsigner.com/eMsecure/V3_0/Index" method="POST">
                                        <input id="Parameter1" name="Parameter1" type="hidden" [value]='param1' />
                                        <input id="Parameter2" name="Parameter2" type="hidden" [value]='param2' />
                                        <input id="Parameter3" name="Parameter3" type="hidden" [value]='param3' />
                                        <button pButton pRipple onclick="submit()" type="submit"
                                            class="p-button-success custom-button approve_btn">
                                            <span class="button-label" [ngClass]="fontService.buttonClass">perform
                                                E-sign</span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-dialog header="Payment Details" [(visible)]="visible" [modal]="true" [style]="{ width: '85vw' }" [draggable]="false"
    [resizable]="false">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Voucher Number</th>
                <th scope="col">Owned By Land Owner</th>
                <th scope="col">Total Amount</th>
                <th scope="col">Bank</th>
                <th scope="col">Branch</th>
                <th scope="col">Account Number</th>
                <th scope="col">Ifscode</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of availableAmount">
                <td>{{data.name}}</td>
                <td>{{data.mobileNumber}}</td>
                <td>{{data.voucherNumber}}</td>
                <td>{{data.ownedByLandOwner}}</td>
                <td>{{data.amount}}</td>
                <td>{{data.bankName}}</td>
                <td>{{data.branchName}}</td>
                <td>{{data.accountNumber}}</td>
                <td>{{data.ifscCode}}</td>
            </tr>
        </tbody>
    </table>
    <div>
        <p-button label="Approve" class="submit mx-auto" (click)="submitPayment()"></p-button>
    </div>
</p-dialog>
<p-dialog #dialog [(visible)]="signaturedialog" [modal]="true" styleClass="ib-dialog" [style]="{ width: '850px' }"
    (onHide)="hideDialog()">
    <p-header [ngClass]="fontService.headingClass">
        Implement FingurPrint
    </p-header>
    <ng-template pTemplate="content">
        <div class="col-12">
            <div class="form-inner-panel">
                <div class="row gy-2">
                    <!-- src/app/biometric-registration/biometric-registration.component.html -->
                    <app-biometric-test [scannerReady]="scan" [registrationStatusField]="figureData">
                        <!-- <div *ngIf="scannerReady">
          <h2  class="mb-2">Scanner is ready</h2>
          <button (click)="registerFingerprint()">Register Fingerprint</button>
          <div *ngIf="registrationStatus">
            <p>{{ registrationStatus }}</p>
          </div>
        </div>
        <div *ngIf="!scannerReady">
          <h2>Scanner is not ready</h2>
        </div> -->
                    </app-biometric-test>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button icon="pi pi-times" class="btn btn-primary" (click)="hideDialog()">
            <span class="button-label" [ngClass]="fontService.buttonClass" custom-button
                jhiTranslate="common.cancelbutton">
            </span>
        </button>
    </ng-template>
</p-dialog>
<p-dialog [(visible)]="paymentSucessDialog" [style]="{ width: '500px', height: '225px' }" [modal]="true"
    [baseZIndex]="1000" styleClass="ib-dialog">
    <p-header>
        Payment Alert Box
    </p-header>
    <h1 style="text-align: center; font-size: 18px">
        {{ paymentSucessText }}
    </h1>
    <ng-template pTemplate="footer">
        <button pButton pRipple class="p-button-text custom-button" (click)="paymentSucessDialog = false">

            <span class="button-label" label="Ok" (click)=" redirectsuccess()">Ok</span>
        </button>
    </ng-template>
</p-dialog>
<p-confirmDialog></p-confirmDialog>
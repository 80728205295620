import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { Component, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { NavbarService } from './layout/navbar/navbar.service';
import { HttpResponse } from '@angular/common/http';
import { NotificationService } from './alert/notification.service';
import { SigninService } from './auth/signin/signin.service';
import { SharedService } from './shared/service/master-data-share-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ib-sna-developement';
  inactivityTimeout:any;
  currentRoute: string | undefined;
  visible: boolean = false
  showHead: boolean | undefined; 
  userName: any;
  roleName: any;
  userProfile: any;
  constructor(private router: Router,private location: Location, 
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private loginService: SigninService,
    private navbarService: NavbarService,
    private sharedService:SharedService,
    private notificationService: NotificationService) {
    // on route change to '/login', set the variable showHead to false
      router.events.forEach((event) => {
        if (event instanceof NavigationEnd) {
          if (event['url'] == '/login' || event['url'] == '/' || event['url'].startsWith('/error') || event['url'].startsWith('/generic-error')
          || event['urlAfterRedirects'].startsWith('/error')
          || event['url'].startsWith('/ForgotPassword') || event['url'].startsWith('/ResetPassword')) {
            this.showHead = false;
          } else {
            this.showHead = true;
          }
        }
      });
    }

  async ngOnInit() {
    const id = this.router.routerState.snapshot.root;
    const token: string | null =
    this.localStorageService.retrieve('authenticationToken') ?? this.sessionStorageService.retrieve('authenticationToken');
    if (token) {
      //console.log("token not null", token);
      try {
        await this.loginService.preStatus().toPromise();
        await this.sharedService.getMasterService();
      } catch (error) {
      }
    }else{
      //console.log('token null not getting any master service..');
    }

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        //console.log('event',event)
      }
    });
  }

   @HostListener("window:mousemove")
  @HostListener("window:keydown")
  resetInactivityTimer() {
    clearTimeout(this.inactivityTimeout);
    this.inactivityTimeout = setTimeout(() => {
      this.logout();
    }, 600000); // Set the desired time in milliseconds (e.g., 10 mins). - 600000
  }

  // @HostListener("window:beforeunload", ["$event"])
  // onBeforeUnload(event: BeforeUnloadEvent) {
  //   if(this.localStorageService.retrieve('refresh')){
  //     this.localStorageService.clear('refresh');
  //     return;
  //   }
  //   this.logout();
  // }

  @HostListener("window:popstate", ["$event"])
  onPopState(event: any) {
    event.preventDefault();
    this.logout();
  }

  @HostListener("contextmenu", ["$event"])
  onContextMenu(event: MouseEvent) {
    event.preventDefault();
  }
  @HostListener("paste", ["$event"]) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener("copy", ["$event"]) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener("cut", ["$event"]) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
 
  logout(): void {
    this.navbarService.logout().subscribe(
      (res: HttpResponse<any>) => {},
      (err) => {}
    );
    this.localStorageService.clear();
    this.sessionStorageService.clear();
    localStorage.clear();
    this.router.navigate([""]);
  }

  showDialog() {
    this.loginService.getProfile().subscribe(
      (res) => {
        this.userProfile = res.body.data
        this.visible = true
      }, (err) => {
        this.notificationService.alertError(err.error.error, '');
      })
  }
  // async logoutByPromise(): Promise<string> {
  //   return new Promise<string>(
  //     (resolve) => {
  //       this.navbarService.logout().subscribe(
  //         () => {
  //           resolve('SUCCESS')
  //         },
  //         () => {
  //           resolve(null);
  //         }
  //       );
  //     }
  //   );
  // }
}

<div class="hamburger">
    <h2>Land Details</h2>
</div>
<div class="bg">
    <div class="tableDesign">
        <div class="row">
            <div class="col-md-12">
                <p-toolbar styleClass="toolbar">
                    <ng-template pTemplate="left">
                        <h5 [ngClass]="fontService.headingClass">
                            Land
                        </h5>
                    </ng-template>
                    <ng-template pTemplate="right">
                        <!-- <button pButton pRipple icon="pi pi-plus" iconPos="left"
                            class="p-button-secondary custom-button new-button" (click)="openNew('new','')"
                            styleClass="button">
                            <span class="button-label" [ngClass]="fontService.buttonClass"
                                jhiTranslate="common.newbutton"></span>
                        </button> -->
                    </ng-template>
                </p-toolbar>
            </div>
        </div>
        <div class="row">
            <div class="container bgFilter mb-3">
                <div class="row align-items-center mt-2">
                    <div class="col-sm-4 col-md-3">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass">Project
                            Name</label>
                        <ng-select id="department" bindLabel="projectName" bindValue='id'
                            [(ngModel)]="stage1Filter.projectName" [items]="projectList" appearance="outline"
                            class="form-control" appNgSelectInputRestriction>
                        </ng-select>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass">Survey
                            Number</label>
                        <input type="text" class="form-control" name="name" [(ngModel)]="stage1Filter.surveyNo"
                            autocomplete="off" maxlength="60" id="name" appSerachInputRestriction/>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass">Subdivision
                            Number</label>
                        <input type="text" class="form-control" [(ngModel)]="stage1Filter.subdivision" name="name"
                            autocomplete="off" maxlength="60" id="name" appSerachInputRestriction/>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass">Village
                            Name</label>
                        <input type="text" class="form-control" name="name" [(ngModel)]="stage1Filter.villageName"
                            autocomplete="off" maxlength="60" id="name" appSerachInputRestriction/>
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass">Taluk Name</label>
                        <input type="text" class="form-control" [(ngModel)]="stage1Filter.talukName" name="name"
                            autocomplete="off" maxlength="60" id="name" appSerachInputRestriction/>
                    </div>
                </div>
                <div class="flex align-items-center mt-3 ">
                    <p-button label="Submit" class="submit" (click)="loadPage()"></p-button>
                </div>
            </div>
            <div #customerTable id="entities" class="col-md-12">
                <div class="table-scroll">
                    <table class="table">
                        <thead>
                            <tr>
                                <th rowspan="2">S.No</th>
                                <th rowspan="2">Project Name <span (click)="loadPage(page,'projectName')"><i
                                            class="fa fa-sort"></i> </span> </th>
                                <th rowspan="2">Taluk <span (click)="loadPage(page,'talukName')"><i class="fa fa-sort"></i>
                                    </span></th>
                                <th rowspan="2">Village <span (click)="loadPage(page,'villageName')"><i
                                            class="fa fa-sort"></i> </span></th>
                                <th rowspan="2">Survey
                                    Number <span (click)="loadPage(page,'surveyNumber')"><i class="fa fa-sort"></i> </span>
                                </th>
                                <th rowspan="2">Subdivision
                                    Number <span (click)="loadPage(page,'subDivisionNumber')"><i class="fa fa-sort"></i>
                                    </span></th>
                                <th rowspan="2">Part Subdivision
                                    Number <span (click)="loadPage(page,'partSubDivisionNumber')"><i class="fa fa-sort"></i>
                                    </span></th>
                                <th rowspan="2" class="text-center">Total extent of
                                    the Survey
                                    Number as per
                                    Tamilnilam <span (click)="loadPage(page,'totalExtentAsPerTamilnilam')"><i
                                            class="fa fa-sort"></i> </span></th>
                                <th rowspan="2">Acquired
                                    extent as per
                                    Award
                                    (In hectares) <span (click)="loadPage(page,'acquiredExtent')"><i class="fa fa-sort"></i>
                                    </span> </th>
                                <th colspan="2" class="text-center">
                                    Land acquired
                                    under which
                                    mode? (In
                                    hectares)
                                </th>
                                <th colspan="2" class="text-center">
                                    Compensation
                                    Determined (In
                                    Rupees)
                                </th>
    
                                <th rowspan="2">
                                    Total
                                    Compensation
                                    determined for
                                    this survey
                                    number <span (click)="loadPage(page,'totalCompensation')"><i class="fa fa-sort"></i>
                                    </span>
                                </th>
                                <th rowspan="2">
                                    Action
                                </th>
    
    
                            </tr>
                            <tr>
                                <th>As per Private
                                    Negotiation <span (click)="loadPage(page,'asPerPrivateNegotiation')"><i
                                            class="fa fa-sort"></i> </span></th>
                                <th>As
                                    per Act <span (click)="loadPage(page,'asPerActExtent')"><i class="fa fa-sort"></i>
                                    </span></th>
                                <th>
                                    As Per Private Negotiation Cost <span
                                        (click)="loadPage(page,'asPerPrivateNegotiationCost')"><i class="fa fa-sort"></i>
                                    </span>
                                </th>
    
                                <th>
                                    As Per Act Cost <span (click)="loadPage(page,'asPerActCost')"><i class="fa fa-sort"></i>
                                    </span>
                                </th>
    
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of beneficary;let i=index">
                                <td>{{generateIndex(i)}}</td>
                                <td>{{data.projectId.projectName}}</td>
                                <td>{{data.taluk.name}}</td>
                                <td>{{data.village.name}}</td>
                                <td>{{data.surveyNumber}}</td>
                                <td>{{data.subDivisionNumber}}</td>
                                <td>{{data.partSubDivisionNumber ?? '-'}}</td>
                                <td>{{data.totalExtentAsPerTamilnilam}}</td>
                                <td>{{data.acquiredExtent }}</td>
                                <td>{{data.asPerPrivateNegotiation }}</td>
                                <td>{{data.asPerActExtent }}</td>
                                <td>{{data.asPerPrivateNegotiationCost }}</td>
                                <td>{{data.asPerActCost }}</td>
                                <td>{{data.totalCompensation }}</td>
                                <td>
                                    <a class="cursor-pointer" (click)="edit(data)"> Edit </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
             
                <div class="text-center" *ngIf="totalCount == 0">
                    <img src="../../../assets/img/notFound.png" width="419px" alt="">
                </div>
                <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)" [totalRecords]="totalCount"
                    [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>

            </div>
        </div>
    </div>

    <!-- Export to Excel and pdf is removed -->
    <!--     <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <button pButton pRipple icon="pi pi-file-excel" class="p-button-help custom-button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.exportexcel"></span>
                    </button>
                    <button pButton pRipple icon="pi pi-file-pdf" class="p-button-help custom-button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.exportpdf"></span>
                    </button>
                </ng-template>
                <ng-template pTemplate="right">
                     <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success" (click)="openNew()"
                        styleClass="button"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </div> -->
    <p-dialog #dialog [(visible)]="agencyDialog" [style]="{ width: '850px' }" [modal]="true" [closable]="true"
        (onHide)="hideDialog()" styleClass="ib-dialog" appendTo="body">
        <p-header [ngClass]="fontService.headingClass">
            Land Details
        </p-header>
        <div class="row align-items-end">
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">Survey No</label>
                <input type="text" id="agencyName" class="form-control" name="agencyName"
                    (ngModelChange)="checkName()" maxlength="100" style="background: lightgray;" autocomplete="off" readonly
                    [(ngModel)]="stage1.survey" />
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">Sub Division Number</label>
                <input type="text" id="agencyName" class="form-control" name="agencyName"
                    (ngModelChange)="checkName()" maxlength="100" style="background: lightgray;" autocomplete="off" readonly
                    [(ngModel)]="stage1.subdivision" />
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">Total extent of
                    the Survey
                    Number as per
                    Tamilnilam</label>
                <input type="text" id="agencyName" class="form-control" name="agencyName"
                    (ngModelChange)="checkName()" maxlength="100" style="background: lightgray;" autocomplete="off" readonly
                    [(ngModel)]="stage1.tamilnilam" />
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">Acquired
                    extent as per
                    Award
                    (In hectares)</label>
                <input type="number" id="agencyName" class="form-control" name="agencyName"
                    (ngModelChange)="checkName()" maxlength="100" style="background: lightgray;" autocomplete="off" readonly
                    [(ngModel)]="stage1.acquireExtent" appNumberonly/>
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">As Per Act Extent</label>
                <input type="number" id="agencyName" class="form-control" name="agencyName"
                    (ngModelChange)="checkName()" maxlength="100" autocomplete="off" style="text-transform:uppercase"
                    [(ngModel)]="stage1.asPerActExtent" appNumberonly/>
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">As Per Private Negotiation</label>
                <input type="number" id="agencyName" class="form-control" name="agencyName"
                    (ngModelChange)="checkName()" maxlength="100" autocomplete="off"
                    onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                    [(ngModel)]="stage1.asPerPrivateNegotiation" appNumberonly/>
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">As Per Act Cost</label>
                <input type="number" id="agencyName" class="form-control" name="agencyName"
                    (ngModelChange)="checkName()" maxlength="100" autocomplete="off"
                    onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                    [(ngModel)]="stage1.asPerActCost" appNumberonly/>
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">As Per Private Negotiation Cost</label>
                <input type="number" id="agencyName" class="form-control" name="agencyName"
                    (ngModelChange)="checkName()" maxlength="100" autocomplete="off"
                    onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                    [(ngModel)]="stage1.asPerPrivateNegotiationCost" />
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">Total Compensation</label>
                <input type="number" id="agencyName" class="form-control" name="agencyName"
                    (ngModelChange)="checkName()" maxlength="100" autocomplete="off"
                    onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                    [(ngModel)]="stage1.totalCompensation" appNumberonly/>
            </div>

        </div>
        <ng-template pTemplate="footer">
            <div>
                <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button saveButton"
                    (click)="onSubmit()" type="submit">
                    <span class="button-label">Submit</span>
                </button>
            </div>
        </ng-template>


    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { User, userDto } from './checker';
import { ResetPassword } from 'src/app/auth/reset-password/authuser';
export type EntityArrayResponseType = HttpResponse<User[]>;
@Injectable()
export class UserService {
  protected resourceFliterUrl = environment.SERVER_API_URL + ('api/getAdminUserByFilter');
  protected roleUrl = environment.SERVER_API_URL + ('api/roleList');
  protected getUserDtoUrl = environment.SERVER_API_URL + ('api/getAdminUserDtoObject')
  protected resourceSaveUrl = environment.SERVER_API_URL + ('api/SaveAdminUser');
  protected updateSaveUrl = environment.SERVER_API_URL + ('api/updateUser');
  protected resourceEditUrl = environment.SERVER_API_URL + ('api/getAdminUser');
  protected beneficiary = environment.SERVER_API_URL + ('api/benificiaryLandOwner/getProjectIdWithPaymentStatus');

  protected filterUrl = environment.SERVER_API_URL + ('api/project/getList')
  protected stage1 = environment.SERVER_API_URL + ('api/projectDetailLocation/getByProjectId')
  protected stage2 = environment.SERVER_API_URL + ('api/projectSurvey/getByProjectId')
  protected stage3 = environment.SERVER_API_URL + ('api/benificiaryLandOwner/getByProjectId')
  protected stage4 = environment.SERVER_API_URL + ('api/benificiaryLandOwner/getProjectIdWithVoucherGeneration')
  protected stage5 = environment.SERVER_API_URL + ('api/benificiaryLandOwner/getProjectIdWithPaymentStatus');
  protected changePasswordUrl = environment.SERVER_API_URL+('api/resetPassword');



  protected resourceDeleteUrl = environment.SERVER_API_URL + ('api/deleteUser');



  constructor(private http: HttpClient) { }



  filter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter == 'stage1') {
      const options = createRequestOption(req);
      return this.http
        .get<any[]>(this.stage1, { params: options, observe: 'response' })
    } else if (filter == 'stage2') {
      const options = createRequestOption(req);
      return this.http
        .get<any[]>(this.stage2, { params: options, observe: 'response' })
    }
    else if (filter == 'stage3') {
      const options = createRequestOption(req);
      return this.http
        .get<any[]>(this.stage3, { params: options, observe: 'response' })
    }
    else if (filter == 'stage4') {
      const options = createRequestOption(req);
      return this.http
        .get<any[]>(this.stage4, { params: options, observe: 'response' })
    }
    else if (filter == 'stage5') {
      const options = createRequestOption(req);
      return this.http
        .get<any[]>(this.stage5, { params: options, observe: 'response' })
    }

  }

  getProjects(): Observable<any> {
    // const options = createRequestOption(req);
    return this.http
      .get<any[]>(this.filterUrl, { observe: 'response' });
  }

  getUserDto(): Observable<any> {
    // const options = createRequestOption(req);
    return this.http
      .get<userDto>(this.getUserDtoUrl, { observe: 'response' });
  }

  getUser(id: number): Observable<HttpResponse<any>> {
    return this.http.get<userDto>(`${this.resourceEditUrl}?id=${id}`, { observe: 'response' });
  }
  getListOfBeneficiary(req): Observable<HttpResponse<any>> {
    const options = createRequestOption(req);
    return this.http.get<any>(`${this.beneficiary}`, { params: options, observe: 'response' });
  }
  changePassword(resetPassword:ResetPassword): any {
    return this.http
    .post(this.changePasswordUrl,resetPassword, {responseType: 'text'  })
  }





}
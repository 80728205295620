import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/alert/notification.service';
import { AuthUser } from './authuser';
import { ResetService } from './reset.service';
import { NavbarService } from 'src/app/layout/navbar/navbar.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { EncryptService } from 'src/app/shared/encrypt.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  login?: AuthUser;
  keyCloakUuid: string;
  public pwdMismatch: boolean = false;
  phide: boolean = true;
  cphide: boolean = true;
  resetPwdForm = this.fb.group({
    password: [null, [Validators.required]],
    confirmPassword: [null, [Validators.required]],
  });
  passwordError: string;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router,
    private resetService: ResetService, private notificationService: NotificationService,
    private navbarService: NavbarService, private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService, private encryptService: EncryptService) { }



  ngOnInit(): void {

  }
  get password() {
    return this.resetPwdForm.get('password')!.value;
  }
  get confirmPassword() {
    return this.resetPwdForm.get('confirmPassword')!.value;
  }
  navToLogin() {
    this.logout();
  }
  submit() {
    this.pwdMismatch = false;
    this.passwordError = null;
    this.login = new AuthUser();
    if (this.resetPwdForm.invalid) {
      return;
    }
    if (
      !this.passwordPolicyValidator(
        this.resetPwdForm.get("password")!.value
      )
    ) {
      // Display an error message or handle the password policy violation
      this.passwordError =
        "Password must be a minimum of 8 characters including Number, Upper, Lower and one special character";
      return;
    }
    if (this.password == this.confirmPassword) {
      this.login.password = this.encryptService.encryptData(this.resetPwdForm.get('password')!.value);
      this.resetService.change(this.login).subscribe(
        (res: any) => {
          this.notificationService.alertSuccess('Changed Successfully', '');
          this.logout();
        },
        (onError) => {
          if(onError.status === 400){
            this.notificationService.alertError(onError.error,'')
          }else{
          this.notificationService.alertError("Password Reset Failed!", "");
        }}
      );
    }
    else {
      this.pwdMismatch = true;
    }

  }

  passwordPolicyValidator(password: string): boolean {
    const minLength = 8;
const maxLength =15;
    const hasUpperCase = /[A-Z]/.test(password);    
    const hasLowerCase = /[a-z]/.test(password);    
    const hasNumeric = /[0-9]/.test(password);      
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password); 
    
 
    return (password.length >= minLength && hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChar) && (password.length<maxLength);
  }

  logout(): void {
    this.navbarService.logout().subscribe(
      (res: HttpResponse<any>) => { },
      (err) => { }
    );
    this.localStorageService.clear();
    this.sessionStorageService.clear();
    this.router.navigate(['']);
  }
}

<div class="row">
  <div class="col-lg-6 col-sm-6 col-12 left-content">
    <div>
      <div class="login">
        <img class="tn-logo" src="/./assets/img/ib-rdp-tng-logo.svg" alt="Image" />
        <h4>Government of Tamil Nadu</h4>

        <h3 class="text-uppercase">Commissionerate of Land Administration</h3>
        <div class="laaten-content">
          <h5 class="text-center text-uppercase">Land Acquisition Agency of Tamil Nadu</h5>
        </div>
        <div class="font-login mb-4 mt-4" style="color: #455a64" *ngIf="!otpverified">
          Forgot Password?
        </div>
        <div class="font-login mb-4 mt-4" style="color: #455a64" *ngIf="otpverified">
          Enter your new Password
        </div>
        <p>Single Nodal <span>Account</span></p>
      </div>
      <div class="mt-3">
        <div class="login-content">
          <div class="col-7 mx-auto login-form">
            <div class="text-center">
              <!-- <img  style="width:40%" src="/./assets/img/logo.png" alt="Image" > -->
            </div>
            <div class="login-content" *ngIf="!otpverified">
              <form class="form" role="form" (ngSubmit)="verifyotp()" [formGroup]="otpForm">
                <div class="form-group mb-4" *ngIf="!showOtp">
                  <label htmlFor="username">Username</label>
                  <input id="username" type="text" id="orangeForm-email" class="form-control full"
                    formControlName="username" uppercase name="username" autocomplete="off" />
                  <div *ngIf="inValidUser" class="text-danger mt-2">
                    <small>Please enter valid user name</small>
                  </div>
                  <div class="row mb-2">
                    <div class="col-7">
                      <div>
                        <label htmlFor="username">Enter Captcha </label>
                        <input id="captcha" type="text" id="captcha" class="form-control full" formControlName="captcha"
                          oninput="this.value = this.value.replace(/[^0-9A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                          onblur="this.value =this.value.trim();" />
                        <div *ngIf="inValidCaptcha" class="text-end mt-2">
                          <small class="text-danger">Incorrect Captcha.Please try again..</small>
                        </div>
                        <div *ngIf="userNameError" class="mt-2">
                          <small style="font-size: 30px;" class="text-danger">{{userNameError}}</small>
                        </div>
                        <div *ngIf="captchaExpireError" class="text-end mt-2">
                          <small class="text-danger">{{captchaExpireError}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-5">
                      <div class="row mt-4">
                        <div class="col-9">
                          <div class="text-center" *ngIf="spinners">
                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                          </div>
                          <div *ngIf="!spinners">
                            <img class="img" [src]="image" />
                          </div>
                        </div>
                        <div class="col-3">
                          <i class="pi pi-refresh pt-2" style="font-size: 1.2rem" (click)="loadCaptcha()"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-4" *ngIf="showOtp">
                  <input id="otp" name="otp" maxlength="6" autocomplete="off" formControlName="otp" type="text"
                    class="form-control full" placeholder="ENTER OTP" />
                    <div *ngIf="otpError" class="text-danger mt-2">
                      <small>{{otpError}}</small>
                  </div>
                  <div class="mt-2">Resend OTP in: {{ otpTimer }} seconds</div>
                </div>
                <div class="row mb-4 submitPass">
                  <button type="submit" *ngIf="showOtp" [disabled]="isSubmitDisabled"  class="btn btn-color btn-secondary submit">
                    Submit
                  </button>
                  <button type="button" [disabled]="disableButton" class="btn btn-secondary btn-color"
                    (click)="sendotp()">
                    {{ otpButtonName }}
                  </button>
                  <button type="button" (click)="navToLogin()" class="btn btn-link btn-sm mt-3">
                    Back to Login
                  </button>
                </div>
                <div class="footer-logo"></div>
              </form>
            </div>
            <div class="login-content" *ngIf="otpverified">
              <form class="form" role="form" (ngSubmit)="submit()" [formGroup]="forgotPwdForm">
                <label htmlFor="username">New Password</label>
                <div class="form-group mb-4 eye-container">
                  <input id="password" name="password" autocomplete="off" formControlName="password"
                    [type]="hide ? 'password' : 'text'" required class="form-control full" />
                  <i class="pi pi-eye" *ngIf="hide" (click)="hide = false"></i>
                  <i class="pi pi-eye-slash" *ngIf="!hide" (click)="hide = true"></i>
                </div>
                <label htmlFor="username">Confirm Password</label>

                <div class="form-group mb-4 eye-container">
                  <input id="confirmPassword" name="confirmPassword" autocomplete="off"
                    formControlName="confirmPassword" class="form-control full" [type]="phide ? 'password' : 'text'"
                    required />
                  <i class="pi pi-eye" *ngIf="phide" (click)="phide = false"></i>
                  <i class="pi pi-eye-slash" *ngIf="!phide" (click)="phide = true"></i>
                </div>
                <div *ngIf="pwdMismatch" class="text-danger mb-2">
                  <small>The passwords you entered do not match. Please
                    re-enter</small>
                </div>
                <div *ngIf="passwordError" class="text-danger mb-2">
                  <small>{{ passwordError }}</small>
                </div>
                <div class="mb-4 submitPass">
                  <button type="submit" [disabled]="!forgotPwdForm.valid" class="btn btn-secondary">
                    Submit
                  </button>
                  <button type="button" (click)="navToLogin()" class="btn btn-link btn-sm mt-3">
                    Back to Login
                  </button>
                </div>
                <div class="footer-logo"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-footer">
      <img style="float: right;" src="../../../assets/img/IOB.png" width="150px" height="50px" alt="">
    </div>
  </div>
  <div class="col-lg-6 col-sm-6 laatnBg">
    <div>
      <img src="../../../assets/img/bg.jpeg" class="w-100" style="height: 100vh;" alt="" />
    </div>
  </div>
</div>
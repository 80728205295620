<nav class="navbar navbar-expand-xl bg-body-tertiary navBg">
  <a class="navbar-brand">
    <div class="col-lg-4 col-md-4 col-sm-6 d-flex">
      <img src="./assets/img/ib-rdp-tng-logo.svg" alt="Tamil Nadu Government" />
      <div>
        <h1 class="head" [ngClass]="{
              'tamil-header-font': selectedLanguage === 'nav.ta',
              'english-header-font': selectedLanguage === 'nav.en'
            }">
          LAATAN
        </h1>
        <h5 class="cla text-uppercase">Land Acquisition Agency of Tamil Nadu</h5>
      </div>
    </div>
  </a>
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div *ngIf="!disableMenu" class="collapse navbar-collapse col-8" style="font-size: 11px;" id="navbarSupportedContent">
    <ul class="navbar-nav ms-auto mb-2 mb-lg-0" *ngIf="!isMaker">
      <li [routerLinkActive]="['active']" class="nav-item mx-2" [hidden]="hideMenu('DASHBOARD')">
        <a routerLink="home" jhiTranslate="nav.dashboard" class="nav-link">Dashboard</a>
      </li>
      <li class="nav-item dropdown" *ngIf="!isChecker">
        <a id="navbarDropdown" [routerLinkActive]="['active']" role="button" data-bs-toggle="dropdown"
          class="nav-link dropdown-toggle show">
          View
        </a>
        <ul _aria-labelledby="navbarDropdown" class="dropdown-menu " data-bs-popper="static">
          <li>
            <a routerLink="agency" class="dropdown-item" [hidden]="hideMenu('AGENCY')">List of Requisitioning
              Departments</a>
          </li>
          <li>
            <a routerLink="project" class="dropdown-item" [hidden]="hideMenu('PROJECT')">List of Projects</a>
          </li>
          <li>
            <a routerLink="lao-list" class="dropdown-item">List of
              User</a>
          </li>
          <li>
            <a routerLink="profile" class="dropdown-item">List of
              Profile</a>
          </li>
          <li>
            <a routerLink="approve-profile" class="dropdown-item">Profile Approval</a>
          </li>
          <li>
            <a routerLink="account-sna" class="dropdown-item">SNA Account</a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown" *ngIf="roleIdAgency || roleIdForProject || !isChecker">
        <a id="navbarDropdown" [routerLinkActive]="['active']" role="button" data-bs-toggle="dropdown"
          class="nav-link dropdown-toggle show">
          Add
        </a>
        <ul _aria-labelledby="navbarDropdown" class="dropdown-menu " data-bs-popper="static">
          <li>
            <a routerLink="Add-agency" *ngIf="roleIdAgency" class="dropdown-item" [hidden]="hideMenu('AGENCY')">Add
              new Requisitioning Departments</a>
          </li>
          <li>
            <a routerLink="create-project" *ngIf="roleIdForProject" class="dropdown-item"
              [hidden]="hideMenu('PROJECT')">Add new Project</a>
          </li>
          <!-- <li>
              <a routerLink="add-lao" class="dropdown-item">Add new User</a>
            </li> -->
        </ul>
      </li>

      <li [routerLinkActive]="['active']" class="nav-item mx-2" *ngIf="isChecker">
        <a routerLink="checker-project" class="nav-link">Project</a>
      </li>
      <li class="nav-item dropdown" *ngIf="isChecker">
        <a id="navbarDropdown" [routerLinkActive]="['active']" role="button" data-bs-toggle="dropdown"
          class="nav-link dropdown-toggle show">
          Payment
        </a>
        <ul _aria-labelledby="navbarDropdown" class="dropdown-menu " data-bs-popper="static">
          <li>
            <a routerLink="payment" class="dropdown-item">Make Payment</a>
          </li>
          <li>
            <a routerLink="payment-status" class="dropdown-item">Payment Status</a>
          </li>
        </ul>
      </li>
      <li [routerLinkActive]="['active']" class="nav-item mx-2" [hidden]="hideMenu('DASHBOARD')">
        <a routerLink="account-statement" class="nav-link">SNA Account</a>
      </li>


      <!-- <li class="nav-item dropdown"  *ngIf="isChecker">
          <a id="navbarDropdown" [routerLinkActive]="['active']" role="button" data-bs-toggle="dropdown"
            class="nav-link dropdown-toggle show">
            Establishment Charges
          </a>
          <ul _aria-labelledby="navbarDropdown" class="dropdown-menu " data-bs-popper="static">
            <li  *ngIf="!isChecker">
              <a routerLink="raise-request" class="dropdown-item">
                Raise a Request
              </a>
            </li>
            <li  *ngIf="isChecker">
              <a routerLink="pending-request" class="dropdown-item">
                Pending Request
              </a>
            </li>
            <li>
              <a routerLink="status-request" class="dropdown-item">
                Status of Request
              </a>
            </li>
          </ul>
        </li> -->
      <li class="nav-item dropdown" id="myDropdown">
        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown"> Reports </a>
        <ul class="dropdown-menu">
          <!-- <li>
              <a class="dropdown-item" routerLink="agency-report">Agency Based</a>
            </li> -->
          <li>
            <a class="dropdown-item" routerLink="project-report">Project Based</a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="beneficiary-report">Beneficiary Based</a>
          </li>
          <li *ngIf="Id == 2">
            <a class="dropdown-item" routerLink="department-report">Payment report</a>
          </li>
          <li *ngIf="Id != 2">
            <a class="dropdown-item" routerLink="lao-report">Payment report</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="getData('ProjectWiseReport')" routerLink="dynamic-report">Project
              wise
              report</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="getData('DistrictWiseReport')" routerLink="dynamic-report">District
              wise
              report</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="getData('ReqAgencyWiseReport')" routerLink="dynamic-report">Requesting
              agency wise report</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="getData('BenificiaryWiseReport')" routerLink="dynamic-report">Beneficiary
              wise report</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="getData('ExtentWiseReport')" routerLink="dynamic-report">Extent wise
              report</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="getData('ExpenditureReport')" routerLink="dynamic-report">Account Details
              Report
            </a>
          </li>
          <!-- <li>
              <a class="dropdown-item" (click)="getData('OnBoardReport')"
                routerLink="dynamic-report">Onboard Report 
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="getData('UserLoginReport')"
                routerLink="dynamic-report">User login Report 
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="getData('VoucherStatusReport')"
                routerLink="dynamic-report">Voucher Status Report 
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="getData('PaymentSuccessReport')"
                routerLink="dynamic-report">Payment success Report 
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="getData('PaymentFailureReport')"
                routerLink="dynamic-report">Payment failure  Report 
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="getData('PaymentPendingReport')"
                routerLink="dynamic-report">Payment pending Report 
              </a>
            </li> -->
        </ul>
      </li>
    </ul>
    <ul class="navbar-nav ms-auto mb-2 mb-lg-0" *ngIf="isMaker">
      <li class="nav-item mx-2" [routerLinkActive]="['active']">
        <a routerLink="home" class="nav-link"> Dashboard</a>
      </li>
      <li class="nav-item dropdown">
        <a id="navbarDropdown" [routerLinkActive]="['active']" role="button" data-bs-toggle="dropdown"
          class="nav-link dropdown-toggle show">
          Taluks and Villages
        </a>
        <ul _aria-labelledby="navbarDropdown" class="dropdown-menu " data-bs-popper="static">
          <li>
            <a routerLink="stage1" class="dropdown-item">Add Taluks and Villages Details</a>
          </li>
          <li>
            <a routerLink="stage1-view" class="dropdown-item">View</a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown">
        <a id="navbarDropdown" [routerLinkActive]="['active']" role="button" data-bs-toggle="dropdown"
          class="nav-link dropdown-toggle show">
          Land
        </a>
        <ul _aria-labelledby="navbarDropdown" class="dropdown-menu " data-bs-popper="static">
          <li>
            <a routerLink="stage2" class="dropdown-item">Add Land details</a>
          </li>
          <li>
            <a routerLink="stage2-view" class="dropdown-item">View</a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown">
        <a id="navbarDropdown" [routerLinkActive]="['active']" role="button" data-bs-toggle="dropdown"
          class="nav-link dropdown-toggle show">
          Beneficiary
        </a>
        <ul _aria-labelledby="navbarDropdown" class="dropdown-menu " data-bs-popper="static">
          <li>
            <a routerLink="stage3" class="dropdown-item">Add Beneficiaries Details</a>
          </li>
          <!-- <li>
            <a routerLink="stage3-DD" class="dropdown-item">Add Court Deposit</a>
          </li> -->

          <li>
            <a routerLink="stage3-view" class="dropdown-item">Add Award/Legal heir</a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown">
        <a id="navbarDropdown" [routerLinkActive]="['active']" role="button" data-bs-toggle="dropdown"
          class="nav-link dropdown-toggle show">
          Award Proceeding No
        </a>
        <ul _aria-labelledby="navbarDropdown" class="dropdown-menu " data-bs-popper="static">
          <li>
            <a routerLink="proceeding-no" class="dropdown-item">Add Award Proceeding No</a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown">
        <a id="navbarDropdown" [routerLinkActive]="['active']" role="button" data-bs-toggle="dropdown"
          class="nav-link dropdown-toggle show">
          Generate voucher
        </a>
        <ul _aria-labelledby="navbarDropdown" class="dropdown-menu " data-bs-popper="static">
          <li>
            <a routerLink="stage4" class="dropdown-item">Recommend for Payment</a>
          </li>
          <!-- <li>
            <a routerLink="recommend-dd" class="dropdown-item">Recommend for Court deposit</a>
          </li> -->
          <li>
            <a routerLink="stage5" class="dropdown-item">Perform eSign</a>
          </li>
          <li>
            <a routerLink="stage4-view" class="dropdown-item">View</a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown" id="myDropdown" [hidden]="hideMenu('REPORT')" *ngIf="!isChecker">
        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown"> Reports </a>
        <ul class="dropdown-menu">
          <!-- <li>
              <a class="dropdown-item" routerLink="agency-report">Agency Based</a>
            </li> -->
          <li>
            <a class="dropdown-item" routerLink="project-report">Project Based</a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="beneficiary-report">Beneficiary Based</a>
          </li>
          <li *ngIf="Id == 2">
            <a class="dropdown-item" routerLink="department-report">Payment report</a>
          </li>
          <li *ngIf="Id != 2">
            <a class="dropdown-item" routerLink="lao-report">Payment report</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="getData('ProjectWiseReport')" routerLink="dynamic-report">Project
              wise
              report</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="getData('DistrictWiseReport')" routerLink="dynamic-report">District
              wise
              report</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="getData('ReqAgencyWiseReport')" routerLink="dynamic-report">Requesting
              agency wise report</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="getData('BenificiaryWiseReport')" routerLink="dynamic-report">Beneficiary
              wise report</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="getData('ExtentWiseReport')" routerLink="dynamic-report">Extent wise
              report</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="getData('ExpenditureReport')" routerLink="dynamic-report">Account Details
              Report
            </a>
          </li>
          <!-- <li>
              <a class="dropdown-item" (click)="getData('OnBoardReport')"
                routerLink="dynamic-report">Onboard Report 
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="getData('UserLoginReport')"
                routerLink="dynamic-report">User login Report 
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="getData('VoucherStatusReport')"
                routerLink="dynamic-report">Voucher Status Report 
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="getData('PaymentSuccessReport')"
                routerLink="dynamic-report">Payment success Report 
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="getData('PaymentFailureReport')"
                routerLink="dynamic-report">Payment failure  Report 
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="getData('PaymentPendingReport')"
                routerLink="dynamic-report">Payment pending Report 
              </a>
            </li> -->
        </ul>
      </li>

      <li [routerLinkActive]="['active']" class="nav-item">
        <a routerLink="change-password" class="nav-link">Change Password</a>
      </li>

    </ul>
    <div class="admin-info">
      <div class="ml-2 me-2 mt-1">
        <span class="welcome">Welcome</span>
        <p class="name mb-0">{{userName}}</p>
      </div>
      <div class="capBorder dropdown ml-2" data-bs-toggle="dropdown" data-bs-toggle="dropdown">
        <li class="nav-item list-style-none">
          <a id="navbarDropdown" class="nav-link">
            <h2>{{indexLetter}}</h2>
          </a>
          <ul _aria-labelledby="navbarDropdown" class="dropdown-menu " data-bs-popper="static">
            <li *ngIf="isMaker || isChecker">
              <a class="nav-link logout" (click)="showDialog()">My Profile</a>
            </li>
            <li>
              <a class="nav-link logout" (click)="logout()">logout</a>
            </li>
          </ul>
        </li>
      </div>
    </div>
  </div>
  <div *ngIf="disableMenu">
    <div class="d-flex justify-content-end admin-info">
      <div class="ml-2 me-2 mt-1">
        <span class="welcome">Welcome</span>
        <p class="name mb-0">{{userName}}</p>
      </div>
      <div class="capBorder dropdown ml-2" data-bs-toggle="dropdown">
        <li class="nav-item list-style-none">
          <a id="navbarDropdown" class="nav-link">
            <h2>{{indexLetter}}</h2>
          </a>
          <ul aria-labelledby="navbarDropdown" class="dropdown-menu" data-bs-popper="static">
            <li>
              <a class="nav-link logout" (click)="logout()">Logout</a>
            </li>
          </ul>
        </li>
      </div>
    </div>
  </div>
</nav>
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
export type EntityArrayResponseType = HttpResponse<Agency[]>;
import { Agency, AgencyDto } from './stage3';
import { EncryptService } from 'src/app/shared/encrypt.service';

@Injectable({ providedIn: 'root' })
export class AgencyService {
  constructor(private http: HttpClient,private encryptService:EncryptService) { }
  protected resourceFliterUrl = environment.SERVER_API_URL + ('api/benificiaryLandDetail');
  protected getBaseURL = environment.SERVER_API_URL + ('api/benificiaryLandOwner/getConfirmedList');
  protected resourceVaildFliterUrl = environment.SERVER_API_URL + ('api/getIAgencyValidByFilter');
  protected resourceSaveUrl = environment.SERVER_API_URL + ('api/benificiaryLandDetail')
  protected resourceDeleteUrl = environment.SERVER_API_URL + ('api/deleteImplementingAgency')
  protected getAgencyUrl = environment.SERVER_API_URL + ('api/benificiaryLandOwner')
  protected getStage3View = environment.SERVER_API_URL + ('api/benificiaryLandOwner')
  protected updateStage3View = environment.SERVER_API_URL + ('api/benificiaryLandOwner/updateById')

  protected legal = environment.SERVER_API_URL + ('api/benificiaryLegalier')
  protected getIFSC = environment.SERVER_API_URL + ('api/fetchBankInfoByIfscCode')
  protected getLevelMasters = environment.SERVER_API_URL + ('api/levelMasterList');
  protected filterUrl = environment.SERVER_API_URL + ('api/project/getList')
  protected TalukVillageUrl = environment.SERVER_API_URL + ('api/getLevelMasterByParentId')
  protected uploadImageUrl = environment.SERVER_API_URL+('api/uploadFile');
  protected viewFileUrl=environment.SERVER_API_URL+('api/viewFile');
  protected viewBeneficiaryList=environment.SERVER_API_URL+('api/benificiaryLandOwner');
  protected validations=environment.SERVER_API_URL+('api/benificiaryLandOwner/getUpdateValidation');
  protected update =environment.SERVER_API_URL+('api/benificiaryLandOwner/updateBeneficiaryWithLegalHeir');

  protected deleteFileUrl=environment.SERVER_API_URL+('api/deleteFile');

  validation(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<Agency[]>(this.validations, { params: options, observe: 'response' })
  }
  uploadImage(image){

    return this.http.post<any>(this.uploadImageUrl,image,{observe: 'response' })

  }
  updateBeneficiary(data){
   // let val={requestPayload:this.encryptService.encryptData(JSON.stringify(data))}
    return this.http.post<any>(this.update,data,{observe: 'response' })

  }
  deleteimage(fileName: string): any {
    return this.http.get(`${this.deleteFileUrl}/${fileName}`, { observe: 'response' });
  }

  viewimage(fileName: string): any {
    return this.http.get(`${this.viewFileUrl}/${fileName}`, { observe: 'response' });
  }
  viewBeneficiary(id: any): any {
    return this.http.get(`${this.viewBeneficiaryList}?proceedingId=${id}`, { observe: 'response' });
  }

  filter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = []
    }
    const options = createRequestOption(req);
    return this.http
      .get<Agency[]>(this.getBaseURL, { params: options, observe: 'response' })
  }

  validFilter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = []
    }
    const options = createRequestOption(req);
    return this.http
      .post<Agency[]>(this.resourceVaildFliterUrl, filter, { params: options, observe: 'response' })
  }

  save(agency?: any): Observable<EntityArrayResponseType> {
    return this.http
      .post<AgencyDto[]>(this.resourceFliterUrl, agency, { observe: 'response' })
  }
  getLevelMaster(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.getLevelMasters}`, { observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
  }

  getOneAgency(id: number): Observable<HttpResponse<any>> {
    return this.http.get<AgencyDto>(`${this.getAgencyUrl}?id=${id}`, { observe: 'response' });
  }
  saveBeneficiary(filter,id: number): Observable<HttpResponse<any>> {
    return this.http.post<AgencyDto>(`${this.getAgencyUrl}?parentId=${id}`,filter, { observe: 'response' });
  }
  saveAward(filter,id: number): Observable<HttpResponse<any>> {
   // let val={requestPayload:this.encryptService.encryptData(JSON.stringify(filter))}
    return this.http.post<AgencyDto>(`${this.updateStage3View}/${id}`,filter, { observe: 'response' });
  }
  saveLegal(filter: any,id): Observable<HttpResponse<any>> {
   // let val={requestPayload:this.encryptService.encryptData(JSON.stringify(filter))}
    return this.http.post<AgencyDto>(`${this.legal}?beneficiaryLandOwnerId=${id}`,filter, { observe: 'response' });
  }
  getIFSCCode(id: number): Observable<HttpResponse<any>> {
    return this.http.get<AgencyDto>(`${this.getIFSC}?ifscCode=${id}`, { observe: 'response' });
  }
  getProjects(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.filterUrl, {
      // params: options,
      observe: 'response',
    });
  }
  beneficiary(id): Observable<HttpResponse<any>> {
    return this.http.get<AgencyDto>(`${this.resourceFliterUrl}/${id}`, { observe: 'response' });
  }
  talukAndVillages(parentId: any, LevelType) {
    return this.http
      .post<AgencyDto[]>(`${this.TalukVillageUrl}?parentId=${parentId}&levelTypeId=${LevelType}`, { observe: 'response' })
  }

  deleteAgency(id): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceFliterUrl}/${id}`, { observe: 'response' });
  }
}
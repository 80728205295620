<div class="hamburger">
    <h2>Project</h2>
</div>
<div class="container bg">
    <div class="row bgFilter">
        <div class="col-sm-4 col-md-3">
            <label class="form-control-label req">Project
                Name</label>
            <ng-select id="department" bindLabel="projectName" (change)="selectProject($event)" appearance="outline"
                [items]="projectList" [(ngModel)]="fundFilter" class="form-control" appNgSelectInputRestriction>
            </ng-select>
        </div>
    </div>
</div>
<div class="mt-4" *ngIf="isShowTable">
    <div class="container">
        <p-steps [model]="items" [readonly]="false" [activeIndex]="activeIndex"
            (activeIndexChange)="onActiveIndexChange($event)">
        </p-steps>
    </div>
    <div class="mt-3">
        <div *ngIf="activeIndex == '0'">
            <div class="row mt-3">

                <div #customerTable id="entities" class="col-md-12 ">
                    <div class="row bgFilter">
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req">Taluk Name
                            </label>
                            <input type="text" class="form-control" [(ngModel)]="stage1Filter.taluk" name="name"
                                autocomplete="off" maxlength="60" id="name" />
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req">Village
                                Name</label>
                            <input type="text" class="form-control" [(ngModel)]="stage1Filter.village" name="name"
                                autocomplete="off" maxlength="60" id="name" />
                        </div>
                        <div class="flex align-items-center mt-2">
                            <p-button label="Submit" class="submit" (click)="loadPage(page,'stage1')"></p-button>
                            &nbsp; <p-button label="Next" class="submit" (click)="onActiveIndexChange(1)"></p-button>
                        </div>
                    </div>
                    <div class="table-scroll">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">S.No</th>
                                    <th scope="col">Project <span (click)="loadPage(page,'stage1','projectName')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th scope="col">Taluk <span (click)="loadPage(page,'stage1','talukName')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th scope="col">Village <span (click)="loadPage(page,'stage1','villageName')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th scope="col">Name of the Unit <span
                                            (click)="loadPage(page,'stage1','nameOfUnit')"><i class="fa fa-sort"></i>
                                        </span></th>
                                    <th scope="col">Name of the Block <span
                                            (click)="loadPage(page,'stage1','nameOfBlock')"><i class="fa fa-sort"></i>
                                        </span></th>
                                    <th scope="col">Through Act <span (click)="loadPage(page,'stage1','throughAct')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th scope="col">Through Private Negotiation <span
                                            (click)="loadPage(page,'stage1','throughPrivateNegotiation')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th scope="col">Total Extent Land <span
                                            (click)="loadPage(page,'stage1','totalExtentLand')"><i
                                                class="fa fa-sort"></i>
                                        </span></th>
                                    <th scope="col">Status </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of beneficary;let i =index">
                                    <td>{{generateIndex(i)}}</td>
                                    <td>{{data.projectId?.projectName}}</td>
                                    <td>{{data.taluk?.name}}</td>
                                    <td>{{data.village?.name}}</td>
                                    <td>{{data.nameOfUnit}}</td>
                                    <td>{{data.nameOfBlock}}</td>
                                    <td>{{data.throughAct }}</td>
                                    <td>{{data.throughPrivateNegotiation }}</td>
                                    <td>{{data.totalExtentLand }}</td>
                                    <td>
                                        --
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="text-center" *ngIf="totalCount == 0">
                        <img src="../../../assets/img/notFound.png" alt="">
                    </div>
                    <p-paginator *ngIf="totalCount != 0" [rows]="itemsPerPage" (onPageChange)="onPageChange($event)"
                        [totalRecords]="totalCount" [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
                </div>
            </div>
        </div>
        <div *ngIf="activeIndex == '1'">
            <div class="row">
                <div id="entities" class="col-md-12">
                    <div class="row bgFilter">
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req">Taluk Name
                            </label>
                            <input type="text" class="form-control" [(ngModel)]="stage2Filter.taluk" name="name"
                                autocomplete="off" maxlength="60" id="name" />
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req">Village
                                Name</label>
                            <input type="text" class="form-control" [(ngModel)]="stage2Filter.village" name="name"
                                autocomplete="off" maxlength="60" id="name" />
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req">Survey Number
                            </label>
                            <input type="text" class="form-control" [(ngModel)]="stage2Filter.survey" name="name"
                                autocomplete="off" maxlength="60" id="name" />
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req">Sub Division Number
                            </label>
                            <input type="text" class="form-control" [(ngModel)]="stage2Filter.subdivision" name="name"
                                autocomplete="off" maxlength="60" id="name" />
                        </div>
                        <div class="flex align-items-center mt-2">
                            <p-button label="Submit" class="submit" (click)="loadPage(page,'stage2')"></p-button>
                            &nbsp;
                            <p-button label="Next" class="submit" (click)="onActiveIndexChange(2)"></p-button>
                            &nbsp;
                            <p-button label="Previous" class="submit" (click)="onActiveIndexChange(0)"></p-button>
                        </div>
                    </div>
                    <div class="table-scroll">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th rowspan="2" style="width:200px">S.No</th>
                                    <th rowspan="2" style="width:200px">Survey
                                        Number <span (click)="loadPage(page,'stage2','surveyNumber')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th rowspan="2" style="width:200px">Subdivision
                                        Number <span (click)="loadPage(page,'stage2','subDivisionNumber')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th rowspan="2" style="width:200px">Part Subdivision
                                        Number <span (click)="loadPage(page,'stage2','partSubDivisionNumber')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th rowspan="2" class="text-center">Total extent of
                                        the Survey
                                        Number as per
                                        Tamilnilam <span
                                            (click)="loadPage(page,'stage2','totalExtentAsPerTamilnilam')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th rowspan="2">Acquired
                                        extent as per
                                        Award
                                        (In hectares) <span (click)="loadPage(page,'stage2','acquiredExtent')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th colspan="2" class="text-center">
                                        Land acquired
                                        under which
                                        mode? (In
                                        hectares)
                                    </th>
                                    <th colspan="2" class="text-center">
                                        Compensation
                                        Determined (In
                                        Rupees)
                                    </th>

                                    <th rowspan="2">
                                        Total
                                        Compensation
                                        determined for
                                        this survey
                                        number <span (click)="loadPage(page,'stage2','totalCompensation')"><i
                                                class="fa fa-sort"></i> </span>
                                    </th>
                                </tr>
                                <tr>
                                    <th>As per Private
                                        Negotiation <span (click)="loadPage(page,'stage2','asPerPrivateNegotiation')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th>As
                                        per Act <span (click)="loadPage(page,'stage2','asPerActExtent')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th>
                                        As Per Private Negotiation Cost <span
                                            (click)="loadPage(page,'stage2','asPerPrivateNegotiationCost')"><i
                                                class="fa fa-sort"></i> </span>
                                    </th>
                                    <th>
                                        As Per Act Cost <span (click)="loadPage(page,'stage2','asPerActCost')"><i
                                                class="fa fa-sort"></i> </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of beneficary;let i=index">
                                    <td>{{generateIndex(i)}}</td>
                                    <td>{{data.surveyNumber}}</td>
                                    <td>{{data.subDivisionNumber}}</td>
                                    <td>{{data.partSubDivisionNumber}}</td>
                                    <td>{{data.totalExtentAsPerTamilnilam }}</td>
                                    <td>{{data.acquiredExtent }}</td>
                                    <td>{{data.asPerPrivateNegotiation }}</td>
                                    <td>{{data.asPerActExtent }}</td>
                                    <td>{{data.asPerPrivateNegotiationCost }}</td>
                                    <td>{{data.asPerActCost }}</td>
                                    <td>{{data.totalCompensation }}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>


                    <div class="text-center" *ngIf="totalCount == 0">
                        <img src="../../../assets/img/notFound.png" alt="">
                    </div>
                    <p-paginator *ngIf="totalCount != 0" [rows]="itemsPerPage" (onPageChange)="onPageChange($event)"
                        [totalRecords]="totalCount" [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
                </div>
            </div>
        </div>
        <div *ngIf="activeIndex == '2'">
            <div #customerTable id="entities" class="col-md-12 bg">
                <div class="row bgFilter">
                    <div class="col-sm-4 col-md-3">
                        <label class="form-control-label req">
                            Name</label>
                        <input type="text" class="form-control" [(ngModel)]="stage3Filter.name" name="name"
                            autocomplete="off" maxlength="60" id="name" />
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <label class="form-control-label req">Mobile Number
                        </label>
                        <input type="text" class="form-control" name="name" autocomplete="off" maxlength="60"
                            [(ngModel)]="stage3Filter.mobile" (keypress)="keyPressNumberForMobile($event)"
                            style="text-transform:uppercase" id="name" />
                    </div>
                    <div class="col-sm-4 col-md-3">
                        <label class="form-control-label req">Aadhaar Number
                        </label>
                        <input type="text" class="form-control" name="name" autocomplete="off" maxlength="60"
                            [(ngModel)]="stage3Filter.aadhaar" (keypress)="keyPressNumberForAadhaar($event)"
                            style="text-transform:uppercase" id="name" />
                    </div>
                    <div class="flex align-items-center mt-2">
                        <p-button label="Submit" class="submit" (click)="loadPage(page,'stage3')"></p-button>
                        &nbsp;
                        <p-button label="Next" class="submit" (click)="onActiveIndexChange(3)"></p-button>
                        &nbsp;
                        <p-button label="Previous" class="submit" (click)="onActiveIndexChange(1)"></p-button>


                    </div>
                </div>
                <div class="table-scroll">
                    <table style="width:100%" class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th style="width:100px; ">Land Owner name <span
                                        (click)="loadPage(page,'stage3','benificiaryLandOwnerName')"><i
                                            class="fa fa-sort"></i> </span></th>
                                <th style="width:100px; ">Survey Number <span
                                        (click)="loadPage(page,'stage3','surveyNumber')"><i class="fa fa-sort"></i>
                                    </span></th>
                                <th style="width:100px; ">Sub division Number <span
                                        (click)="loadPage(page,'stage3','subDivisionNumber')"><i class="fa fa-sort"></i>
                                    </span></th>
                                <th style="width:100px; ">Part Sub division Number <span
                                        (click)="loadPage(page,'stage3','partSubDivisionNumber')"><i
                                            class="fa fa-sort"></i>
                                    </span></th>
                                <th style="width:100px; ">Adhaar Number <span
                                        (click)="loadPage(page,'stage3','aadharNumber')"><i class="fa fa-sort"></i>
                                    </span></th>
                                <th style="width:100px; ">Mobile Number <span
                                        (click)="loadPage(page,'stage3','mobileNumber')"><i class="fa fa-sort"></i>
                                    </span></th>
                                <th class="text-center">Account Number <span
                                        (click)="loadPage(page,'stage3','accountNumber')"><i class="fa fa-sort"></i>
                                    </span></th>
                                <th>
                                    IFSC Code <span (click)="loadPage(page,'stage3','ifscCode')"><i
                                            class="fa fa-sort"></i>
                                    </span></th>
                                <th class="text-center">
                                    Bank Name <span (click)="loadPage(page,'stage3','nameOfBank')"><i
                                            class="fa fa-sort"></i> </span>
                                </th>
                                <th>
                                    Branch Name
                                </th>
                                <th>
                                    Extent owned by the
                                    Land Owner as per
                                    Award?(In hectares) <span (click)="loadPage(page,'stage3','extentOwned')"><i
                                            class="fa fa-sort"></i> </span>
                                </th>
                                <th>
                                    Compensation
                                    Amount to be
                                    disbursed? (in Rupees) <span (click)="loadPage(page,'stage3','totalAmount')"><i
                                            class="fa fa-sort"></i> </span>
                                </th>
                            </tr>
                        </thead>
                        <tr *ngFor="let data of beneficary;let i =index">
                            <td>{{generateIndex(i)}}</td>
                            <td>{{data.benificiaryLandOwnerName}}</td>
                            <td>{{data.surveyNumber}}</td>
                            <td>{{data.subDivisionNumber}}</td>
                            <td>{{data.benificiaryLandDetail?.partSubDivisionNumber ?? '-'}}</td>
                            <td>{{data.aadharNumber}}</td>
                            <td>{{data.mobileNumber}}</td>
                            <td>{{data.accountNumber}}</td>
                            <td class="text-uppercase">{{data.ifscCode}}</td>
                            <td>{{data.nameOfBank}}</td>
                            <td>{{data.branchName}}</td>
                            <td>{{data.extentOwned}}</td>
                            <td>{{data.totalAmount|indianRupee }}</td>
                        </tr>
                    </table>
                </div>


                <div class="text-center" *ngIf="totalCount == 0">
                    <img src="../../../assets/img/notFound.png" alt="">
                </div>
                <p-paginator *ngIf="totalCount != 0" [rows]="itemsPerPage" (onPageChange)="onPageChange($event)"
                    [totalRecords]="totalCount" [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>

            </div>
        </div>
        <div *ngIf="activeIndex == '3'">
            <div class="row">
                <div #customerTable id="entities" class="col-md-12">
                    <div class="row bgFilter">
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req">
                                Name</label>
                            <input type="text" class="form-control" [(ngModel)]="stage4Filter.name" name="name"
                                autocomplete="off" maxlength="60" id="name" />
                        </div>


                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req">Mobile Number
                            </label>
                            <input type="text" class="form-control" name="name" autocomplete="off" maxlength="60"
                                [(ngModel)]="stage4Filter.mobile" (keypress)="keyPressNumberForMobile($event)"
                                style="text-transform:uppercase" id="name" />
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req">Aadhaar Number
                            </label>
                            <input type="text" class="form-control" name="name" autocomplete="off" maxlength="60"
                                [(ngModel)]="stage4Filter.aadhaar" (keypress)="keyPressNumberForAadhaar($event)"
                                style="text-transform:uppercase" id="name" />
                        </div>


                        <div class="flex align-items-center mt-2">
                            <p-button label="Submit" class="submit" (click)="loadPage(page,'stage4')"></p-button>
                            &nbsp;
                            <p-button label="Next" class="submit" (click)="onActiveIndexChange(4)"></p-button>
                            &nbsp;
                            <p-button label="Previous" class="submit" (click)="onActiveIndexChange(2)"></p-button>

                        </div>
                    </div>
                    <div class="table-scroll">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Beneficiary Name <span
                                            (click)="loadPage(page,'stage4','benificiaryLandOwnerName')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th>Survey Number <span (click)="loadPage(page,'stage4','surveyNumber')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th>Subdivision Number <span
                                            (click)="loadPage(page,'stage4','subDivisionNumber')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th>Part Subdivision Number <span
                                            (click)="loadPage(page,'stage4','partSubDivisionNumber')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th>Aadhaar Number <span (click)="loadPage(page,'stage4','aadharNumber')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th>
                                        Account Number <span (click)="loadPage(page,'stage4','accountNumber')"><i
                                                class="fa fa-sort"></i> </span>
                                    </th>
                                    <th>
                                        Bank Name <span (click)="loadPage(page,'stage4','nameOfBank')"><i
                                                class="fa fa-sort"></i> </span>
                                    </th>
                                    <th>
                                        Mobile Number <span (click)="loadPage(page,'stage4','mobileNumber')"><i
                                                class="fa fa-sort"></i> </span>
                                    </th>
                                    <th>
                                        Compensation Amount <span (click)="loadPage(page,'stage4','totalAmount')"><i
                                                class="fa fa-sort"></i> </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of beneficary;let i =index">
                                    <td>{{generateIndex(i)}}</td>
                                    <td>{{data.benificiaryLandOwnerName}}</td>
                                    <td>{{data.surveyNumber}}</td>
                                    <td>{{data.subDivisionNumber}}</td>
                                    <td>{{data.partSubDivisionNumber ?? '-'}}</td>
                                    <td>{{data.aadharNumber }}</td>
                                    <td>{{data.accountNumber }}</td>
                                    <td>{{data.nameOfBank }}</td>
                                    <td>{{data.mobileNumber }}</td>
                                    <td>{{data.totalAmount |indianRupee }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="text-center" *ngIf="totalCount == 0">
                        <img src="../../../assets/img/notFound.png" alt="">
                    </div>
                    <p-paginator *ngIf="totalCount != 0" [rows]="itemsPerPage" (onPageChange)="onPageChange($event)"
                        [totalRecords]="totalCount" [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>

                </div>
            </div>
        </div>
        <div *ngIf="activeIndex == '4'">
            <div class="row mt-3">
                <div #customerTable id="entities" class="col-md-12">
                    <div class="row bgFilter">
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req">
                                Name</label>
                            <input type="text" class="form-control" [(ngModel)]="stage5Filter.name" name="name"
                                autocomplete="off" maxlength="60" id="name" />
                        </div>

                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req">Mobile Number
                            </label>
                            <input type="text" class="form-control" name="name" autocomplete="off" maxlength="60"
                                [(ngModel)]="stage5Filter.mobile" (keypress)="keyPressNumberForMobile($event)"
                                style="text-transform:uppercase" id="name" />
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req">Aadhaar Number
                            </label>
                            <input type="text" class="form-control" name="name" autocomplete="off" maxlength="60"
                                [(ngModel)]="stage5Filter.aadhaar" (keypress)="keyPressNumberForAadhaar($event)"
                                style="text-transform:uppercase" id="name" />
                        </div>


                        <div class="flex align-items-center mt-2">
                            <p-button label="Submit" class="submit" (click)="loadPage(page,'stage5')"></p-button>
                            &nbsp;
                            <p-button label="Previous" class="submit" (click)="onActiveIndexChange(3)"></p-button>

                        </div>
                    </div>
                    <div class="table-scroll">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="background-color: mistyrose;" scope="col">S.No
                                    </th>
                                    <th style="background-color: mistyrose;" scope="col">Beneficiary Name <span
                                            (click)="loadPage(page,'stage5','benificiaryLandOwnerName')"><i
                                                class="fa fa-sort"></i> </span>
                                    </th>
                                    <th style="background-color: mistyrose;" scope="col">Survey
                                        Number <span (click)="loadPage(page,'stage5','surveyNumber')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th style="background-color: mistyrose;" scope="col">Subdivision
                                        Number <span (click)="loadPage(page,'stage5','subDivisionNumber')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th style="background-color: mistyrose;" scope="col">Part Subdivision
                                        Number <span (click)="loadPage(page,'stage5','partSubDivisionNumber')"><i
                                                class="fa fa-sort"></i> </span></th>
                                    <th style="background-color: mistyrose;" scope="col">Mobile
                                        Number <span (click)="loadPage(page,'stage5','mobileNumber')"><i
                                                class="fa fa-sort"></i> </span>
                                    </th>
                                    <th style="background-color: mistyrose;" scope="col">Bank Name <span
                                            (click)="loadPage(page,'stage5','nameOfBank')"><i class="fa fa-sort"></i>
                                        </span>
                                    </th>
                                    <th style="background-color: mistyrose;" scope="col">Account Number <span
                                            (click)="loadPage(page,'stage5','accountNumber')"><i class="fa fa-sort"></i>
                                        </span>
                                    </th>
                                    <th style="background-color: mistyrose;" scope="col">Aadhaar Number <span
                                            (click)="loadPage(page,'stage5','aadharNumber')"><i class="fa fa-sort"></i>
                                        </span>
                                    </th>
                                    <th style="background-color: mistyrose;" scope="col">Amount <span
                                            (click)="loadPage(page,'stage5','totalAmount')"><i class="fa fa-sort"></i>
                                        </span>
                                    </th>
                                    <th style="background-color: mistyrose;" scope="col">Status
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let data of beneficary;let i =index">
                                    <td>{{generateIndex(i)}}</td>
                                    <td>{{data.benificiaryLandOwnerName}}</td>
                                    <td>{{data.surveyNumber}}</td>
                                    <td>{{data.subDivisionNumber}}</td>
                                    <td>{{data.partSubDivisionNumber}}</td>
                                    <td>{{data.mobileNumber}}</td>
                                    <td>{{data.nameOfBank}}</td>
                                    <td>{{data.accountNumber}}</td>
                                    <td>{{data.aadharNumber}}</td>
                                    <td>{{data.totalAmount}}</td>
                                    <td>{{data.status}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="text-center" *ngIf="totalCount == 0">
                        <img src="../../../assets/img/notFound.png" alt="">
                    </div> <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)"
                        [totalRecords]="totalCount" [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
                </div>
            </div>
        </div>
    </div>
</div>
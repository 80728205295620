export class authModel {
    username: string;
    password: string;
    rememberMe: boolean;
    captchaText: string;
    id: number;
}
export class User {
    roleCode: string;
    otp: string;
    userName: string;
}
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { AccountSna, AccountSnaDto } from './account-sna';
import { AccountStatement } from '../account-statement/acc-statement';
export type EntityArrayResponseType = HttpResponse<AccountSna[]>;

@Injectable()
export class AccountSnaService {

    constructor(private http: HttpClient) { }

    protected getAllSna = environment.SERVER_API_URL+('api/snaList');

    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getSnaByFilter');
    protected accountStatement = environment.SERVER_API_URL+('api/getAccountStatement');
    protected latestaccountStatement = environment.SERVER_API_URL+('api/accountStatementUpdated');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveSna');
    // protected accountStatement = environment.SERVER_API_URL+('api/getvaluetoUI');
    protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteSna');
    protected getSnaUrl = environment.SERVER_API_URL+('api/getSna');

    filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<AccountSna[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
    }

    getAllSnaList(): Observable<any>{
      return this.http
        .get<AccountSnaDto[]>(this.getAllSna, {observe: 'response' })
    }


    getAccountStatement(req,data):Observable<any>{
      const options = createRequestOption(req);
      return this.http.post<AccountStatement[]>(this.accountStatement,data,{  params: options,observe : 'response'});
    }
    getlatestAccountStatement(data):Observable<any>{
      // const options = createRequestOption(data);
       return this.http.post<AccountStatement[]>(this.latestaccountStatement,data,{observe : 'response'});
     }
    save(sna?:AccountSnaDto): Observable<any> {
      return this.http
      .post<any>(this.resourceSaveUrl,sna, {observe: 'response' })
    }

    delete(id: number): Observable<HttpResponse<{}>> {
      return this.http.delete(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
    }

    getSna(id: number): Observable<HttpResponse<any>> {
      return this.http.get<AccountSnaDto>(`${this.getSnaUrl}?id=${id}`, { observe: 'response' });
    }




}
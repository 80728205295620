<div class="row">
    <div class="col-lg-6 col-sm-6 col-12 left-content">
        <div>
            <div class="login">
                <img class="tn-logo" src="/./assets/img/ib-rdp-tng-logo.svg" alt="Image" />
                <h4>Government of Tamil Nadu</h4>

                <h3 class="text-uppercase">Commissionerate of Land Administration</h3>
                <div class="laaten-content">
                    <h5 class="text-center text-uppercase">Land Acquisition Agency of Tamil Nadu</h5>
                </div>
                <div class="font-login mb-4 mt-4" style="color: #455a64">
                    Reset Password?
                </div>

                <p>Single Nodal <span>Account</span></p>
            </div>
            <div class="mt-3">
                <div class="login-content">
                    <div class="col-7 mx-auto login-form">
                        <div class="text-center">
                            <!-- <img  style="width:40%" src="/./assets/img/logo.png" alt="Image" > -->
                        </div>

                        <div class="login-content">
                            <form class="form" role="form" (ngSubmit)="submit()" [formGroup]="resetPwdForm">
                                <div class="form-group mb-4 eye-container">
                                    <input id="password" name="password" autocomplete="off" formControlName="password"
                                        [type]="phide ? 'password' : 'text'" required class="form-control full"
                                        placeholder="NEW PASSWORD">
                                    <i class="pi pi-eye" *ngIf="phide" (click)="phide=false"></i>
                                    <i class="pi pi-eye-slash" *ngIf="!phide" (click)="phide=true"></i>
                                </div>
                                <div class="form-group mb-4 eye-container">
                                    <input id="confirmPassword" name="confirmPassword" autocomplete="off"
                                        formControlName="confirmPassword" class="form-control full" required
                                        placeholder="CONFIRM NEW PASSWORD" [type]="cphide ? 'password' : 'text'">
                                    <i class="pi pi-eye" *ngIf="cphide" (click)="cphide=false"></i>
                                    <i class="pi pi-eye-slash" *ngIf="!cphide" (click)="cphide=true"></i>
                                </div>
                                <div *ngIf="passwordError" class="mt-2">
                                    <small style="font-size: 30px;" class="text-danger">{{passwordError}}</small>
                                  </div>
                                <div *ngIf="pwdMismatch" class="text-danger mb-2">
                                    <small>New Password and Confirm New Password should be same. Please re-enter</small>
                                </div>
                                <div class="mb-4 submitPass">
                                    <button type="submit" [disabled]="!resetPwdForm.valid" class="btn btn-secondary"
                                       >Submit</button>
                                <button type="button" (click)="navToLogin()" class="btn btn-link btn-sm mt-3">
                                    Back to Login
                                  </button>
                                </div>
                                <div class="footer-logo"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-footer">
            <img style="float: right;" src="../../../assets/img/IOB.png" width="150px" height="50px" alt="">
        </div>
    </div>
    <div class="col-lg-6 col-sm-6 laatnBg">
        <div>
            <img src="../../../assets/img/bg.jpeg" class="w-100" style="height: 100vh;" alt="" />
        </div>
    </div>
</div>
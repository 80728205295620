import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { ApiResponse, ReturnPayment } from '../return-payment-view/return';

export type EntityArrayResponseType = HttpResponse<ReturnPayment[]>;


@Injectable({
    providedIn :'root'
})


export class ReturnPaymentService{
    
    protected apiUrl = 'assets/json/sample-data.json';

    protected  apiDataUrl = environment.SERVER_API_URL + "api/beneficiaryPayment/getReturnPayment";

    protected apiApproveUrl = environment.SERVER_API_URL + "api/returnpaymentApprove";

    constructor(private http: HttpClient) {}
  
    getReturnPayments(page: number, size: number): Observable<any> {
      return this.http.get<any>(`${this.apiUrl}?page=${page}&size=${size}`);
    }

  fetchReturnpayment(req:any,voucherNo:String ):Observable<EntityArrayResponseType>{
      const options = createRequestOption(req);

    if(voucherNo){
        return this.http.get<ReturnPayment[]>(`${this.apiDataUrl}?voucherNo=${voucherNo}`,{params:options,observe:'response'});
    }else{
        return this.http.get<ReturnPayment[]>(`${this.apiDataUrl}`,{params:options,observe:'response'});
    }
  }

  apiApprovePayment(payment:any):any{
    return this.http.post(`${this.apiApproveUrl}`,payment, { observe: 'response',responseType : 'text' });
  }

}
<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>{{hamHeader}}</h2>
    <h5>{{subHeader}}</h5>
</div>
<div class="container-fluid bg">
    <div class="container bgFilter">
        <div class="flex align-items-center">
            <p-radioButton value="Fund" [(ngModel)]="projectFilter" inputId="ingredient1"></p-radioButton>
            <label for="ingredient1" class="ml-1">Type of Availability of Fund </label>
            <p-radioButton *ngIf="roleNameId != 8" value="Search" [(ngModel)]="projectFilter"
                inputId="ingredient2"></p-radioButton>
            <label *ngIf="roleNameId != 8" for="ingredient2" class="ml-1">Search Options</label>
        </div>
        <div class="flex align-items-center mt-3" *ngIf="projectFilter== 'Fund'">
            <p-radioButton name="pizza" value="PROJECT_WITH_AMOUNT" [(ngModel)]="fundFilter"
                inputId="Pamount"></p-radioButton>
            <label for="Pamount" class="ml-1">Project with Amount </label>
            <p-radioButton name="pizza" value="PROJECT_WITHOUT_AMOUNT" [(ngModel)]="fundFilter"
                inputId="Pwamount"></p-radioButton>
            <label for="Pwamount" class="ml-1">Project without Amount </label>
            <p-radioButton name="pizza" value="BOTH" [(ngModel)]="fundFilter" inputId="both"></p-radioButton>
            <label for="both" class="ml-1">Both </label>
        </div>
        <div class="flex align-items-center mt-3" *ngIf="projectFilter== 'Search'">
            <div class="row">
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Name
                        of the Requisitioning Department</label>
                    <ng-select id="scheme" [items]="AgencyLists" [(ngModel)]="filter.agency" bindLabel="agencyName"
                        appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control"
                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                    </ng-select>
                </div>
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass">Project Name</label>
                    <input type="text" class="form-control" name="name" autocomplete="off"
                        [(ngModel)]="filter.projectName" maxlength="60" id="name" appSerachInputRestriction/>
                </div>&nbsp;
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass">District Name</label>
                    <input type="text" class="form-control" name="name" autocomplete="off" [(ngModel)]="filter.district"
                        maxlength="60" id="name" appSerachInputRestriction/>
                </div>&nbsp;
            </div>
        </div>
        <div class="flex align-items-center mt-3 ">
            <p-button label="Submit" class="submit" 
                (click)="getProjectsFilter(1,fundFilter,filter)"></p-button>
        </div>
        <!-- <div class="col-sm-6 col-md-6 mt-3">
            <span *ngIf="filterQuery.typeOfAvailabilityOfFund != '' "> Fund Type<i class="pi pi-times iconClose"
                    (click)="clear('typeOfFund')"></i>
            </span> &nbsp;
            <span *ngIf="filterQuery.requestingAgencyId !='' ">Agency <i class="pi pi-times iconClose"
                    (click)="clear('agency')"></i></span> &nbsp;
            <span *ngIf="filterQuery.projectName != '' ">Project <i class="pi pi-times iconClose"
                    (click)="clear('project')"></i></span>&nbsp;
            <span *ngIf="filterQuery.districtName != '' ">District <i class="pi pi-times iconClose"
                    (click)="clear('district')"></i></span>
        </div> -->
        
    </div>
    <div class="row tableDesign" *ngIf="isShowTable">
        <div class="row">
            <div class="col-12">
                <p-toolbar styleClass="toolbar">
                    <ng-template pTemplate="left">
                        <h5 [ngClass]="fontService.headingClass" class="head">
                            Project List
                        </h5>
                    </ng-template>
                </p-toolbar>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 px-5">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Project Name  <span (click)="getProjectsFilter(page,fundFilter,filter,'projectName' )"><i class="fa fa-sort"></i> </span></th>
                            <th scope="col">District  <span (click)="getProjectsFilter(page,fundFilter,filter,'district' )"><i class="fa fa-sort"></i> </span></th>
                            <th scope="col">Category of the LAO  <span (click)="getProjectsFilter(page,fundFilter,filter,'lao')"><i class="fa fa-sort"></i> </span></th>
                            <th scope="col">Total funds
                                Received  <span (click)="getProjectsFilter(page,fundFilter,filter,'totalFundsReceived')"><i class="fa fa-sort"></i> </span>
                            </th>
                            <th scope="col">Total funds
                                spent  <span (click)="getProjectsFilter(page,fundFilter,filter,'totalFundsSpent')"><i class="fa fa-sort"></i> </span>
                            </th>
                            <th scope="col">Total Amount
                                Available  <span (click)="getProjectsFilter(page,fundFilter,filter,'totalAmountAvailable')"><i class="fa fa-sort"></i> </span>
                            </th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor='let data of projectList;let i =index'>
                            <td>{{generateIndex(i)}}</td>
                            <td>{{data.projectName}}</td>
                            <td>{{data.district}}</td>
                            <td>{{data.lao}}</td>
                            <td>{{data.totalFundsReceived}}</td>
                            <td>{{data.totalFundsSpent}}</td>
                            <td>{{data.totalAmountAvailable}}</td>
                            <td>
                                <span (click)="openNew('view',data)">
                                    View
                                </span> &nbsp;
                                <span *ngIf="roleId" (click)="openNew('edit',data)">
                                    Edit
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center" *ngIf="totalCount == 0">
                    <img src="../../../assets/img/notFound.png" width="419px" alt="">
                </div>
                <p-paginator [rows]="itemsPerPage" [totalRecords]="totalCount" (onPageChange)="onPageChange($event)"
                    [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
            </div>
        </div>
    </div>
    <p-confirmDialog [style]="{ width: '450px' }">
    </p-confirmDialog>
    <p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
        styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div style='position: relative; height: 100%;'>
                <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
                <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
            </div>
        </ng-template>
    </p-dialog>
    <p-dialog [(visible)]="viewDoc1" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
        styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div style='position: relative; height: 100%;'>
                <ngx-extended-pdf-viewer *ngIf="vFileExt1 === 'pdf'" [src]="vFileUrl1"></ngx-extended-pdf-viewer>
                <img *ngIf="vFileExt1 !== 'pdf'" [src]="vFileUrl1" style="display: block;" height="800px" />
            </div>
        </ng-template>
    </p-dialog>
    <p-dialog [(visible)]="viewDoc2" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
        styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div style='position: relative; height: 100%;'>
                <ngx-extended-pdf-viewer *ngIf="vFileExt2 === 'pdf'" [src]="vFileUrl2"></ngx-extended-pdf-viewer>
                <img *ngIf="vFileExt2 !== 'pdf'" [src]="vFileUrl2" style="display: block;" height="800px" />
            </div>
        </ng-template>
    </p-dialog>
    <p-dialog #dialog [(visible)]="projectDialog" [style]="{ width: '1000px' }" header="User Details" [modal]="true"
        styleClass="ib-dialog" (onHide)="hideDialog()">
        <p-header [ngClass]="fontService.headingClass">
            Project
        </p-header>
        <ng-template pTemplate="content">
            <form [formGroup]="projectForm">
                <div class="col-12">
                    <div class="form-inner-panel">
                        <div class="row gy-2 align-items-end">
                            <div class="col-12">
                                <h6 [ngClass]="fontService.headingClass">
                                    Details of Project
                                </h6>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Name
                                    of the Requisitioning Department</label>
                                <ng-select id="scheme" [items]="AgencyLists" formControlName="requestingDep"
                                    bindLabel="agencyName" appearance="outline" [closeOnSelect]="true"
                                    [clearable]="true" class="form-control" [readonly]='readonly'
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                                </ng-select>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="name">Project Name
                                </label>
                                <input type="text" class="form-control" formControlName="projectName" name="name"
                                    autocomplete="off" maxlength="60" [readonly]='readonly'
                                    onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </div>
                            <div class="col-sm-6 col-md-4" id="calender">
                                <label class="form-control-label req" for="scheme"
                                    [ngClass]="fontService.labelClass">Date of
                                    Administrative Sanction</label>
                                <p-calendar [readonlyInput]="true" styleClass="datepicker"
                                    formControlName="sanctionDate" dateFormat="dd/mm/yy" [maxDate]="toDate"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.agreementDate}"
                                    [disabled]='readonly' appendTo="body"></p-calendar>
                            </div>
                            <!-- <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Tentative
                                Land Acquisition Cost as per A.S(In Rupees)
                            </label>
                            <input type="number" class="form-control" formControlName="costOfLand" name="name"
                                autocomplete="off" maxlength="60"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                        </div> -->
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="name">Total extent
                                    of lands to be acquired
                                </label>
                                <input type="number" class="form-control" (keyup)="getValues($event)"
                                    formControlName="landsAcquire" name="name" [readonly]='readonly'
                                    (keypress)="keyPressNumbersDecimal($event,'land')" id="name" appNumberonly/>
                            </div>

                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Land
                                    Unit</label>
                                <ng-select id="scheme" [items]="landUnits" formControlName="landUnit" bindLabel="name"
                                    bindValue="id" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                                    class="form-control" [readonly]='readonly'
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                                </ng-select>
                            </div>
                        </div>
                        <div class="row gy-2 ">
                            <h6 [ngClass]="fontService.headingClass" class="mt-4">
                                Details of Land Acquisition
                            </h6>
                            <div formArrayName="LandDetailsForm">
                                <div *ngFor="let landDetails of detailsOfLand.controls; let i=index">
                                    <div [formGroupName]="i">
                                        <div class="row gy-2 align-items-end">
                                            <div class="col-sm-6 col-md-4">
                                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                                    for="name">District Name</label>
                                                <ng-select [appendTo]="'.p-dialog'" id="scheme"
                                                    (change)="getDistricts($event,i)" [items]="district"
                                                    formControlName="districtName" bindLabel="name" bindValue="id"
                                                    appearance="outline" [closeOnSelect]="true" [clearable]="true"
                                                    class="form-control districtDrop" [readonly]=true
                                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                                                </ng-select>
                                            </div>
                                            <div class="col-sm-6 col-md-4">
                                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                                    for="name">Extent to be acquired
                                                    in the district
                                                </label>
                                                <input type="number" class="form-control"
                                                    formControlName="landAcquireInDistrict" [readonly]=true
                                                    (keypress)="keyPressNumbersDecimal($event,'land')"
                                                    (keyup)="getValues($event)" id="name" appNumberonly/>
                                                <span class="text-danger error-info" *ngIf="showError">Acquired land in
                                                    the districts should be less than total amount of land </span>
                                            </div>
                                            <div class="col-sm-6 col-md-3">
                                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                                    for="name">LAO Category</label>
                                                <ng-select id="scheme" formControlName="Categorylao"
                                                    [readonly]=true [items]="categoryLAO" bindLabel="category"
                                                    bindValue="id" appearance="outline"
                                                    (change)="getDistricts($event,i)" [closeOnSelect]="true"
                                                    [clearable]="true" class="form-control"
                                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                                                </ng-select>
                                            </div>
                                            <div class="col-sm-6 col-md-3"
                                                *ngIf="!detailsOfLand.controls[i].get('lao').disabled">
                                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                                    for="name">LAO to whom the Project
                                                    to be mapped?</label>
                                                <ng-select id="scheme" formControlName="lao" [items]="LAOAdmin"
                                                    [readonly]=true bindLabel="designation"
                                                    bindValue="designation" appearance="outline" [closeOnSelect]="true"
                                                    [clearable]="true" class="form-control"
                                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                                                </ng-select>
                                            </div>
                                            <span [hidden]="!this.detailsOfLand.valid || readonly">
                                                <i class="pi pi-plus-circle mt-1" (click)="addSkill()"></i> &nbsp;
                                                <i class="pi pi-trash" *ngIf="detailsOfLand.length > 1"
                                                    (click)="removeSkill(i)"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <p-header [ngClass]="fontService.headingClass">
                                    Virtual Account
                                </p-header>
                            </div>

                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="name">Virtual AC No
                                </label>
                                <input type="text" class="form-control bgg" readonly formControlName="VAcNo" name="name"
                                    autocomplete="off" maxlength="60"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Bank
                                    Name
                                </label>
                                <input type="text" class="form-control bgg" readonly formControlName="bankName" name="name"
                                    autocomplete="off" maxlength="60" 
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="name">Amount
                                </label>
                                <input type="number" class="form-control bgg" readonly formControlName="amount"
                                    (keypress)="keyPressNumbersDecimal($event,'cost')" name="name" autocomplete="off"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" appNumberonly/>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Ifsc
                                    code
                                </label>
                                <input type="text" class="form-control bgg" readonly formControlName="ifscCode" name="name"
                                    autocomplete="off" maxlength="60"
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="name">Branch
                                </label>
                                <input type="text" class="form-control bgg" readonly formControlName="branch" name="name"
                                    autocomplete="off" maxlength="60"
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                            </div>

                            <div class="col-md-12">
                                <label class="form-control-label" for="upload"
                                    [ngClass]="fontService.labelClass">Upload AS
                                    G.O</label><br>
                                    <small [ngClass]="fontService.regularClass" >Note : Allowed file
                                        type Pdf only
                                        Maximum file size should be 5MB</small>
                            </div>
                            <div class="col-md-12" *ngIf="!isFile">
                                <p-fileUpload accept="image/png,image/jpeg,image/jpg,application/pdf" maxFileSize="5000000"
                                    (onUpload)="onUploadfile1($event)" [customUpload]="true" auto="true" mode="basic"
                                    (uploadHandler)="onUploadfile1($event)" multiple="false" [showUploadButton]="false"
                                    [disabled]="isFile || (viewOnly && !edit)" [showCancelButton]="false"
                                    chooseLabel="{{'common.upload'|translate}}">
                                </p-fileUpload>
                            </div>
                            <div class="col" *ngIf="isFile">
                                <a>{{project.fileUpload}}</a> &nbsp;
                                <i *ngIf="!viewOnly || edit" class="pi pi-times add-margin"
                                    (click)="removeDocument()"></i>
                                <div>
                                    <a class="cursor-pointer" (click)="viewDocument()">View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ng-template>
        <ng-template pTemplate="footer">
            <div *ngIf="!viewOnly">
                <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button cancelButton"
                    (click)="hideDialog()">
                    <span class="button-label" [ngClass]="fontService.buttonClass" custom-button
                        jhiTranslate="common.cancelbutton"></span>
                </button>
                <button [pTooltip]="(disableButton == false)? 'Total extent
                of lands must be equal to the acquired land
                in the districts':'' " [disabled]="!disableButton" pButton pRipple icon="pi pi-check"
                    class="p-button-text custom-button saveButton" (click)="saveData('edit')">
                    <span class="button-label" [ngClass]="fontService.buttonClass"
                        jhiTranslate="common.addbutton"></span>
                </button>
            </div>
        </ng-template>
    </p-dialog>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RDService {
  private baseUrl = 'https://localhost:11200';

  private biometricStatus = new BehaviorSubject<any>(null);

  fingureprint : Observable<any> = this.biometricStatus.asObservable();


  updatebiometric(data:any){
      this.biometricStatus.next(data); 
  }

  constructor(private http: HttpClient) { }

  getDeviceInfo(): Observable<string> {
    const url = `${this.baseUrl}/rd/info`;
    const headers = new HttpHeaders({ 'Content-Type': 'text/xml' });
    return this.http.request('DEVICEINFO', url, { headers, responseType: 'text' });
  }

  captureBiometric(): Observable<string> {
    const url = `${this.baseUrl}/rd/capture`;
    const headers = new HttpHeaders({ 'Content-Type': 'text/xml' });
    const body = `
      <PidOptions>
        <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="20000" otp="" posh="UNKNOWN" env="P" wadh="E0jzJ/P8UopUHAieZn8CKqS4WPMi5ZSYXgfnlfkWjrc=" />
        <Demo></Demo>
        <CustOpts>
          <Param name="ValidationKey" value="" />
        </CustOpts>
      </PidOptions>`;
    return this.http.request('CAPTURE', url, { headers, body, responseType: 'text' });
  }

  finguredata(pidOptions:any,pId:string){
    return this.http
    .post(`${environment.SERVER_API_URL}api/figurePrintStatuscheck?aadhaarNo=${pId}`, pidOptions, { observe: 'response' , responseType: 'text'})
  }

  finguredataReturnPayment(pidOptions:any,pId:string){
    return this.http
    .post(`${environment.SERVER_API_URL}api/figurePrintStatuscheckWithPayment?aadhaarNo=${pId}`, pidOptions, { observe: 'response' , responseType: 'text'})
  }
 
}
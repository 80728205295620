import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { AddProfile, Profile } from './profile';
export type EntityResponseType = HttpResponse<Profile>;
export type EntityArrayResponseType = HttpResponse<Profile[]>;

@Injectable({ providedIn: 'root' })
export class ProfileService {
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/profileList');
    protected resourceFliterVaildUrl = environment.SERVER_API_URL+('api/getProfilesValidByFilter');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveProfile');
    protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteProfile');
    protected getProfileUrl = environment.SERVER_API_URL+('api/getProfile');
    protected aadhaarUrl = environment.SERVER_API_URL+('api/getAadhaarUat');
    protected otpValidateUrl = environment.SERVER_API_URL+('api/getAadhaarDetailsUat');
    protected getImageUrl = environment.SERVER_API_URL+('api/getAadhaarPhoto');
    constructor(protected http: HttpClient) {}
    filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .get<Profile[]>(this.resourceFliterUrl, { params:options, observe: 'response' })
    }
    vaildFilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<Profile[]>(this.resourceFliterVaildUrl,filter, { params:options, observe: 'response' })
    }

    save(profile?:Profile): Observable<EntityArrayResponseType> {
        return this.http
        .post<Profile[]>(this.resourceSaveUrl,profile, {observe: 'response' })
    }
    
    delete(id: number): Observable<HttpResponse<{}>> {
      return this.http.delete(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
    }

    getProfile(id: number): Observable<HttpResponse<any>> {
      return this.http.get<AddProfile>(`${this.getProfileUrl}?id=${id}`, { observe: 'response' });
    }

    sendOtp(aadhaarNo: number): Observable<HttpResponse<{}>> {
      return this.http.get(`${this.aadhaarUrl}?aadhaarNo=${aadhaarNo}`, { observe: 'response' });
    }

    validateOtp(txnNo:string,aadhaarNo: number,otp:number): Observable<HttpResponse<{}>> {
      return this.http.get(`${this.otpValidateUrl}?txnNo=${txnNo}&aadhaarNo=${aadhaarNo}&otp=${otp}`, { observe: 'response' });
    }

    getImage(aadhaarNo: number): Observable<any> {
      return this.http.get(`${this.getImageUrl}?aadhaarNo=${aadhaarNo}`,{ observe: 'response', responseType: 'text' });
    }

   

}

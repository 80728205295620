import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AgencyService } from './payment-generation.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'app-payment-generation',
  templateUrl: './payment-generation.component.html',
  styleUrls: ['./payment-generation.component.scss'],
})

export class PaymentGenerationComponent implements OnInit {
  id: any;
  voucherNo: any;
  beneficiaryNo: any;
  today: Date;
  params: any;
  singleBeneficiary: any;
  beneficaryForm: any = {};
  errorShow: any = false;
  balanceAmount: any;
  previousTranscation: any;
  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    protected notificationService: NotificationService,
    private loginService: SigninService,

    private AgencyService: AgencyService
  ) {
    this.route.queryParams.subscribe((params) => {
      //console.log(params); // { order: "popular" }

      this.params = params;
    });

    this.AgencyService.balanceAmount(this.params.singleUser).subscribe(res => {
      this.balanceAmount= res.body
    })
  }
  products = [
    {
      id: '1000',
      code: 'f230fh0g3',
      name: 'Bamboo Watch',
      description: 'Product Description',
      image: 'bamboo-watch.jpg',
      price: 65,
      category: '10000',
      quantity: 24,
      inventoryStatus: 'INSTOCK',
      rating: 5,
    },
  ];
  ngOnInit(): void {
    this.AgencyService.getOneBeneficiary(this.params.beneficaryId).subscribe(
      (res: any) => {
        this.beneficiaryNo = res.body.data;
        this.singleBeneficiary = this.beneficiaryNo.landOwners.filter(
          (res) => res.id == this.params.singleUser
        )[0];
        this.beneficaryForm.bName =
          this.singleBeneficiary.benificiaryLandOwnerName;
        this.beneficaryForm.aadhaarNo = this.singleBeneficiary.aadharNumber;
        this.beneficaryForm.mobileNo = this.singleBeneficiary.mobileNumber;
        this.beneficaryForm.bankName = this.singleBeneficiary.nameOfBank;
        this.beneficaryForm.branchName = this.singleBeneficiary.branchName;
        this.beneficaryForm.ifScCode = this.singleBeneficiary.ifscCode;
        this.beneficaryForm.accNo = this.singleBeneficiary.accountNumber;

        this.voucherNo =
          this.beneficiaryNo.districtLGDCode.code + '_' + Date.now();
        this.today = new Date();
      }
    );
  }


  onUpload(event: UploadEvent) {
    this.messageService.add({
      severity: 'info',
      summary: 'Success',
      detail: 'File Uploaded with Basic Mode',
    });
  }

  transcationAmount(eve) {
    if (
      Number(eve.target.value) >
      Number(this.singleBeneficiary.compensationAmount)
    ) {
      this.errorShow = true;
    } else {
      this.errorShow = false;
    }
  }
  generateVoucher() {
    if (
      this.beneficaryForm.ProceedingNo != '' &&
      this.beneficaryForm.ProceedingDate != '' &&
      this.beneficaryForm.description != ''
    ) {
      let payload = {
        beneficiaryLandOwnerId: this.params.singleUser,
        proceedingsNumber: this.beneficaryForm.ProceedingNo,
        proceedingsDate: this.beneficaryForm.ProceedingDate,
        proceedingsUpload: '',
        description: this.beneficaryForm.description,
        transactionAmount: this.beneficaryForm.transcationAmount,
        status: 'NEW',
        voucherNo:this.voucherNo
      };

      this.AgencyService.save(payload).subscribe((res) => {
        this.notificationService.alertSuccess('Saved Successfully', '');

        this.router.navigateByUrl('/payment')
      });
    }
  }

  visible: boolean = false;

  showDialog() {
    this.AgencyService.previousTranscation(this.params.singleUser).subscribe(res => {
      this.previousTranscation = res.body.data
      //console.log(res);
      
    })
    this.visible = true;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'croreTwoDecimal'
})
export class CroreTwoDecimalPipe implements PipeTransform {

  transform(value: number): string {
    if (!value && value !== 0) return '';

    const croreValue = value / 10000000; // 1 crore = 10 million (10,000,000)
    return `${croreValue.toFixed(2)} `;
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AgencyService } from './lao.service';
import { Agency, AgencyDto } from './lao';
import { SearchResult } from 'src/app/shared/models/search-result';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelType } from '../level-type/level-type';
import { ZonalService } from '../zonal/zonal.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-add-fund-allocation',
  templateUrl: './add-lao.component.html',
  styleUrls: ['./add-lao.component.scss']
})
export class AddFundAllocationComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;
  // @ViewChild('department', ) department: NgSelectComponent;

  agencyDialog: boolean = false;
  cols: any[];
  zonalDto: any;
  searchResult: SearchResult<Agency> = new SearchResult<Agency>();
  agency?: AgencyDto;
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  levelType: LevelType[];
  levelTypeDto: LevelType;
  levelMasterList: any;
  zonalList: any;
  // validation fields properties

  //required fields property

  fund: any = {
    category: '',
    name: ''
  }
  showNameError: any;
  showMobileNoError: string;
  showEmailError: string;
  showEmailUniError: string;
  gstUniError: string;
  viewOnly: boolean = false;
  hamHeader: string;
  subHeader: string;
  agencyList: any;
  stateList: any;
  state: any[];
  district: any;
  totalCount: any;
  pageCount: number = 0;
  index: number;
  roleId: boolean = false;
  pageLength: number;
  projectList: any;
  categoryLAO: any;
  roles: any;
  districtId: any;
  selectCategory: any;
  selectRole: any;
  constructor(
    private agencyService: AgencyService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private loginService: SigninService,
    private levelTypeService: LevelTypeService,
    private zonalService: ZonalService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
    this.getLaoCategory()
    this.getRoles()
    this.agency = new AgencyDto();
    this.submitted = false;
    this.agencyService.getLevelMaster().subscribe(
      (res: HttpResponse<any[]>) => {
        let stateList = res.body;
        this.state = stateList.filter((res) => res.levelTypeDto.id == 1);
        this.district = stateList.filter((res) => res.levelTypeDto.id == 2);
      },
      () => {
        this.onError();
      }
    );
    this.agencyDialog = true;
    this.viewOnly = false;

    if (this.router.url == '/agency') {
      this.hamHeader = 'Requisitioning Departments';
      this.subHeader = 'Home  /   Requisitioning Departments';
    }
    // this.AgencyService.getAgencys().then(data => this.agencys = data);
    // this.loadPage(1);
  }

  getUserrole() {
    this.loginService.getuserDto().subscribe(
      (res) => {
        if (res.body.role.id != 1 && res.body.role.id != 8 && res.body.role.id != 5 && res.body.role.id != 4 && res.body.role.id != 3) {
          this.roleId = true
        } else {
          this.roleId = false
        }
      },
      (onError) => { }
    );
  }

  ngAfterViewInit() {
    // this.updateDialogFont();
  }

  govermentType = [
    {
      name: 'CENTRAL_GOVERNMENT',
    },
    {
      name: 'STATE_GOVERNMENT',
    },
  ];


  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);
    this.agencyService.filter({ ...query }, this.filterProps).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (err) => {
        this.spinner.hide();
        this.notificationService.alertError('Error!', err.error.error);
      }
    );
  }

  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data.data ?? [];
    this.agencyList = data.data;
    this.totalCount = data.totalCount;
  }

  protected onError(): void { }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  vendorFilter($event) {
    let filter = new filterQuery();
    filter.key = 'name';
    filter.operation = 'contains';
    filter.value = $event.term;
    let filterquery = [];
    filterquery.push(filter);
    this.zonalService.filter({ size: 10, page: 0 }, filterquery).subscribe(
      (res: HttpResponse<any>) => {
        this.zonalList = res.body;
      },
      () => { }
    );
  }

  onProjectChange(e) {
    let districtList = e.projectLocation
    districtList.forEach(element => {
    });

  }

  onDistrict(e) {
    this.districtId = e.id

  }

  onLAO(e) {
    this.selectCategory = e.id

  }

  getLaoCategory() {
    this.agencyService.getCategoryLao().subscribe((res: any) => {
      this.categoryLAO = res.body.data;
    });
  }

  getRoles() {
    this.agencyService.getRoles().subscribe((res: any) => {
      this.roles = res.body;
    });
  }







  hideDialog() {
   
    this.agency = new AgencyDto();
    this.levelTypeDto = new LevelType();
    this.levelTypeDto = null;
    this.levelType = null;
    this.levelMasterList = null;
    this.agencyDialog = false;
    this.showNameError = null;
    this.showMobileNoError = null;
    this.showEmailError = null;
    this.showEmailUniError = null;
    this.gstUniError = null;
    // this.viewOnly = false;
  }

  keyPressNumberForMobile(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) || event.target.value.length >= 10) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  saveLao(data) {
    if (data == 'add') {
      let payload = {
        "mobile": this.fund.mobile,
        "email": this.fund.email,
        "zonalName": null,
        "userActiveFlag": "Yes",
        "resetPassword": null,
        "loginAttempt": null,
        "resetDate": null,
        "implementingAgencyId": null,
        "designation":this.fund.designaiton,
        "levelMasterId": this.districtId,
        "profileStatus": "Yes",
        "keyCloakUuid": "null",
        "laoCategoryId": this.selectCategory,
      };
      this.agencyService.save(payload).subscribe(
        (res: HttpResponse<Agency[]>) => {
          this.agencyDialog = false;
          this.notificationService.alertSuccess('Saved Successfully', '');
          this.router.navigateByUrl('/lao-list')
          this.hideDialog();
        },
        (err) => {
          this.onError();
          this.notificationService.alertError('Error!', err.error.error);
          this.spinner.hide();
        }
      );
    } else {

      let payload = {
        agencyName: this.agency.name,
        governmentType: this.agency.govermentType.name,
        address1: this.agency.address1,
        address2: this.agency.address2,
        street: this.agency.street,
        city: this.agency.city,
        districtLgdCode: this.agency.district.id,
        state: this.agency.state.id,
        pinCode: this.agency.pincode,
        authorizedPersonName: this.agency.agency,
        authorizedPersonDesignation: this.agency.designation,
        authorizedPersonMobileNumber: this.agency.mobile,
        authorizedPersonEmailId: this.agency.email,
        officePhoneNumber: this.agency.offliceMobile,
        officeEmailId: this.agency.ooficeemail,
      };
      this.agencyService.edit(payload, this.agency.id).subscribe(
        (res: HttpResponse<Agency[]>) => {
          this.agencyDialog = false;
          this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
          this.hideDialog();
        },
        (err) => {
          this.onError();
          this.notificationService.alertError('Error!', err.error.error);
          this.spinner.hide();
        }
      );
    }
  }

  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }

  onPageChange(event: PageEvent) {
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    let page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.loadPage(page);
  }





  

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '../../shared/font-service/font.service';
import { ValidationService } from '../../shared/validation/validation.service';
import { NotificationService } from '../../alert/notification.service';
import { Dialog } from 'primeng/dialog';
import { SearchResult } from '../../shared/models/search-result';
import { Project } from '../../project/project.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SigninService } from '../../auth/signin/signin.service';
import { PaymentService } from '../../payments/payment-voucher/payment-voucher.service';
import { ProjectService } from '../../project/project.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ReportService } from './report.service';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-beneficiary-based-report',
  templateUrl: './beneficiary-based-report.component.html',
  styleUrls: ['./beneficiary-based-report.component.scss']
})
export class BeneficiaryBasedReportComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;

  projectDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Project> = new SearchResult<Project>();
  checked: boolean;
  project?: Project;
  projectFilter!: string;
  fundFilter: any = ''
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any;
  commercialList: Project[];

  readonly: boolean;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  requestingDepartment: any = ''
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  bankName: any;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showCompanyNameError: string;
  showPanError: string;
  showeEmailError: string;
  showAadhaarNoError: string;
  showGstError: string;
  showaccountNoError: string;
  bankList: any;
  villageLevelDto: null;
  user: any;
  isFile: boolean;
  vFileExt: any;
  viewDoc: boolean;
  vFileUrl: string;
  enablepublicContributionAmount: boolean = false;
  publicContributionAmount = [
    {
      id: '1',
      name: '1/3',
      value: 0.33,
    },
    {
      id: 2,
      name: '1/5',
      value: 0.2,
    },
    {
      id: 3,
      name: '1/2',
      value: 0.5,
    },
  ];
  selectedpublicContribution: any;
  enableView: boolean = false;
  blockLevelDto: null;
  limitAmount: number;
  viewOnly: boolean = false;
  agencyList: any;
  edit: boolean;
  projectForm: FormGroup;
  hamHeader: string;
  subHeader: string;
  projectList: any;
  landUnits: any;
  AgencyLists: any;
  getOneProject: any;
  state: any[];
  district: any[];
  beneficiary=''
  projectName
  date: Date | undefined;
  LAOAdmin: any[];
  singleProject: any;
  isShow: boolean = false;
  remainValue: number;
  disableButton: boolean = true;
  items: any;
  activeIndex: number;
  totalCount: any;
  fileUpload: any;
  pageCount: number = 0;
  index: any;
  showError: boolean = false;
  fromDate: any = '';
  toDate: any = ''
  roleId: boolean = false;
  districtId: any[];
  label: string;
  projectId: any;
  districtIds: any;
  deletedProject: any[];
  editContent: any;
  deletePayload: any;
  pageLength: number;
  isShowTable: boolean = false;
  maxDate: Date;
  actionName: any;
  beneficiaryList: any;
  constructor(
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    public datepipe: DatePipe,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private paymentService: PaymentService,
    private projectService: ProjectService,
    private reportService: ReportService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      // this.updateDialogFont();
    });
    this.getUserrole();
  }

  typeOfFund = [
    {
      name: 'Project with Amount',
      value: 'PROJECT_WITH_AMOUNT',
    },
    {
      name: 'Project without Amount',
      value: 'PROJECT_WITHOUT_AMOUNT',
    },
  ]
  ngOnInit() {
    this.maxDate = new Date()
    this.getProjectsFilter(1, 'first')
    this.getProjects()
  }

  getProjects() {
    this.projectService.getProjects().subscribe(
      (res: any) => {
        this.projectList = res.body.data;
      },
      () => {
      }
    )
  }
  getProjectsFilter(page, navigator) {
    this.spinner.show();
    let fromDate
    let toDate
    let projectName
    let benificiaryName

    this.actionName = navigator
    if (navigator == 'first') {
      fromDate = this.datepipe.transform(new Date("01-01-2023"), 'yyyy-MM-dd');
      toDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    } else {
      fromDate = this.datepipe.transform(this.fromDate, 'YYYY-MM-dd')
      toDate = this.datepipe.transform(this.toDate, 'YYYY-MM-dd')
    }
    const pageToLoad: number = page ?? this.page ?? 1;
    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      sort: ['createdOn,asc'],
      fromDate: fromDate ?? '',
      toDate: toDate ?? '',
      projectName : this.projectName ? this.projectName.projectName :'',
      benificiaryName :this.beneficiary ?? ''
    };
    this.reportService.getProjectFilter(filterQuery).subscribe(res => {
      this.spinner.hide();
      this.filterProps = this.filterProps ? this.filterProps : [];
      this.filterProps.push({
        key: 'activeFlag',
        operation: 'equals',
        value: 'YES',
      });
      this.onSuccess(res.body, res.headers, page, '');
    })
  }

  getUserrole() {
    this.loginService.getuserDto().subscribe(
      (res) => {
        if (
          res.body.role.id != 1 &&
          res.body.role.id != 5 &&
          res.body.role.id != 4 &&
          res.body.role.id != 3
        ) {
          this.roleId = true;
        } else {
          this.roleId = false;
        }
      },
      (onError) => { }
    );
  }

  forms() {
    this.projectForm = this.formBuilder.group({
      requestingDep: ['', Validators.required],
      projectName: ['', Validators.required],
      sanctionDate: ['', Validators.required],
      costOfLand: ['', Validators.required],
      landsAcquire: ['', Validators.required],
      unitsOfland: ['', Validators.required],
      TypeOfFund: ['', Validators.required],
      LandDetailsForm: this.formBuilder.array([]),
    });
  }

  detailsform(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      districtName: ['', Validators.required],
      landAcquireInDistrict: ['', Validators.required],
      lao: ['', Validators.required],
      isFlag: [],
    });
  }

  getProject() {
    //console.log(this.projectFilter)
  }

  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  get detailsOfLand(): FormArray {
    return this.projectForm.get('LandDetailsForm') as FormArray;
  }

  addSkills() {
    this.detailsOfLand.push(this.detailsform());
  }

  addSkill() {
    let totalLand: number = 0;
    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.landAcquireInDistrict);
    });
    if (totalLand != 0) {
      if (this.projectForm.get('landsAcquire').value >= totalLand) {
        this.remainValue =
          Number(this.projectForm.get('landsAcquire').value) -
          Number(totalLand);
        if (totalLand == this.projectForm.get('landsAcquire').value) {
          this.notificationService.alertInfo(
            'Equal',
            'Acquired land in the district is equal to total amount of land'
          );
        } else {
          this.detailsOfLand.push(this.detailsform());
        }
      } else {
        this.notificationService.alertError(
          'Alert',
          'Acquired land in the district should be less than total amount of land'
        );
      }
    }
  }

  removeSkill(i: number) {

    let deleteData = this.detailsOfLand.at(i).value;
    this.deletedProject.push(deleteData);
    this.deletedProject.forEach(element => {
      if (element.id != '' && element.id != undefined) {
        this.editContent.push(element)
        this.deletedProject = []
      }
    });

    this.detailsOfLand.removeAt(i);

    let totalLand: number = 0;
    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.landAcquireInDistrict);
    });
    if (Number(this.projectForm.get('landsAcquire').value) == totalLand) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }
    this.getValues('');
  }

  ngAfterViewInit() {
    // this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;
    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }


  calcAmount() {
    if (!this.enablepublicContributionAmount) {
      this.project.administrativeSanctionAmount = this.project.totalCost;
      return;
    }
    this.projectService
      .getAmount(this.selectedpublicContribution.id, this.project.totalCost)
      .subscribe(
        (res: any) => {
          this.project.publicContributionAmount = res.body;
          this.project.administrativeSanctionAmount = Number(
            (
              this.project.totalCost - this.project.publicContributionAmount
            ).toFixed(2)
          );
        },
        () => { }
      );
  }

  onUploadfile1($event) {
    const formData = new FormData();
    const i = $event.files.length - 1;
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }
    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.notificationService.alertSuccess('Image Upload SuccessFully', '');
        this.fileUpload = res.body.fileDownloadUri;
        this.project.fileUpload = res.body.fileName;
        this.isFile = true;
      },
      (onerr) => { }
    );
  }

  getValues(eve) {
    let totalLand: number = 0;
    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.landAcquireInDistrict);
    });
    if (Number(this.projectForm.get('landsAcquire').value) == totalLand) {
      this.disableButton = true;
      this.showError = false;
    } else if (Number(this.projectForm.get('landsAcquire').value) < totalLand) {
      this.showError = true;
      this.disableButton = false;
    } else {
      this.disableButton = false;
      this.showError = false;
    }
  }

  viewDocument() {
    this.paymentService.viewimage(this.project.fileUpload).subscribe((res) => {
      const filename = res.body.fileName;
      this.vFileExt = filename.split('.').pop();
      this.vFileUrl =
        'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc = true;
    });
  }

  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }

  onPageChange(event: PageEvent) {
    //console.log(event);
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    let page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.getProjectsFilter(page,this.actionName);
  }
  removeExtraSpaces(text: string): string {
    return text.replace(/\s+/g, ' ').trim();
  }

  hideDialog() {
    this.ngSelectInvalid = false;
    this.project = new Project();
    this.projectDialog = false;
    this.enablepublicContributionAmount = null;
    this.selectedpublicContribution = null;
    this.isFile = false;
    this.viewOnly = false;
    this.edit = false;
  }



 



  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,
    navigate: any
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    // this.searchResult.items = data ?? [];
    this.beneficiaryList = data.data;
    this.totalCount = data.totalCount;
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    // this.loadPage(event.page);
  }

  protected onError(): void { }

  onSelectRow($event) {

    if ($event.type === 'select' || $event.type === 'view') {
      if ($event.type === 'view') {
        this.viewOnly = true;
      } else if ($event.type === 'select') {
        this.viewOnly = true;
        this.edit = true;
      }
      this.paymentService.getProjectByWorkid($event.value.workId).subscribe(
        (res: Project) => {
          this.project = res;
          if (this.project.technicalSanctionDate) {
            this.project.technicalSanctionDate = new Date(
              this.project.technicalSanctionDate
            );
          }
          if (this.project.agreementDate) {
            this.project.agreementDate = new Date(this.project.agreementDate);
          }
          this.projectDialog = true;
          if (this.project.administrativeSanctionDate) {
            this.project.administrativeSanctionDate = new Date(
              this.project.administrativeSanctionDate
            );
          }
          if (this.project.fileUpload) {
            this.isFile = true;
          }
          this.publicContributionAmount.forEach((element) => {
            if (element.value === this.project.contributionPercentage) {
              this.selectedpublicContribution = element;
            }
          });

          if (this.project.publicContribution == 'Yes') {
            this.enablepublicContributionAmount = true;
          }
        },
        () => {
          this.onError();
        }
      );
    }

  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AlertMessage } from './alert-message';
import { NotificationService } from './notification.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  providers: [MessageService]
})
export class AlertComponent implements OnInit, OnDestroy {

  private notifySubscription!: Subscription;

  constructor(private msgSvc: MessageService, private notifySvc: NotificationService) {
    this.notifySubscription = this.notifySvc.alertsObserver.subscribe(
      alertMsg => {
        this.showAlert(alertMsg);
      }
    );
   }

   ngOnDestroy(): void {
    if (this.notifySubscription) {
      this.notifySubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
   
  }

  showAlert(msg: AlertMessage): void {
    this.msgSvc.clear();

    this.msgSvc.add(msg);
  }

  clear(){
    this.msgSvc.clear();

  }


}


export class AuthUser {
    username: string;
    password: string;
    refreshToken: string;
    keyCloakUserId: string;
    
}
export class ResetPassword {
	UserName:string;
	currentPassword:string;
	oldPassword:string;
}
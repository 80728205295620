<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Project</h2>
</div>
<div class="container bg">
    <form [formGroup]="projectForm">
        <div class="col-12">
            <div class="form-inner-panel">
                <div class="row gy-2">
                    <div class="col-12">
                        <h6 [ngClass]="fontService.headingClass">
                            Details of Project
                        </h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Name of the
                            Requisitioning Department</label>
                        <ng-select id="scheme" [items]="AgencyLists" formControlName="requestingDep"
                            bindLabel="agencyName" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                            class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                        </ng-select>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Project Name
                        </label>
                        <input type="text" class="form-control" formControlName="projectName" name="name"
                            autocomplete="off" maxlength="60"
                            onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                            [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Total extent
                            of lands to be acquired(In hectare)
                        </label>
                        <input type="number" class="form-control" formControlName="landsAcquire" (keyup)="getValues($event)"
                            (keypress)="keyPressNumbersDecimal($event,'land')" name="name" appNumberonly/>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">
                            Land Unit</label>
                        <ng-select id="scheme" [items]="landUnits" formControlName="landUnit" bindValue="id"
                            bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                            class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                        </ng-select>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                            for="name">Amount
                        </label>
                        <input type="number" class="form-control"
                            formControlName="landsAmount" name="name"
                             id="name" appNumberonly/>
                    </div>
                    <div class="col-sm-6 col-md-4">
                        <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Date of
                            Administrative Sanction</label>
                        <p-calendar [readonlyInput]="true" [readonlyInput]="true" [maxDate]="toDate" styleClass="datepicker" formControlName="sanctionDate"
                        dateFormat="dd/mm/yy" class="same_line_fix" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.agreementDate}"
                            appendTo="body"></p-calendar>
                    </div>
                    
                </div>
                <div class="row gy-2 ">
                    <h6 [ngClass]="fontService.headingClass" class="mt-4">
                        Details of Land Acquisition
                    </h6>
                    <small>Note : Total extent
                        of lands to be acquired is equal to the acquired
                        in the districts </small>
                    <div formArrayName="LandDetailsForm" class="mb-3">
                        <div *ngFor="let landDetails of detailsOfLand.controls; let i=index">
                            <div [formGroupName]="i">
                                <div class="row gy-2 align-items-end">
                                    <div class="col-sm-6 col-md-3">
                                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                            for="name">District Name</label>
                                        <ng-select id="scheme" [items]="district" formControlName="districtName"
                                            bindLabel="name" bindValue="id" dropdownPosition="top"
                                            [closeOnSelect]="true" [clearable]="true" class="form-control"
                                            [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                                        </ng-select>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                            for="name">Extent to be acquired
                                            in the district(In hectare)
                                        </label>
                                        <input type="number" class="form-control"
                                            (keypress)="keyPressNumbersDecimal($event,'land')"
                                            formControlName="landAcquireInDistrict" (keyup)="getValues($event)"
                                            name="name" appNumberonly/>
                                        <span class="text-danger error-info" *ngIf="showError">Acquired land in
                                            the districts should be less than total amount of land </span>
                                    </div>
                                    <div class="col-sm-6 col-md-3">
                                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                            for="name">LAO Category</label>
                                        <ng-select id="scheme" formControlName="Categorylao" [items]="categoryLAO"
                                            bindLabel="category" bindValue="id" appearance="outline"
                                            (change)="getDistricts($event,i)" [closeOnSelect]="true" [clearable]="true"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                                        </ng-select>
                                    </div>
                                    <div class="col-sm-6 col-md-3"
                                        *ngIf="!detailsOfLand.controls[i].get('lao').disabled">
                                        <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                            for="name">LAO to whom the Project
                                            to be mapped?</label>
                                        <ng-select id="scheme1" formControlName="lao" [items]="LAOAdmin"
                                            bindLabel="designation" bindValue="designation" appearance="outline"
                                            [closeOnSelect]="true" [clearable]="true" class="form-control disabled" appNgSelectInputRestriction>
                                        </ng-select>
                                    </div>
                                    <span [hidden]="!this.detailsOfLand.valid">
                                        <i class="pi pi-plus-circle mt-1" (click)="addSkill()"></i> &nbsp;
                                        <i class="pi pi-trash" *ngIf="detailsOfLand.length > 1"
                                            (click)="removeSkill(i)"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <label class="form-control-label" for="upload" [ngClass]="fontService.labelClass">Upload
                            AS G.O</label><br>
                        <small [ngClass]="fontService.regularClass" >Note : Allowed file
                            type Pdf only
                            Maximum file size should be 5MB</small>
                    </div>
                    <div class="col-md-12" *ngIf="!isFile">
                        <p-fileUpload accept="image/png,image/jpeg,image/jpg,application/pdf" maxFileSize="5000000"
                            (onUpload)="onUploadfile1($event)" [customUpload]="true" auto="true" mode="basic"
                            (uploadHandler)="onUploadfile1($event)" multiple="false" [showUploadButton]="false"
                            [disabled]="isFile || (viewOnly && !edit)" [showCancelButton]="false"
                            chooseLabel="{{'common.upload'|translate}}">
                        </p-fileUpload>

                    </div>
                    <div class="col" *ngIf="isFile">
                        <a class="cursor-pointer" (click)="viewDocument()">{{project.fileUpload}}</a>
                        <i *ngIf="!viewOnly || edit" class="pi pi-times add-margin" (click)="removeDocument()"></i>
                    </div>
                    <div class="row mb-5 mt-4" *ngIf="isShowBankDetails">
                        <div class="col-md-12 mb-3">
                            <p-header [ngClass]="fontService.headingClass">
                                Virtual Account
                            </p-header>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Virtual
                                AC
                                No
                            </label>
                            <input type="text" class="form-control" readonly formControlName="VAcNo" name="name"
                                maxlength="60" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}"
                                id="name" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Bank
                                Name
                            </label>
                            <input type="text" class="form-control" readonly formControlName="bankName" name="name"
                                autocomplete="off" maxlength="60"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Ifsc
                                code
                            </label>
                            <input type="text" class="form-control" readonly formControlName="ifscCode" name="name"
                                autocomplete="off" maxlength="60"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                        </div>
                        <div class="col-sm-6 col-md-4 mt-2">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Branch
                            </label>
                            <input type="text" class="form-control" readonly formControlName="branch" name="name"
                                autocomplete="off" maxlength="60"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
                        </div>
                        <div class="col-sm-6 col-md-4 mt-2">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Amount
                            </label>
                            <input type="number" class="form-control" readonly formControlName="amount"
                                (keypress)="keyPressNumbersDecimal($event,'cost')"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" appNumberonly/>
                        </div>
                    </div>
                    <div *ngIf="!viewOnly" class="btnDrop">
                        <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button cancelButton"
                            routerLink="/project">
                            <span class="button-label" [ngClass]="fontService.buttonClass" custom-button
                                jhiTranslate="common.cancelbutton"></span>
                        </button> &nbsp;
                        <button [pTooltip]="(disableButton == false)? 'Total extent
                        of lands is equal to the acquired land
                        in the districts':'' " [disabled]="!disableButton || projectForm.invalid" pButton pRipple
                            icon="pi pi-check" class="p-button-text custom-button saveButton" (click)="saveData('Add')">
                            <span class="button-label" [ngClass]="fontService.buttonClass">Submit</span>
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </form>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
    <p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
        styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div style='position: relative; height: 100%;'>
                <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
                <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
            </div>
        </ng-template>
    </p-dialog>
    <p-dialog [(visible)]="viewDoc1" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
        styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div style='position: relative; height: 100%;'>
                <ngx-extended-pdf-viewer *ngIf="vFileExt1 === 'pdf'" [src]="vFileUrl1"></ngx-extended-pdf-viewer>
                <img *ngIf="vFileExt1 !== 'pdf'" [src]="vFileUrl1" style="display: block;" height="800px" />
            </div>
        </ng-template>
    </p-dialog>
    <p-dialog [(visible)]="viewDoc2" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
        styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div style='position: relative; height: 100%;'>
                <ngx-extended-pdf-viewer *ngIf="vFileExt2 === 'pdf'" [src]="vFileUrl2"></ngx-extended-pdf-viewer>
                <img *ngIf="vFileExt2 !== 'pdf'" [src]="vFileUrl2" style="display: block;" height="800px" />
            </div>
        </ng-template>
    </p-dialog>
</div>
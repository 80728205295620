// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  I18N_HASH: '',
  production: false,
  environment: 'development',
   SERVER_API_URL: 'https://laatanapi.demodev.in/',
  // SERVER_API_URL: 'https://tnlaatan.iob.in/',
  // SERVER_API_URL: 'https://pmayuat.iob.in:4434/',
  // SERVER_API_URL: 'http://43.204.161.64:8080/',
    // SERVER_API_URL: 'http://localhost:8081/',
  // CDAC_URL:'https://es-staging.cdac.in/esignlevel2/2.1/form/signdoc',
  CDAC_URL:'https://esignservice.cdac.in/esign2.1/2.1/form/signdoc',
  RAZOR:'https://ifsc.razorpay.com',
  VERSION: 'Dev-4.1.0',
  encryptKey: "3DC1E1CFC314CF853A2E67995760D289",
  rolestate:"SA",
  roleDistrict:"DMAKER",
  roleDeputyBdo:"BMAKER",
  roleZonal:"ZC1",
  roleBDO:"BC2",
  roleDistrictC1:"DC1",
  roleDistrictC2:"DC2",
  roleDistrictAdmin:"DA",
  roleOtherDeparment:"ODM",
  roleOtherDeparmentC1:"ODC1",
  roleOtherDeparmentC2:"ODC2",
  roleMla:"MLA",
  roleSuperAdmin:"SSA",
  bankAdmin:"BA",
};

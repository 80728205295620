import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SearchResult } from 'src/app/shared/models/search-result';
import { RaiseRequestService } from '../raise-request/raise-request.service';
RaiseRequestService
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-status-request',
  templateUrl: './status-request.component.html',
  styleUrls: ['./status-request.component.scss']
})
export class StatusRequestComponent implements OnInit {
  statusReq: any;
  itemsPerPage: any = 10;
  pageCount: number=0;
  pageLength: number;
  page: number;
  totalCount: any;
  index: any;

  constructor(private RaiseRequestService: RaiseRequestService) { }

  ngOnInit(): void {
    this.loadPage()
  }


  loadPage(page?: number, dontNavigate?: boolean): void {

    const pageToLoad: number = page ?? this.page ?? 1;

    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };


    this.RaiseRequestService.getStatusRequest({ ...query }).subscribe((res: any) => {
      this.statusReq = res.body.data
      this.totalCount = res.body.totalCount;

      //console.log(res);
    })
  }

  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }

  onPageChange(event: PageEvent) {
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    let page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.loadPage(page);
  }
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { createRequestOption } from '../auth/request/request-util';

export type EntityListResponseType = HttpResponse<any>;

@Injectable({ providedIn: 'root' })
export class ReportService {
  protected loadReportUrl = environment.SERVER_API_URL + 'api/loadReport';
  protected downoadExcel = environment.SERVER_API_URL + 'api/download/excel/exportAll';

 
  constructor(protected http: HttpClient) {}
  getData(parms,payload): Observable<any> {
    if(payload === null){
        payload = []
      }
      const options = createRequestOption(parms);
        return this.http
        .post<any[]>(`${environment.SERVER_API_URL}api/executeReport`,payload, { params:options, observe: 'response' })
  }
  
  loadReport(code){
    return this.http.get(` ${this.loadReportUrl}?key=${code}`,{observe: 'response'} )
  }
   
  downloadExcel(code){
    return this.http.get(`${this.downoadExcel}?reportType=${code}`,{observe: 'response',responseType:'text'} )
  }
 
}

import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})


export class DashboardNewComponent implements OnInit {
	fundDtails: any;
	getAmountSpent: any;
	fundAllocation: any;
	fundList: any;
	chartOptions: { animationEnabled: boolean; exportEnabled: boolean; axisX: { reversed: boolean; }; axisY: { prefix: string; suffix: string; includeZero: boolean; }; toolTip: { shared: boolean; }; data: { type: string; name: string; showInLegend: string; yValueFormatString: string; color: string; dataPoints: { y: number; label: string; }[]; }[]; };
	fundSpent: any = [];
	chartOptions1: any;
	districts: any;
	selectedYear = ''
	selectedMonth = ''
	chartOptions2: any
	districtList: any[];
	projectPercentage: any;
	percentage: any[];
	projectFund: any;
	projectCount: any;
	districtCount: any;
	month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	year = []
	yearList: number[];
	isShowFirstChart: boolean = false;
	isShowSecondChart: boolean;
	isShowThirdChart: boolean;
	totalAmountSpent: any;
	totalNoOfBeneficiaries: any;
	projectCountTotal: any;
	constructor(private ds: DashboardService,
		private spinner: NgxSpinnerService,
	) {
	}
	ngOnInit(): void {
		this.yearList = this.year.reverse()
		this.getFunds()
		this.getTotalFund()
		this.getDistricts()
		this.getProjectPercentage()
		this.getProjectFundAllocation()
		this.districtWiseCount()
		this.projectByDepartments()
		this.generateArrayOfYears()
	}
	chart: any;

	generateArrayOfYears() {
		var max = new Date().getFullYear()
		var min = max - 20
		this.year = []
		for (var i = max; i >= min; i--) {
			this.year.push(i)
		}
		return this.year
	}


	getProjectPercentage() {
		this.percentage = []
		this.spinner.show()
		this.isShowThirdChart = false
		let params = {
			year: this.selectedYear,
			month: new String(this.selectedMonth).toUpperCase()
		}
		this.ds.getBeneficiaryPercentage(params).subscribe(res => {
			this.projectPercentage = res.body.data
			this.isShowThirdChart = true
			this.spinner.hide()
			this.projectPercentage.label.forEach(element => {
				let obj = {
					name: element.name,
				}
				this.percentage.push(obj)
			});
			this.projectPercentage.percentages.forEach((element, i) => {
				this.percentage[i].y = element
			});
			//console.log(this.percentage, 'this.percentage');

			this.chartOptions2 = {
				animationEnabled: true,
				data: [{
					type: "doughnut",
					yValueFormatString: "#,###.##'%'",
					indexLabel: "{name}",
					dataPoints: this.percentage
				}]
			}
		})
	}

	getProjectFundAllocation() {
		let params = {
			year: this.selectedYear,
			month: new String(this.selectedMonth).toUpperCase()
		}
		this.ds.getProjectFund(params).subscribe(res => {
			this.projectFund = res.body.data
		})
	}
	districtWiseCount() {
		let params = {
			year: this.selectedYear,
			month: new String(this.selectedMonth).toUpperCase()
		}
		this.ds.districtCount(params).subscribe(res => {
			this.districtCount = res.body.data.data
			this.totalAmountSpent = res.body.data.totalAmountSpent
			this.totalNoOfBeneficiaries = res.body.data.totalNoOfBeneficiaries
		})
	}
	projectByDepartments() {
		let params = {
			year: this.selectedYear,
			month: new String(this.selectedMonth).toUpperCase()
		}
		this.ds.projectByCount(params).subscribe(res => {
			this.projectCount = res.body.data.data
			this.projectCountTotal = res.body.data.totalProjects


			//console.log(this.projectCount, 'this.projectCount');

		})
	}

	getFunds() {
		let params = {
			year: this.selectedYear,
			month: new String(this.selectedMonth).toUpperCase()
		}
		this.ds.getFundReport(params).subscribe(res => {
			this.fundDtails = res.body.data
		})
	}

	filter() {
		this.getFunds()
		this.getProjectFundAllocation()
		this.districtWiseCount()
		this.projectByDepartments()
		this.getDistricts()
		this.getTotalFund()
		this.getProjectPercentage()
	}

	clear() {
		this.selectedMonth = ''
		this.selectedYear = ''
		this.isShowFirstChart = false
		this.isShowSecondChart = false
		this.isShowThirdChart = false
		this.getFunds()
		this.getProjectFundAllocation()
		this.districtWiseCount()
		this.projectByDepartments()
		this.getDistricts()
		this.getTotalFund()
		this.getProjectPercentage()

	}

	getDistricts() {
		this.districtList = []
		this.isShowSecondChart = false
		let params = {
			year: this.selectedYear,
			month: new String(this.selectedMonth).toUpperCase()
		}
		this.ds.getDistricts(params).subscribe(res => {
			this.isShowSecondChart = true
			this.districts = res.body.data
			this.districts.label.forEach(element => {
				let obj = {
					label: element.name,
				}
				this.districtList.push(obj)
			});
			this.districts.count.forEach((element, i) => {
				this.districtList[i].y = element
			});
			//console.log(this.districtList, 'this.districtList');

			this.chartOptions1 = {
				animationEnabled: true,
				axisY: {
					includeZero: true,
					suffix: "",
					title: 'Beneficiaries'
				},
				axisX: {
					title: 'Districts'
				},
				data: [{
					type: "bar",
					indexLabel: "{y}",
					color: "#BB524C",
					yValueFormatString: "",
					dataPoints: this.districtList
				}]
			}
		})
	}
	getTotalFund() {
		this.fundList = []
		this.fundSpent = []
		this.isShowFirstChart = false
		let params = {
			year: this.selectedYear,
			month: new String(this.selectedMonth).toUpperCase()
		}
		this.ds.getSpentAmout(params).subscribe(res => {
			this.getAmountSpent = res.body.data
			this.isShowFirstChart = true

			this.getAmountSpent.label.forEach(element => {
				let obj = {
					label: element,
				}
				this.fundList.push(obj)
			});
			this.getAmountSpent.dataset.AmountReceived.forEach((element, i) => {
				this.fundList[i].y = element
			});
			this.getAmountSpent.label.forEach(element => {
				let obj = {
					label: element,
				}
				this.fundSpent.push(obj)
			});
			this.getAmountSpent.dataset.AmountSpent.forEach((element, i) => {
				this.fundSpent[i].y = element
			});
			this.chartOptions = {
				animationEnabled: true,
				exportEnabled: true,
				axisX: {
					reversed: true
				},
				axisY: {
					prefix: "",
					suffix: "",
					includeZero: true
				},
				toolTip: {
					shared: true
				},
				data: [
					{
						type: "stackedBar",
						name: "Allocation",
						showInLegend: "true",
						yValueFormatString: "",
						color: "#4C55C6",
						dataPoints: this.fundList
					}, {
						type: "stackedBar",
						name: "Expenditure",
						showInLegend: "true",
						yValueFormatString: "",
						color: "#35B1FF",
						dataPoints: this.fundSpent
					}
				]
			}



			//console.log(this.chartOptions, 'this.chartOptions');

		})
	}
}

import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import * as CryptoJS from "crypto-js";

@Injectable({ providedIn: "root" })
export class EncryptService {
  encryptData(data: string): string {
    const captchaLength = 16; // Specify the desired captcha length
    const saltCharacters =
      "abranNumcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ234567890";
    let captchaStr = "";

    while (captchaStr.length < captchaLength) {
      const index = Math.floor(Math.random() * saltCharacters.length);
      captchaStr += saltCharacters.charAt(index);
    }
    const captcha = captchaStr;
    //console.log(captcha);
    //console.log(captcha.toString());
    const key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
    const iv = CryptoJS.enc.Utf8.parse(captcha);
    const paddedData = this.zeroPadData(data).toString();
    const encryptedData = CryptoJS.AES.encrypt(paddedData, key, {
      iv: iv,
    }).toString();
    const encryptedTextWithIV = captcha.toString() + encryptedData.toString();

    const base64CipherText1 = encryptedData.toString(CryptoJS.enc.Base64);

    //console.log("base64CipherText1" + base64CipherText1);

    const base64CipherText = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(encryptedTextWithIV)
    );
    //console.log(base64CipherText);
    return base64CipherText;
  }

  zeroPadData(data: string): string {
    const blockSize = 16;
    const paddingLength = blockSize - (data.length % blockSize);
    const paddedData = data + "\x00".repeat(paddingLength);
    return paddedData;
  }

  decryptData(encryptedData: string): string {
    const saltCharacters =
      'abranNumcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ234567890';
  
    // Extract IV and encrypted data from the concatenated string
    const captchaLength = 16;
    const base64Decoded = CryptoJS.enc.Base64.parse(encryptedData);
    const encryptedTextWithIV = CryptoJS.enc.Utf8.stringify(base64Decoded);
    const captcha = encryptedTextWithIV.substring(0, captchaLength);
    const encryptedDataString = encryptedTextWithIV.substring(captchaLength);
  
    // Convert captcha to CryptoJS.enc.Utf8.parse format for IV
    const iv = CryptoJS.enc.Utf8.parse(captcha);
  
    // Decrypt the data
    const key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
    const decryptedData = CryptoJS.AES.decrypt(encryptedDataString, key, {
      iv: iv,
    });
  
    // Remove padding from the decrypted data
    const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
    const nullCharIndex = decryptedText.indexOf('\x00');
    const originalData = nullCharIndex !== -1 ? decryptedText.slice(0, nullCharIndex) : decryptedText;
  
    return originalData;
  }
}

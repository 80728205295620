<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Proceeding No</h2>
</div>
<div class="container-fluid bg">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                </ng-template>
                <ng-template pTemplate="right">
                    <button class="btn btn-primary" styleClass="button" (click)="openAdd()">
                        Add
                    </button>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-md-12  px-5">
            <table class="table mt-2">
                <thead>
                    <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Project Name</th>
                        <th scope="col">Proceeding No</th>
                        <th scope="col">Date </th>
                        <th scope="col">File</th>
                        <th scope="col">Beneficiary</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of procedingList;let i =index">
                        <td>{{generateIndex(i)}}</td>
                        <td>{{data.projectDetailId?.projectName}}</td>
                        <td>{{data.proceedingNumber}}</td>
                        <td>{{data.proceedingDate | date}}</td>
                        <td>
                            <a class="cursor-pointer" (click)="viewDocumentForlist(data.files)">
                                view
                            </a>
                        </td>
                        <td>
                            <a class="cursor-pointer" (click)="view(data)">
                                view
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center" *ngIf="totalCounts == 0">
                <img src="../../../assets/img/notFound.png" width="419px" alt="">
            </div>
            <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)" [totalRecords]="totalCounts"
                [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
        </div>
    </div>
</div>
<p-dialog #dialog [(visible)]="proceeding" [style]="{ width: '1000px',height:'600px' }" [modal]="true" [closable]="true"
    styleClass="ib-dialog">
    <p-header [ngClass]="fontService.headingClass">
        Proceeding No
    </p-header>
    <div class="row mt-3">
        <div class="col-sm-6 col-md-4">
            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                Proceeding No </label>
            <input type="text" name="designaiton" [(ngModel)]="proceedingObj.proceedingNo" class="form-control"
                maxlength="50" />
        </div>
        <div class="col-sm-6 col-md-4">
            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                Date </label> <br>
            <p-calendar [readonlyInput]="true" dateFormat="dd/mm/yy" [(ngModel)]="proceedingObj.date"></p-calendar>
        </div>
        <div class="col-sm-6 col-md-4">
            <div class="col-md-12">
                <label class="form-control-label req" for="upload" [ngClass]="fontService.labelClass">Proceeding
                    Document</label><br>
            </div>
            <div class="col-md-12" *ngIf="!isFile">
                <p-fileUpload accept="image/png,image/jpeg,image/jpg,application/pdf" (onUpload)="onUploadfile1($event)"
                    [customUpload]="true" auto="true" (uploadHandler)="onUploadfile1($event)" multiple="false" mode="basic" maxFileSize="1000000"
                    [(ngModel)]="proceedingObj.proceedingDocument" [showUploadButton]="false" [showCancelButton]="false"
                    chooseLabel="{{'common.upload'|translate}}">
                </p-fileUpload>
            </div>
            <div class="col" *ngIf="isFile">
                <a class="cursor-pointer" (click)="viewDocument()">{{fileUploadURL}}</a>
                <i class="pi pi-times add-margin" (click)="removeDocument()"></i>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 col-md-4">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Project
                Name</label>
            <ng-select id="department" bindLabel="projectName" (change)="selectProject($event)" appearance="outline"
                [(ngModel)]="proceedingObj.projectName" [items]="projectList" class="form-control" appNgSelectInputRestriction>
            </ng-select>
        </div>
    </div>
    <div>
        <div #customerTable id="entities" class="col-md-12 mt-3">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" style="background-color: #017DC3;">Beneficiary Name
                        </th>
                        <th scope="col" style="background-color: #017DC3;">Survey
                            Number</th>
                        <th scope="col" style="background-color: #017DC3;">Subdivision
                            Number</th>
                        <th scope="col" style="background-color: #017DC3;">Mobile
                            Number
                        </th>
                        <th scope="col" style="background-color: #017DC3;">S.No</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of beneficiaryData;let i =index">
                        <td>{{data.benificiaryLandOwnerName}}</td>
                        <td>{{data.surveyNumber}}</td>
                        <td>{{data.subDivisionNumber}}</td>
                        <td>{{data.mobileNumber}}</td>
                        <!-- <td>
                            <p-checkbox [binary]="true" inputId="binary"
                                (onChange)="generateVoucher($event,data)"></p-checkbox>
                        </td> -->
                        <td>
                            <input type="text" name="designaiton" (keyup)="SerialNos($event,data)" class="form-control"
                            maxlength="50" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center" *ngIf="totalCount == 0">
                <img src="../../../assets/img/notFound.png" width="290px" alt="">
            </div>
        </div>
    </div>
    <p-footer>
        <button pRipple icon="pi pi-plus" (click)="OnSubmit()"
            [disabled]="proceedingObj.proceedingNo =='' || proceedingObj.date == '' || fileUploadURL == '' || finalSerial.length == 0"
            iconPos="left" class="btn btn-primary" styleClass="button">
            Submit
        </button>
    </p-footer>
</p-dialog>
<p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
    styleClass="ib-dialog">
    <ng-template pTemplate="body">
        <div style='position: relative; height: 100%;'>
            <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
            <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
        </div>
    </ng-template>
</p-dialog>
<p-dialog [(visible)]="viewDocList" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
    styleClass="ib-dialog">
    <ng-template pTemplate="body">
        <div style='position: relative; height: 100%;'>
            <ngx-extended-pdf-viewer *ngIf="vFileExtList === 'pdf'" [src]="vFileUrlList"></ngx-extended-pdf-viewer>
            <img *ngIf="vFileExtList !== 'pdf'" [src]="vFileUrlList" style="display: block;" height="800px" />
        </div>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="viewList" [style]="{ width: '1000px', height: '500px' }" [modal]="true" [baseZIndex]="1000"
    styleClass="ib-dialog">
    <p-header [ngClass]="fontService.headingClass">
        <span>Project Name:{{selectProcedding?.projectDetailId.projectName}} </span> &nbsp;
        <span>Proceeding No: {{selectProcedding?.proceedingNumber}} </span> &nbsp;
        <span>Date: {{selectProcedding?.proceedingDate |date}}</span>
    </p-header>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col" style="background-color: #017DC3;">S.No
                    </th>
                    <th scope="col" style="background-color: #017DC3;">Beneficiary Name
                    </th>
                    <th scope="col" style="background-color: #017DC3;">Survey
                        Number</th>
                    <th scope="col" style="background-color: #017DC3;">Subdivision
                        Number</th>
                    <th scope="col" style="background-color: #017DC3;">Mobile
                        Number
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of beneficiaryData;let i =index">
                    <td>{{data.beneficiaryLandOwnerSerialNumber}}</td>
                    <td>{{data.benificiaryLandOwnerName}}</td>
                    <td>{{data.surveyNumber}}</td>
                    <td>{{data.subDivisionNumber}}</td>
                    <td>{{data.mobileNumber}}</td>
                </tr>
            </tbody>
        </table>
        <div class="text-center" *ngIf="totalCount1 == 0">
            <img src="../../../assets/img/notFound.png" width="290px" alt="">
        </div>
    </div>
</p-dialog>
<div id="error-page">
    <div class="content text-center">
            <div class="row"><h2 class="header">
                404
             </h2>
            </div>
             <div class="row">
        <h2 class="sub-header">
            PAGE NOT FOUND
         </h2>
        </div>
        <div class="row">
        <p>
            Sorry, we are unable to process your request.<br>
             The requested URL was not found on this server.
         </p>
        </div>
       <div class="btn">
        <a class="btn btn-warning" [routerLink]="['/login']">Go Back To Homepage</a>
       </div>
    </div>
 </div>



<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Beneficiary Report</h2>
</div>
<div class="container bg mx-auto">
    <div class="row">
        <div class="col-lg-2">
            <div>
                <label class="form-control-label req" for="scheme">
                    From Date</label>
            </div>

            <p-calendar [readonlyInput]="true" dateFormat="dd/mm/yy" [maxDate]="maxDate" [(ngModel)]="fromDate"
                appendTo="body"></p-calendar>
        </div>
        <div class="col-lg-2">
            <div>
                <label class="form-control-label req" for="scheme">
                    To Date</label>
            </div>
            <p-calendar [readonlyInput]="true" [(ngModel)]="toDate" [maxDate]="maxDate" dateFormat="dd/mm/yy"
                appendTo="body"></p-calendar>
        </div>
        <div class="col-lg-2">
            <div>
                <label class="form-control-label req" for="scheme">
                    Beneficiary Name</label>
            </div>
            <input type="text" [(ngModel)]="beneficiary" id="designaiton" name="designaiton" class="form-control"
                maxlength="50" />
        </div>
        <div class="col-lg-2">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Project
                Name</label>
            <ng-select id="department" bindLabel="projectName" appearance="outline" [(ngModel)]="projectName"
                [items]="projectList" class="form-control" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-lg-2 pt-4">
            <p-button label="Submit" class="submit" [disabled]="(fromDate == '' ||
            toDate == '' ) && beneficiary == '' &&  projectName == ''"
                (click)="getProjectsFilter(1,'action')"></p-button> &nbsp;
            <p-button label="Reset" class="submit"
                (click)="fromDate = '';
                toDate='' ; beneficiary=''; projectName='';getProjectsFilter(1,'action')"></p-button>
        </div>
    </div>
    <div class="row tableDesign">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Beneficiary Name</th>
                    <th scope="col">Project Name</th>
                    <th scope="col">Mobile Number</th>
                    <th scope="col">Aadhaar Number</th>
                    <th scope="col">Bank Name</th>
                    <th scope="col">Account Number</th>
                    <th scope="col">Compensation Amount</th>
                    <th scope="col">Payment Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let data of beneficiaryList;let i =index'>
                    <td>{{generateIndex(i)}}</td>
                    <td>{{data?.beneficiaryLandOwnerName}}</td>
                    <td>{{data?.projectName}}</td>
                    <td>{{data?.mobileNumber}}</td>
                    <td>{{data?.aadharNumber}}</td>
                    <td>{{data?.nameOfBank}}</td>
                    <td>{{data?.accountNumber}}</td>
                    <td>{{data?.compensationAmount}}</td>
                    <td>{{data?.paymentStatus}}</td>
                </tr>
            </tbody>
        </table>
        <p-paginator [rows]="itemsPerPage" [totalRecords]="totalCount" (onPageChange)="onPageChange($event)"
            [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
    </div>
</div>
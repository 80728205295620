import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class DashboardService {
  protected getRequestingDepartment = environment.SERVER_API_URL + 'api/dashboard/getRequestingDepartments';
  protected getExtendLand = environment.SERVER_API_URL + 'api/dashboard/getAcquiredLandByProjects';
  protected getMajorSpentUrl = environment.SERVER_API_URL + 'api/dashboard/getAmountDetails';
  protected getProjectFUnd = environment.SERVER_API_URL + 'api/dashboard/getDashboardProjectDetails';
  protected getBeneficiaryDistrict = environment.SERVER_API_URL + 'api/dashboard/getBeneficiariesBasedOnDistrict';
  protected getDepartment = environment.SERVER_API_URL + 'api/dashboard/getRequitioningBodyDetails';
  protected getAmountSpents = environment.SERVER_API_URL + 'api/dashboard/getTotalAmountSpent';
  protected getAmountBalance = environment.SERVER_API_URL + 'api/dashboard/getTotalBalanceAmount';
  protected getProjectValues = environment.SERVER_API_URL + 'api/dashboard/getTotalProjectValue';
  protected getProjectPercentage = environment.SERVER_API_URL + 'api/dashboard/getDashboardRequestingBodyPercentage';
  protected getDistrict = environment.SERVER_API_URL + 'api/dashboard/getDistrictDetails';
  protected getProjects = environment.SERVER_API_URL + 'api/dashboard/getDistrictByProjects';
  protected getCompFundByLevelUrl = environment.SERVER_API_URL + 'api/getCompFundByLevel';
  protected getAmountReceiveValue = environment.SERVER_API_URL + 'api/dashboard/getTotalAmountReceived';
  protected getProjectList = environment.SERVER_API_URL + 'api/dashboard/getMonthWiseProjects';
  protected getPanchayatFundReportUrl = environment.SERVER_API_URL + 'api/getFundDetailsByLevelMaster';
  protected getMajorCompSpentUrl = environment.SERVER_API_URL + 'api/getMajorCompSpentByLevel';
  protected districtCounts = environment.SERVER_API_URL + 'api/dashboard/getBeneficiariesCountAndAmountSpent';
  protected projectCount= environment.SERVER_API_URL + 'api/dashboard/getDashboardRequestingBodyDetails';
  filterProps: any;
  constructor(protected http: HttpClient) { }

  getFundReport(req) {
    const options = createRequestOption(req);
    return this.http.get<any>(
      `${this.getMajorSpentUrl}`, { params:options, observe: 'response' });
  }
  getProjectFund(req) {
    const options = createRequestOption(req);

    return this.http.get<any>(
      `${this.getProjectFUnd}`, { params:options,observe: 'response' });
  }
  districtCount(req) {
    const options = createRequestOption(req);

    return this.http.get<any>(
      `${this.districtCounts}`, {params:options, observe: 'response' });
  }
  projectByCount(req) {
    const options = createRequestOption(req);

    return this.http.get<any>(
      `${this.projectCount}`, {params:options, observe: 'response' });
  }
  getDepartments() {
    return this.http.get<any>(
      `${this.getDepartment}`, { observe: 'response' });
  }
  getSpentAmout(req) {
    const options = createRequestOption(req);
    return this.http.get<any>(
      `${this.getAmountSpents}`, {params:options, observe: 'response' });
  }
  getAmountBalances() {
    return this.http.get<any>(
      `${this.getAmountBalance}`, { observe: 'response' });
  }
  getBeneficiaryPercentage(req) {
    const options = createRequestOption(req);

    return this.http.get<any>(
      `${this.getProjectPercentage}`, {params:options, observe: 'response' });
  }
  getAmountReceive() {
    return this.http.get<any>(
      `${this.getAmountReceiveValue}`, { observe: 'response' });
  }
  getProjectsss() {
    return this.http.get<any>(
      `${this.getProjectList}`, { observe: 'response' });
  }
  getRequestingDepartmet() {
    return this.http.get<any>(
      `${this.getRequestingDepartment}`, { observe: 'response' });
  }
  getExtendLands() {
    return this.http.get<any>(
      `${this.getExtendLand}`, { observe: 'response' });
  }
  getRequestingDepartments() {
    return this.http.get<any>(
      `${this.getMajorSpentUrl}`, { observe: 'response' });
  }


  getDistricts(req) {
    const options = createRequestOption(req);

    return this.http.get<any>(
      `${this.getBeneficiaryDistrict}`, { params:options,observe: 'response' });
  }
  getProjectsByDistrict(id) {
    return this.http.get<any>(
      `${this.getProjects}?districtId=${id}`, { observe: 'response' });
  }

  getFunddetails(levelMasterId: number) {
    return this.http.get<any>(
      `${this.getPanchayatFundReportUrl}?levelMasterId=${levelMasterId}`,
      { observe: 'response' }
    );
  }
  getCompFundByLevel(levelId, parentId, compTypeId) {
    return this.http.get<any>(
      `${this.getCompFundByLevelUrl}?levelId=${levelId}&parentId=${parentId}&compTypeId=${compTypeId}`,
      { observe: 'response' }
    );
  }


}
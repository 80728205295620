<div class="container">
    <div class="row mb-3 align-items-center">
        <div class="col-md-6">
            <h3>Return Payments</h3>
        </div>
        <div class="col-md-6 text-end d-flex gap-2">
            <input pInputText type="text" [(ngModel)]="searchVoucher" placeholder="Search Voucher No"
                class="p-inputtext p-component search-box" />
            <button pButton pRipple type="button" icon="pi pi-search" (click)="loadPayments()"></button>
            <button pButton pRipple type="button" icon="pi pi-times"
                (click)="searchVoucher = ''; loadPayments()"></button>
        </div>
    </div>
    <!-- [resizableColumns]="true" columnResizeMode="expand"  scrollHeight="500px" -->
    <div class="row mt-4">
        <p-table #dt [value]="filteredPayments" [lazy]="true" [paginator]="true" [rows]="pageSize"
            [totalRecords]="totalRecords" [loading]="loading" 
             stateStorage="local" stateKey="return-payments-table" [scrollable]="true"
           dataKey="id" editMode="row" [rowsPerPageOptions]="[5,10,20,50]"
            (onLazyLoad)="loadPayments($event)"
            >
            <ng-template pTemplate="header">
                <tr>
                    <th >District</th>
                    <th >Taluk</th>
                    <th >Village</th>
                    <th >Project</th>
                    <th >Voucher No</th>
                    <th>Type</th>
                    <th >Beneficiary Name</th>

                    <th >Amount</th>
                    <th >Account No</th>
                    <th >IFSC Code</th> 
                     <th >Return Payment Date</th>
                    <th >UTR No</th>
                    <!-- <th>Actions</th> -->
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-payment let-i="rowIndex">
                <tr>
                    <td>{{ payment.district }}</td>
                    <td>{{ payment.taluk }}</td>
                    <td>{{ payment.village }}</td>
            
                    <!-- Editable Fields -->
                    <td>{{ payment.projectName }}</td>
                    <td>{{ payment.voucherNumber }}</td>
                    <td>{{ payment.type }}</td>
                    <td>  {{ payment.beneficiaryName }}
                            
                    </td>
                    
            
                    <td> 
                   ₹{{ payment.beneficiaryAmount | indianRupee }}
                    </td>
                    <td>  {{ payment.accountNo  }}</td>
                    <td>  {{ payment.ifscode  }}</td>
                    <td>{{ payment.transDate | date: 'yyyy-MM-dd' }}</td>
                    <td>{{ payment.utrNo }}</td>
            
                    <!-- Actions -->
                    <!-- <td>
                        <button *ngIf="!editingRow[payment.id]" pButton pRipple type="button" icon="pi pi-pencil"
                            (click)="onRowEditInit(payment)" text rounded></button>
            
                        <button *ngIf="editingRow[payment.id]" pButton pRipple type="button" icon="pi pi-check"
                            (click)="onRowEditSave(payment, i)" text rounded></button>
            
                        <button *ngIf="editingRow[payment.id]" class="mx-3" pButton pRipple type="button" icon="pi pi-times"
                            (click)="onRowEditCancel(payment)" text rounded></button>
                    </td> -->
                </tr>
            </ng-template>
            
        </p-table>
    </div>
</div>
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
export type EntityListResponseType = HttpResponse<any>;

@Injectable({ providedIn: 'root' })
export class NavbarService {
  protected getMenuUrl = environment.SERVER_API_URL + 'api/getMenu';
  protected logOutUrl = environment.SERVER_API_URL + 'api/logOut';

  private selectedReport = new BehaviorSubject<any>(null);
  myData$: Observable<any> = this.selectedReport.asObservable();

  updateSelectedReport(newData: any) {
    console.log(newData)
    this.selectedReport.next(newData);
  }

  constructor(protected http: HttpClient) { }
  getMenu(): Observable<EntityListResponseType> {
    return this.http.get<any>(this.getMenuUrl, { observe: 'response' });
  }
  logout(): Observable<any> {
    return this.http.post(this.logOutUrl, {}, { observe: 'response', responseType: 'text' });
  }
}

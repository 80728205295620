<div class="hamburger">
    <!-- <h2>{{hamHeader}}</h2>
    <h5>{{subHeader}}</h5> -->
</div>
<div class="container-fluid bg">
    <div class="tableDesign">
            <div class="row">
                <div class="col-md-12">
                    <p-toolbar styleClass="toolbar">
                        <ng-template pTemplate="left">
                            <h5 class="tableHead">
                                Beneficiary Payment Voucher 
                            </h5>
                        </ng-template>
                    </p-toolbar>
                    <hr>
                    <div class="row px-4 mt-3 align-items-center">
                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Voucher Number</label>
                            <p class="lableValue">{{voucherNo}}</p>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Voucher Date</label>
                            <p class="lableValue">{{today |date:'dd/MM/yyyy'}}</p>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Implementing Agency</label>
                            <p class="lableValue">{{beneficiaryNo?.projectID.agencyId.agencyName }}</p>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Project Value</label>
                            <p class="lableValue">{{beneficiaryNo?.projectID.tentativeLandCost |currency:'INR'}}</p>
                        </div>
                    </div>
                </div>
                <div class="row px-4 mt-3 align-items-center">
                    <div class="col-lg-3">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Beneficiary Name</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]='beneficaryForm.bName' readonly
                                aria-describedby="emailHelp">
                        </div>
                    </div>
                    <!-- <div class="col-lg-3">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">PAN Number</label>
                            <input type="email" class="form-control" id="exampleInputEmail1"  [(ngModel)]='beneficaryForm.panNumber' readonly
                                aria-describedby="emailHelp">
                        </div>
                    </div> -->
                    <div class="col-lg-3">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Aadhaar Number</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]='beneficaryForm.aadhaarNo' readonly
                                aria-describedby="emailHelp">
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Mobile Number</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]='beneficaryForm.mobileNo' readonly
                                aria-describedby="emailHelp">
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Bank Name</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]='beneficaryForm.bankName' readonly
                                aria-describedby="emailHelp">
                        </div>
                    </div>
                </div>
                <div class="row px-4 mt-3 align-items-center">
                   
                    <div class="col-lg-3">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Branch Name</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]='beneficaryForm.branchName' readonly
                                aria-describedby="emailHelp">
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">IFSC Code</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]='beneficaryForm.ifScCode' readonly
                                aria-describedby="emailHelp">
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Account Number</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]='beneficaryForm.accNo' readonly
                                aria-describedby="emailHelp">
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Proceeding Number</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]='beneficaryForm.ProceedingNo'
                                aria-describedby="emailHelp">
                        </div>
                    </div>

                </div>
                <div class="row px-4 mt-3 align-items-center">

                    <div class="col-lg-3">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Proceeding Date</label>
                          
                                <p-calendar [readonlyInput]="true" styleClass="datepicker"  dateFormat="dd/mm/yy"
                                class="same_line_fix" [(ngModel)]='beneficaryForm.ProceedingDate'
                                appendTo="body"></p-calendar>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Proceeding upload</label>
                            <p-fileUpload mode="basic" chooseLabel="upload" name="demo[]"
                                url="https://www.primefaces.org/cdn/api/upload.php" accept="image/*"
                                maxFileSize="1000000" (onUpload)="onUpload($event)"></p-fileUpload>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Description</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" [(ngModel)]='beneficaryForm.description'></textarea>

                        </div>
                    </div>
                </div>

                <p-toolbar styleClass="toolbar mt-3">
                    <ng-template pTemplate="left">
                        <h5 class="tableHead">
                            Beneficiary Transaction Amount
                        </h5>
                    </ng-template>
                </p-toolbar>
                <hr>
                <div class="row px-4 mt-3 align-items-start">
                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label ">Total Amount</label>
                            <p class="lableValue">{{singleBeneficiary?.compensationAmount |indianRupee }}</p>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Balance Amount</label>
                            <p class="lableValue"> {{balanceAmount?.data.BalanceAmount | currency:"INR"}} </p>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Transaction Amount</label>
                            <input type="number" class="form-control" id="exampleInputEmail1" (keyup)="transcationAmount($event)" [(ngModel)]='beneficaryForm.transcationAmount'
                                aria-describedby="emailHelp" appNumberonly>
                            <p class="text-danger" *ngIf="errorShow">Transaction Amount less than or equal to the total amount</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3 floatR">
                            <p class="bottomLable text-end">Total Amount : <span>₹10,000,000</span> </p>
                            <p class="bottomLable">Transaction Amount : <span>{{beneficaryForm.transcationAmount |indianRupee }}</span> </p>
                        </div>
                    </div>
                </div>
                <!-- <div class="row px-4 mt-3">
                    <div class="col-lg-9">
                          <button type="button" class="btn btn-secondary">Secondary</button>
                    </div>
                    <div class="col-lg-3 ml-3">
                        <button type="button" class="btn btn-primary">Generate Voucher</button> &nbsp;
                        <button type="button" class="btn btn-primary">Cancel</button>
                    </div>
                </div> --> 
                <div class="alignFooter">
                        <button type="button" class="btn btn-secondary" (click)="showDialog()">View Previous Transaction</button>
                        <div>
                            <button type="button" class="btn btn-primary" (click)="generateVoucher()">Generate Voucher</button> &nbsp;
                            <button type="button" class="btn btn-info" routerLink="/payment">Cancel</button>
                        </div>
                </div>
            </div>
        </div>
    </div>

</div>

<p-dialog header="View Payment Transaction" [modal]="true" [(visible)]="visible" [style]="{width: '60vw'}">
    <p-table [value]="previousTranscation" [tableStyle]="{ 'min-width': '55rem' }">
        <ng-template pTemplate="header">
            <tr>
                <th>Voucher No</th>
                <th>Account Number</th>
                <th>Transaction Amount</th>
                <th>Transaction Date</th>
                <th>UTR Number</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td>{{ product.voucherNo }}</td>
                <td>{{ product?.beneficiaryLandOwnerId.accountNumber }}</td>
                <td>{{ product.transactionAmount }}</td>
                <td>{{ product.voucherDate }}</td>
                <td> -- </td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>

import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { UserService } from './checker.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LevelType } from '../level-type/level-type';
import { ZonalService } from '../zonal/zonal.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { Dialog } from 'primeng/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/alert/notification.service';
import { FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-user',
  templateUrl: './checker-project.component.html',
  styleUrls: ['./checker-project.component.scss'],
})
export class CheckerProjectComponent implements OnInit {
  items: MenuItem[] | undefined;

  activeIndex: any = 0;
  fundFilter
  projectList: any;
  projectId: any;
  isShowTable: boolean = false;
  beneficary: any;
  stage1: any;
  index: any;
  pageCount: number = 0;
  pageLength: any;

  stage1Filter = {
    taluk: '',
    village: ''
  }
  stage2Filter = {
    taluk: '',
    village: '',
    survey: '',
    subdivision: '',
  }
  stage3Filter = {
    name: '',
    mobile: '',
    aadhaar: '',
  }
  stage4Filter = {
    name: '',
    mobile: '',
    aadhaar: '',
  }
  stage5Filter = {
    name: '',
    mobile: '',
    aadhaar: '',
  }
  paymentStatus = {
    name: '',
    mobile: '',
    aadhaar: '',
  }
  stageName: string = 'stage1';
  sortDirection: string = 'asc';


  onActiveIndexChange(event: number) {
    this.activeIndex = event;
    if (event == 0) {
      this.loadPage(this.page, 'stage1')
      this.stageName = "stage1"
    } else if (event == 1) {
      this.loadPage(this.page, 'stage2')
      this.stageName = "stage2"
    } else if (event == 2) {
      this.loadPage(this.page, 'stage3')
      this.stageName = "stage3"
    } else if (event == 3) {
      this.loadPage(this.page, 'stage4')
      this.stageName = "stage4"
    } else if (event == 4) {
      this.loadPage(this.page, 'stage5')
      this.stageName = "stage5"
    }
  }

  ngOnInit() {
    this.getProjects()
    this.items = [
      {
        label: 'Taluks and Villages',
        command: (event: any) => this.messageService.add({ severity: 'info', summary: 'First Step', detail: event.item.label })
      },
      {
        label: 'Land Details',
        command: (event: any) => this.messageService.add({ severity: 'info', summary: 'Second Step', detail: event.item.label })
      },
      {
        label: 'Beneficiary Details',
        command: (event: any) => this.messageService.add({ severity: 'info', summary: 'Third Step', detail: event.item.label })
      },
      {
        label: 'Payment Details',
        command: (event: any) => this.messageService.add({ severity: 'info', summary: 'Last Step', detail: event.item.label })
      },
      {
        label: 'Payment History',
        command: (event: any) => this.messageService.add({ severity: 'info', summary: 'Last Step', detail: event.item.label })
      }
    ];
  }

  keyPressNumberForMobile(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) || event.target.value.length >= 10) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  keyPressNumberForAadhaar(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) || event.target.value.length >= 12) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  selectProject(e) {
    this.projectId = e.id
    this.onActiveIndexChange(this.activeIndex)
    this.isShowTable = true
    console.log(this.projectId, ' this.projectId');

  }


  getProjects() {
    this.UserService.getProjects().subscribe(
      (res: any) => {
        this.projectList = res.body.data;
      },
      () => {
        // this.onError();
      }
    );
  }





  @ViewChild('dialog') dialog: Dialog;
  // @ViewChild('department', ) department: NgSelectComponent;
  agencyDialog: boolean = false;
  cols: any[];
  zonalDto: any;
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  agencyList
  levelType: LevelType[];
  levelTypeDto: LevelType;
  levelMasterList: any;
  levelMasterDto: any;
  zonalList: any;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public showGstError = false;
  public showIfscError = false;
  public showBMobile = false;
  //required fields property

  districtList: void;
  singleBeneficiaryList: any;
  label: any;
  splitDistirct: any[];
  totalCount: any;

  constructor(
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    public UserService: UserService,
    public router: Router,
    private zonalService: ZonalService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    public formBuilder: FormBuilder,
  ) { }



  ngAfterViewInit() {
    this.updateDialogFont();
  }





  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadPage(page?: number, tabName?: string, params?: any): void {
    let query
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    if (tabName == 'stage1') {
      if (params) {
        query = {
          project: this.projectId,
          page: pageToLoad - 1,
          size: this.itemsPerPage,
          talukName: this.stage1Filter.taluk ?? '',
          villageName: this.stage1Filter.village ?? '',
          sortBy: params,
          sortDirection: this.sortDirection
        };
        if (this.sortDirection == 'asc') {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
      } else {
        query = {
          project: this.projectId,
          page: pageToLoad - 1,
          size: this.itemsPerPage,
          talukName: this.stage1Filter.taluk ?? '',
          villageName: this.stage1Filter.village ?? ''
        };
      }
    } else if (tabName == 'stage2') {
      if (params) {
        query = {
          project: this.projectId,
          page: pageToLoad - 1,
          size: this.itemsPerPage,
          talukName: this.stage2Filter.taluk ?? '',
          villageName: this.stage2Filter.village ?? '',
          subDivisionNumber: this.stage2Filter.subdivision ?? '',
          surveyNumber: this.stage2Filter.survey ?? '',
          sortBy: params,
          sortDirection: this.sortDirection
        };
        if (this.sortDirection == 'asc') {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
      } else {
        query = {
          project: this.projectId,
          page: pageToLoad - 1,
          size: this.itemsPerPage,
          talukName: this.stage2Filter.taluk ?? '',
          villageName: this.stage2Filter.village ?? '',
          subDivisionNumber: this.stage2Filter.subdivision ?? '',
          surveyNumber: this.stage2Filter.survey ?? ''
        };
      }
    } else if (tabName == 'stage3') {
      if (params) {
        query = {
          project: this.projectId,
          page: pageToLoad - 1,
          size: this.itemsPerPage,
          name: this.stage3Filter.name ?? '',
          mobileNumber: this.stage3Filter.mobile ?? '',
          aadharNumber: this.stage3Filter.aadhaar ?? '',
          sortBy: params,
          sortDirection: this.sortDirection
        };
        if (this.sortDirection == 'asc') {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
      } else {
        query = {
          project: this.projectId,
          page: pageToLoad - 1,
          size: this.itemsPerPage,
          name: this.stage3Filter.name ?? '',
          mobileNumber: this.stage3Filter.mobile ?? '',
          aadharNumber: this.stage3Filter.aadhaar ?? ''
        };
      }
    } else if (tabName == 'stage4') {
      if (params) {
        query = {
          project: this.projectId,
          page: pageToLoad - 1,
          size: this.itemsPerPage,
          name: this.stage4Filter.name ?? '',
          mobileNumber: this.stage4Filter.mobile ?? '',
          aadharNumber: this.stage4Filter.aadhaar ?? '',
          sortBy: params,
          sortDirection: this.sortDirection
        };
        if (this.sortDirection == 'asc') {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
      } else {
        query = {
          project: this.projectId,
          page: pageToLoad - 1,
          size: this.itemsPerPage,
          name: this.stage4Filter.name ?? '',
          mobileNumber: this.stage4Filter.mobile ?? '',
          aadharNumber: this.stage4Filter.aadhaar ?? ''
        };
      }
    } else if (tabName == 'stage5') {
      if (params) {
        query = {
          project: this.projectId,
          page: pageToLoad - 1,
          size: this.itemsPerPage,
          name: this.stage5Filter.name ?? '',
          mobileNumber: this.stage5Filter.mobile ?? '',
          aadharNumber: this.stage5Filter.aadhaar ?? '',
          sortBy: params,
          sortDirection: this.sortDirection
        };
        if (this.sortDirection == 'asc') {
          this.sortDirection = 'desc'
        } else {
          this.sortDirection = 'asc'
        }
      } else {
        query = {
          project: this.projectId,
          page: pageToLoad - 1,
          size: this.itemsPerPage,
          name: this.stage5Filter.name ?? '',
          mobileNumber: this.stage5Filter.mobile ?? '',
          aadharNumber: this.stage5Filter.aadhaar ?? ''
        };
      }
    }
    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);

    console.log(tabName, '');

    this.UserService.filter({ ...query }, tabName).subscribe(
      (res: any) => {
        this.onSuccess(res.body, res.headers, pageToLoad);
      },
      (err) => {

        this.onError(err);
      }
    );
  }

  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,
  ): void {
    this.spinner.hide();
    this.page = page;
    this.beneficary = data.data
    this.totalCount = data.totalCount;
  }

  onPageChange(event: any) {
    console.log(event);
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    this.page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.loadPage(this.page, this.stageName);
  }

  protected onError(err): void {
    this.spinner.hide();
    this.beneficary=[]
    this.totalCount=0
    this.notificationService.alertError('Error!', err.error.error);
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    // this.loadPage(event.page);
  }

  vendorFilter($event) {
    let filter = new filterQuery();
    filter.key = 'name';
    filter.operation = 'contains';
    filter.value = $event.term;
    let filterquery = [];
    filterquery.push(filter);
    this.zonalService.filter({ size: 10, page: 0 }, filterquery).subscribe(
      (res: HttpResponse<any>) => {
        this.zonalList = res.body;
      },
      () => { }
    );
  }

  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }
}
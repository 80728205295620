import { CommonService } from './../../shared/common.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProfileService } from './profie.service';
import { AddProfile, Profile } from './profile';
import { SearchResult } from 'src/app/shared/models/search-result';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { educationalQualification, gender, maritalStatus, categoryReservation, community, filterQuery } from 'src/app/shared/common.model';
import { NgxSpinnerService } from 'ngx-spinner';

import { Dialog } from 'primeng/dialog';
import { FontService } from 'src/app/shared/font-service/font.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
// import { saveAs } from 'file-saver';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { environment } from 'src/environments/environment';
// import { EncryptService } from 'src/app/shared/encrypt.service';
import { NavbarService } from 'src/app/layout/navbar/navbar.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Location } from '@angular/common';
import { FilterTable } from 'src/app/shared/models/table-filter-model';
import { EncryptService } from 'src/app/shared/encrypt.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;

  profileDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Profile> = new SearchResult<Profile>();
  profiles?: Profile[];
  profile?: AddProfile;
  selectedProfiles: Profile[] = [];
  submitted?: boolean;
  filterProps: any;
  dateOfAssumingOffice: Date;
  profileDetails: any = {};
  page: number;
  itemsPerPage: number = 10;
  genderList: gender[];
  educationList: educationalQualification[];
  maritalList: maritalStatus[];
  categoryReservationList: categoryReservation[];
  community: community[];
  name: any;
  otp: number;
  Profiledetails: any;
  enableFeild: boolean;

  // validation fields properties
  resendotp = false;
  sendotp: boolean = false;
  otpOpen = false;
  public aadhaarError: boolean = false;
  public otpError: boolean = false;
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public showBMobile = false;
  today: Date;
  minDate = new Date('01/03/2015');
  maxDate = new Date();

  //required fields property
  ngSelectInvalid: boolean = false;
  aadharNoNameError: string;
  panNoError: string;
  mobileNoError: string;
  emailIdError: string;
  txnNo: any;
  rolecode: any;
  otpButtonName: string = 'Send OTP';
  enableApproved: boolean;
  currentPath: string;
  profileStatus: any;
  enableCreated: boolean;
  readOnly: boolean;
  filter: FilterTable;
  enableFilter: boolean = true;
  userId: any;

  constructor(
    private ProfileService: ProfileService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    protected router: Router,
    private CommonService: CommonService,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private encryptService: EncryptService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private navbarService: NavbarService,
    private location: Location
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  async ngOnInit() {
    this.currentPath = this.location.path();
    this.today = new Date();
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach(label => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadValue() {
    this.CommonService.getGenderList().subscribe(
      (res: HttpResponse<gender[]>) => {
        this.genderList = res.body;
      },
      () => {
        this.onError();
      }
    );
    // this.CommonService.geteducationList().subscribe(
    //   (res: HttpResponse<educationalQualification[]>) => {
    //     this.educationList = res.body;
    //   },
    //   () => {
    //     this.onError();
    //   }
    // );
    // this.CommonService.getMartialStatusList().subscribe(
    //   (res: HttpResponse<maritalStatus[]>) => {
    //     this.maritalList = res.body;
    //   },
    //   () => {
    //     this.onError();
    //   }
    // );
    // this.CommonService.getCategoryReservationList().subscribe(
    //   (res: HttpResponse<categoryReservation[]>) => {
    //     this.categoryReservationList = res.body;
    //   },
    //   () => {
    //     this.onError();
    //   }
    // );
    // this.CommonService.getcommunityList().subscribe(
    //   (res: HttpResponse<community[]>) => {
    //     this.community = res.body;
    //   },
    //   () => {
    //     this.onError();
    //   }
    // );
  }

  loadPage(page?: number, dontNavigate?: boolean) {
    //console.log('loadpage');
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    // let filquery = new filterQuery();
    // filquery.key = 'activeFlag';
    // filquery.operation = 'equals';
    // filquery.value = 'YES';
    // this.filterProps.push(filquery);

    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    if (this.rolecode !== 'SA' && this.currentPath !== '/approve-profile' && this.currentPath !== '/approve-line-profile') {
      let filquery = new filterQuery();
      filquery.key = 'activeFlag';
      filquery.operation = 'notEquals';
      filquery.value = 'DELETED';
      this.filterProps.push(filquery);
      filquery = new filterQuery();
      filquery.key = 'adminUser.id';
      filquery.operation = 'Equals';
      filquery.value = this.userId;
      this.filterProps.push(filquery);
     
      this.ProfileService.vaildFilter({ ...query }, this.filterProps).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    } else if (this.currentPath === '/approve-profile' || this.currentPath === '/approve-line-profile') {
     
      //console.log('jdjdjjddddhdhdhhdhdhdhdhdhdhhdhdhdhdhfilterprops',this.rolecode)
      if (this.rolecode === 'SA'){
       
        let filquery = new filterQuery();
        filquery.key = 'profileStatus';
        filquery.operation = 'equals';
        filquery.value = 'SA';
        this.filterProps.push(filquery);
        let filquery1 = new filterQuery();
        filquery1.key = 'activeFlag';
        filquery1.operation = 'notEquals';
        filquery1.value = 'DELETED';
        this.filterProps.push(filquery1);
      }
      // if(this.currentPath === '/approve-line-profile') {
      //   query['roleId'] = 'Yes';
      // }
      
      this.ProfileService.vaildFilter({ ...query }, this.filterProps).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.ProfileService.filter({ ...query }, this.filterProps).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    }
  }

  protected onSuccess(data: Profile[] | null, headers: HttpHeaders, page: number, navigate: boolean): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {
    this.notificationService.alertError('An unexpected error occured,please try again', '');
    // this.enableFeild = false
  }

  async aadhaarNocheck() {
    this.validateAadhaar();
    console.log(this.profile.incharge)
    if(this.profile.incharge=='No'){
      const filterData = await this.getfilterdata('aadhaarNo', this.profile.aadhaarNo);
      if (filterData.length !== 0) {
        this.aadharNoNameError = 'Aadhaar No Already Exists';
      } else {
        this.aadharNoNameError = null;
      }
    }
    else{
 
    }

  }

  async panNoCheck() {
    const filterData = await this.getfilterdata('panNo', this.profile.panNo);
    if (filterData.length !== 0) {
      this.panNoError = 'PanNo Already Exists';
    } else {
      this.panNoError = null;
    }
  }

  async mobileNo() {
    const filterData = await this.getfilterdata('mobileNo', this.profile.mobileNo);
    if (filterData.length !== 0) {
      this.mobileNoError = 'MobileNo Already Exists';
    } else {
      this.mobileNoError = null;
    }
  }

  async emailCheck() {
    const filterData = await this.getfilterdata('emailId', this.profile.emailId);
    if (filterData.length !== 0) {
      this.emailIdError = 'Email Already Exists';
    } else {
      this.emailIdError = null;
    }
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>(resolve => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
        {
          key: 'activeFlag',
          operation: 'equals',
          value: 'YES',
        },
      ];
      if (this.profile.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.profile.id,
        });
      }
      this.ProfileService.vaildFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<Profile[]>) => {
          resolve(res.body);
        },
        () => {
          this.onError();
          resolve(null);
        }
      );
    });
  }

  openNew() {
    this.loadValue();
    this.submitted = false;
    this.profileDialog = true;
    this.enableFeild = false;
    this.profile = new AddProfile();
  }

  sendOtp() {
    //if (!this.aadharNoNameError) {
    setTimeout(() => {
      this.otpButtonName = 'Resend OTP';
      this.sendotp = false;
    }, 30000);
    this.ProfileService.bankSendOtp(this.profile.aadhaarNo).subscribe(
      (res: any) => {
        this.notificationService.alertSuccess(res.body,'');
        this.sendotp = true;
        //console.log('resssss', res.body);
        this.txnNo = res.body;
      },
      err => {
        this.notificationService.alertError(err.error,'');
        //console.log('dhdhdhhdhdjhdjjd', err);
        this.onError();
        this.hideDialog();
      }
    );
    // }
  }

  async validateOtp(otp) {
    this.spinner.show();
    //console.log('logss',otp)
    this.ProfileService
      .bankValidateOtp(this.profile.aadhaarNo,this.profile.otp)
      .subscribe(
    	async (res: HttpResponse<any>) => {
    		//console.log('logsss',res)
    		this.profile = res.body;
        this.profile.dateOfBirthUi = new Date(this.profile.dateOfBirth);
    		this.enableFeild = true;
        this.setApproveProfile();
        this.spinner.hide();
    	},
    	() => {
        this.otpButtonName = 'Resend OTP';
        this.sendotp = false;
        this.profile.otp = null;
        this.spinner.hide();
    	  this.onError();
    	}
      );
    //console.log('logss', otp);
    // this.ProfileService.validateOtp(this.profile.aadhaarNo, this.profile.otp).subscribe(
    //   async (res: HttpResponse<any>) => {
    //     //console.log('logsss', res);
    //     const details = res.body;
    //     //console.log('logsssdes', details);
    //     this.enableFeild = true;
    //     this.setApproveProfile();
    //     const dateParts = details.inforamtion['@attributes']['dob'].split('-');
    //     const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
    //     this.profile.dateOfBirthUi = new Date(formattedDate);
    //     this.profile.dateOfBirth = new Date(formattedDate);
    //     this.profile.name = details.inforamtion['@attributes']['name'];
    //     this.genderList.forEach(element => {
    //       if (element.name[0] === details.inforamtion['@attributes']['gender']) {
    //         this.profile.genderDto = element;
    //       }
    //     });
    //     this.profile.doorNo = details.address['@attributes']['house'];
    //     this.profile.street = details.address['@attributes']['street'];
    //     this.profile.landmark = details.address['@attributes']['lm'];
    //     this.profile.district = details.address['@attributes']['dist'];
    //     this.profile.city = details.address['@attributes']['vtc'];
    //     this.profile.pinCode = details.address['@attributes']['pc'];
    //     this.profile.state = details.address['@attributes']['state'];
    //     // this.profile.fileNameOne = await this.getImage();
    //   },
    //   () => {
    //     this.onError();
    //   }
    // );
  }

  getImage(): Promise<any> {
    return new Promise<any>(resolve => {
      this.ProfileService.getImage(this.profile.aadhaarNo).subscribe(
        (res: any) => {
          //console.log('res', res.body);
          const binaryString = atob(res.body);
          const binaryLen = binaryString.length;
          const bytes = new Uint8Array(binaryLen);
          for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          const blob = new Blob([bytes], { type: 'image/jpeg' });
          const formData = new FormData();
          formData.append('file', blob, 'profile');
          this.paymentService.uploadImage(formData).subscribe((res: HttpResponse<any>) => {
            resolve(res.body.fileName);
          });
        },
        err => {
          this.onError();
          resolve(null);
        }
      );
    });
  }

  async onLazyLoad(event) {
    await this.getRole();
    this.filterProps = event.filterDefinitions;
    
    this.itemsPerPage = event.size;
    if (this.filter) {
      this.filterTable(this.filter);
    }
    
      this.loadPage(event.page);
   
  }

  filterTable(event: FilterTable) {
    this.filter = event;
    this.filterProps = [];
    //console.log('dddd', event);
    // if(event.districtlistIds) {
    //   this.filterProps.push({
    //     key: 'levelMasterId',
    //     operation: 'equals',
    //     multiValue: event.districtlistIds,
    //   });
    // }
    if (event.blockId && (this.currentPath === '/approve-profile' || this.currentPath === '/approve-line-profile')) {
      this.filterProps.push({
        key: 'levelMasterId',
        operation: 'equals',
        value: event.blockId,
      });
    } else {
      if (event.districtId && (this.rolecode !== 'DC1' ) && (this.currentPath === '/approve-profile' || this.currentPath === '/approve-line-profile') ) {
        this.filterProps.push({
          key: 'levelMasterId',
          operation: 'equals',
          value: event.districtId,
        });
      }
    }

    if (event.iaId) {
      this.filterProps.push({
        key: 'implementingAgency.id',
        operation: 'equals',
        value: event.iaId,
      });
    }

    if (event.finyearId) {
      this.filterProps.push({
        key: 'finYear.id',
        operation: 'equals',
        multiValue: event.finyearId,
      });
    }
    
    this.loadPage(1);
    
  }

  hideDialog() {
    this.otpOpen = false;
    this.enableFeild = false;
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.panError = false;
    this.pinError = false;
    this.showBMobile = false;
    this.aadhaarError = false;
    this.profile = new AddProfile();
    this.ngSelectInvalid = false;
    this.profileDialog = false;
    this.resendotp = false;
    this.sendotp = false;
    this.otpButtonName = 'Send OTP';
    this.readOnly = null;
    this.aadharNoNameError = null;
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(this.profile.mobileNo);
    !this.profile.mobileNo ? (this.showMobileError = false) : true;
    return !this.showMobileError && !!this.profile.mobileNo;
  }

  validateEmail() {
    if (this.profile.emailId) {
      this.emailError = !this.validationService.isEmailValid(this.profile.emailId);
      !this.profile.emailId ? (this.emailError = false) : true;
      return !this.emailError;
    } else {
      return true;
    }
  }

  validatePan() {
    if (this.profile.panNo) {
      this.panError = !this.validationService.isPanValid(this.profile.panNo);
      !this.profile.panNo ? (this.panError = false) : true;
      return !this.panError;
    } else {
      return true;
    }
  }

  validatePin() {
    this.pinError = !this.validationService.isPincodeValid(this.profile.pinCode);
    !this.profile.pinCode ? (this.pinError = false) : true;
    return !this.pinError && !!this.profile.pinCode;
  }

  validateAotp() {
    this.otpError = !this.validationService.isOtpValid(this.profile.otp);
    !this.profile.otp ? (this.otpError = false) : true;
    // return !this.showMobileError && !!this.profile.otp;
  }

  validateAadhaar() {
    this.aadhaarError = !this.validationService.isAadhaarValid(this.profile.aadhaarNo);
    !this.profile.aadhaarNo ? (this.aadhaarError = false) : true;

    // return !this.showMobileError && !!this.profile.aadhaarNo;
  }
  setApproveProfile() {
    this.profile.profileStatus = 'SA';

  }

  saveProfile() {
    const isFormValid =
      this.validateMobile() &&
      this.validateEmail() &&
      // this.validatePan() &&
      this.profile.mobileNo &&
      // this.profile.maritalStatusDto &&
      // this.profile.dateOfAssumingOffice &&
      // this.profile.educationalQualificationDto &&
      this.profile.genderDto;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    } else {
      this.ngSelectInvalid = false;

      // if (this.rolecode != 'DA' && this.rolecode != 'BC2' && this.rolecode != 'VC2' && this.rolecode != 'SA') {
      //   this.profile.categoryReservationDto = null;
      //   this.profile.communityDto = null;
      // }
      this.ProfileService.save({ requestPayload: this.encryptService.encryptData(JSON.stringify(this.profile)) }).subscribe(
        (res: any) => {
          this.profileDialog = false;

          this.logout();
          this.notificationService.alertSuccess('Profile Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
    }
  }
  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'View') {
      if ($event.type === 'View') {
        this.readOnly = true;
      }
      this.ProfileService.getProfile($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.profile = res.body;
          this.profileDialog = true;
          this.enableFeild = true;

          if (this.profile.dateOfBirth) {
            this.profile.dateOfBirthUi = new Date(this.profile.dateOfBirth);
          }
          // if (this.profile.termValidUpTo) {
          //   this.profile.termValidUpTo = new Date(this.profile.termValidUpTo);
          // }
          // if (this.profile.dateOfAssumingOffice) {
          //   this.profile.dateOfAssumingOffice = new Date(this.profile.dateOfAssumingOffice);
          // }
          this.loadValue();
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.ProfileService.delete($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }

  approveProfile(event) {
    //console.log('skjhgfdfghjklkjhgf', event);
    if (event.type === 'approve') {
      this.ProfileService.approveProfile(event.value).subscribe(
        (res: any) => {
          //console.log('lsskdkdkdkdkdkd', res);
          this.notificationService.alertSuccess(res.body, '');
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    } else if (event.type === 'Reject') {
      this.ProfileService.delete(event.value).subscribe(
        (res: any) => {
          //console.log('lsskdkdkdkdkdkd', res);
          this.notificationService.alertSuccess(res.body, '');
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }

  logout(): void {
    this.navbarService.logout().subscribe(
      (res: HttpResponse<any>) => {},
      err => {}
    );
    this.localStorageService.clear();
    this.sessionStorageService.clear();
    this.router.navigate(['']);
  }

  getRole(): Promise<any> {
    return new Promise<any>(resolve => {
      this.loginService.getuserDto().subscribe(
        res => {
          this.rolecode = res.body.role.code;
          this.profileStatus = res.body.profileStatus;
          this.userId = res.body.id;
          if (this.profileStatus === 'No' && this.currentPath === '/profile') {
            this.enableCreated = true;
            if(this.rolecode !== 'SA' && this.profileStatus === 'No') {
              this.enableFilter = false;
            }
          }
          if (this.rolecode !== 'SA' && this.currentPath === '/approve-profile') {
            this.cols = [
              {
                field: 'district',
                header: 'District',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
              },
              // {
              //   field: 'blockName',
              //   header: 'Block',
              //   isSortable: true,
              //   isFilterable: false,
              //   isSelectcolumn: true,
              // },
              {
                field: 'adminUser',
                header: 'Category',
                width: 5,
                isSelectcolumn: true,
                type: 'dropDown_with_other_some_field',
                 Dfield: 'role',
                 Efield: 'code'
              },
              // {
              //   field: 'roleName',
              //   header: 'Designation',
              //   isSortable: true,
              //   isFilterable: false,
              //   isSelectcolumn: true,
              // },
              {
                field: 'adminUser',
                header: 'User Name',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
                type: 'dropDown_with_other_field',
                 Dfield: 'userName'
              },
              {
                field: 'adminUser',
                header: 'CUG Mobile No',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
                type: 'dropDown_with_other_field',
                  Dfield: 'mobile'
              },
              {
                field: 'name',
                header: 'Profile Name',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
              },
      
              {
                field: 'mobileNo',
                header: 'Aadhaar Linked Mobile No',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
              },
              {
                field: 'aadhaarNo',
                header: 'Aadhaar No',
                jhiTranslate: 'tableHead.aadhaarno',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
              },
              // {
              //   field: 'termValidUpTo',
              //   header: 'Term Valid Upto',
              //   jhiTranslate: 'tableHead.termValidUpTo',
              //   isSortable: false,
              //   isSelectcolumn: true,
              //   type: 'date',
              // },
            ];
           
          }
        
          else if (this.rolecode !== 'SA' && this.currentPath !== '/approve-profile') {
            this.cols = [
              {
                field: 'district',
                header: 'District',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
              },
             
              
              {
                field: 'adminUser',
                header: 'Category',
                width: 5,
                isSelectcolumn: true,
                type: 'dropDown_with_other_some_field',
                 Dfield: 'role',
                 Efield: 'code'
              },
              // {
              //   field: 'roleName',
              //   header: 'Designation',
              //   isSortable: true,
              //   isFilterable: false,
              //   isSelectcolumn: true,
              // },
              {
                field: 'adminUser',
                header: 'User Name',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true, type: 'dropDown_with_other_field',
                 Dfield: 'userName'
              },
              {
                field: 'adminUser',
                header: 'CUG Mobile No',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true, type: 'dropDown_with_other_field',
                  Dfield: 'mobile'
              },
              {
                field: 'name',
                header: 'Profile Name',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
              },
      
              {
                field: 'mobileNo',
                header: 'Aadhaar Linked Mobile No',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
              },
              {
                field: 'aadhaarNo',
                header: 'Aadhaar No',
                jhiTranslate: 'tableHead.aadhaarno',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
              },
              // {
              //   field: 'termValidUpTo',
              //   header: 'Term Valid Upto',
              //   jhiTranslate: 'tableHead.termValidUpTo',
              //   isSortable: false,
              //   isSelectcolumn: true,
              //   type: 'date',
              // },
            ];
          } 
          else {
            this.cols = [
              {
                field: 'district',
                header: 'District',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
              },
      
              {
                field: 'adminUser',
                header: 'Category',
                width: 5,
                isSelectcolumn: true,
                type: 'dropDown_with_other_some_field',
                 Dfield: 'role',
                 Efield: 'code'
              },
      
              // {
              //   field: 'roleName',
              //   header: 'Designation',
              //   isSortable: true,
              //   isFilterable: false,
              //   isSelectcolumn: true,
              // },
              {
                field: 'adminUser',
                header: 'User Name',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true, type: 'dropDown_with_other_field',
                 Dfield: 'userName'
              },
              {
                field: 'adminUser',
                header: 'CUG Mobile No',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true, type: 'dropDown_with_other_field',
                  Dfield: 'mobile'
              },
              {
                field: 'name',
                header: 'Profile Name',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
              },
      
              {
                field: 'mobileNo',
                header: 'Aadhaar Linked Mobile No',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
              },
              {
                field: 'aadhaarNo',
                header: 'Aadhaar No',
                jhiTranslate: 'tableHead.aadhaarno',
                isSortable: true,
                isFilterable: false,
                isSelectcolumn: true,
              },
              // {
              //   field: 'termValidUpTo',
              //   header: 'Term Valid Upto',
              //   jhiTranslate: 'tableHead.termValidUpTo',
              //   isSortable: false,
              //   isSelectcolumn: true,
              //   type: 'date',
              // },
            ];
      
          }
          resolve(this.rolecode);
        },
        onError => {
          resolve(null);
        }
      );
    });
  }
}

<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>LAO Details</h2>
</div>
<div class="container bg">
    <div class="col-md-12">
        <div class="form-inner-panel">
            <div class="row gy-2">
              
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass">District
                    </label>
                    <ng-select id="department" bindLabel="name"  appearance="outline"
                        [(ngModel)]="fund.district" [closeOnSelect]="true" [clearable]="true" [items]="district"
                        class="form-control" [disabled]="viewOnly" (change)="onDistrict($event)" appNgSelectInputRestriction>
                    </ng-select>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass">Category of
                        LAO</label>
                    <ng-select id="department" bindLabel="category"  appearance="outline"
                        [(ngModel)]="fund.category" [closeOnSelect]="true" [clearable]="true" [items]="categoryLAO"
                        class="form-control" [disabled]="viewOnly" (change)="onLAO($event)" appNgSelectInputRestriction>
                    </ng-select>
                </div>
     
                <!-- <div class="col-sm-6 col-md-4" *ngIf="isCategoryVisible">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass">Category</label>
                    <ng-select id="department" bindLabel="name" bindValue="id" appearance="outline"
                        [(ngModel)]="fund.text" [closeOnSelect]="true" [clearable]="true" [items]="category"
                        class="form-control" [disabled]="viewOnly"  (change)="oncategoryChange($event)" appNgSelectInputRestriction>
                    </ng-select>
                </div> -->
                <!-- <div class="col-sm-6 col-md-4" *ngIf="!isMakerVisible && !isCheckerVisible">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for="agencyName">Mobile
                        No</label>
                    <input type="number" id="agencyName" #mobileNo="ngModel" ngModel name="agencyName" (keypress)="keyPressNumberForMobile($event)"
                        [(ngModel)]="fund.mobile" class="form-control" minlength="10" pattern="^[6-9]\d{9}$" />
                    <div *ngIf="mobileNo.dirty && mobileNo.invalid">
                        <small class="form-text text-danger" *ngIf="mobileNo.control.errors.pattern">
                            Please enter valid mobile number
                        </small>
                    </div>
                </div>

                <div class="col-sm-6 col-md-4" *ngIf="!isMakerVisible && !isCheckerVisible" >
                    <label class="form-control-label req" [ngClass]="fontService.labelClass"
                        for="agencyName">Email</label>
                    <input type="email" id="agencyName" name="agencyName" [(ngModel)]="fund.email"
                        class="form-control" />
                </div> -->
         
            </div>
            <div class="row gy-2 mt-2" *ngIf="isMakerVisible">
                <h2 class="maker_text">Maker</h2>

                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass"
                        for="agencyName">Username</label>
                    <input type="text" id="agencyName" name="agencyName" (change)="onchangeUserNameMaker($event)" uppercase (keyup)="onchangeMakerName($event)" [(ngModel)]="fund.makerName"
                        class="form-control" />
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for="agencyName">Mobile
                        No</label>
                    <input type="number" id="agencyName" #mobileNo="ngModel" ngModel name="agencyName" (keypress)="keyPressNumberForMobile($event)"
                        [(ngModel)]="fund.makermobileNo" class="form-control" minlength="10" pattern="^[6-9]\d{9}$" />
                    <div *ngIf="mobileNo.dirty && mobileNo.invalid">
                        <small class="form-text text-danger" *ngIf="mobileNo.control.errors.pattern">
                            Please enter valid mobile number
                        </small>
                    </div>
                </div>

                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass"
                        for="agencyName">Email</label>
                    <input type="email" id="agencyName" name="agencyName" [(ngModel)]="fund.makerEmail"
                        class="form-control" />
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label " [ngClass]="fontService.labelClass"
                        for="agencyName">Designaiton</label>
                    <input type="text" id="agencyName" name="agencyName" readonly  [(ngModel)]="fund.makerDesignation"
                        class="form-control"  style="background: lightgray;" />
                </div>
            </div>
            <div class="row gy-2 mt-2" *ngIf="isCheckerVisible">
                <h2 class="maker_text">Checker</h2>

                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass"
                        for="agencyName">Username</label>
                    <input type="text" id="agencyName" name="agencyName" (change)="onchangeUserNameChecker($event)" uppercase (keyup)="onchangeCheckerName($event)"   [(ngModel)]="fund.checkerName"
                        class="form-control" />
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for="agencyName">Mobile
                        No</label>
                    <input type="number" id="agencyName" #mobileNo="ngModel" ngModel name="agencyName" (keypress)="keyPressNumberForMobile($event)"
                        [(ngModel)]="fund.checkermobileNo" class="form-control" minlength="10" pattern="^[6-9]\d{9}$" />
                    <div *ngIf="mobileNo.dirty && mobileNo.invalid">
                        <small class="form-text text-danger" *ngIf="mobileNo.control.errors.pattern">
                            Please enter valid mobile number
                        </small>
                    </div>
                </div>

                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass"
                        for="agencyName">Email</label>
                    <input type="email" id="agencyName" name="agencyName" [(ngModel)]="fund.checkerEmail"
                        class="form-control" />
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label" [ngClass]="fontService.labelClass"
                        for="agencyName">Designaiton</label>
                    <input type="text" id="agencyName" name="agencyName" readonly [(ngModel)]="fund.checkerDesignation"
                        class="form-control"  style="background: lightgray;" />
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!viewOnly" class="mt-3">
        <button pButton pRipple icon="pi pi-times" iconPos="left" class="p-button-text custom-button cancelButton"
            routerLink="/lao-list">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span>
        </button> &nbsp;
        <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button saveButton"
            (click)="saveLao('add')" type="submit" >
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
        </button>
    </div>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indianRupee'
})
export class IndianRupeePipe implements PipeTransform {
  transform(value: number | string): string {
    if (!value) return '0.00'; // Return default for falsy values

    // Convert to string and remove non-numeric characters
    const numStr = value.toString().replace(/[^0-9.]/g, '');
    
    // Split into integer and decimal parts
    const [integerPart, decimalPart] = numStr.split('.');

    // Format the integer part
    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);
    let result = '';

    if (otherDigits) {
      result += (otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ','); // for the remaining digits
    }
    result += lastThreeDigits;

    // Add decimal part if it exists
    if (decimalPart) {
      // Keep only two decimal places, and concatenate '0' if decimal part is only 1 digit
      if (decimalPart.length === 1) {
        result += '.' + decimalPart + '0'; // e.g. 5 becomes 50
      } else {
        result += '.' + decimalPart.slice(0, 2); // Take only first two digits
      }
    } else {
      result += '.00'; // If no decimal part, add .00
    }

    return result;
  }
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import {
  paymentVoucherGen, ImplementingAgencyDto, VendorTransaction, DescriptionDto, limitDto, ComponentMasterDto, MyFiles, EmuthraResponseDto, EmudhraRequest
  , SwapTransactionDto,
  SwappingTransactionDto
} from './payment-voucher.model';
import { Project } from 'src/app/project/project.model';
import { EncryptService } from 'src/app/shared/encrypt.service';
export type EntityResponseType = HttpResponse<paymentVoucherGen>;
export type EntityArrayResponseType = HttpResponse<VendorTransaction[]>;
export type EntityArraySwapResponseType = HttpResponse<SwapTransactionDto[]>;
export type DescrptionArrayResponseType = HttpResponse<DescriptionDto[]>;
@Injectable({
    providedIn: 'root'
  })
  
export class PaymentService {
  protected getVoucherNoUrl = environment.SERVER_API_URL + ('api/getVoucherNo');
  protected VoucherSaveUrl = environment.SERVER_API_URL + ('api/voucher/generatePdfQ');
  protected VoucherSwapSaveUrl = environment.SERVER_API_URL + ('api/voucher/swapGeneratePdf');
  protected PaymentIntergationUrl = environment.SERVER_API_URL + ('api/paymentIntegration');
  protected PaymentStatusUrl = environment.SERVER_API_URL + ('api/transactionStatus');
  protected emudhraUrl = environment.SERVER_API_URL + ('api/emudhraRedirect');
  protected getFindImplementingById = environment.SERVER_API_URL + ('api/getImplementingAgency')
  protected getListTransction = environment.SERVER_API_URL + ('api/getVendorTransactionByFilter');
  protected getSwapListTransction = environment.SERVER_API_URL + ('api/getSwapTransactionByFilter');
  protected getLastfiveTransction = environment.SERVER_API_URL + ('api/getCommercialLastFiveTransaction')
  protected getLastPersonalFiveTransction = environment.SERVER_API_URL + ('api/getPersonalLastFiveTransaction')
  protected uploadImageUrl = environment.SERVER_API_URL + ('api/uploadFile');
  protected uploadImageUrl1 = environment.SERVER_API_URL + ('api/uploadMultipleFiles');
  protected downloadFileUrl = environment.SERVER_API_URL + ('api/downloadFile');
  protected viewFileUrl = environment.SERVER_API_URL + ('api/viewFile');
  protected deleteFileUrl = environment.SERVER_API_URL + ('api/deleteFile');
  protected descrptionUrl = environment.SERVER_API_URL + ('api/descriptionList');
  protected UpdatedVoucher = environment.SERVER_API_URL + ('api/getVendorTransaction')
  protected UpdatedSwapVoucher = environment.SERVER_API_URL + ('api/getSwapTransaction')
  protected getLimitAmount = environment.SERVER_API_URL + ('api/getIAComponentLimitByIaIdAndCompnentTypeId')
  protected getComponentMasterUrl = environment.SERVER_API_URL + ('api/getComponentMaster')
  protected DeleteTransctionUrl = environment.SERVER_API_URL + ('api/deleteVendorTransaction')
  protected DeleteSwapTransctionUrl = environment.SERVER_API_URL + ('api/deleteSwapTransaction')
  protected revertVendorTransUrl = environment.SERVER_API_URL + ("api/revertVendorTransaction");
  protected revertSwapTransUrl = environment.SERVER_API_URL + ("api/revertSwapTransaction");
  protected reportTransUrl = environment.SERVER_API_URL + ("api/getVendorTransactionValidByFilter");
  protected getfileUrl = environment.SERVER_API_URL + ('api/cdacRequest');
  protected getPdfUrl = environment.SERVER_API_URL + ('api/emuthraSignedDownload');
  protected getSwapfileUrl = environment.SERVER_API_URL + ('api/cdacSwapRequest');
  protected getSwapPdfUrl = environment.SERVER_API_URL + ('api/swapEmuthraSignedDownload');
  protected getpaymentStatusUrl = environment.SERVER_API_URL + ('api/paymentStatus');
  protected getZonalCheckersUrl = environment.SERVER_API_URL + ('api/grtZonalCheckers');
  protected getProjectByWorkidUrl = environment.SERVER_API_URL + ('api/getProjectByWorkid');
  protected getWorkPaymentPartCountUrl = environment.SERVER_API_URL + ('api/getWorkPaymentPartCount');
  protected checkPendingVoucherUrl = environment.SERVER_API_URL + ('api/checkPendingVoucher');
  protected getTransactionByProjectUrl = environment.SERVER_API_URL + ('api/getTransactionByProject');
  constructor(protected http: HttpClient,private encryptionService:EncryptService) { }

  getVoucher(): Observable<any> {
    return this.http.get(this.getVoucherNoUrl, { observe: 'response', responseType: 'text' });
  }

  getfile(fileName: string): Observable<any> {
    return this.http.get(`${this.getfileUrl}?fileName=${fileName}`, { observe: 'response' });

  }
  getSwapfile(fileName: string): Observable<any> {
    return this.http.get(`${this.getSwapfileUrl}?fileName=${fileName}`, { observe: 'response' });

  }

  genVocher(vocher: paymentVoucherGen) {
    return this.http.post(this.VoucherSaveUrl, vocher, { observe: 'response', responseType: 'arraybuffer' })
  }
  genSwapVocher(vocher: SwappingTransactionDto) {
    return this.http.post(this.VoucherSwapSaveUrl, vocher, { observe: 'response', responseType: 'arraybuffer' })
  }
  paymentSuccess(vocher: paymentVoucherGen) {
    return this.http.post(this.PaymentIntergationUrl, vocher, { observe: 'response', responseType: 'text' })
  }

  getZonalCheckers(id: any) {
    return this.http.get(`${this.getZonalCheckersUrl}?levelMasterId=${id}`, { observe: 'response' })
  }

  getProjectByWorkid(id: any) {
    return this.http.post<Project>(`${this.getProjectByWorkidUrl}?id=${id}`, { observe: 'response' })
  }
  getWorkPaymentPartCount(id: any) {
    return this.http.post<any>(`${this.getWorkPaymentPartCountUrl}?workId=${id}`, { observe: 'response' })
  }
  getcheckPendingVoucher(id: string): Observable<any> {
    return this.http.post(`${this.checkPendingVoucherUrl}?workId=${id}`, null, { responseType: 'text' });
  }

  getPaymentStatus(id: any) {
    return this.http.get(`${this.getpaymentStatusUrl}?id=${id}`, { observe: 'response' })
  }
  paymentStatus(id: any) {
    return this.http.get(`${this.PaymentStatusUrl}?interactionId=${id}`, { observe: 'response' })
  }

  emudhraRedriect(file: EmudhraRequest) {
    return this.http.post(this.emudhraUrl, file, { observe: 'response' })
  }

  getPdfFile(fileName: string) {
    return this.http.get(`${this.getPdfUrl}?filename=${fileName}`, { observe: 'response', responseType: 'arraybuffer' })
  }

  getSwapPdfFile(fileName: string) {
    return this.http.get(`${this.getSwapPdfUrl}?filename=${fileName}`, { observe: 'response', responseType: 'arraybuffer' })
  }

  updatedVocher(id: number): Observable<HttpResponse<any>> {
    return this.http.get<paymentVoucherGen>(`${this.UpdatedVoucher}?id=${id}`, { observe: 'response' });
  }
  updatedSwapVocher(id: number): Observable<HttpResponse<any>> {
    return this.http.get<SwappingTransactionDto>(`${this.UpdatedSwapVoucher}?id=${id}`, { observe: 'response' });
  }

  deletedVocher(id: number): Observable<any> {
    return this.http.get(`${this.DeleteTransctionUrl}?id=${id}`, { observe: 'response' });
  }

  deletedSwapVocher(id: number): Observable<any> {
    return this.http.get(`${this.DeleteSwapTransctionUrl}?id=${id}`, { observe: 'response' });
  }
  getComponentMaster(id: number): Observable<HttpResponse<any>> {
    return this.http.get<ComponentMasterDto>(`${this.getComponentMasterUrl}?id=${id}`, { observe: 'response' });
  }

  getimage(fileName: string): any {
    return this.http.get(`${this.downloadFileUrl}/${fileName}`, { observe: 'response' });
  }
  viewimage(fileName: string): any {
    return this.http.get(`${this.viewFileUrl}/${fileName}`, { observe: 'response' });
  }
  deleteimage(fileName: string): any {
    return this.http.get(`${this.deleteFileUrl}/${fileName}`, { observe: 'response' });
  }

  getDescrptionList(): Observable<DescrptionArrayResponseType> {
    return this.http
      .get<DescriptionDto[]>(this.descrptionUrl, { observe: 'response' })
  }
  filter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = []
    }
    const options = createRequestOption(req);
    return this.http
      .post<VendorTransaction[]>(this.getListTransction, filter, { params: options, observe: 'response' })
  }
  swapFilter(req?: any, filter?: any): Observable<EntityArraySwapResponseType> {
    if (filter === null) {
      filter = []
    }
    const options = createRequestOption(req);
    return this.http
      .post<SwapTransactionDto[]>(this.getSwapListTransction, filter, { params: options, observe: 'response' })
  }
  reportfilter(req?: any, filter?: any, fromDate?: any, toDate?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = []
    }
    if (fromDate && toDate) {
      const options = createRequestOption(req);
      return this.http
        .post<VendorTransaction[]>(`${this.reportTransUrl}?fromDate=${fromDate}&toDate=${toDate}`, filter, { params: options, observe: 'response' })
    }
    else {
      const options = createRequestOption(req);
      return this.http
        .post<VendorTransaction[]>(this.reportTransUrl, filter, { params: options, observe: 'response' })
    }
  }

  getListVendorTransction(id) {
    const options = createRequestOption(id);
    return this.http.get<VendorTransaction>(this.getLastfiveTransction, { params: { id: id, page: 0, size: 5 }, observe: 'response' })
  }

  getListPersonalVendorTransction(id) {
    const options = createRequestOption(id);
    return this.http.get<VendorTransaction>(this.getLastPersonalFiveTransction, { params: { id: id, page: 0, size: 5 }, observe: 'response' })
  }

  getByImplemeting(id: number) {
    return this.http
      .get<ImplementingAgencyDto>(this.getFindImplementingById, { params: { id: id }, observe: 'response' })

  }
  uploadImage(image) {
    // let val=this.encryptionService.encryptData(image);
    // console.log(val)
    return this.http.post<any>(this.uploadImageUrl, image, { observe: 'response' })

  }
  uploadImage1(image) {
    return this.http.post<any>(this.uploadImageUrl1, image, { observe: 'response' })

  }
  getLimit(id: number, compId: number) {
    return this.http
      .get<limitDto>(this.getLimitAmount, { params: { iaId: id, componentTypeId: compId }, observe: 'response' })

  }

  revertTranaction(id: number) {
    return this.http.get<any>(`${this.revertVendorTransUrl}?id=${id}`, {
      observe: "response",
    });
  }

  revertSwapTranaction(id: number) {
    return this.http.get<any>(`${this.revertSwapTransUrl}?id=${id}`, {
      observe: "response",
    });
  }

  getTransactionByProject(workId: string) {
    return this.http.post<any>(`${this.getTransactionByProjectUrl}?workId=${workId}`, { observe: 'response' })
  }

}
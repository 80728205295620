<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Fund Received</h2>
</div>
<div class="container bg mx-auto">
    <div class="row">
        <div class="col-lg-2">
            <div>
                <label class="form-control-label req" for="scheme">
                    From Date</label>
            </div>

            <p-calendar [readonlyInput]="true" dateFormat="dd/mm/yy" [maxDate]="maxDate" [(ngModel)]="fromDate"
                appendTo="body"></p-calendar>
        </div>
        <div class="col-lg-2">
            <div>
                <label class="form-control-label req" for="scheme">
                    To Date</label>
            </div>
            <p-calendar [readonlyInput]="true" [(ngModel)]="toDate" [maxDate]="maxDate" dateFormat="dd/mm/yy"
                appendTo="body"></p-calendar>
        </div>
        <div class="col-lg-2">
            <div>
                <label class="form-control-label req" for="scheme">
                    District Name</label>
            </div>
            <ng-select id="department"  appearance="outline" [(ngModel)]="districtName"
                [items]="districtNames" class="form-control" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-lg-2">
            <div>
                <label class="form-control-label req" for="scheme">
                    Agency Name</label>
            </div>
            <ng-select id="department"  appearance="outline" [(ngModel)]="agencyName"
                [items]="agencyNames" class="form-control" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-lg-2">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Project
                Name</label>
            <ng-select id="department"  appearance="outline" [(ngModel)]="projectName"
                [items]="projectList" class="form-control" appNgSelectInputRestriction>
            </ng-select>
        </div>
        
    </div>
    <div class="pt-4">
        <p-button label="Submit" class="submit" 
            (click)="getProjectsFilter(1,'action')"></p-button> &nbsp;
        <p-button label="Reset" class="submit"
            (click)="fromDate = '';
            toDate='' ; districtName='';agencyName=''; projectName='';getProjectsFilter(1,'first')"></p-button>&nbsp;
        <p-button label="Home" class="submit" routerLink="/main-dashboard"></p-button>

    </div>
    <div class="row tableDesign">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Project Name</th>
                    <th scope="col">Department</th>
                    <th scope="col">Total Amount Received</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let data of beneficiaryList;let i =index'>
                    <td>{{generateIndex(i)}}</td>
                    <td >{{data?.projectName}}</td>
                    <td>{{data?.agencyName}}</td>
                    <td>{{data?.fundReceived}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[appNgSelectInputRestriction]'
})
export class NgSelectInputRestrictionDirective {

 // Define the regex for the characters you want to restrict
 private regex: RegExp = new RegExp('[!@#$%^&*+={}\\[\\]|?<>\\\\/]');
  
 constructor(private ngControl: NgControl,private renderer: Renderer2) {}

 @HostListener('input', ['$event'])
 onInputChange(event: Event): void {
 
   const input = event.target as HTMLInputElement;

   let value = input.value;
  
   // Replace the restricted characters with an empty string

   const newValue = value.replace(this.regex, '');

 
   if (newValue !== value) {
     this.renderer.setProperty(input, 'value', newValue);
     this.ngControl.control?.setValue(newValue);

   }
 }
}

<div class="Main">
  <div class="container-fluid  mt-3">
    <div class="d-flex bd-highlight">
      <div class="p-2 flex-grow-1 bd-highlight">
        <h2>Fund Management Dashboard</h2>
      </div>
      <div class="p-2 bd-highlight">
        <ng-select id="department" class="mt-1" [(ngModel)]="selectedYear" [items]="year" appearance="outline"
          [clearable]="false" class="form-control">
        </ng-select>
      </div>
      <div class="p-2 bd-highlight">
        <ng-select id="department" class="mt-1" [(ngModel)]="selectedMonth" [items]="month" appearance="outline"
          [clearable]="false" class="form-control">
        </ng-select>
      </div>
      <div class="p-2 bd-highlight">
        <button type="button" class="btn btn-warning" (click)="filter()"
          [disabled]="(selectedYear == '' || selectedMonth == '')">Filter</button> &nbsp;
        <button type="button" class="btn btn-danger" (click)="clear()">Clear</button>
      </div>
    </div>
    <div class="card top">
      <div class="row g-xl-3">
        <div class="col-lg-3">
          <div class="card list1" routerLink="/noProjectReport" style="cursor: pointer;">
            <div class="row">
              <div class="col-lg-6 bl1">
                <p>No of Projects</p>
                <h3>{{this.fundDtails?.totalNoOfProjects}}</h3>
              </div>
              <div class="col-lg-6">
                <img src="../../../assets/img/Background.png" style="float: right;" alt="">
              </div>
              <p class="mt-2"> <span
                [ngClass]="this.fundDtails?.noOfProjectStatus.status == 'INCREASED' ? 'increase' : this.fundDtails?.noOfProjectStatus.status == 'DECREASED' ? 'decrease' :'noChange' ">
                <i *ngIf="this.fundDtails?.fundReceivedStatus.status == 'INCREASED'" class="fa fa-arrow-up" aria-hidden="true"></i>
                <i *ngIf="this.fundDtails?.fundReceivedStatus.status == 'DECREASED'" class="fa fa-arrow-down" aria-hidden="true"></i>  {{this.fundDtails?.noOfProjectStatus.projectsFromLastMonth}}
              </span> From last Month</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card list2" routerLink="/fund-received" style="cursor: pointer;">
            <div class="row ">
              <div class="col-lg-6 bl2">
                <p>Fund Received(In Crores)</p>
                <h3>{{this.fundDtails?.totalFundReceived / 10000000 | number: '1.1-4'}}</h3>
                
              </div>
              <div class="col-lg-6">
                <img src="../../../assets/img/Background (1).png" style="float: right;" alt="">
              </div>
              <p class="mt-2"> <span
                [ngClass]="this.fundDtails?.fundReceivedStatus.status == 'INCREASED' ? 'increase' : this.fundDtails?.fundReceivedStatus.status == 'DECREASED' ? 'decrease' : 'noChange'">
                <i *ngIf="this.fundDtails?.fundReceivedStatus.status == 'INCREASED'" class="fa fa-arrow-up" aria-hidden="true"></i>
                <i *ngIf="this.fundDtails?.fundReceivedStatus.status == 'DECREASED'" class="fa fa-arrow-down" aria-hidden="true"></i>
                {{this.fundDtails?.fundReceivedStatus.fromLastMonth}}
              </span> From last Month</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card list3" routerLink="/expenditure-report" style="cursor: pointer;">
            <div class="row ">
              <div class="col-lg-6 bl3">
                <p>Expenditure(In Crores)</p>
                <h3>{{this.fundDtails?.totalFundSpent / 10000000 | number: '1.4-4'}}</h3>
               
              </div>
              <div class="col-lg-6">
                <img src="../../../assets/img/Background (2).png" style="float: right;" alt="">
              </div>
              <p class="mt-2"> <span
                [ngClass]="this.fundDtails?.fundSpentStatus.status == 'INCREASED' ? 'increase' : this.fundDtails?.fundSpentStatus.status == 'DECREASED' ? 'decrease' :'noChange' ">
                <i *ngIf="this.fundDtails?.fundSpentStatus.status == 'INCREASED'" class="fa fa-arrow-up" aria-hidden="true"></i>
                <i *ngIf="this.fundDtails?.fundSpentStatus.status == 'DECREASED'" class="fa fa-arrow-down" aria-hidden="true"></i> 
                {{this.fundDtails?.fundSpentStatus.fromLastMonth}}
              </span> From last Month</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card list4">
            <div class="row ">
              <div class="col-lg-6 bl4">
                <p>Closing Balance(In Crores)</p>
                <h3>{{this.fundDtails?.totalAvailableAmount / 10000000 |number: '1.4-4'}}</h3>
              
              </div>
              <div class="col-lg-6">
                <img src="../../../assets/img/Overlay.png" style="float: right;" alt="">
              </div>
              <p class="mt-2"> <span
                [ngClass]="this.fundDtails?.balanceStatus.status == 'INCREASED' ? 'increase' : this.fundDtails?.balanceStatus.status == 'DECREASED' ? 'decrease' :'noChange' ">
                <i *ngIf="this.fundDtails?.balanceStatus.status == 'INCREASED'" class="fa fa-arrow-up" aria-hidden="true"></i>
                <i *ngIf="this.fundDtails?.balanceStatus.status == 'DECREASED'" class="fa fa-arrow-down" aria-hidden="true"></i>
               {{this.fundDtails?.balanceStatus.fromLastMonth }} 
              </span> From last Month</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-1 g-xl-2 ">
      <div class="col-lg-5">
        <div class="card p-3">
          <h2 class="head">Fund Allocation Vs Expenditure</h2>
          <canvasjs-chart *ngIf="isShowFirstChart" [options]="chartOptions"
            [styles]="{width: '100%', height:'360px'}"></canvasjs-chart>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card p-3">
          <h2 class="head">District-Wise Beneficiary Count </h2>
          <canvasjs-chart *ngIf="isShowSecondChart" [options]="chartOptions1"
            [styles]="{width: '100%', height:'360px'}"></canvasjs-chart>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card p-3">
          <h2 class="head">Projects by Department </h2>
          <canvasjs-chart *ngIf="isShowThirdChart" [options]="chartOptions2"
            [styles]="{width: '100%', height:'360px'}"></canvasjs-chart>
        </div>
      </div>
    </div>
    <div class="row mt-1 g-xl-2">
      <div class="col-lg-5">
        <div class="card p-3">
          <h5 class="card-title">Project Fund Position</h5>
          <div class="stripe">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Project Name</th>
                  <th scope="col">No of Projects Involved</th>
                  <th scope="col">Total Extend</th>
                  <th scope="col">Amount Received</th>
                  <th scope="col">Expenditure</th>
                  <th scope="col">Closing Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of projectFund">
                  <th>{{data.projectName}}</th>
                  <td>{{data.noOfDistricts}}</td>
                  <td>{{data.totalExtent}}</td>
                  <td>{{data.amountReceived}}</td>
                  <td>{{data.expenditure}}</td>
                  <td>{{data.balance}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card p-3">
          <div class="stripe">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Districts</th>
                  <th scope="col">No of Beneficiaries</th>
                  <th scope="col">Spent Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of districtCount">
                  <td>{{data.district}}</td>
                  <td>{{data.noOfBeneficiaries}}</td>
                  <td>{{data.amountSpent}}</td>
                </tr>
                <tr class="bottom">
                  <td>Total</td>
                  <td>{{totalNoOfBeneficiaries}}</td>
                  <td>{{totalAmountSpent}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card p-3">
          <div class="stripe">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Request Departments</th>
                  <th scope="col">No of Projects</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of projectCount">
                  <td>{{data.requestingAgency}}</td>
                  <td>{{data.noOfProjects}}</td>
                </tr>
                <tr class="bottom">
                  <td>Total</td>
                  <td>{{projectCountTotal}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { filterQuery } from './../common.model';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterMatchMode, LazyLoadEvent } from 'primeng/api';
import { Subject } from 'rxjs';
import { ASC, DESC, ITEMS_PER_PAGE } from 'src/app/config/pagination.constants';
import { SearchResult } from '../models/search-result';
import { TableColumn } from '../models/table-column';
import * as dayjs from 'dayjs';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { HttpResponse } from '@angular/common/http';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { FilterTable } from '../models/table-filter-model';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { ReportService } from 'src/app/dynamic-report-table/dynamic-report-service';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'custom-filter-table',
  templateUrl: './custom-filter-table.component.html',
  styleUrls: ['./custom-filter-table.component.scss'],
})
export class CustomFilterTableComponent implements OnInit {
  matchModeOptions = [
    { label: 'Contains', value: FilterMatchMode.CONTAINS },
    { label: 'Equals', value: FilterMatchMode.EQUALS },
  ];
  @ViewChild('dt') tableToExport: ElementRef;

  @Output() emitRow = new EventEmitter();
  @Output() emitFilter = new EventEmitter();
  @Output() searchParam = new EventEmitter();
  @Output() dynamicParam = new EventEmitter();

  @Output() showTable = new EventEmitter();
  @Output() redirectTo = new EventEmitter();
  @Output() approve = new EventEmitter();
  @Input() disableDeleteIcon = true;
  @Input() disableEditIcon = true;
  @Input() disableLockIcon = true;
  @Input() disableDeactivateIcon = true;
  @Input() lazyLoadOnInit = true;
  @Input() paginator = true;
  @Input() showProfileButton = false;
  @Input() showRedirectButton = false;
  @Input() showTableButton = false;
  @Input() enableView ;
  @Input() enableViewIcon = false;
  @Input() fileName;
  @Input() keyName;
  @Input() back;

  
  
  @Input() enableApprove = false;
  @Input() enableReject = false;
  @Input() enableApproveStatus = false;
  @Input() districtFilter = false;
  @Input() blockFilter = false;
  @Input() VillageFilter = false;
  @Input() finYearFilter = false;
  @Input() dateFilter = false;
  @Input() searchButton = false;
  @Input() iAFilter = false;
  villageLevelList: any;
  blockVillageDto: any;
  blockLevelList: LevelMasterDto[];
  districtListDto: any;
  districtLevelList: LevelMasterDto[];
  VillageDto: any;
  finYear: FinYear[];
  finYearDto: any;
  toDate: Date;
  fromDate: Date;
  implementingAgencyDto: any;
  iaCompList: any;
  minDate = new Date('01/03/2015');
  maxDate = new Date();

  @Input() set columnsValues(frozenValue: any[]) {
    //console.log('kdjfjvdksxjcjxkcvjckxcnxmcxmcn', frozenValue);
    this.columnOptions = [];
    this.selectedColumns = [];
    let temp = []
    temp.push({ header: 'S No', field: 'sno', isSortable: false, isFilterable: false, isSelectcolumn: true },)
    frozenValue?.forEach((frozen) => {
      temp.push(frozen)
    })
    frozenValue = temp;
    frozenValue?.forEach((frozen) => {
      delete frozen.value;
      this.columnOptions.push(frozen);
      if (frozen.isSelectcolumn) {
        this.selectedColumns.push(frozen);
      }
    });

    ////console.log(this.columnOptions,this.selectedColumns,'******************')
  }
  columnOptions: TableColumn[] = [];
  selectedColumns: TableColumn[] = [];
  listItems: Subject<any[]> = new Subject<any[]>();
  totalCount = 0;
  first = 0;
  sortOrder = 1;
  sortField = 'id';
  valueFromEvent: any;
  @Input() itemsPerPage = ITEMS_PER_PAGE;
  @ViewChild('reportTable', { static: true }) reportTable!: any;
  @Input() set filterSearchResult(filterSearchResult: SearchResult<any>) {
    if (!filterSearchResult) {
      return;
    }
    ////console.log(filterSearchResult)
    this.listItems.next(filterSearchResult.items);
    this.setSNO(filterSearchResult)
    this.totalCount = filterSearchResult.total;
    this.totalCount = filterSearchResult.total;
  }

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    private levelMasterService: LevelMasterService,
    private limitTransListService: LimitTransListService,
    private reportService: ReportService,
    private spinner: NgxSpinnerService,
    private NotificationService: NotificationService
    

  ) { }

  ngOnInit(): void {
    this.onStateLevelfilterChange();
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYear = res.body;
      },
      () => { }
    );
    const currentPage = this.activatedRoute.snapshot.queryParamMap.get('page');
    const currentSize = this.activatedRoute.snapshot.queryParamMap.get('size');

    const currentSort = this.activatedRoute.snapshot.queryParamMap
      .get('sort')
      ?.split(',');
    if (currentSort) {
      this.sortField = currentSort[0];
      this.sortOrder = currentSort[1] === ASC ? 1 : -1;
    }
    if (currentPage) {
      this.first = Number(currentSize) * (Number(currentPage) - 1);
    }
  }

  ngAfterViewInit() {
    const table =
      this.reportTable.containerViewChild.nativeElement.querySelector('table');
    table.setAttribute('id', 'excelTable');
  }
  setSNO(data) {
    //console.log(this.first, data['items']);
    data['items'].forEach((eachData, ind) => {
      eachData.sno = ind + this.first + 1;
    })

  }

  getChild(data) {
    //console.log(data, 'data');
  }

  loadFilterValues(event: LazyLoadEvent) {
    //console.log('jdjdjdjjdjdjdjdjddjkkd', event);
    let filterDefinitions = [];
    let filterValue = null;
    if (Object.keys(event.filters).length !== 0) {
      for (let column of this.selectedColumns) {
        filterValue = event.filters[column.field];
        //console.log('lkjhgfdfghjkljhhh', filterValue);
        if (filterValue?.value && column.type != 'dropDown') {
          //console.log('lkjhgfdfghjkljhhhfiltersss');
          let query = new filterQuery();
          query.key = column.field;
          query.operation = filterValue.matchMode;
          query.value = filterValue.value.toUpperCase().trim();
          filterDefinitions.push(query);
        }
        if (
          filterValue?.value &&
          column.type === 'dropDown' &&
          column.isFilterable
        ) {
          let query = new filterQuery();
          query.key = column.field + '.id';
          query.operation = filterValue.matchMode;
          query.value = filterValue.value.id;
          //console.log('ddldldldldd', query);
          filterDefinitions.push(query);
        }
      }
    }

    let sortDefinitions = {
      predicate: event.sortField,
      ascending: event.sortOrder === 1 ? true : false,
    };

    this.first = event.first;
    event.first = (event.first + event.rows) / event.rows;

    let filterData = {
      filterDefinitions,
      sortDefinitions,
      page: event.first,
      size: event.rows,
    };
    this.searchParam.emit(filterData);
  }

  // onRowSelected(value, type) {
  //   let data = {
  //     value,
  //     type
  //   };
  //   this.emitRow.emit(data);
  // }

  onSelectRow(record: any, type: any) {
    this.emitRow.emit({ value: record, type });
  }

  onSelect(record: any, name: any) {
    //console.log(record, 'record');

    this.dynamicParam.emit({ value: record, name });
  }

  approveStatus(record: any, type: any) {
    this.emitRow.emit({ value: record, type });
  }

  onSelectshowTable(record: any) {
    this.showTable.emit(record);
  }

  getDate(date: Date) {
    return dayjs(date);
  }

  navigateProfile() {
    this.router.navigate(['/profile']);
  }

  navigatetoVoucherPage(record: any) {
    this.redirectTo.emit(record);
  }

  approveRecord(id: number, type) {
    //console.log('d;lkijuhgfdfghjkhgfghjnhg');

    this.approve.emit({ value: id, type });
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.table_to_book(
        document.getElementById('excelTable')
      );
      //console.log('workSheet data', worksheet);
      var workbook = xlsx.utils.book_new();
      workbook.SheetNames = worksheet.SheetNames;
      workbook.Sheets = worksheet.Sheets;
      const fileName = this.fileName ? this.fileName + '.xlsx' : 'report.xlsx';
      xlsx.writeFileXLSX(workbook, fileName);
    });
  }
  onStateLevelfilterChange($event?, id?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            //console.log(res.body);
            this.districtLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            //console.log(res.body);
            this.districtLevelList = res.body;
          },
          () => { }
        );
    }
  }

  downloadExcel(name,filename) {
    this.spinner.show()
    this.reportService.downloadExcel(name)
      .subscribe(
        (res) => {
          this.saveBlobToExcel(res.body, filename);
          this.spinner.hide()
        }, (err) => {
          this.spinner.hide()
          this.NotificationService.alertError("Error",'')
        })
  }
  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }

  onDistrictLevelFilterChange($event?, id?) {
    //console.log('logs', id);
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: id ? id : this.districtListDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            //console.log(res.body);
            this.blockLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: id ? id : this.districtListDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            //console.log(res.body);
            this.blockLevelList = res.body;
          },
          () => { }
        );
    }
    this.implementingAgencyDto = null;
    this.limitTransListService
      .getImplementingAgencyByLevelMaster(this.districtListDto.id)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.iaCompList = res.body;
        },
        () => { }
      );
  }

  onBlockLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.blockVillageDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.blockVillageDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    }
    this.implementingAgencyDto = null;
    this.limitTransListService
      .getImplementingAgencyByLevelMaster(this.blockVillageDto.id)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.iaCompList = res.body;
        },
        () => { }
      );
  }
  filterData() {
    //console.log('jshgfghjkhghjk');
    let filter = new FilterTable();
    filter.district = this.districtListDto?.name;
    filter.block = this.blockVillageDto?.name;
    filter.village = this.VillageDto?.name;
    filter.districtId = this.districtListDto?.id;
    filter.blockId = this.blockVillageDto?.id;
    filter.villageId = this.VillageDto?.id;
    filter.iaId = this.implementingAgencyDto?.id
    filter.finyearId = this.finYearDto?.id
    //console.log('dkdkdkdkdkddkdkdkdkkd', filter);
    this.emitFilter.emit(filter);
  }
  clearData() {
    this.districtListDto = null;
    this.blockVillageDto = null;
    this.VillageDto = null;
    this.implementingAgencyDto = null;
    this.finYearDto = null;
    this.fromDate = null;
    this.toDate = null;
    this.filterData();

  }
}

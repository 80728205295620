import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Login } from './login.model';
import { HttpClient } from '@angular/common/http';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';


import { createRequestOption } from '../request/request-util';
import { Router } from '@angular/router';

type JwtToken = {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
};
@Injectable({
  providedIn: 'root',
})
export class SigninService {
  private refreshTokenTimeout;
  private csrfToken$: Promise<string> | undefined;
  xsrfToken: string;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    protected router: Router,
    
  ) {}
  protected resourceUrl = environment.SERVER_API_URL + 'api/authenticate';
  protected getUserDetails = environment.SERVER_API_URL + 'api/getAdminUserDto';
  protected getUserProfile = environment.SERVER_API_URL + 'api/getProfile/getAdminUserId';

  // protected getCaptchaUrl = environment.SERVER_API_URL + 'api/getCaptcha';
  protected getCaptchaUrl = environment.SERVER_API_URL + 'api/generateCaptcha';
  protected validateCapchaUrl = environment.SERVER_API_URL + 'api/verifyCaptcha';
  protected getOtpUrl = environment.SERVER_API_URL + 'api/otp';
  protected getOtpValidUrl = environment.SERVER_API_URL + 'api/validateOtp';
  protected nicUrl = environment.SERVER_API_URL + 'api/nicIntegration';
  protected refreshTokenUrl = environment.SERVER_API_URL + 'api/refreshToken';
  protected getCheckOtpValidUrl = environment.SERVER_API_URL + 'api/checkValidateOtp';
  protected getCheckForgetOtpValidUrl = environment.SERVER_API_URL + 'api/checkForgetValidateOtp'

  protected csrfUrl = environment.SERVER_API_URL + 'api/csrf/token';



  login(credentials: any): Observable<void> {
    this.localStorageService.clear();
    this.sessionStorageService.clear();
    // const options = createRequestOption(credentials);
    return this.http
      .post<JwtToken>(this.resourceUrl,credentials)
      .pipe(
        map((response) =>
          this.authenticateSuccess(response, credentials.rememberMe)
        )
      );
  }

  getCapthcaImage(): any {
    return this.http.get(this.getCaptchaUrl, { responseType: 'text' });
  }

  getOtp(): any {
    return this.http.post(this.getOtpUrl,{}, { responseType: "text" });
  }

  testintegration():any {
    return this.http.post(this.nicUrl,{},{ responseType: 'text' });
  }

  Capthcavalidate(capthca:string,id:number): any {
    return this.http.get(`${this.validateCapchaUrl}?captchaText=${capthca}&id=${id}`, { responseType: 'text' });
  }

  otpvalidate(otp,id): any {
    return this.http.post(`${this.getOtpValidUrl}?id=${id}`,otp, { responseType: 'text' });
  }

  preStatus():any {
    return this.http.post(`${environment.SERVER_API_URL + 'api/status'}`,{},{responseType : 'text'});
  }
  checkOtpValidate(id):any{
    return this.http.post(`${this.getCheckOtpValidUrl}?id=${id}`,{},{responseType : 'text' });
  }

  checkForgetOtpValidate(id):any{
    return this.http.post(`${this.getCheckForgetOtpValidUrl}?id=${id}`,{},{responseType : 'text' });
  }

  getuserDto():any {
    return this.http.get(this.getUserDetails, {observe: 'response' })
  }
  getProfile():any {
    let userId= localStorage.getItem('userID')
    return this.http.get(`${this.getUserProfile}/${userId}`, {observe: 'response' })
  }
  public loadPemFile(filePath: string): Promise<string> {
    return this.http.get(filePath, { responseType: 'text' }).toPromise();
  }
  getPasswordDto():any {
    return this.http.get(`${this.getUserDetails}?status=Yes`, {observe: 'response' })
  }

  getCsrfToken(): Promise<string>  {
    if (!this.csrfToken$) {
      this.csrfToken$ = this.http.get<{ token: string }>(this.csrfUrl)
        .pipe(
          map(response => response.token),
          catchError(() => of('')),  // Handle errors gracefully
          shareReplay(1)  // Cache the response
        )
        .toPromise();
    }
    return this.csrfToken$;
  }

  private authenticateSuccess(
    response: JwtToken,
    rememberMe: boolean,
    refresh = false
  ): void {
    const jwt = response.access_token;
    const idToken = response.id_token;
    const refreshToken = response.refresh_token;

    /** TBD temporary fix */
    //  this.localStorageService.clear('authenticationToken');
    //  this.sessionStorageService.clear('authenticationToken');

    //  this.localStorageService.clear('refreshToken');
    //   this.sessionStorageService.clear('refreshToken');
    /** TBD temporary fix */

    if (rememberMe) {
      this.localStorageService.store('authenticationToken', jwt);
      this.sessionStorageService.clear('authenticationToken');
      this.localStorageService.store('refreshToken', refreshToken);
      this.sessionStorageService.clear('refreshToken');
    } else {
      this.sessionStorageService.store('authenticationToken', jwt);
      this.localStorageService.clear('authenticationToken');
      this.sessionStorageService.store('refreshToken', refreshToken);
      this.localStorageService.clear('refreshToken');
    }
  this.timeOutFunction(response)
  }

  refreshToken(): Observable<any> {
    let data = {
      refreshToken : this.localStorageService.retrieve('refreshToken') ?? this.sessionStorageService.retrieve('refreshToken')
    };
    return this.http
      .post<JwtToken>(this.refreshTokenUrl, data)
      .pipe(map(response => this.authenticateSuccess(response, true, true)));
  }
  timeOutFunction(token:JwtToken): void {
    this.refreshTokenTimeout = setTimeout(() => {
      this.refreshToken().subscribe();
    }, token.expires_in * 1000);
  }
  stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
  setToken(token: string): void {
  
    this.xsrfToken = token;
  }

  getToken(): string | null {
    return this.xsrfToken;
  }

}

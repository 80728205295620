import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
	ApexAxisChartSeries,
	ApexChart,
	ChartComponent,
	ApexDataLabels,
	ApexXAxis,
	ApexPlotOptions,
	ApexStroke,
	ApexTitleSubtitle,
	ApexYAxis,
	ApexTooltip,
	ApexFill,
	ApexLegend,
	ApexGrid
} from "ng-apexcharts";
import { style } from '@angular/animations';
import { DatePipe } from '@angular/common';

export type ChartOption = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis;
	stroke: ApexStroke;
	title: ApexTitleSubtitle;
	tooltip: ApexTooltip;
	fill: ApexFill;
	legend: ApexLegend;
};

export type ChartOptions = {
	series: ApexAxisChartSeries;
	chart: ApexChart;
	dataLabels: ApexDataLabels;
	plotOptions: ApexPlotOptions;
	xaxis: ApexXAxis;
	yaxis: ApexYAxis;
	stroke: ApexStroke;
	title: ApexTitleSubtitle;
	tooltip: ApexTooltip;
	fill: ApexFill;
	legend: ApexLegend;
};

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})

export class DashboardNewComponent implements OnInit {
	@ViewChild("chart") chart: ChartComponent;
	public chartOption: Partial<ChartOption>;

	@ViewChild("charts") chartOne: ChartComponent;
	public chartOptions: any;
	response

	@ViewChild("chartLine") chartLine: ChartComponent;

	fundDtails: any;
	getAmountSpent: any;
	fundAllocation: any;
	listName = 'over'
	fundList: any;
	fundSpent: any = [];
	districts: any;
	selectedYear = ''
	fromDate = ''
	toDate = ''
	selectedMonth = ''
	districtList: any[];
	projectPercentage: any;
	percentage: any[];
	projectFund: any;
	projectCount: any;
	districtCount: any;
	year = []
	yearList: number[];
	isShowFirstChart: boolean = false;
	isShowSecondChart: boolean = false;
	isShowThirdChart: boolean;
	totalAmountSpent: any;
	totalNoOfBeneficiaries: any;
	projectCountTotal: any;
	beneficiaryCount: any;
	awardBeneficiary: any[];
	pendingBeneficiary: any[];
	fundDetails: boolean;
	spent: any[];
	balance: any[];
	total: any[];
	fundShortageDetails: any;
	disbursementDetails: any;
	data: { labels: string[]; datasets: { label: string; data: number[]; fill: boolean; borderColor: string; tension: number; }[]; };
	options: any;
	month: any;
	count: any;
	expandedRows = {}
	agencyDetails: any;
	activeIndex
	visible: any;
	noOfProjects: any = false;
	getNoOfProjects: any;
	totalcount: number;
	isFundExpenditure
	chartOptionForPie: { animationEnabled: boolean; title: { text: string; }; data: { type: string; startAngle: number; indexLabel: string; yValueFormatString: string; dataPoints: { y: number; name: string; }[]; }[]; };
	optionsforPie: { plugins: { legend: { labels: { usePointStyle: boolean; color: string; }; }; }; };
	dataforPie: { labels: string[]; datasets: { data: number[]; backgroundColor: string[]; hoverBackgroundColor: string[]; }[]; };
	selectProject: any;
	fundReceivedData: any;
	agencyListForFilter
	ingredient
	isFundReceived: boolean;
	expenditureList: any;
	agencyList: any;
	agencyPopup: boolean;
	agencyFilter
	districtPopup: boolean;
	flag: any;
	agencyListAbstract: any;
	getList: any;
	districtListForFilter: any;
	districtFilter: any;
	districtListAbstract: any;
	showPie: boolean;
	fundReceivedDataLength: any;
	popupFromDate = ''
	popuptoDate = ''
	popupExpenditurefromDate = ''
	popupExpendituretoDate = ''
	fundReceivedDataTotal: any;
	noProject: any;
	totalAmountReceived: any;
	amountDisburse: any;
	amountBalance: any;
	tooltipContent: string;
	roleCode: string;
	overallSNA: any;
	disbursementData: any;
	agencyNames: string[];
	courtList: any;
	dayWiseAbstarct: boolean = false;
	constructor(private ds: DashboardService,
		private spinner: NgxSpinnerService,
		private datePipe: DatePipe,
	) {
		this.roleCode = localStorage.getItem('roleCode')
	}
	onRowExpand(event: any) {
		console.log(event);
	}
	onRowCollapse(event: any) {
	}
	expandAll() {
		this.expandedRows = this.fundReceivedData.reduce((acc, p) => (acc[p.id] = true) && acc, {});
	}

	collapseAll() {
		this.expandedRows = {};
	}

	ngOnInit(): void {
		this.getFunds()
		this.getProjectPercentage()
		this.getProjectFundAllocation()
		this.districtWiseCount()
		this.projectByDepartments()
		this.fundShortage()
	}
	search(params?) {
		if (params == 'search') {
			let date = {
				fromDate: this.datePipe.transform(this.fromDate, 'yyyy-MM-dd'),
				toDate: this.datePipe.transform(this.toDate, 'yyyy-MM-dd')
			}
			this.getFunds(date)
			this.getProjectPercentage(date)
			this.getProjectFundAllocation(date)
			this.districtWiseCount(date)
			this.projectByDepartments(date)
			this.fundShortage(date)
			this.getAbstract(this.listName)
			
		} else {
			this.fromDate = ''
			this.toDate = ''
			this.getFunds()
			this.getProjectPercentage()
			this.getProjectFundAllocation()
			this.districtWiseCount()
			this.projectByDepartments()
			this.fundShortage()
			this.getAbstract(this.listName)

		}
	}
	fundShortage(date?) {
		this.spinner.show()
		this.ds.fundShortage(date).subscribe(res => {
			this.fundShortageDetails = res.body.data
			this.spinner.hide()

			console.log(this.fundShortageDetails, 'this.fundShortageDetails');
		})
	}
	getProjectPercentage(date?) {
		this.awardBeneficiary = []
		this.pendingBeneficiary = []
		// this.spinner.show()


		this.isShowThirdChart = false
		this.ds.getSpentAmout(date).subscribe(res => {
			this.beneficiaryCount = res.body.data
			let awardArr = []
			let pendingArr = []
			let award
			let pending
			let court
			let lable = []
			let courtArr = []
			this.beneficiaryCount.label.forEach((element, i) => {
				lable.push(element.name)
			})
			this.beneficiaryCount.beneficiaries.forEach((element, i) => {
				awardArr.push(element.awardedBeneficiaries)
				pendingArr.push(element.pendingBeneficiaries)
				courtArr.push(element.awardedCourtDDs)
				award = {
					name: 'Awarded',
					data: awardArr
				}
				pending = {
					name: 'Pending',
					data: pendingArr
				},
					court = {
						name: 'Court',
						data: courtArr
					}
			})
			this.chartOption = {
				series: [
					award, pending, court
				],
				chart: {
					type: "bar",
					height: 350,
					stacked: true
				},
				plotOptions: {
					bar: {
						horizontal: true
					}
				},

				stroke: {
					width: 1,
					colors: ["#fff"]
				},

				xaxis: {
					categories: lable,
					labels: {

					}
				},
				yaxis: {
					title: {
						text: undefined
					}
				},
				tooltip: {

				},
				fill: {
					opacity: 1
				},
				legend: {
					position: "bottom",
					horizontalAlign: "center",
					offsetX: 40
				}
			};
			this.isShowFirstChart = true

		})
	}

	getProjectFundAllocation(date?) {
		this.spent = []
		this.balance = []
		this.total = []
		this.ds.getProjectFund(date).subscribe(res => {
			this.projectFund = res.body.data
			this.projectFund.fundDetails.forEach((element, i) => {
				this.spent.push(Math.round(element.spent))
				this.balance.push(Math.round(element.balance))
			});
			this.chartOptions = {
				series: [
					{
						name: "Spent",
						data: this.spent
					},
					{
						name: "Balance",
						data: this.balance
					},
				],
				chart: {
					type: "bar",
					height: 350,
					stacked: true,
					toolbar: {
						show: true
					},
					zoom: {
						enabled: true
					},
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							legend: {
								position: "bottom",
								offsetX: -10,
								offsetY: 0
							}
						}
					}
				],
				plotOptions: {
					bar: {
						horizontal: false,
					}
				},
				dataLabels: {
					enabled: false,
				},
				xaxis: {
					type: "category",
					categories: this.projectFund.requestioningBody,
					labels: {
						show: false,
					}
				},
				fill: {
					opacity: 1,
					colors: ['#ff4560', '#00e396']
				}
			};
			this.fundDetails = true
		})
	}
	getDrillDown(data, flag) {
		this.agencyPopup = true
		if (flag == 'agency') {
			this.agencyList = data.requestioningBodyDrillDown
		} else if (flag == 'district') {
			this.districtPopup = true
			this.agencyList = data.districtDrillDown
		}
	}
	expenditure(filter?) {
		this.activeIndex=0

		if (filter) {
			let param = {
				fromDate: this.datePipe.transform(this.popupExpenditurefromDate, 'yyyy-MM-dd'),
				toDate: this.datePipe.transform(this.popupExpendituretoDate, 'yyyy-MM-dd')
			}
			this.spinner.show()
			this.ds.expenditure(param).subscribe(res => {
				this.isFundExpenditure = true
				this.expenditureList = res.body.data
				this.spinner.hide()
			})
		} else {
			this.popupExpenditurefromDate=''
			this.popupExpendituretoDate=''
			this.spinner.show()
			this.ds.expenditure().subscribe(res => {
				this.isFundExpenditure = true
				this.spinner.hide()
				this.expenditureList = res.body.data
			})
		}
	}
	over(data) {
		this.tooltipContent = `Created on:${this.datePipe.transform(data.createdOn, 'dd-MM-yyyy')}
		No of districts:${data.noOfDistricts}
		Administrative sanction date:${this.datePipe.transform(data.administrativeSanctionDate, 'dd-MM-yyyy')} 
		Virtual account number: ${data.virtualAccountNumber}
	  `
	}
	districtWiseCount(date?) {
		this.ds.districtCount(date).subscribe(res => {
			this.response = res.body
			const months = this.response.data.months;
			const totalExpenditure = this.response.data.agency.map((item: any) => item.totalExpenditure);
			console.log(totalExpenditure, 'totalExpenditure');
			this.isShowSecondChart = true
			this.data = {
				labels: months,
				datasets: [
					{
						label: 'Expenditure',
						data: totalExpenditure,
						fill: false,
						borderColor: 'rgb(235, 49, 182)', // Line color
						tension: 0.3
					}
				]
			};

			this.options = {
				responsive: true,
				interaction: {
					mode: 'index',
					intersect: false,
				},
				plugins: {
					tooltip: {
						callbacks: {
							title: (tooltipItems) => {
								const index = tooltipItems[0].dataIndex;
								const agencyExpenditures = this.response.data.agency[index].agencyExpenditures;
								return this.formatAgencyExpenditures(agencyExpenditures, index);
							},
							label: () => ''
						}
					}
				}
			};
		})
	}
	formatAgencyExpenditures(agencyExpenditures: any, index): string {
		let length = Object.keys(agencyExpenditures).length
		let expendituresList = `${this.response.data.months[index]} Agencies:\n`;
		if (length != 0) {
			for (const agency in agencyExpenditures) {
				expendituresList += `${agency}: ${agencyExpenditures[agency]}\n`;
			}
		} else {
			expendituresList = 'No Agency for this Month'
		}
		return expendituresList;
	}
	changeValue(flag) {
		if (flag == 'agency') {
			let data = []
			let payloadList
			this.agencyFilter.forEach(element => {
				let payload = {
					id: element.id,
					name: element.agencyName
				}
				data.push(payload)
				payloadList = {
					dataList: data
				}
			});
			this.agencyListAbstract = []
			this.spinner.show()
			this.ds.getAgencyAbstract(payloadList).subscribe((res: any) => {
				this.agencyListAbstract = res.body.data
				this.spinner.hide()
			})
		} else if (flag == 'district') {
			let data = []
			let payloadList
			this.districtFilter.forEach(element => {
				let payload = {
					id: element.id,
					name: element.name
				}
				data.push(payload)
				payloadList = {
					dataList: data
				}
			});
			this.spinner.show()
			this.districtListAbstract = []
			this.ds.getDistrcitAbstract(payloadList).subscribe((res: any) => {
				this.districtListAbstract = res.body.data
				this.spinner.hide()
			})
		}
	}
	getAbstract(flag) {
		this.listName = flag
		this.collapseAll()
		this.ds.getList().subscribe(res => {
			this.getList = res.body.data
			this.agencyListForFilter = this.getList.agencyNames
			this.districtListForFilter = this.getList.districtNames
		})
		let date
		console.log(this.fromDate,'this.fromDate');
		console.log(this.fromDate,'this.fromDate');
		if(this.fromDate != '' && this.toDate != '') {
			 date = {
				fromDate: this.datePipe.transform(this.fromDate, 'yyyy-MM-dd'),
				toDate: this.datePipe.transform(this.toDate, 'yyyy-MM-dd')
			}
		} else {
			date={}
		}
		if (flag == 'agency') {
			this.agencyFilter = ''
			this.spinner.show()
			// let pay = {}
			this.agencyListAbstract = []
			this.ds.getAgencyAbstract(date).subscribe((res: any) => {
				this.agencyListAbstract = res.body.data
				this.spinner.hide()
			})
		} else if (flag == 'district') {
			this.districtFilter = ''
			// let pay = {}
			this.spinner.show()
			this.districtListAbstract = []
			this.ds.getDistrcitAbstract(date).subscribe((res: any) => {
				this.districtListAbstract = res.body.data
				this.spinner.hide()
			})
		} else if (flag == 'oversna') {
			this.spinner.show()
			this.ds.getOverSNA().subscribe((res: any) => {
				this.overallSNA = res.body.data
				this.spinner.hide()
			})
		}
	}
	dayWise(){
		this.listName = 'day'
		this.getDayWiseAbstarctReport()
	}
	getDayWiseAbstarctReport(){
		this.ds.getDisbursementData().subscribe((response) => {
			this.disbursementData = response.data;
			this.extractAgencyNames();
		  });
	}
	extractAgencyNames(): void {
		const agencySet = new Set<string>();
		this.disbursementData.forEach((entry) => {
			entry.agencies.forEach((agency: any) => agencySet.add(agency.agencyName));
		  });
		  this.agencyNames = Array.from(agencySet);
		}
	  getAmountByAgency(agencies: any[], agencyName: string): number | null {
		const agency = agencies.find((a) => a.agencyName === agencyName);
		return agency ? agency.amountDisbursedByAgency : null;
	  }
	  getProjectsByAgency(agencies: any[], agencyName: string): number | null {
		const agency = agencies.find((a) => a.agencyName === agencyName);
		return agency ? agency.noOfProjects : null;
	  }
	  getAgency(entry,agency){
		this.dayWiseAbstarct = true
		console.log(this.dayWiseAbstarct ,'this.dayWiseAbstarct');

		let data = entry.agencies.filter( res => res.agencyName == agency)[0]
		this.courtList=data
		console.log(this.courtList);
	  }
	  getRandomColor() {
	  	var letters = '0123456789ABCDEF';
	  	var color = '#';
	  	for (var i = 0; i < 6; i++) {
	  		color += letters[Math.floor(Math.random() * 16)];
	  	}
	  	return color;
	  }
	fund(flag, params?) {
		this.spinner.show()
		this.flag = flag
		if (flag == 'receive') {
			if (params) {
				this.collapseAll()
				let param = {
					fromDate: this.datePipe.transform(this.popupFromDate, 'yyyy-MM-dd'),
					toDate: this.datePipe.transform(this.popuptoDate, 'yyyy-MM-dd')
				}
				this.ds.getFundReceived(param).subscribe((res: any) => {
					this.fundReceivedData = res.body.data.result
					this.totalAmountReceived = res.body.data.totalAmountReceived
					this.noProject = res.body.data.totalNoOfProjects
					this.fundReceivedDataLength = res.body.data.result.length - 1
					this.spinner.hide()
				})
			} else {
				this.collapseAll()
				this.popupFromDate = ''
				this.popuptoDate = ''
				this.ds.getFundReceived().subscribe((res: any) => {
					this.fundReceivedData = res.body.data.result
					this.totalAmountReceived = res.body.data.totalAmountReceived
					this.noProject = res.body.data.totalNoOfProjects
					this.fundReceivedDataLength = res.body.data.result.length - 1
					this.isFundReceived = true
					this.spinner.hide()
				})
			}
		} else
		 if (flag == 'balance') {
			if (params) {
				this.collapseAll()
				params = {
					fromDate: this.datePipe.transform(this.popupFromDate, 'yyyy-MM-dd'),
					toDate: this.datePipe.transform(this.popuptoDate, 'yyyy-MM-dd')
				}
				this.ds.getBalance(params).subscribe((res: any) => {
					this.fundReceivedData = res.body.data.result
					this.fundReceivedDataLength = res.body.data.result.length - 1
					this.totalAmountReceived = res.body.data.totalAmountReceived
					this.noProject = res.body.data.totalNoOfProjects
					this.amountDisburse = res.body.data.totalAmountDisbursed
					this.amountBalance = res.body.data.totalBalanceAmount
					this.isFundReceived = true
					this.spinner.hide()
				})
			} else {
				this.popupFromDate = ''
				this.popuptoDate = ''
				this.collapseAll()
				this.ds.getBalance().subscribe((res: any) => {
					this.fundReceivedData = res.body.data.result
					this.fundReceivedDataLength = res.body.data.result.length - 1
					this.totalAmountReceived = res.body.data.totalAmountReceived
					this.noProject = res.body.data.totalNoOfProjects
					this.amountDisburse = res.body.data.totalAmountDisbursed
					this.amountBalance = res.body.data.totalBalanceAmount
					this.isFundReceived = true
					this.spinner.hide()
				})
			}
		}
	}
	noOfProject() {
		this.spinner.show()
		this.totalcount = 0
		let lable = []
		let percentage = []
		this.showPie = false
		this.selectProject = ''
		this.ds.getNoProjects().subscribe((res: any) => {
			this.getNoOfProjects = res.body.data
			this.noOfProjects = true
			this.getNoOfProjects.forEach(element => {
				this.totalcount += element.totalNoOfProjects
			});
			this.getNoOfProjects.forEach((element, i) => {
				lable.push(element.agencyName)
			});
			this.getNoOfProjects.forEach(element => {
				percentage.push(element.totalProjectPercent)
			});
			this.spinner.hide()
		})
	}
	getData(i) {
		let color = []
		let percentage = []
		let lable = []
		this.selectProject = this.getNoOfProjects[i].projects
		let indexPercentage = 100 / this.selectProject.length
		this.selectProject.forEach(element => {
			color.push(this.getRandomColor())

			lable.push(element.name)
			percentage.push(indexPercentage)
		});
		this.showPie = true

		const documentStyle = getComputedStyle(document.documentElement);
		const textColor = documentStyle.getPropertyValue('--text-color');
		this.dataforPie = {
			labels: lable,
			datasets: [
				{
					data: percentage,
					backgroundColor: color,
					hoverBackgroundColor: color
				}
			]
		};

		this.optionsforPie = {
			plugins: {
				legend: {
					labels: {
						usePointStyle: true,
						color: textColor,

					}
				}
			}
		};

	}

	selectData(e) {
		this.spinner.show()
		this.ds.projectMonth(this.month[e.element.index]).subscribe((res: any) => {
			this.agencyDetails = res.body.data
			this.visible = true
			this.spinner.hide()
		})
	}
	projectByDepartments(date?) {
		this.ds.projectByCount(date).subscribe(res => {
			this.disbursementDetails = res.body.data
			console.log(this.disbursementDetails, 'this.disbursementDetails');
		})
	}
	getFunds(date?) {
		this.ds.getFundReport(date).subscribe(res => {
			this.fundDtails = res.body.data
		})
	}
	filter() {
		this.getFunds()
		this.getProjectFundAllocation()
		this.districtWiseCount()
		this.projectByDepartments()
		// this.getDistricts()
		// this.getTotalFund()
		this.getProjectPercentage()
	}
	clear() {
		this.selectedMonth = ''
		this.selectedYear = ''
		this.isShowFirstChart = false
		this.isShowSecondChart = false
		this.isShowThirdChart = false
		this.getFunds()
		this.getProjectFundAllocation()
		this.districtWiseCount()
		this.projectByDepartments()
		this.getProjectPercentage()

	}
	get() {
	}
}

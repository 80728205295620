<div class="hamburger">
    <h2>Beneficiary Details</h2>
</div>
<div class="container-fluid ">
    <div class="">
        <div class="row">
            <div class="col-md-12">
                <p-toolbar styleClass="toolbar">
                    <ng-template pTemplate="left">
                        <h5 [ngClass]="fontService.headingClass">
                            Beneficiary
                        </h5>
                    </ng-template>
                    <ng-template pTemplate="right">
                    </ng-template>
                </p-toolbar>
            </div>
        </div>
        <div class="row">
            <div #customerTable id="entities" class="col-md-12">
                <div class="container bgFilter mb-3">
                    <div class="row align-items-center mt-2">
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass">Project
                                Name</label>
                            <ng-select id="department" bindLabel="projectName" bindValue="id" [items]="projectList"
                                [(ngModel)]="stage1Filter.projectName" appearance="outline" class="form-control" appNgSelectInputRestriction>
                            </ng-select>
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass">Name</label>
                            <input type="text" class="form-control" [(ngModel)]="stage1Filter.name" name="name"
                                autocomplete="off" maxlength="60" id="name" appSerachInputRestriction/>
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass">Mobile
                                Number</label>
                            <input type="text" class="form-control" name="name" autocomplete="off" maxlength="10"
                                [(ngModel)]="stage1Filter.mobile" (keypress)="keyPressNumberForMobile($event)"
                                style="text-transform:uppercase"   oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !stage1Filter.mobile}" id="name" />
                        </div>
                    </div>
                    <div class="flex align-items-center mt-3 ">
                        <p-button label="Submit" class="submit" (click)="loadPage()"></p-button>
                    </div>
                </div>
                <div class="table-scroll">
                    <table style="width:100%" class="table">
                        <thead>
                            <tr>
                                <th style="width:100px; ">Land Owner name </th>
                                <th>Project Name <span (click)="loadPage(page,'projectName')"><i class="fa fa-sort"></i>
                                    </span></th>
                                <th>Taluk <span (click)="loadPage(page,'talukName')"><i class="fa fa-sort"></i> </span>
                                </th>
                                <th>Village <span (click)="loadPage(page,'villageName')"><i class="fa fa-sort"></i>
                                    </span>
                                </th>
                                <th style="width:100px; ">Survey Number <span (click)="loadPage(page,'surveyNumber')"><i
                                            class="fa fa-sort"></i> </span></th>
                                <th style="width:100px; ">Sub division Number <span
                                        (click)="loadPage(page,'subDivisionNumber')"><i class="fa fa-sort"></i> </span>
                                </th>
                                <th style="width:100px; ">Part Sub division Number <span
                                        (click)="loadPage(page,'partSubDivisionNumber')"><i class="fa fa-sort"></i>
                                    </span>
                                </th>
                                <th style="width:100px; ">Mobile Number <span (click)="loadPage(page,'mobileNumber')"><i
                                            class="fa fa-sort"></i> </span></th>
                                <th class="text-center">Account Number <span (click)="loadPage(page,'accountNumber')"><i
                                            class="fa fa-sort"></i> </span></th>
                                <th class="text-center">
                                    Bank Name <span (click)="loadPage(page,'nameOfBank')"><i class="fa fa-sort"></i>
                                    </span>
                                </th>
                                <th class="text-center">
                                    Image
                                </th>
                                <th class="text-center">
                                    Legal Heir
                                </th>
                                <th>
                                    Extent owned by the
                                    Land Owner as per
                                    Award(In hectares)
                                </th>
                                <th>
                                    Total Amount (in Rupees) <span (click)="loadPage(page,'totalAmount')"><i
                                            class="fa fa-sort"></i> </span>
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tr *ngFor="let data of confirmValues">
                            <td>{{data.benificiaryLandOwnerName}}</td>
                            <td>{{data.benificiaryLandDetail.projectID.projectName}}</td>
                            <td>{{data.benificiaryLandDetail.talukLGDCode.name}}</td>
                            <td>{{data.benificiaryLandDetail.revenueLGDCode.name}}</td>
                            <td>{{data.surveyNumber}}</td>
                            <td>{{data.subDivisionNumber}}</td>
                            <td>{{data.benificiaryLandDetail.partSubDivisionNumber}}</td>
                            <td>{{data.mobileNumber}}</td>
                            <td>{{data.accountNumber}}</td>
                            <td>{{data.nameOfBank}}</td>
                            <td>
                                <a class="cursor-pointer" (click)="viewDocumentForlist(data.photo)">
                                    view
                                </a>
                            </td>
                            <td>
                                <a class="cursor-pointer" (click)="viewLegalHeir(data.beneficiaryLegalierId)">
                                    view
                                </a>
                            </td>
                            <td>{{data.extentOwned}}</td>
                            <td>{{data.totalAmount|indianRupee }}</td>
                            <td>
                                <div class="button-label" (click)="getAwards(data)">Award</div>
                                <div class="button-label" (click)="getLegalzier(data)">Legal Heir</div>
                                <div class="button-label" (click)="edit(data)">Edit</div>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="text-center" *ngIf="totalCount == 0">
                    <img src="../../../assets/img/notFound.png" width="419px" alt="">
                </div>
                <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)" [totalRecords]="totalCount"
                    [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>
            </div>
        </div>
    </div>

    <p-dialog #dialog [(visible)]="awardsDialog" [style]="{ width: '850px',height:'400px' }" [modal]="true"
        [closable]="true" (onHide)="hideDialog()" styleClass="ib-dialog" appendTo="body">
        <p-header [ngClass]="fontService.headingClass">
            Award Amount Details
        </p-header>
        <div class="row align-items-end">
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                    Compensation Amount to be disbursed(Land)(in Rupees)</label>
                <input type="number" name="designaiton" class="form-control" [readonly]="!disableAward"
                    (keyup)="getTotals($event,'landAmount')" maxlength="50" [(ngModel)]="awardAmount.landAmount" appNumberonly/>
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                    Tree Amount </label>
                <input type="number" name="designaiton" class="form-control" [readonly]="!disableAward"
                    (keyup)="getTotals($event,'tree')" maxlength="50" [(ngModel)]="awardAmount.treeAmount" appNumberonly/>
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                    Well amount </label>
                <input type="number" name="designaiton" class="form-control" [readonly]="!disableAward" maxlength="50"
                    (keyup)="getTotals($event,'wellAmonut')" [(ngModel)]="awardAmount.wellAmount" appNumberonly/>
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                    Building amount </label>
                <input type="number" name="designaiton" class="form-control" [readonly]="!disableAward" maxlength="50"
                    (keyup)="getTotals($event,'Building')" [(ngModel)]="awardAmount.buildingAmount" appNumberonly/>
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                    Other Structure amount </label>
                <input type="number" name="designaiton" class="form-control" maxlength="50" [readonly]="!disableAward"
                    (keyup)="getTotals($event,'other')" [(ngModel)]="awardAmount.otherStructure" appNumberonly/>
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label" [ngClass]="fontService.labelClass" for="levelMasterName">
                    Total Amount </label>
                <input type="number" name="designaiton" [readonly]="!disableAward" class="form-control" maxlength="50"
                    readonly [(ngModel)]="awardAmount.totalAmount" appNumberonly/>
            </div>
        </div>
        <div class="mt-4" *ngIf="disableAward">
            <button pRipple icon="pi pi-plus" iconPos="left" [disabled]="awardAmount.landAmount == '' "
                class="btn btn-primary" (click)="onSubmit()" styleClass="button">
                <span style="color: white;" [ngClass]="fontService.buttonClass">Submit</span>
            </button>
        </div>
    </p-dialog>

    <p-dialog #dialog [(visible)]="legalzier" [style]="{ width: '850px' }" [modal]="true" [closable]="true"
        (onHide)="hideDialog()" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Legal Heir <span class="view">
                Total Amount : {{selectData?.totalAmount |indianRupee }}
            </span>
        </p-header>

        <form [formGroup]="legalzierForm">
            <div formArrayName="AccountDetailsForm">
                <div *ngFor="let landDetails of detailsOfAccount.controls; let i=index" [formGroupName]="i">
                    <div class="bgFilter">
                        <div class="row align-items-end">
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="levelMasterName">
                                    Name</label>
                                <input type="text" name="designaiton" class="form-control" maxlength="50"
                                    formControlName="landOwneName" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="levelMasterName">
                                    Is this bank account are same?</label>
                                <ng-select id="type" [items]="categories" bindLabel="name" bindValue="key"
                                    appearance="outline" [closeOnSelect]="true" formControlName="isBank"
                                    [clearable]="true" class="form-control" (change)="getValue($event,i)"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
                                </ng-select>

                            </div>
                        </div>
                        <div class="row mt-3"
                            *ngIf="detailsOfAccount.controls[i].get('isBank').value == 'Y' || detailsOfAccount.controls[i].get('isBank').value == 'N' ">
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="levelMasterName">
                                    Account No </label>
                                <input type="text" name="designaiton" class="form-control" maxlength="50"
                                    formControlName="bankAccountNo" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="levelMasterName">
                                    IFSC Code </label>
                                <input type="text" name="designaiton" class="form-control" uppercase (keyup)="ifscCode($event,i)"
                                    maxlength="50" formControlName="ifscCode" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="levelMasterName">
                                    Bank Name </label>
                                <input type="text" name="designaiton" class="form-control" readonly maxlength="50"
                                    formControlName="bankName" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="levelMasterName">
                                    Branch Name </label>
                                <input type="text" name="designaiton" readonly class="form-control" maxlength="50"
                                    formControlName="branchName" />
                            </div>
                         
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="levelMasterName">
                                    Aadhaar No </label>
                                <input type="text" name="designaiton" (keypress)="keyPressAadhaar($event)"
                                    class="form-control" maxlength="12" formControlName="aadhaarNo" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="levelMasterName">
                                    Amount </label>
                                <input type="text" name="designaiton" class="form-control" maxlength="50"
                                    (keyup)="getValues($event,i)" formControlName="legalAmount" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" />

                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                    for="levelMasterName">
                                    Mobile Number </label>
                                <input type="text" name="designaiton" class="form-control" maxlength="10"
                                     formControlName="mobileNumber" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"/>

                            </div>
                            <div class="col-sm-6 col-md-4">
                                <div class="col-md-12">
                                    <label class="form-control-label req" for="upload"
                                        [ngClass]="fontService.labelClass">Legal Heir
                                        Photo</label><br>
                                </div>
                                <div class="col-md-12" *ngIf="!detailsOfAccount.controls[i].get('legalPhoto').value">
                                    <p-fileUpload accept="image/png,image/jpeg,image/jpg,application/pdf"  maxFileSize="1000000" mode="basic"
                                        (onUpload)="onUploadfile($event,'photo',i)" [customUpload]="true" auto="true"
                                        (uploadHandler)="onUploadfile($event,'photo',i)" multiple="false"
                                        [showUploadButton]="false" [showCancelButton]="false"
                                        chooseLabel="{{'common.upload'|translate}}">
                                    </p-fileUpload>
                                </div>
                                <div class="col" *ngIf="detailsOfAccount.controls[i].get('legalPhoto').value">
                                    <a class="cursor-pointer"
                                        (click)="viewDocument(detailsOfAccount.controls[i].get('legalPhoto').value)">{{detailsOfAccount.controls[i].get('legalPhoto').value}}</a>
                                    <i class="pi pi-times add-margin"
                                        (click)="removeDocument(detailsOfAccount.controls[i].get('legalPhoto').value,i)"></i>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <div class="col-md-12">
                                    <label class="form-control-label req" for="upload"
                                        [ngClass]="fontService.labelClass">Legal Heir
                                        Document</label><br>
                                </div>
                                <div class="col-md-12" *ngIf="!detailsOfAccount.controls[i].get('legalDocument').value">
                                    <p-fileUpload accept="image/png,image/jpeg,image/jpg,application/pdf"  maxFileSize="1000000" mode="basic" 
                                        (onUpload)="onUploadfile($event,'Document',i)" [customUpload]="true" auto="true"
                                        (uploadHandler)="onUploadfile($event,'Document',i)" multiple="false"
                                        [showUploadButton]="false" [showCancelButton]="false"
                                        chooseLabel="{{'common.upload'|translate}}">
                                    </p-fileUpload>
                                </div>
                                <div>
                              
                            </div>
                                <div class="col" *ngIf="detailsOfAccount.controls[i].get('legalDocument').value">
                                    <a class="cursor-pointer"
                                        (click)="viewDocument1(detailsOfAccount.controls[i].get('legalDocument').value)">{{detailsOfAccount.controls[i].get('legalDocument').value}}</a>
                                    <i class="pi pi-times add-margin"
                                        (click)="removeDocument1(detailsOfAccount.controls[i].get('legalDocument').value,i)"></i>
                                </div>
                            </div>
                            <div  class="col-sm-6 col-md-4">
                                <p-button label="Verify Account" class="submit" (click)='accountValidation(i)' ></p-button>
                                <p *ngIf="detailsOfAccount.controls[i].get('verfyBank').value" class="text-success">Account Holder Name: {{detailsOfAccount.controls[i].get('verfyBank').value}} <i class="pi pi-verified"></i></p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <i class="pi pi-plus-circle mt-1 fonts" *ngIf="legalzierForm.valid" (click)="addLegal()"></i>
                        &nbsp;
                        <i class="pi pi-trash fonts" *ngIf="detailsOfAccount.length > 1" (click)="removeSkill(i)"></i>
                    </div>
                </div>

            </div>
        </form>
        <div class="mt-4" class="onSubmit">
            <button pRipple icon="pi pi-plus" [disabled]="!disableButton || legalzierForm.invalid"  iconPos="left"
                class="btn btn-primary" (click)="onSubmitLegal()" styleClass="button">
                <span style="color: white;" [ngClass]="fontService.buttonClass">Submit</span>
            </button>
        </div>
    </p-dialog>
    <p-dialog #dialog [(visible)]="editDialog" [style]="{ width: '850px' }" [modal]="true" [closable]="true"
        (onHide)="hideDialog()" styleClass="ib-dialog" appendTo="body">
        <p-header [ngClass]="fontService.headingClass">
            Beneficiary
        </p-header>
        <div class="row bgFilter">
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">Beneficiary Name</label>
                <input type="text" id="agencyName" class="form-control" name="agencyName" (ngModelChange)="checkName()"
                    maxlength="100" autocomplete="off" style="text-transform:uppercase"
                    [(ngModel)]="stage1.benificiaryLandOwnerName" />
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">Mobile Number</label>
                <input type="number" id="agencyName" class="form-control" name="agencyName"
    maxlength="100" autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" style="text-transform:uppercase"
                    [(ngModel)]="stage1.mobileNumber" appNumberonly />
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">Aadhar Number</label>
                <input type="number" id="agencyName" class="form-control" name="agencyName"
                    (ngModelChange)="checkName()" maxlength="12" autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"style="text-transform:uppercase"
                    [(ngModel)]="stage1.aadharNumber" appNumberonly/>
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">IFSC Code</label>
                <input type="text" id="agencyName" class="form-control" name="agencyName" (ngModelChange)="checkName()"
                    maxlength="100" autocomplete="off" onblur="this.value =this.value.trim();"
                    style="text-transform:uppercase" [(ngModel)]="stage1.ifscCode" />
            </div>
            <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" for="agencyName">Account Number</label>
                <input type="number" id="agencyName" class="form-control" name="agencyName"
                    (ngModelChange)="checkName()" maxlength="100" autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" style="text-transform:uppercase"
                    [(ngModel)]="stage1.accountNumber" appNumberonly/>
            </div>
            <div class="col-sm-6 col-md-4" *ngIf="!stage1.photo">
                <p-fileUpload accept="image/png,image/jpeg,image/jpg,application/pdf" (onUpload)="onUploadfile1($event)"
                    [customUpload]="true" auto="true" (uploadHandler)="onUploadfile1($event)" multiple="false"
                  [showUploadButton]="false" [disabled]="stage1.photo"
                    [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                </p-fileUpload>
            </div>
            <div class="col" *ngIf="stage1.photo">
                <a>{{stage1.photo}}</a> &nbsp;
                <i *ngIf="stage1.photo" class="pi pi-times add-margin" (click)="removeDocuments(stage1.photo)"></i>
                <div>
                    <a class="cursor-pointer" (click)="viewDocument(stage1.photo)">View</a>
                </div>
            </div>
        </div>
        <div class="row bgFilter mt-3" *ngIf="isStructure">
            <div class="row align-items-end">
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                        Compensation Amount to be disbursed(Land)(in Rupees)</label>
                    <input type="text" name="designaiton" class="form-control" (keyup)="getTotals($event,'landAmount')"
                        maxlength="50" readonly [(ngModel)]="awardAmount.landAmount" />
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                        Tree Amount </label>
                    <input type="text" name="designaiton" class="form-control" (keyup)="getTotals($event,'tree')"
                        maxlength="50" [(ngModel)]="awardAmount.treeAmount" />
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                        Well amount </label>
                    <input type="text" name="designaiton" class="form-control" maxlength="50"
                        (keyup)="getTotals($event,'wellAmonut')" [(ngModel)]="awardAmount.wellAmount" />
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                        Building amount </label>
                    <input type="text" name="designaiton" class="form-control" maxlength="50"
                        (keyup)="getTotals($event,'Building')" [(ngModel)]="awardAmount.buildingAmount" />
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                        Other Structure amount </label>
                    <input type="text" name="designaiton" class="form-control" maxlength="50"
                        (keyup)="getTotals($event,'other')" [(ngModel)]="awardAmount.otherStructure" />
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label" [ngClass]="fontService.labelClass" for="levelMasterName">
                        Total Amount </label>
                    <input type="text" name="designaiton" class="form-control" maxlength="50" readonly
                        [(ngModel)]="awardAmount.totalAmount" />
                </div>
            </div>
        </div>
        <div class="row bgFilter mt-3" *ngIf="isProceeding">
            <div class="row align-items-end">
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                        Proceeding Number</label>
                    <input type="text" name="designaiton" class="form-control" maxlength="50"
                        [(ngModel)]="Proceeding.Proceeding" />
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for="levelMasterName">
                        Proceeding Date </label>
                    <p-calendar [(ngModel)]="Proceeding.date" (onSelect)="getChange($event)"
                        dateFormat="dd/mm/yy"></p-calendar>
                </div>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <div>
                <button pButton pRipple icon="pi pi-check" class="p-button-text custom-button saveButton"
                    (click)="onSubmitEdit()" type="submit">
                    <span>Submit</span>
                </button>
            </div>
        </ng-template>


    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>
<p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
    styleClass="ib-dialog">
    <ng-template pTemplate="body">
        <div style='position: relative; height: 100%;'>
            <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
            <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
        </div>
    </ng-template>
</p-dialog>
<p-dialog [(visible)]="viewDoc1" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
    styleClass="ib-dialog">
    <ng-template pTemplate="body">
        <div style='position: relative; height: 100%;'>
            <ngx-extended-pdf-viewer *ngIf="vFileExt1 === 'pdf'" [src]="vFileUrl1"></ngx-extended-pdf-viewer>
            <img *ngIf="vFileExt1 !== 'pdf'" [src]="vFileUrl1" style="display: block;" height="800px" />
        </div>
    </ng-template>
</p-dialog>
<p-dialog [(visible)]="viewDocList" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
    styleClass="ib-dialog">
    <ng-template pTemplate="body">
        <div style='position: relative; height: 100%;'>
            <ngx-extended-pdf-viewer *ngIf="vFileExtList === 'pdf'" [src]="vFileUrlList"></ngx-extended-pdf-viewer>
            <img *ngIf="vFileExtList !== 'pdf'" [src]="vFileUrlList" style="display: block;" height="800px" />
        </div>
    </ng-template>
</p-dialog>
<p-dialog [(visible)]="viewListForLegal" [style]="{ width: '1000px', height: '500px' }" [modal]="true"
    [baseZIndex]="1000" styleClass="ib-dialog">
    <p-header [ngClass]="fontService.headingClass">
        Legal Heir
    </p-header>
    <div>
        <table class="table">
            <thead>
                <tr>

                    <th scope="col" style="background-color: #017DC3;">Name
                    </th>
                    <th scope="col" style="background-color: #017DC3;">Aadhaar No
                        Number</th>
                    <th scope="col" style="background-color: #017DC3;">Account Number
                    </th>
                    <th scope="col" style="background-color: #017DC3;">Bank Name

                    </th>
                    <th scope="col" style="background-color: #017DC3;">Amount
                    </th>
                    <th scope="col" style="background-color: #017DC3;">Legal Heir Photo
                    </th>
                    <th scope="col" style="background-color: #017DC3;">Legal Heir Document
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of legalHeir;let i =index">
                    <td>{{data.legalierName}}</td>
                    <td>{{data.aadharNumber}}</td>
                    <td>{{data.accountNumber}}</td>
                    <td>{{data.bankName}}</td>
                    <td>{{data.legalHierAmount}}</td>
                    <td>
                        <a class="cursor-pointer" (click)="viewDocumentForlist(data.legalierPhoto)">View</a>
                    </td>
                    <td>
                        <a class="cursor-pointer" (click)="viewDocumentForlist(data.legalierDocument)">View</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="text-center" *ngIf="legalHeir?.length == 0">
            <img src="../../../assets/img/notFound.png" width="290px" alt="">
        </div>

    </div>
</p-dialog>
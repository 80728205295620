import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  protected calculatePercentageUrl = environment.SERVER_API_URL + ('api/calculatePercentage');
  protected saveProjectDtoUrl = environment.SERVER_API_URL + ('api/project')
  protected getProject = environment.SERVER_API_URL + ('api/benificiaryLandDetail/report')
  protected filterUrl = environment.SERVER_API_URL + ('api/project/getList')
  protected getAgencys = environment.SERVER_API_URL + ('api/requestingAgency/getList')
  protected getUnits = environment.SERVER_API_URL + ('api/landUnit')
  protected getOneUnit = environment.SERVER_API_URL + ('api/project')
  protected adminLAO =environment.SERVER_API_URL + ('api/adminUserListByLevelMasterId')
  protected getLevelMasters = environment.SERVER_API_URL + ('api/allLevelMasterList');

  constructor(protected http: HttpClient) { }
  getAmount(percentage: any, amount: any) {
    return this.http.get(`${this.calculatePercentageUrl}?amount=${amount}&state=${percentage}`, { observe: 'response' })
  }

  getProjectFilter(req: any) {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.getProject}`, {
      params: options,
      observe: 'response',
    });
  }


  getAgencyReport(req: any) {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.getProject}`, {
      params: options,
      observe: 'response',
    });
  }



  filter(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.filterUrl, {
      params: options,
      observe: 'response',
    });
  }



}
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { gender } from './common.model';
import { educationalQualification } from './common.model';
import { maritalStatus } from './common.model';
import { categoryReservation } from './common.model';
import { community } from './common.model';
export type GenderEntityArrayResponseType = HttpResponse<gender[]>;
export type EducationEntityArrayResponseType = HttpResponse<educationalQualification[]>;
export type maritalEntityArrayResponseType = HttpResponse<maritalStatus[]>;
export type categoryReservationEntityArrayResponseType = HttpResponse<categoryReservation[]>;
export type communityEntityArrayResponseType = HttpResponse<community[]>;

@Injectable({ providedIn: 'root' })
export class CommonService {
    protected genderListUrl = environment.SERVER_API_URL+('api/genderList');
    protected educationListUrl = environment.SERVER_API_URL+('api/educationalQualificationList');
    protected marialstausUrl = environment.SERVER_API_URL+('api/maritalStatusList');
    protected categoryReservationUrl = environment.SERVER_API_URL+('api/categoryReservationList');
    protected communityUrl = environment.SERVER_API_URL+('api/communityList');

    constructor(protected http: HttpClient) {}
    getGenderList(): Observable<GenderEntityArrayResponseType> {
        return this.http
          .get<gender[]>(this.genderListUrl, { observe: 'response' });
    }
    geteducationList(): Observable<EducationEntityArrayResponseType> {
        return this.http
          .get<educationalQualification[]>(this.educationListUrl, { observe: 'response' });
    }
    getMartialStatusList(): Observable<maritalEntityArrayResponseType> {
        return this.http
          .get<maritalStatus[]>(this.marialstausUrl, { observe: 'response' });
    }
    getCategoryReservationList(): Observable<categoryReservationEntityArrayResponseType> {
        return this.http
          .get<categoryReservation[]>(this.categoryReservationUrl, { observe: 'response' });
    }
    getcommunityList(): Observable<communityEntityArrayResponseType> {
        return this.http
          .get<community[]>(this.communityUrl, { observe: 'response' });
    }


}
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SigninService } from '../signin/signin.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private loginService: SigninService,
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url || (request.url.startsWith('http') && !(environment.SERVER_API_URL && request.url.startsWith(environment.SERVER_API_URL)))) {
      return next.handle(request);
    }
    const token: string | null =
      this.localStorageService.retrieve('authenticationToken') ?? this.sessionStorageService.retrieve('authenticationToken');
    const roleCode: string | null =
      this.localStorageService.retrieve('X_FORM_CODE') ?? this.sessionStorageService.retrieve('X_FORM_CODE');
      //console.log(roleCode)
    let headers = request.headers.set('Accept', 'application/json,application/pdf')
      .set('X-Scheme-Code', '1');
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }
    if (roleCode) {
      headers = headers.set('x-mock-response-code', roleCode);
    }
    const tokenData = this.loginService.getToken();
    console.log(tokenData,'tokenData');
    
    if(tokenData){
    headers = headers.set('X-XSRF-TOKEN', this.loginService.getToken() || '');
    }

    const clonedRequest = request.clone({ headers });

    return next.handle(clonedRequest).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // Extract the XSRF-TOKEN from the response headers
          //console.log(event)
          //console.log('777777777777777777777777777777777777777',event.headers.get('set-cookie'));
          const xsrfTokenFromResponse = event.headers.get('X-XSRF-TOKEN') || event.headers.get('x-xsrf-token');
          //console.log(xsrfTokenFromResponse)
          if (xsrfTokenFromResponse) {
            this.loginService.setToken(xsrfTokenFromResponse);
          }else{
            if (!request.url.includes('/assets/i18n/en.json')){
        
              this.router.navigate(['/generic-error']);
            }
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {

        //console.log(error)
        if (error.status === 401) {
          this.localStorageService.clear();
          this.sessionStorageService.clear();
          this.router.navigate(['/login']);
        } else if (error.status === 405) {
          //console.log("error 405")
          // this.router.navigate(['/error']);
        }else if (error.status === 406) {
          //console.log("error "+error.status)
          this.router.navigate(['/generic-error']);
        } else if (error.status === 429) {
          //console.log("error 429")
          this.router.navigate(['/request-error']);
        }
        //console.log(error.headers.get('X-XSRF-TOKEN'))
        const xsrfTokenFromResponse = error.headers.get('X-XSRF-TOKEN')
        if (xsrfTokenFromResponse) {
          this.loginService.setToken(xsrfTokenFromResponse);
        }else{
          // debugger
          //temp
          // this.router.navigate(['/generic-error']);
        if (!request.url.includes('/api/status')){
        }
      }
        // Handle other error statuses as needed
        return throwError(error);
      })
    );
  }
    }

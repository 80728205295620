<div id="error-page">
    <div class="content text-center">
        <div class="row">
            <h2 class="header">
                400
             </h2> 
        <h2 class="sub-header mt-2">
            Bad Request
         </h2>
        </div>
        <div class="row">
        <p>
            Sorry, we are unable to process your request.<br>
             Too many request found on this server, 
             Try after sometime.
         </p>
         </div>
         <div class="btn">
            <a class="btn btn-warning" [routerLink]="['/login']">Go Back To Homepage</a>
           </div>
    </div>
 </div>
<div class="hamburger">
    <h2>Payment Details</h2>
</div>
<div class="container-fluid bg">
    <div class="tableDesign">
        <div class="row">
            <div class="col-md-12">
                <p-toolbar styleClass="toolbar">
                    <ng-template pTemplate="left">
                        <h5 [ngClass]="fontService.headingClass">
                            Payment
                        </h5>
                    </ng-template>
                </p-toolbar>
            </div>
        </div>
        <div class="row">
            <div #customerTable id="entities" class="col-md-12">
                <div class="container bgFilter mb-3">
                    <div class="row align-items-center mt-2">
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass">Project
                                Name</label>
                            <ng-select id="department" bindLabel="projectName" bindValue="id" [items]="projectList"
                                [(ngModel)]="stage1Filter.projectId" appearance="outline" class="form-control" appNgSelectInputRestriction>
                            </ng-select>
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass">Name</label>
                            <input type="text" class="form-control" name="name" autocomplete="off"
                                [(ngModel)]="stage1Filter.name" maxlength="60" id="name" />
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass">Mobile
                                Number</label>
                            <input type="number" class="form-control" name="name" autocomplete="off"
                                [(ngModel)]="stage1Filter.mobile" maxlength="60" id="name" appNumberonly/>
                        </div>
                        <div class="col-sm-4 col-md-3">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass">Aadhaar
                                Number</label>
                            <input type="number" class="form-control" name="name" autocomplete="off"
                                [(ngModel)]="stage1Filter.aadhaar" maxlength="60" id="name" appNumberonly/>
                        </div>
                    </div>
                    <div class="flex align-items-center mt-3 ">
                        <p-button label="Submit" class="submit" (click)="loadPage()"></p-button>
                    </div>
                </div>
                <div class="table-scroll">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Beneficiary Name <span (click)="loadPage(page,'benificiaryLandOwnerName')"><i
                                            class="fa fa-sort"></i> </span></th>
                                <th>Project Name <span (click)="loadPage(page,'projectName')"><i class="fa fa-sort"></i>
                                    </span></th>
                                <th>Taluk <span (click)="loadPage(page,'talukName')"><i class="fa fa-sort"></i> </span>
                                </th>
                                <th>Village <span (click)="loadPage(page,'villageName')"><i class="fa fa-sort"></i>
                                    </span></th>
                                <th>Survey Number <span (click)="loadPage(page,'surveyNumber')"><i
                                            class="fa fa-sort"></i> </span></th>
                                <th>Subdivision Number <span (click)="loadPage(page,'subDivisionNumber')"><i
                                            class="fa fa-sort"></i> </span></th>
                                <th>Part Subdivision Number <span (click)="loadPage(page,'partSubDivisionNumber')"><i
                                            class="fa fa-sort"></i> </span></th>
                                <th>Aadhaar Number <span (click)="loadPage(page,'aadharNumber')"><i
                                            class="fa fa-sort"></i> </span></th>
                                <th>
                                    Account Number <span (click)="loadPage(page,'accountNumber')"><i
                                            class="fa fa-sort"></i> </span>
                                </th>
                                <th>
                                    Bank Name <span (click)="loadPage(page,'nameOfBank')"><i class="fa fa-sort"></i>
                                    </span>
                                </th>
                                <th>
                                    Mobile Number <span (click)="loadPage(page,'mobileNumber')"><i
                                            class="fa fa-sort"></i> </span>
                                </th>
                                <th>
                                    Compensation Amount <span (click)="loadPage(page,'compensationAmount')"><i
                                            class="fa fa-sort"></i> </span>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of beneficary;let i =index">
                                <td>{{generateIndex(i)}}</td>
                                <td>{{data.benificiaryLandOwnerName}}</td>
                                <td>{{data.benificiaryLandDetail.projectID.projectName}}</td>
                                <td>{{data.benificiaryLandDetail.talukLGDCode.name}}</td>
                                <td>{{data.benificiaryLandDetail.revenueLGDCode.name}}</td>
                                <td>{{data.surveyNumber}}</td>
                                <td>{{data.subDivisionNumber}}</td>
                                <td>{{data.benificiaryLandDetail.partSubDivisionNumber}}</td>
                                <td>{{data.aadharNumber }}</td>
                                <td>{{data.accountNumber }}</td>
                                <td>{{data.nameOfBank }}</td>
                                <td>{{data.mobileNumber }}</td>
                                <td>{{data.totalAmount |indianRupee  }}</td>

                        </tbody>
                    </table>

                </div>

                <div class="text-center" *ngIf="totalCount == 0">
                    <img src="../../../assets/img/notFound.png" width="419px" alt="">
                </div>
                <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)" [totalRecords]="totalCount"
                    [rowsPerPageOptions]="[10,20,30,50,100]"></p-paginator>

            </div>
        </div>
    </div>

    <!-- Export to Excel and pdf is removed -->
    <!--     <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <button pButton pRipple icon="pi pi-file-excel" class="p-button-help custom-button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.exportexcel"></span>
                    </button>
                    <button pButton pRipple icon="pi pi-file-pdf" class="p-button-help custom-button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.exportpdf"></span>
                    </button>
                </ng-template>
                <ng-template pTemplate="right">
                     <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success" (click)="openNew()"
                        styleClass="button"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </div> -->
    <p-dialog #dialog [(visible)]="agencyDialog" [style]="{ width: '850px' }" [modal]="true" [closable]="true"
        (onHide)="hideDialog()" styleClass="ib-dialog" appendTo="body">
        <p-header [ngClass]="fontService.headingClass">
            District User
        </p-header>



    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AgencyService } from './lao.service';
import { Agency, AgencyDto } from './lao';
import { SearchResult } from 'src/app/shared/models/search-result';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelType } from '../level-type/level-type';
import { ZonalService } from '../zonal/zonal.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-add-fund-allocation',
  templateUrl: './add-lao.component.html',
  styleUrls: ['./add-lao.component.scss']
})
export class AddFundAllocationComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;
  // @ViewChild('department', ) department: NgSelectComponent;

  agencyDialog: boolean = false;
  cols: any[];
  zonalDto: any;
  searchResult: SearchResult<Agency> = new SearchResult<Agency>();
  agency?: AgencyDto;
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  levelType: LevelType[];
  levelTypeDto: LevelType;
  levelMasterList: any;
  zonalList: any;
  // validation fields properties

  //required fields property

  fund: any = {
    district:'',
    category: '',
    name: '',
    makerName:'',
    checkerName:'',
    makermobileNo:'',
    checkermobileNo:'',
    makerEmail:'',
    checkerEmail:'',
    makerDesignation:'',
    checkerDesignation:''
  }
  showNameError: any;
  showMobileNoError: string;
  showEmailError: string;
  showEmailUniError: string;
  gstUniError: string;
  viewOnly: boolean = false;
  hamHeader: string;
  subHeader: string;
  agencyList: any;
  stateList: any;
  state: any[];
  district: any;
  totalCount: any;
  pageCount: number = 0;
  index: number;
  roleId: boolean = false;
  pageLength: number;
  projectList: any;
  categoryLAO: any;
  category:any;
  roles: any;
  districtId: any;
  selectCategory: any;
  selectRole: any;
  isCategoryVisible: boolean =false;
  isMakerVisible: boolean =false;
  isCheckerVisible: boolean =false;
  districtName: any;
  categoryName: any;
  constructor(
    private agencyService: AgencyService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private loginService: SigninService,
    private levelTypeService: LevelTypeService,
    private zonalService: ZonalService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
  ) {
  }

  ngOnInit() {

    this.category=[{'id':5,'name':'Maker'},{'id':4,'name':'Checker'}]
    this.getLaoCategory()
    this.getRoles()
    this.agency = new AgencyDto();
    this.submitted = false;
    this.agencyService.getLevelMaster().subscribe(
      (res: HttpResponse<any[]>) => {
        let stateList = res.body;
        this.state = stateList.filter((res) => res.levelTypeDto.id == 1);
        this.district = stateList.filter((res) => res.levelTypeDto.id == 2);
      },
      () => {
        this.onError();
      }
    );
    this.agencyDialog = true;
    this.viewOnly = false;

    if (this.router.url == '/agency') {
      this.hamHeader = 'Requisitioning Departments';
      this.subHeader = 'Home  /   Requisitioning Departments';
    }
    // this.AgencyService.getAgencys().then(data => this.agencys = data);
    // this.loadPage(1);
  }

  getUserrole() {
    this.loginService.getuserDto().subscribe(
      (res) => {
        if (res.body.role.id != 1 && res.body.role.id != 8 && res.body.role.id != 5 && res.body.role.id != 4 && res.body.role.id != 3) {
          this.roleId = true
        } else {
          this.roleId = false
        }
      },
      (onError) => { }
    );
  }

  ngAfterViewInit() {
    // this.updateDialogFont();
  }

  govermentType = [
    {
      name: 'CENTRAL_GOVERNMENT',
    },
    {
      name: 'STATE_GOVERNMENT',
    },
  ];


  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);
    this.agencyService.filter({ ...query }, this.filterProps).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (err) => {
        this.spinner.hide();
        this.notificationService.alertError('Error!', err.error.error);
      }
    );
  }
  onchangeUserNameMaker(data){
    console.log(data.target.value,this.categoryName)
    if(this.categoryName=='Special DRO' || this.categoryName =='Special DRO Maker'){
      this.fund.makerDesignation=`Special Tahsildar HQ, ${data.target.value} ${this.districtName}`
    }else if(this.categoryName=='Special Tahsildar(Coll)')
   {
    this.fund.makerDesignation=`Tahsildar HQ, ${data.target.value} ${this.districtName}`
   }

  }
  onchangeMakerName(data){
    const inputElement = data.target as HTMLInputElement;
    let value: string = inputElement.value;
    this.fund.makerDesignation=''
    if(this.categoryName=='Special DRO')
 
    if (!value.startsWith('STD')) {
     
      // this.projectForm.get('ifscCode').patchValue('IOBA', { emitEvent: false });
      this.fund.makerName='STD', { emitEvent: false };
    }
    else if(this.categoryName=='Special Tahsildar(Coll)')
 
    if (!value.startsWith('SPT')) {
     
      // this.projectForm.get('ifscCode').patchValue('IOBA', { emitEvent: false });
      this.fund.makerName='SPT', { emitEvent: false };
    }
  }

  onchangeCheckerName(data){
    const inputElement = data.target as HTMLInputElement;
    let value: string = inputElement.value;
    this.fund.checkerDesignation=''
    if(this.categoryName=='Special DRO'){
      if (!value.startsWith('SDRO')) {
        this.fund.checkerName='SDRO', { emitEvent: false };
      }
    }  else if(this.categoryName=='DRO'){

      if (!value.startsWith('DRO')) {
        this.fund.checkerName='DRO', { emitEvent: false };
      }
    } else if(this.categoryName=='District Collector'){
      if (!value.startsWith('COLL')) {
        this.fund.checkerName='COLL', { emitEvent: false };
      }
    }
 
  
  }
  onchangeUserNameChecker(data){
    if(this.categoryName=='Special DRO'){
    this.fund.checkerDesignation=`Special SDRO HQ, ${data.target.value} ${this.districtName}`;
    }
  else if(this.categoryName=='DRO'){
    this.fund.checkerDesignation=`DRO, ${data.target.value} ${this.districtName}`;
  }
  else if(this.categoryName=='District Collector'){
    this.fund.checkerDesignation=`COLLECTOR, ${data.target.value} ${this.districtName}`;
  }}
  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data.data ?? [];
    this.agencyList = data.data;
    this.totalCount = data.totalCount;
  }

  protected onError(): void { }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  vendorFilter($event) {
    let filter = new filterQuery();
    filter.key = 'name';
    filter.operation = 'contains';
    filter.value = $event.term;
    let filterquery = [];
    filterquery.push(filter);
    this.zonalService.filter({ size: 10, page: 0 }, filterquery).subscribe(
      (res: HttpResponse<any>) => {
        this.zonalList = res.body;
      },
      () => { }
    );
  }

  onProjectChange(e) {
    let districtList = e.projectLocation
    districtList.forEach(element => {
    });

  }

  onDistrict(e) {
    this.districtId = e.id
    this.districtName=e.name;

  }

  onLAO(e) {
    console.log(e)
    this.fund.makermobileNo='';
    this.fund.makerDesignation='';
    this.fund.makerEmail='';
    this.fund.checkermobileNo='';
    this.fund.checkerDesignation=''
    this.fund.checkerEmail='';
    this.selectCategory = e.id;
    this.categoryName=e.category;
 if(e.category=='Special DRO'){

  this.fund.makerName='STD'
  this.fund.checkerName='SDRO'
  this.isMakerVisible=true;
  this.isCheckerVisible=true;
 }else if(e.category=='District Collector'){

  this.isMakerVisible=false;
  this.isCheckerVisible=true;
  this.fund.checkerName='COLL'
 }else if(e.category=='DRO'){
 
  this.isCheckerVisible=true;
  this.isMakerVisible=false;
  this.fund.checkerName='DRO'
  
 }else if(e.category=='Special Tahsildar(Coll)'){
  this.fund.makerName='SPT'
  this.isMakerVisible=true;
  this.isCheckerVisible=false;
 }
 else if(e.category=='Special DRO Maker'){
  this.fund.makerName='STD'
  this.isMakerVisible=true;
  this.isCheckerVisible=false;
 }

  }

  oncategoryChange(e){
console.log(e)
if(e.id == '5'){
  this.isCheckerVisible=false;
this.isMakerVisible=true;

}else{
  this.isMakerVisible=false;
  this.isCheckerVisible=true;
}
  }

  getLaoCategory() {
    this.agencyService.getCategoryLao().subscribe((res: any) => {
console.log(res)
      this.categoryLAO = res.body.data.filter(x=>x.category !=='Special Tahsildar(SDRO)');
      let data= {
        "id": 3,
        "category": "Special DRO Maker",
        "activeFlag": "Yes",
        "createdOn": null,
        "updatedOn": null,
        "createdBy": null,
        "updatedBy": null,
        "roleId": {
          "id": 4,
          "code": "DC1",
          "name": "District Checker1",
          "createdOn": "2023-07-19T21:11:01.021+00:00",
          "updatedOn": "2023-07-19T21:11:21.844+00:00"
        },
        "code": "sdro"
      }

    this.categoryLAO.push(data)
    });
  }

  getRoles() {
    this.agencyService.getRoles().subscribe((res: any) => {
      this.roles = res.body;
    });
  }







  hideDialog() {
   
    this.agency = new AgencyDto();
    this.levelTypeDto = new LevelType();
    this.levelTypeDto = null;
    this.levelType = null;
    this.levelMasterList = null;
    this.agencyDialog = false;
    this.showNameError = null;
    this.showMobileNoError = null;
    this.showEmailError = null;
    this.showEmailUniError = null;
    this.gstUniError = null;
    // this.viewOnly = false;
  }

  keyPressNumberForMobile(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) || event.target.value.length >= 10) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  saveLao(data1) {
    if(this.isCheckerVisible){
     if(!this.fund.checkermobileNo || !this.fund.checkerEmail || !this.fund.checkerName){
      this.notificationService.alertError('Please fill all the mandantory fields marked with *','');
      return
     }
    }
    if(this.isMakerVisible){
      if(!this.fund.makermobileNo || !this.fund.makerEmail || !this.fund.makerName){
       this.notificationService.alertError('Please fill all the mandantory fields marked with *','');
       return
      }
     }
     if(!this.fund.district || !this.fund.category){
      this.notificationService.alertError('Please fill all the mandantory fields marked with *','');
      return
     }
     console.log(this.fund.category)
     if(this.fund.category.category=='Special DRO Maker'){

      this.fund.category={
        "id": 3,
        "category": "Special DRO",
        "activeFlag": "Yes",
        "createdOn": null,
        "updatedOn": null,
        "createdBy": null,
        "updatedBy": null,
        "roleId": {
          "id": 4,
          "code": "DC1",
          "name": "District Checker1",
          "createdOn": "2023-07-19T21:11:01.021+00:00",
          "updatedOn": "2023-07-19T21:11:21.844+00:00"
        },
        "code": "sdro"
      }}
     this.spinner.show();
    // if (data == 'add') {
    //   let payload = {
    //     "mobile": this.fund.mobile,
    //     "email": this.fund.email,
    //     "zonalName": null,
    //     "userActiveFlag": "Yes",
    //     "resetPassword": null,
    //     "loginAttempt": null,
    //     "resetDate": null,
    //     "implementingAgencyId": null,
    //     "designation":this.fund.designaiton,
    //     "levelMasterId": this.districtId,
    //     "profileStatus": "Yes",
    //     "keyCloakUuid": "null",
    //     "laoCategoryId": this.selectCategory,
    //   };

    // } else {

    //   let payload = {
    //     agencyName: this.agency.name,
    //     governmentType: this.agency.govermentType.name,
    //     address1: this.agency.address1,
    //     address2: this.agency.address2,
    //     street: this.agency.street,
    //     city: this.agency.city,
    //     districtLgdCode: this.agency.district.id,
    //     state: this.agency.state.id,
    //     pinCode: this.agency.pincode,
    //     authorizedPersonName: this.agency.agency,
    //     authorizedPersonDesignation: this.agency.designation,
    //     authorizedPersonMobileNumber: this.agency.mobile,
    //     authorizedPersonEmailId: this.agency.email,
    //     officePhoneNumber: this.agency.offliceMobile,
    //     officeEmailId: this.agency.ooficeemail,
    //   };
    //   this.agencyService.edit(payload, this.agency.id).subscribe(
    //     (res: HttpResponse<Agency[]>) => {
    //       this.agencyDialog = false;
    //       this.loadPage(this.page);
    //       this.notificationService.alertSuccess('Saved Successfully', '');
    //       this.hideDialog();
    //     },
    //     (err) => {
    //       this.onError();
    //       this.notificationService.alertError('Error!', err.error.error);
    //       this.spinner.hide();
    //     }
    //   );
    // }
    let data={

      'laoCategory':this.fund.category,
       'levelMasterDto':this.fund.district,
       'roleId':this.fund.category.roleId.id,
       'makerUserName':this.fund.makerName,
       'makerMobileNo':this.fund.makermobileNo,
       'makerEmail':this.fund.makerEmail,
       'makerDesignation':this.fund.makerDesignation,
       'checkerUserName':this.fund.checkerName,
       'checkerMobileNo':this.fund.checkermobileNo,
       'checkerEmail':this.fund.checkerEmail,
       'checkerDesignation':this.fund.checkerDesignation
 }
     console.log(data);
 
       this.agencyService.save(data).subscribe(
         (res) => {
           this.agencyDialog = false;
           this.spinner.hide();
           this.notificationService.alertSuccess('Saved Successfully', '');
           this.router.navigateByUrl('/lao-list')
           this.hideDialog();
         },
         (err) => {
           //this.onError();
           
           const separatedLines = err.error.split('\n').filter(line => line.trim() !== '');
           console.log(separatedLines)
           this.spinner.hide();
           this.notificationService.alertError('Error!',separatedLines);
        
         }
       );
  }

  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }

  onPageChange(event: PageEvent) {
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    let page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.loadPage(page);
  }





  

}

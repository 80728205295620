import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { LimitTransListService } from '../../limit-translist/limit-translist.service';
import { HttpResponse } from '@angular/common/http';
import { FinYear } from '../../limit-translist/limit-translist.module';
import { Location } from '@angular/common';
@Component({
  selector: 'app-limit-allocation',
  templateUrl: './limit-allocation.component.html',
  styleUrls: ['./limit-allocation.component.scss']
})
export class LimitAllocationComponent implements OnInit {
  limitAllocationDialog:boolean=false;
  limitcorrectionDialog:boolean=false;
  currentPath: any;
  govermentType = [
    {
      name: 'CENTRAL_GOVERNMENT',
    },
    {
      name: 'STATE_GOVERNMENT',
    },]
    categoryList = [
      {
        name: 'Overall',
      },
      {
        name: 'Budget',
      }, {
        name: 'Req.Agency',
      }, {
        name: 'Project',
      }];
      agencyList:any =[];
      projectList:any =[];
      categoryData:any;
  finYear: HttpResponse<FinYear[]>;
  isType: boolean =false;
  isProject: boolean =false;
  heading: string;
  constructor( public fontService: FontService, private limitTransListService: LimitTransListService, private location: Location) {
    this.currentPath = this.location.path();
   }

  ngOnInit(): void {
    this.getFinyear();
    if(this.currentPath=='limit-allocation'){
this.heading='Budget Allocation'
    }else{
      this.heading='Budget Correction'
    }
  }
  openNew(){
    if(this.currentPath=='limit-allocation'){
      this.limitAllocationDialog=true
    }else{
    this.limitcorrectionDialog=true
  }

  }
  hideDialog(){
this.limitAllocationDialog=false
  }
  saveLimit(){

  }
  onChangeCategory(data){
console.log(data)
if(data.name=='Req.Agency'){
  this.isType=true;
}else if(data.name=='Project'){
this.isType=true;
this.isProject=true;
}else{
  this.isType=false;
this.isProject=false;
}

  }
  getFinyear(){
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<any>) => {
        console.log(res)
        this.finYear = res.body;
      },
      () => { }
    );
  }

}

<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>{{hamHeader}}</h2>
</div>
<div class="container-fluid bg">
    <div class="tableDesign">
        <div class="row px-4 bgFilter align-items-center">
            <div class="row align-items-center mt-2">
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">Project
                        Name</label>
                    <ng-select id="department" bindLabel="projectName" bindValue="id"
                        [(ngModel)]="stage1Filter.projectName" [items]="projectList" appearance="outline"
                        class="form-control" appNgSelectInputRestriction>
                    </ng-select>
                </div>
                <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">
                        Payment Status</label>
                    <ng-select id="department"
                        [(ngModel)]="stage1Filter.status" [items]="paymentStatus" appearance="outline"
                        class="form-control" appNgSelectInputRestriction>
                    </ng-select>
                </div>
                <!-- <button (click)="openDDdialog()">upload DD</button> -->
                <!-- <div class="col-sm-4 col-md-3">
                    <label class="form-control-label req">
                        Name</label>
                    <input type="text" class="form-control" name="name" [(ngModel)]="stage1Filter.name"
                        autocomplete="off" maxlength="60" id="name" appSerachInputRestriction/>
                </div> -->
         
            </div>
            <div class="flex align-items-center mt-3 ">
                <p-button label="Submit" class="submit" (click)="loadPage()"></p-button>
            </div>
        </div>
    </div>
    <div class="row" >
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Court Payment Status
                    </h5>
                </ng-template>
                <ng-template pTemplate="right">
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row mt-3" >
        <div #customerTable id="entities" class="col-md-12 px-1 payment_table">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">S.No
                        </th>
                        <th scope="col">Project Name <span (click)="loadPage(page,'projectName')"><i
                                    class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">Survey
                            Number <span (click)="loadPage(page,'surveyNumber')"><i class="fa fa-sort"></i> </span></th>
                        <th scope="col">Subdivision
                            Number <span (click)="loadPage(page,'subDivisionNumber')"><i class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">
                            Proceeding Number<span (click)="loadPage(page,'proceedingNo')" ><i class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">
                        Extent Owned<span (click)="loadPage(page,'extentOwned')" ><i class="fa fa-sort"></i> </span>
                        </th>
                        <th scope="col">Amount <span (click)="loadPage(page,'totalAmount')"><i class="fa fa-sort"></i>
                        </span>
                    </th>
                    <th scope="col">Remmiter<span (click)="loadPage(page,'remmiter')"><i class="fa fa-sort"></i>
                    </span>
                </th>
                <th scope="col">Payable<span (click)="loadPage(page,'payable')"><i class="fa fa-sort"></i>
                </span>
            </th>
            <th scope="col">Favour<span (click)="loadPage(page,'favour')"><i class="fa fa-sort"></i>
            </span>
        </th>
        <th scope="col">Voucher Number<span (click)="loadPage(page,'voucherNumber')"><i class="fa fa-sort"></i>
        </span>
    </th>
                        <th scope="col">Bank Name <span (click)="loadPage(page,'bankName')"><i class="fa fa-sort"></i>
                            </span>
                        </th>
                        <th scope="col">Branch Name<span (click)="loadPage(page,'branchName')"><i class="fa fa-sort"></i>
                        </span>
                    </th>
                        <!-- <th scope="col">Account Number <span (click)="loadPage(page,'accountNumber')"><i
                                    class="fa fa-sort"></i> </span>
                        </th> -->
                        <!-- <th scope="col">Aadhaar Number <span (click)="loadPage(page,'aadharNumber')"><i
                                    class="fa fa-sort"></i> </span>
                        </th> -->
                 
                        <th scope="col">Status
                        </th>
                        <!-- <th scope="col">Utr No
                        </th> -->
                        <th scope="col">Download
                        </th>
                        <th scope="col">Upload
                        </th>
                        <!-- <th scope="col">View
                        </th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of paymentList;let i =index">
                        <td>{{generateIndex(i)}}</td>
                        <td>{{data.projectName}}</td>
                        <td>{{data.surveyNumber}}</td>
                        <td>{{data.subDivisionNumber}}</td>
                        <td>{{data.proceedingNo}}</td>
                          <td>{{data.extentOwned}}</td>
                          <td>{{data.amount |indianRupee }}</td>
                          <td>{{data.remmiter}}</td>
                          <td>{{data.payable}}</td>
                          <td>{{data.favour}}</td>
                      
                        <td>{{data.voucherNumber}}</td>
                        <td>{{data.bankName}}</td>
                        <td>{{data.branchName}}</td>
                      
                        <!-- <td>{{data.aadharNumber}}</td> -->
                      
                        <td>
                            {{data.status}}
                        </td>
                        <!-- <td>
                            {{data.utrNo}}
                        </td> -->
                        <td class="download_link" (click)="downdloadPdf(data)">Download Pdf</td>
                        <td  *ngIf="!data.ddNumber && roleCode=='DMAKER' "class="download_link" (click)="openDDdialog(data)">Upload DD</td>
                        <td *ngIf="data.ddNumber || roleCode!='DMAKER' " >-</td>
                        <!-- <td *ngIf="data.ddNumber" >View</td> -->
                    </tr>
                </tbody>
            </table>
     
            <div class="text-center" *ngIf="totalCount == 0">
                <img src="../../../assets/img/notFound.png" alt="">
            </div>
            <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)" [totalRecords]="totalCount"
                [rowsPerPageOptions]="[10,20,30,50,100,2000]"></p-paginator>
        </div>
    </div>
    <!-- Export to Excel and pdf is removed -->
    <!--     <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <button pButton pRipple icon="pi pi-file-excel" class="p-button-help custom-button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.exportexcel"></span>
                    </button>
                    <button pButton pRipple icon="pi pi-file-pdf" class="p-button-help custom-button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.exportpdf"></span>
                    </button>
                </ng-template>
                <ng-template pTemplate="right">
                     <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success" (click)="openNew()"
                        styleClass="button"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </div> -->
    <p-dialog #dialog [(visible)]="agencyDialog" [style]="{ width: '850px' }" [modal]="true" [closable]="true"
        styleClass="ib-dialog" appendTo="body">
        <p-header [ngClass]="fontService.headingClass">
            District User
        </p-header>



    </p-dialog>
    <p-dialog #dialog [(visible)]="ddDialog" [style]="{ width: '850px' }" [modal]="true" [closable]="true"
    styleClass="ib-dialog" appendTo="body">
    <!-- <p-header [ngClass]="fontService.headingClass">
        District User
    </p-header> -->
    <p-header [ngClass]="fontService.headingClass">
 Court DD Details
      </p-header>
      <ng-template pTemplate="content">
        <div class="col-12">
          <div class="form-inner-panel">
  
            <div class="row gy-2">
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" 
                  for="snaName">DD Number</label>
                <input type="text" id="snaName" name="snaName" autocomplete="off" maxlength="6"
                  oninput="this.value = this.value.replace(/[^0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                  onblur="this.value =this.value.trim();"  class="form-control"  [ngClass]="{ 'is-invalid': ngSelectInvalid && !ddDetails.ddNumber }"[(ngModel)]="ddDetails.ddNumber"
         
                  [readonly]="readonly" />
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" 
                  for="snaCode">DD Date</label>
                  <p-calendar [readonlyInput]="true" appendTo="body" 
                   [(ngModel)]="ddDetails.ddDate"
                  [maxDate]="today" [ngClass]="{ 'is-invalid': ngSelectInvalid && !ddDetails.ddDate }"></p-calendar>
              </div>
              <div class="col-sm-6 col-md-4">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" 
                  for="shortName">DD Deposited Date</label>
                  <p-calendar [readonlyInput]="true" appendTo="body"
                  [(ngModel)]="ddDetails.depositedOn" [ngClass]="{ 'is-invalid': ngSelectInvalid && !ddDetails.depositedOn }"
                  [maxDate]="today"></p-calendar>
              </div>
              <div class="col-sm-12 col-md-8">
                <label class="form-control-label req" [ngClass]="fontService.labelClass" 
                  for="snaDescription">Court Name</label>
                  <input type="text" id="snaName" name="snaName" autocomplete="off" maxlength="100"    
              class="form-control"  [(ngModel)]="ddDetails.courtName"
              [ngClass]="{ 'is-invalid': ngSelectInvalid && !ddDetails.courtName }"
                  [readonly]="readonly" />
              </div>
              <div class="col-md-12">
                <label class="form-control-label req" for="upload" [ngClass]="fontService.labelClass">Upload
                    DD </label><br>
                <small [ngClass]="fontService.regularClass" >Note : Allowed file
                    types are jpg, jpeg, png, pdf and
                    Maximum file size should be 5MB</small>
            </div>
            <div class="col-md-12" *ngIf="!isFile">
                <p-fileUpload accept="image/png,image/jpeg,image/jpg,application/pdf" maxFileSize="5000000"
                    (onUpload)="onUploadfile1($event)" [customUpload]="true" auto="true" mode="basic"
                    (uploadHandler)="onUploadfile1($event)" multiple="false" [showUploadButton]="false"
                    [disabled]="isFile || (viewOnly)" [showCancelButton]="false"
                    chooseLabel="{{'common.upload'|translate}}">
                </p-fileUpload>

            </div>
            <div class="col" *ngIf="isFile">
                <a class="cursor-pointer" (click)="viewDocument()">{{ddDetails.fileName}}</a>
                <i *ngIf="!viewOnly" class="pi pi-times add-margin" (click)="removeDocument()"></i>
            </div>
            </div>
         
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button icon="pi pi-times" class="btn btn-primary" (click)="hideDialog()">
          <span class="button-label" [ngClass]="fontService.buttonClass" custom-button jhiTranslate="common.cancelbutton">
          </span>
        </button>
        <button *ngIf="!readonly" icon="pi pi-check" class="btn btn-primary" (click)="saveDD()">
          <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
        </button>
      </ng-template>


</p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>
<p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000"
styleClass="ib-dialog">
<ng-template pTemplate="body">
    <div style='position: relative; height: 100%;'>
        <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
        <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
    </div>
</ng-template>
</p-dialog>
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '../../shared/font-service/font.service';
import { ValidationService } from '../../shared/validation/validation.service';
import { NotificationService } from '../../alert/notification.service';
import { Dialog } from 'primeng/dialog';
import { DecimalPipe } from '@angular/common';
import { SearchResult } from '../../shared/models/search-result';
import { Project } from '../../project/project.model';
import { SigninService } from '../../auth/signin/signin.service';
import { ProjectService } from '../../project/project.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AgencyService } from '../stage3-view/stage3.service';
import { HttpResponse } from '@angular/common/http';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-proceeding-no',
  templateUrl: './proceeding-no.component.html',
  styleUrls: ['./proceeding-no.component.scss']
})
export class ProceedingNoComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;
  projectDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Project> = new SearchResult<Project>();
  checked: boolean;
  project?: Project;
  totalExtent: any

  projectFilter!: string;
  fundFilter: any = ''
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any;
  commercialList: Project[];
  readonly: boolean;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  projectDistrict
  requestingDepartment: any = ''
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  bankName: any;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showCompanyNameError: string;
  showPanError: string;
  showeEmailError: string;
  showAadhaarNoError: string;
  showGstError: string;
  showaccountNoError: string;
  bankList: any;
  villageLevelDto: null;
  user: any;
  isFile: boolean;
  vFileExt: any;
  viewDoc: boolean;
  vFileUrl: string;
  enablepublicContributionAmount: boolean = false;
  SerialNo
  publicContributionAmount = [
    {
      id: '1',
      name: '1/3',
      value: 0.33,
    },
    {
      id: 2,
      name: '1/5',
      value: 0.2,
    },
    {
      id: 3,
      name: '1/2',
      value: 0.5,
    },
  ];

  proceedingObj = {
    proceedingNo: '',
    date: '',
    proceedingDocument: '',
    projectName: ''
  }
  selectedpublicContribution: any;
  enableView: boolean = false;
  blockLevelDto: null;
  limitAmount: number;
  viewOnly: boolean = false;
  agencyList: any;
  edit: boolean;
  projectForm: FormGroup;
  hamHeader: string;
  subHeader: string;
  projectList: any;
  landUnits: any;
  AgencyLists: any;
  getOneProject: any;
  state: any[];
  district: any[];
  date: Date | undefined;

  LAOAdmin: any[];
  singleProject: any;
  isShow: boolean = false;
  remainValue: number;
  disableButton: boolean = true;
  items: any;
  activeIndex: number;
  totalCount: any;
  fileUpload: any;
  pageCount: number = 0;
  index: any;
  showError: boolean = false;
  roleId: boolean = false;
  districtId: any[];
  label: string;
  projectId: any;
  districtIds: any;
  deletedProject: any[];
  editContent: any;
  deletePayload: any;
  pageLength: number;
  isShowTable: boolean = false;
  totalLandPrivate: any;
  throughAct: any;
  taluk: any[];
  villages: any[];
  sampleNumber: any[];
  projectName: boolean;
  districtsByProject: any;

  totalExtendsValue: any = {
    totalExtent: '',
    fundAllocated: '',
    fundReceived: ''
  };
  isErrorAct: boolean = false;
  currentIndex: any = 0;
  format = '.0-2';

  totals: any;
  proceeding: boolean;
  beneficiaryData: any;
  temp: any = [];
  fileUploadURL: any;
  procedingList: any;
  vFileExtList: any;
  vFileUrlList: string;
  viewDocList: boolean;
  viewList: any;
  totalCounts: any;
  totalCount1: any;
  tempSerial: any = [];
  SerialNoData: any = [];
  finalArr: any = [];
  enableButton: boolean = true;
  finalSerial: any = [];
  selectProcedding: any;
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    public datepipe: DatePipe,
    protected notificationService: NotificationService,
    private _decimalPipe: DecimalPipe,
    private loginService: SigninService,
    private projectService: ProjectService,
    private AgencyService: AgencyService,

  ) {
    this.translateService.onLangChange.subscribe(() => {
    });
  }
  onPageChange(event: PageEvent) {
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    this.page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.getProceeding(this.page)
  }

  typeOfFund = [
    {
      name: 'Project with Amount',
      value: 'PROJECT_WITH_AMOUNT',
    },
    {
      name: 'Project without Amount',
      value: 'PROJECT_WITHOUT_AMOUNT',
    },

  ];

  getProjects() {
    this.projectService.getProjectDetails().subscribe(
      (res: any) => {
        this.projectList = res.body.data;
      },
      () => {
        // this.onError();
      }
    );
  }

  viewDocumentForlist(image) {
    this.spinner.show()
    this.AgencyService.viewimage(image).subscribe((res) => {
      this.spinner.hide()
      const filename = res.body.fileName;
      this.vFileExtList = filename.split('.').pop();
      this.vFileUrlList =
        'data:image/' + this.vFileExtList + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDocList = true;
    }, (err) => {
      this.spinner.hide()
    });
  }

  view(data) {
    this.spinner.show()
    this.selectProcedding = data
    this.AgencyService.viewBeneficiary(data.id).subscribe((res) => {
      this.beneficiaryData = res.body.data
      this.totalCount1 = res.body.totalCount
      this.spinner.hide()
      this.viewList = true
    }, (err) => {
      this.spinner.hide()
    });
  }

  getProceeding(page) {
    this.spinner.show()
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.projectService.getProceeding(query).subscribe(
      (res: any) => {
        this.procedingList = res.body.data;
        this.totalCounts = res.body.totalCount
        this.spinner.hide()

      },
      (err) => {
        this.spinner.hide()

        // this.onError();
      }
    );
  }

  totalExtend() {
  }
  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }

  viewDocument() {
    this.spinner.show()
    this.AgencyService.viewimage(this.fileUploadURL).subscribe((res) => {
      this.spinner.hide()
      const filename = res.body.fileName;
      this.vFileExt = filename.split('.').pop();
      this.vFileUrl =
        'data:pdf/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc = true;
    }, (err) => {
      this.spinner.hide()
    });
  }

  onUploadfile1($event) {
    this.spinner.show()
    const formData = new FormData();
    const i = $event.files.length - 1;
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }
    this.AgencyService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.notificationService.alertSuccess('Image Upload SuccessFully', '');
        this.spinner.hide()
        this.fileUpload = res.body.fileDownloadUri;
        this.fileUploadURL = res.body.fileName;
        this.isFile = true;
      },
      (onerr) => { }
    );
  }

  OnSubmit() {
    let obj = {
      "proceedingNumber": this.proceedingObj.proceedingNo,
      "proceedingDate": this.proceedingObj.date,
      "files": this.fileUploadURL,
      "beneficiaryLandOwner": this.finalSerial,
      "projectId": this.projectId
    }
    this.spinner.show()
    this.projectService.Proceeding(obj).subscribe((res: any) => {
      this.spinner.hide();
      this.proceeding = false
      this.notificationService.alertSuccess("Success", 'Proceeding no added successfully');

      this.getProceeding(this.page)
    }, (err) => {
      this.spinner.hide();
      this.notificationService.alertError(err.error.error, '');
      this.getProceeding(this.page)

    })
  }

  SerialNos(e, data) {
    let obj = {
      beneficiarylandOwnerId: data.id,
      beneficiarySerialNumber: e.target.value
    }
    this.tempSerial.push(obj)
    const mapFromColors = new Map(
      this.tempSerial.map(c => [c.beneficiarylandOwnerId, c])
    );
    this.SerialNoData = [...mapFromColors.values()];
    this.finalSerial = this.SerialNoData.filter(res => res.beneficiarySerialNumber != '')
    console.log(this.finalSerial.length);


  }
  openAdd() {
    this.isFile = false;
    this.proceeding = true
    this.beneficiaryData = []
    this.proceedingObj = {
      proceedingNo: '',
      date: '',
      proceedingDocument: '',
      projectName: ''
    }
    this.fileUploadURL = ''
  }
  onSelectDistrict(e) {
    this.projectService.talukAndVillages(e, 3).subscribe(res => {
      this.taluk = res
    })
  }
  selectProject(e) {
    if (e.id) {
      this.projectDistrict = ''
      this.projectName = e.projectName
      this.projectId = e.id
      this.spinner.show()
      this.projectService.getBeneficiary(this.projectId).subscribe((res: any) => {
        this.beneficiaryData = res.body.data
        this.totalCount = res.body.totalCount
        this.spinner.hide()
      }, (err) => {
        this.totalCount = 0
        this.beneficiaryData = []
        this.notificationService.alertSuccess(err.error.error, '');
        this.spinner.hide()
      })
    }
  }

  // generateVoucher(e, data) {
  //   if (e.checked) {
  //     if (this.temp.length != 0) {
  //       let isCheck = this.temp.every((currentValue) => currentValue != data.id)
  //       if (isCheck) {
  //         this.temp.push(data.id)
  //       }
  //     } else {
  //       this.temp.push(data.id)
  //     }
  //   } else {
  //     let delIndex = this.temp.findIndex(element => element == data.id);
  //     this.temp.splice(delIndex, 1)
  //     if (this.temp.length == 0) {
  //     } else {
  //     }
  //   }

  // }

  removeDocument() {
    this.AgencyService.deleteimage(this.fileUploadURL).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.isFile = false;
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }

  OnSelectTaluk(e, i) {
    this.projectService.talukAndVillages(e.id, 4).subscribe(res => {
      this.villages = res
      this.detailsOfLand.controls[i].get('villageName').setValue('');

    })
    // this.beneficaryForm.get('village').setValue('')

  }
  ngOnInit() {
    this.forms();
    this.addSkills();
    this.getProceeding(1)
    this.getProjects();
    this.loginService.getuserDto().subscribe(
      (res) => {
      })
    this.projectService.getAgency().subscribe((res: any) => {
      this.AgencyLists = res.body.data;
    });
    if (this.router.url == '/project') {
      this.hamHeader = 'Projects';
      this.subHeader = 'Home  /   Projects';
    }
    this.cols = [
      {
        field: 'projectName',
        header: 'Name of the project',
        isSortable: true,
        isFilterable: true,
        width: 10,
        isSelectcolumn: true,
      },
      {
        field: 'agencyId',
        header: 'Requesting Agency',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
        type: 'dropDowns',
      },
      {
        field: 'landUnitId',
        header: 'Lant Unit',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'type',
      },

      {
        field: 'tentativeLandCost',
        header: 'Project Amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'createdOn',
        header: 'Created On',
        jhiTranslate: 'tableHead.createdon',
        isSortable: true,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
  }
  removeSkill(i: number) {
    this.detailsOfLand.removeAt(i);
  }

  getUserrole() {

  }
  addSkill(i) {
    this.currentIndex = i
    let totalLand: number = 0;
    let form = this.projectForm.valid
    if (form) {
      this.detailsOfLand.push(this.detailsform());
    }
  }

  keyPressNumbersDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      let text = event.target.value
      if (text.includes('.')) {
        let decimals = text.split('.');
        if (decimals[1].length >= 4) {
          event.preventDefault();
          return false;
        } else {
          return true;
        }
      }
    }
    return true;
  }

  forms() {
    this.projectForm = this.formBuilder.group({

      LandDetailsForm: this.formBuilder.array([]),
    })
  }

  detailsform(): FormGroup {
    return this.formBuilder.group({
      talukName: ['', Validators.required],
      villageName: ['', Validators.required],
      privateNegotitaion: ['', Validators.required],
      throughAct: ['', Validators.required],
      total: ['', Validators.required],
      totalInSqM: ['', Validators.required],

      nameOfBlock: ['', Validators.required],
      nameOfUnit: ['', Validators.required],

    });
  }


  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  getTotal(i) {
    let privateNegotitaion: any = Number(this.detailsOfLand.controls[i].get('privateNegotitaion').value);
    let throughAct: any = Number(this.detailsOfLand.controls[i].get('throughAct').value)
    let total: any = String(privateNegotitaion + throughAct)
    let heaToSqs = Number(total) * 10000


    if (total.includes('.')) {
      let split = total.split('.');
      let split1 = split[0];
      let split2 = split[1].slice(0, 4)
      let final = split1 + '.' + split2
      let finalValue = Number(final)
      this.detailsOfLand.controls[i].get('total').setValue(finalValue);
      let heaToSq = Number(finalValue) * 10000
      this.detailsOfLand.controls[i].get('totalInSqM').setValue(heaToSq);
    } else {
      this.detailsOfLand.controls[i].get('total').setValue(total);
      this.detailsOfLand.controls[i].get('totalInSqM').setValue(heaToSqs);
    }
    // }
    let totalLand: any = 0;
    let act: any = 0
    let totals: any = 0

    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.privateNegotitaion);
      act += Number(element.throughAct);
      totals += Number(element.total);

    });
    this.totalLandPrivate = totalLand
    this.throughAct = act
    this.totals = totals

  }
  get detailsOfLand(): FormArray {
    return this.projectForm.get('LandDetailsForm') as FormArray;
  }

  addSkills() {
    this.detailsOfLand.push(this.detailsform());
  }



  ngOnDestroy(): void {
    this.notificationService.hideLoader()
  }
}
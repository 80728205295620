import { DatePipe } from '@angular/common';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchResult } from 'src/app/shared/models/search-result';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from 'ngx-webstorage';
import { NotificationService } from 'src/app/alert/notification.service';
import { AccountSnaService } from '../account-sna/account-sna.service';
import { AccountStatement } from './acc-statement';
@Component({
  selector: 'app-account-statement',
  templateUrl: './account-statement.component.html',
  styleUrls: ['./account-statement.component.scss']
})
export class AccountStatementComponent implements OnInit {
  searchResult: SearchResult<any> =
    new SearchResult<any>();
  fromDate: any;
  toDate: any;
  downloadfromDate: any;
  downloadtoDate: any;
  maxDate = new Date();
  filterProps: any[];
  itemsPerPage: any = 10;
  page: number;
  cols: any = [];
  accountTableData: any;
  isTable: boolean = false;
  isDownlaodExcel: boolean = false;
  isDownlaodPdf: boolean = false;
  getTable: boolean = false;
  fetchbtnDisable: boolean = false;
  accountBalance: any;
  status: string;
  showStatus: boolean;
  accountService: any;
  fromDate1: string;
  toDate1: string;
  constructor(private datePipe: DatePipe,
    private notificationService: NotificationService,
    private accountSnaService: AccountSnaService,
    //  private accountService:AccountStatementService,
    public localStorageService: LocalStorageService,
    private spinner: NgxSpinnerService,
    private router: Router) {

  }

  ngOnInit() {
    // 
    //this.getStatementTable()
    this.loadPage('initial');

    //this.checkStatus();
    this.isTable = true;
    this.cols = [
      {
        field: 'transDate',
        header: 'PostDate',
        //  jhiTranslate: 'tableHead.statement.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'dateTime',
      },
      {
        field: 'narration',
        header: 'Narration',
        //  jhiTranslate: 'tableHead.statement.narration',
        isSelectcolumn: true,

      },
      {
        field: 'transactionType',
        header: 'Transaction Type',
        // jhiTranslate: 'tableHead.statement.transactionType',
        isSelectcolumn: true,
      },
      {
        field: 'credit',
        // cfield:'transactionType',
        header: 'Credit',
        // jhiTranslate: 'tableHead.statement.credit',
        isSortable: true,
        isSelectcolumn: true,
        type:'amount'
        // type:'crfield'
      },
      {
        field: 'debit',
        // cfield:'transactionType',
        header: 'Debit',
        //jhiTranslate: 'tableHead.statement.debit',
        isSortable: true,
        isSelectcolumn: true,
        type:'amount'
        // type:'dbfield'
      },

      {
        field: 'currentBalance',
        header: 'Current Balance',
        // jhiTranslate: 'tableHead.statement.currentBalance',
        isSortable: true,
        isSelectcolumn: true,
        type:'amount'
      }
    ]
    this.getAccountBalance();

  }

  //   fetchData(){

  //     if(!this.fromDate || !this.toDate){
  // this.notificationService.alertError('Please select the From Date and To Date to continue','');
  // return
  //     }else{
  //       let fromDate=this.datePipe.transform(this.fromDate,'yyyy-MM-dd');
  //       let toDate = this.datePipe.transform(this.toDate,'yyyy-MM-dd');
  //       let data={
  //         'fromDate':fromDate,
  //         'toDate':toDate
  //       }
  //       this.accountService.downloadaccountStatement(data).subscribe(res=>{
  //         console.log(res)

  //       })

  //     }

  //   }
  downloadPdfData() {

    if (!this.downloadfromDate || !this.downloadtoDate) {
      this.notificationService.alertError('Please select the From Date and To Date to continue', '');
      return
    } else {
      let downloadfromDate = this.datePipe.transform(this.downloadfromDate, 'yyyy-MM-dd');
      let downloadtoDate = this.datePipe.transform(this.downloadtoDate, 'yyyy-MM-dd');
      let data = {
        'fromDate': downloadfromDate,
        'toDate': downloadtoDate
      }
      this.accountService.downloadaccountStatement(data).subscribe(res => {
        console.log(res)

        const blob = new Blob([res.body], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'file.pdf';
        document.body.appendChild(link);
        window.open(url, '_blank');
        link.click();
        URL.revokeObjectURL(url);
        this.hideDialog()
      })

    }

  }

  excelData() {
    if (!this.downloadfromDate || !this.downloadtoDate) {
      this.notificationService.alertError('Please select the From Date and To Date to continue', '');
      return
    } else {
      let downloadfromDate = this.datePipe.transform(this.downloadfromDate, 'yyyy-MM-dd');
      let downloadtoDate = this.datePipe.transform(this.downloadtoDate, 'yyyy-MM-dd');
      let data = {
        'fromDate': downloadfromDate,
        'toDate': downloadtoDate
      }
      this.accountService.downloadaccountStatementExcel(data).subscribe(res => {
        var downloadURL = window.URL.createObjectURL(res);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "Statement.xlsx";
        link.click();
        this.hideDialog()

      })
    }
  }
  getStatementTable(event?) {
    this.getTable = true;
    this.loadPage('initial');

  }
  clearTable() {
    this.fromDate = null;
    this.toDate = null;
    this.getStatementTable();
  }
  filterData() {
    if (!this.fromDate || !this.toDate) {
      this.notificationService.alertError('Please select the From Date and To Date to continue', '');
      return
    } else {
      this.fromDate1 = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
      this.toDate1 = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
      this.loadPage('date')
    }
  }
  getAccountStatementData() {
    this.loadPage('transaction')
    // this.checkStatus()
  }
  checkStatus() {
    this.accountService.getStatus().subscribe(res => {
      console.log(res)
      if (res.body == null) {
        // this.notificationService.alertSuccess('Process not initiated, Now you can fetch the latest statement','');
      } else {
        if (res.body['status'] == 'Failed') {
          this.fetchbtnDisable = true;
          this.status = 'Process to fetch latest statement intiated, Please wait for sometime';
          this.showStatus = true

        } else if (res.body['status'] == 'Success') {
          this.notificationService.alertSuccess('Process completed successfully', '');

        } else if (res.body['status'] == 'Failed1') {
          this.notificationService.alertError('Process failed, Please try after sometime to fetch lastest statement', '')

        }
      }

    }, (err => {
      console.log(err)
    }))
    // this.getStatementTable();
  }
  opendownloadExcel() {
    this.isDownlaodExcel = true;
  }
  opendownloadPdf() {
    this.isDownlaodPdf = true;
  }
  hideDialog() {
    if (this.isDownlaodExcel) {
      this.isDownlaodExcel = false;
      this.downloadfromDate = null;
      this.downloadtoDate = null;
    } else {
      this.isDownlaodPdf = false;
      this.downloadfromDate = null;
      this.downloadtoDate = null;
    }

  }

  // onLazyLoad(event) {

  //   this.filterProps = event.filterDefinitions;
  //   this.itemsPerPage = event.size;
  //   if(this.getTable){
  //     if(this.fromDate && this.toDate){
  //       let fromDate=this.datePipe.transform(this.fromDate,'yyyy-MM-dd');
  //       let toDate = this.datePipe.transform(this.toDate,'yyyy-MM-dd');
  // this.loadPage(event.page,fromDate,toDate)
  //     }else{
  //       this.loadPage(event.page);
  //     }


  //   }

  // }
  // loadPage(page?: number,fromdate?:any,todate?:any): void {
  // this.spinner.show()
  //   const pageToLoad: number = page ?? this.page ?? 1;

  //   let data={
  //     'fromDate':fromdate ? fromdate :null,
  //      'toDate':todate ? todate :null,
  //     'size':this.itemsPerPage,
  //     'page':pageToLoad-1
  //   }
  //   this.accountService.getAccountStatementTable(data).subscribe((res: HttpResponse<any>)=>{
  //     console.log(res)
  //     this.spinner.hide()
  //     this.onSuccess(res.body,res.headers,pageToLoad);

  //     this.isTable=true;


  //   },(error=>{
  //     this.spinner.hide()
  //     this.notificationService.alertError(error.error,'')
  //   }))
  // }


  // protected onSuccess(
  //   data: any | null,
  //   headers: HttpHeaders,
  //   page: number,

  // ): void {
  //   console.log(data,headers)
  //   this.searchResult.total =  Number(headers.get('X-Total-Count'));
  //   // this.spinner.hide();
  //   this.page = page;

  //   this.searchResult.items = data ?? [];
  //   this.searchResult = { ...this.searchResult };
  // }
  getAccountBalance() {
    this.spinner.show()
    this.accountService.getAccountBalance().subscribe(res => {
      this.accountBalance = res['body']['AccountEnquiry_Response']['Body']['Payload']['Account_Balance'];
      this.spinner.hide()
    },
      (err => {
        this.spinner.hide()
        this.notificationService.alertError(err.error, '')
      }))
  }


  loadPage(type): void {
    let data
    this.spinner.show()
    if (type == 'initial' || type == 'transaction') {
      let toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
      data = {
        'fromDate': '2023-12-27',
        'toDate': toDate,
        'accountNo':117201004200000,
       
      }
    } else if (type == 'date') {
      data = {
        'fromDate': this.fromDate1 ? this.fromDate1 : null,
        'toDate': this.toDate1 ? this.toDate1 : null,
        'accountNo':117201004200000,
     
      }
    }
    this.accountSnaService
      .getAccountStatement(data)
      .subscribe(
        (res: HttpResponse<AccountStatement[]>) => {
          this.spinner.hide()
          console.log(res, 'res');
          this.searchResult.total = res.body.length
          this.searchResult.items = res.body;
          this.searchResult = { ...this.searchResult };
        },
        () => {
          // this.onError();
        }
      );
  }
}


import { CommonService } from './../../shared/common.service';
import {  Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProfileService } from './profie.service';
import { AddProfile, Profile } from './profile';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  educationalQualification,
  gender,
  maritalStatus,
  categoryReservation,
  community,
  filterQuery,
} from 'src/app/shared/common.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dialog } from 'primeng/dialog';
import { FontService } from 'src/app/shared/font-service/font.service';
import { TranslateService } from '@ngx-translate/core';
// import { saveAs } from 'file-saver';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.scss']
})
export class AddProfileComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;

  profileDialog: boolean = false;
  cols: any[];
  searchResult;
  profiles?: Profile[];
  profile?: AddProfile ={};
  selectedProfiles: Profile[] = [];
  submitted?: boolean;
  filterProps: any;
  dateOfAssumingOffice: Date;
  profileDetails: any = {};
  page: number;
  itemsPerPage: number = 10;
  genderList: gender[];
  educationList: educationalQualification[];
  maritalList: maritalStatus[];
  categoryReservationList: categoryReservation[];
  community: community[];
  name: any;
  otp: number;
  Profiledetails: any;
  enableFeild: boolean;
  isStateRole:boolean=true;

  // validation fields properties
  resendotp=false;
  sendotp:boolean=false;
  otpOpen = false;
  public aadhaarError: boolean = false;
  public otpError: boolean = false;
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public showBMobile = false;
  today: Date;

  //required fields property
  ngSelectInvalid: boolean = false;
  aadharNoNameError: string;
  panNoError: string;
  mobileNoError: string;
  emailIdError: string;
  txnNo: any;
  rolecode: any;
  otpButtonName:string = "Send OTP";

  constructor(
    private ProfileService: ProfileService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    protected router: Router,
    private CommonService: CommonService,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
  ) {
    this.translateService.onLangChange.subscribe(() => {
      // this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.openNew()
    this.loginService
    .getuserDto()
    .subscribe(
      (res) => {
        if (res.body.role.name?.startsWith('State')) {
          this.isStateRole = false;
        }
        this.rolecode = res.body.role.code
        this.loadPage(1);
      },
      (onError) => {},
     
    );
  
    this.today = new Date();
  }

  ngAfterViewInit() {
    // this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadValue() {
    this.CommonService.getGenderList().subscribe(
      (res: HttpResponse<gender[]>) => {
        this.genderList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.CommonService.geteducationList().subscribe(
      (res: HttpResponse<educationalQualification[]>) => {
        this.educationList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.CommonService.getMartialStatusList().subscribe(
      (res: HttpResponse<maritalStatus[]>) => {
        this.maritalList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.CommonService.getCategoryReservationList().subscribe(
      (res: HttpResponse<categoryReservation[]>) => {
        this.categoryReservationList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.CommonService.getcommunityList().subscribe(
      (res: HttpResponse<community[]>) => {
        this.community = res.body;
      },
      () => {
        this.onError();
      }
    );
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    this.filterProps = this.filterProps ? this.filterProps : [];

    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);

    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    if(this.rolecode !== 'SA'){
      this.ProfileService.filter({ ...query }, this.filterProps).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    }
    else{
      this.ProfileService.vaildFilter({ ...query }, this.filterProps).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    }
    
  }
  protected onSuccess(
    data: Profile[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.spinner.hide();
    this.page = page;
    
    this.searchResult = data ;
  }

  protected onError(): void {
    this.notificationService.alertError("An unexpected error occured,please try again",'');
    // this.enableFeild = false
  }

  async aadhaarNocheck() {
    this.validateAadhaar()
    const filterData = await this.getfilterdata(
      'aadhaarNo',
      this.profile.aadhaarNo
    );
    if (filterData.length !== 0) {
      this.aadharNoNameError = 'Aadhaar No Already Exists';
    } else {
      this.aadharNoNameError = null;
    }
  }

  async panNoCheck() {
    const filterData = await this.getfilterdata('panNo', this.profile.panNo);
    if (filterData.length !== 0) {
      this.panNoError = 'PanNo Already Exists';
    } else {
      this.panNoError = null;
    }
  }

  async mobileNo() {
    const filterData = await this.getfilterdata(
      'mobileNo',
      this.profile.mobileNo
    );
    if (filterData.length !== 0) {
      this.mobileNoError = 'MobileNo Already Exists';
    } else {
      this.mobileNoError = null;
    }
  }

  async emailCheck() {
    const filterData = await this.getfilterdata(
      'emailId',
      this.profile.emailId
    );
    if (filterData.length !== 0) {
      this.emailIdError = 'Email Already Exists';
    } else {
      this.emailIdError = null;
    }
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
        {
          key: 'activeFlag',
          operation: 'equals',
          value: 'YES',
        },
      ];
      if (this.profile.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.profile.id,
        });
      }
      this.ProfileService.vaildFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<Profile[]>) => {
          resolve(res.body);
        },
        () => {
          this.onError();
          resolve(null);
        }
      );
    });
  }
  openNew() {
    this.loadValue();
    this.submitted = false;
    this.profile = new AddProfile();
  }

  sendOtp() {
    this.sendotp=true;
    if (!this.aadharNoNameError) {      
      setTimeout(() => {
        this.otpButtonName="Resend OTP"
        this.sendotp=false;
      }, 30000);
      this.ProfileService.sendOtp(this.profile.aadhaarNo).subscribe(
        (res: HttpResponse<any>) => {
          this.txnNo = res.body;         
        },
        () => {
          this.onError();
          this.hideDialog();
        }
      );
    }
  }

  async validateOtp(otp) {
    this.spinner.show()
    this.ProfileService.validateOtp(
      this.txnNo,
      this.profile.aadhaarNo,
      this.profile.otp
    ).subscribe(
      async (res: HttpResponse<any>) => {
        this.profile = res.body;
        this.enableFeild = true;
        this.otpOpen = true;
        // const dateParts = details.inforamtion['@attributes']['dob'].split('-');
        // const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
        // this.profile.dateOfBirthUi = new Date(formattedDate);
        this.profile.dateOfBirthUi = new Date(this.profile.dateOfBirth);
        this.profile.landmark = this.profile.area

        this.profile.fileNameOne = await this.getImage(
          this.profile.fileNameOne
        );
        this.spinner.hide()
      },
      () => {
        this.spinner.hide()
        this.onError();
        this.hideDialog();
      }
    );
  }

  getImage(image: string): Promise<any> {
    return new Promise<any>((resolve) => {
      const binaryString = atob(image);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'image/jpeg' });
      const formData = new FormData();
      formData.append('file', blob, 'profile');
      this.paymentService
        .uploadImage(formData)
        .subscribe((res: HttpResponse<any>) => {
          resolve(res.body.fileName);
        });
    });
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  hideDialog() {
    this.otpOpen = false;
    this.enableFeild = false;
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.panError = false;
    this.pinError = false;
    this.showBMobile = false;
    this.aadhaarError = false;
    this.profile = new AddProfile();
    this.ngSelectInvalid = false;
    this.profileDialog = false;
    this.resendotp=false;
    this.sendotp=false;
    this.otpButtonName="Send OTP";
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(
      this.profile.mobileNo
    );
    !this.profile.mobileNo ? (this.showMobileError = false) : true;
    return !this.showMobileError && !!this.profile.mobileNo;
  }

  validateEmail() {
    if(this.profile.emailId){
      this.emailError = !this.validationService.isEmailValid(
        this.profile.emailId
      );
      !this.profile.emailId ? (this.emailError = false) : true;
      return !this.emailError;
    }
    else{
      return true;
    }
    
  }

  validatePan() {
    if(this.profile.panNo){
      this.panError = !this.validationService.isPanValid(this.profile.panNo);
      !this.profile.panNo ? (this.panError = false) : true;
      return !this.panError
    }
    else{
      return true;
    }
    
  }

  validatePin() {
    this.pinError = !this.validationService.isPincodeValid(this.profile.pinCode);
    !this.profile.pinCode ? (this.pinError = false) : true;
    return !this.pinError && !!this.profile.pinCode;
  }

  validateAotp() {
    this.otpError = !this.validationService.isOtpValid(this.profile.otp);
    !this.profile.otp ? (this.otpError = false) : true;
    // return !this.showMobileError && !!this.profile.otp;
  }

  validateAadhaar() {
    this.aadhaarError = !this.validationService.isAadhaarValid(
      this.profile.aadhaarNo
    );
    !this.profile.aadhaarNo ? (this.aadhaarError = false) : true;

   
    // return !this.showMobileError && !!this.profile.aadhaarNo;
  }

  saveProfile() {
    const isFormValid =
      this.validateMobile() &&
      this.validateEmail() &&
      this.validatePan() &&
      this.profile.mobileNo &&
      this.profile.maritalStatusDto &&
      this.profile.dateOfAssumingOffice
      
    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    } else {
      this.ngSelectInvalid = false;
      this.profile.adminUserDtoCreatedby = {
        id: 1,
      };
      if(this.rolecode != 'DC2' && this.rolecode != 'BC2' 
      && this.rolecode != 'VC2'  && this.rolecode != 'SA'){
        this.profile.categoryReservationDto=null;
        this.profile.communityDto=null;
      }
      this.ProfileService.save(this.profile).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.profileDialog = false;
          // this.loadPage(this.page);
          window.location.reload();
          this.router.navigate(['/home']);
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
    }
  }
  onSelectRow($event) {
    if ($event.type === 'select') {
      this.ProfileService.getProfile($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.profile = res.body;
          this.profileDialog = true;
          this.enableFeild = true;
          if (this.profile.dateOfBirth) {
            this.profile.dateOfBirthUi = new Date(this.profile.dateOfBirth);
          }
          if (this.profile.termValidUpTo) {
            this.profile.termValidUpTo = new Date(this.profile.termValidUpTo);
          }
          if (this.profile.dateOfAssumingOffice) {
            this.profile.dateOfAssumingOffice = new Date(
              this.profile.dateOfAssumingOffice
            );
          }
          this.loadValue();
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.ProfileService.delete($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }
}

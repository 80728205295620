import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
export type EntityArrayResponseType = HttpResponse<any>;
import { Agency, AgencyDto } from './payment';
import { EncryptService } from 'src/app/shared/encrypt.service';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(private http: HttpClient,private encryptService:EncryptService) { }

  protected resourceSaveUrl = environment.SERVER_API_URL + ('api/benificiaryLandDetail/getList');
  protected paymentUrl = environment.SERVER_API_URL + ('api/benificiaryLandOwner/updateLaoStatus');
  protected paymentRejectUrl = environment.SERVER_API_URL + ('api/benificiaryLandOwner/rejectVoucher');
  protected recommendVoucher = environment.SERVER_API_URL + ('api/benificiaryLandOwner/recommendForPayment');
  protected approveDDVoucherUrl = environment.SERVER_API_URL + ('api/benificiaryLandOwner/approveCourt');
  protected rejectDDVoucherUrl = environment.SERVER_API_URL + ('api/benificiaryLandOwner/rejectCourt');
  protected recommendDDVoucherUrl = environment.SERVER_API_URL + ('api/benificiaryLandOwner/getDataBasedOnVoucherNo');
  protected emudraRequestUrl = environment.SERVER_API_URL+('api/benificiaryLandOwner/emuthraCourtRequest'); 
  protected downloadpdfUrl = environment.SERVER_API_URL+('api/benificiaryLandOwner/getDownloadPdf'); 
  protected viewFileUrl = environment.SERVER_API_URL + ('api/viewFile');
  protected deleteFileUrl = environment.SERVER_API_URL + ('api/deleteFile');
  protected uploadImageUrl = environment.SERVER_API_URL + ('api/uploadFile');
   

  beneficiary(parmas): Observable<HttpResponse<any>> {

    let params = new HttpParams()
    .set('surveyNumber', parmas.sNo != undefined ? parmas.sNo :'' )
    .set('name', parmas.name != undefined ? parmas.name :'')
    .set('mobileNumber', parmas.mobileNumber != undefined ? parmas.mobileNumber :'')

    return this.http.get<AgencyDto>(`${this.resourceSaveUrl}`, { observe: 'response',params });
  }
  payment(parmas): Observable<HttpResponse<any>> {

    let params = new HttpParams()
    .set('voucherNo', parmas.voucherNo != undefined ? parmas.voucherNo :'' )
    .set('name', parmas.name != undefined ? parmas.name :'')
    .set('mobileNumber', parmas.mobileNumber != undefined ? parmas.mobileNumber :'')
    
    return this.http.get<AgencyDto>(`${this.paymentUrl}`, { observe: 'response',params });
  }

  save(beneficiary?: any): Observable<HttpResponse<any>> {
    let val={requestPayload:this.encryptService.encryptData(JSON.stringify(beneficiary))}
    return this.http
      .post<any>(`${this.paymentUrl}`, val, { observe: 'response' })
  }

  recommend(projectDto:any,pId){
    let val={requestPayload:this.encryptService.encryptData(JSON.stringify(projectDto))}
    return this.http
    .post<any>(`${this.recommendVoucher}?project=${pId}`, val, { observe: 'response' })
  }

  reject(voucherNo:string){
    return this.http
    .post(`${this.paymentRejectUrl}?voucherNo=${voucherNo}`,[], { observe: 'response',responseType:'text' })
  }
  rejectCourtDD(voucherNo){
    return this.http.get(`${this.rejectDDVoucherUrl}?voucherNo=${voucherNo}`,{observe:'response' ,responseType:'text'})
    }

approveCourtDD(voucherNo){
return this.http.get(`${this.approveDDVoucherUrl}?voucherNo=${voucherNo}`,{observe:'response',responseType:'text'})
}

recommendCourtDD(voucherNo){
  return this.http.get(`${this.recommendDDVoucherUrl}?voucherNo=${voucherNo}`,{observe:'response'})
  }
  getEmudraCourtrequest(fileName:string){
    
    return this.http.get(`${this.emudraRequestUrl}?fileName=${fileName}`,{observe: 'response'  })
  }
  downloadPdf(data){
    return this.http
    .post(`${this.downloadpdfUrl}?voucherNo=${data}`,[], { observe: 'response',responseType: 'arraybuffer' })
  }
  uploadImage(image) {
    // let val=this.encryptionService.encryptData(image);
    // //console.log(val)
    return this.http.post<any>(this.uploadImageUrl, image, { observe: 'response' })

  }
  viewimage(fileName: string): any {
    return this.http.get(`${this.viewFileUrl}/${fileName}`, { observe: 'response' });
  }
  deleteimage(fileName: string): any {
    return this.http.get(`${this.deleteFileUrl}/${fileName}`, { observe: 'response' });
  }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '../../shared/font-service/font.service';
import { ValidationService } from '../../shared/validation/validation.service';
import { NotificationService } from '../../alert/notification.service';
import { Dialog } from 'primeng/dialog';
import { SearchResult } from '../../shared/models/search-result';
import { Project } from '../../project/project.model';
import { ProjectService } from '../../project/project.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-stage2',
  templateUrl: './stage2.component.html',
  styleUrls: ['./stage2.component.scss']
})
export class Stage2Component implements OnInit {
  @ViewChild('dialog') dialog: Dialog;

  projectDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Project> = new SearchResult<Project>();
  checked: boolean;
  project?: Project;
  projectFilter!: string;
  fundFilter: any = ''
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any;
  commercialList: Project[];
  stage2SearchFilter: any = {
    project: '',

  }
  readonly: boolean;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  requestingDepartment: any = ''
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  projectDistrict
  today: Date;
  bankName: any;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showCompanyNameError: string;
  showPanError: string;
  showeEmailError: string;
  showAadhaarNoError: string;
  showGstError: string;
  showaccountNoError: string;
  bankList: any;
  villageLevelDto: null;
  user: any;
  isFile: boolean;
  vFileExt: any;
  viewDoc: boolean;
  vFileUrl: string;
  enablepublicContributionAmount: boolean = false;
  publicContributionAmount = [
    {
      id: '1',
      name: '1/3',
      value: 0.33,
    },
    {
      id: 2,
      name: '1/5',
      value: 0.2,
    },
    {
      id: 3,
      name: '1/2',
      value: 0.5,
    },
  ];
  selectedpublicContribution: any;
  enableView: boolean = false;
  blockLevelDto: null;
  limitAmount: number;
  viewOnly: boolean = false;
  agencyList: any;
  edit: boolean;
  projectForm: FormGroup;
  hamHeader: string;
  subHeader: string;
  projectList: any;
  landUnits: any;
  AgencyLists: any;
  getOneProject: any;
  state: any[];
  district: any[];
  date: Date | undefined;

  LAOAdmin: any[];
  singleProject: any;
  isShow: boolean = false;
  remainValue: number;
  disableButton: boolean = true;
  items: any;
  activeIndex: number;
  totalCount: any;
  fileUpload: any;
  pageCount: number = 0;
  index: any;
  showError: boolean = false;
  roleId: boolean = false;
  districtId: any[];
  label: string;
  projectId: any;
  districtIds: any;
  deletedProject: any[];
  editContent: any;
  deletePayload: any;
  pageLength: number;
  isShowTable: boolean = false;
  totalLandPrivate: any;
  throughAct: number;
  taluk: any[];
  villages: any[];
  talukId: any;
  villageID: any;
  projectName: any;
  districkList: any;
  selectDistrictId: any;
  tamilnilams: any;
  extend: any;
  asPerAct: any;
  asPerPrivate: any;
  asPerActCost: any;
  asPerPrivateNegoe: any;
  totalCompensation: any;
  RemainLand: any;
  RemainAmount: any;
  tamilNilam: any;
  typeSurveyNo: any;
  typeSubDivision: any;
  selectDistrictCode: any;
  talukCode: any;
  villageCode: any;
  constructor(
    private _decimalPipe: DecimalPipe,

    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    public datepipe: DatePipe,

    protected notificationService: NotificationService,
    private projectService: ProjectService
  ) {
    this.translateService.onLangChange.subscribe(() => {
    });
  }


  typeOfFund = [
    {
      name: 'Project with Amount',
      value: 'PROJECT_WITH_AMOUNT',
    },
    {
      name: 'Project without Amount',
      value: 'PROJECT_WITHOUT_AMOUNT',
    },

  ];

  getProjects() {
    this.projectService.getProjectList().subscribe(
      (res: any) => {

        this.projectList = res.body.data;

      },
      () => {
        // this.onError();
      }
    );
  }


  surveyNo(e, i) {
    // this.typeSurveyNo = e.target.value
    // if (this.typeSurveyNo && this.typeSubDivision) {
    //   let obj = {
    //     districtCode: this.selectDistrictCode,
    //     talukCode: this.talukCode,
    //     villCode: this.villageCode,
    //     surveyNo: this.typeSurveyNo,
    //     subdivNo: this.typeSubDivision,
    //     lgd: true
    //   }
    //   this.projectService.tamilNilams(obj).subscribe(
    //     (res: any) => {
    //       this.tamilNilam = res.body;
    //       this.detailsOfLand.controls[i].get('tamilnilam').setValue(this.tamilNilam.aReg.extAres);
    //     },
    //     () => {
    //       // this.onError();
    //     }
    //   );
    // }
  }

  subDivision(e, i) {
    // this.typeSubDivision = e.target.value
    // if (this.typeSurveyNo && this.typeSubDivision) {
    //   let obj = {
    //     districtCode: this.selectDistrictCode,
    //     talukCode: this.talukCode,
    //     villCode: this.villageCode,
    //     surveyNo: this.typeSurveyNo,
    //     subdivNo: this.typeSubDivision,
    //     lgd: true
    //   }
    //   this.projectService.tamilNilams(obj).subscribe(
    //     (res: any) => {
    //       this.tamilNilam = JSON.parse(res.body);
    //       if (this.tamilNilam == null || this.tamilNilam == '') {
    //         this.notificationService.alertError('Please provide valid survey and subdivision number','Error');
    //       } else {
    //         this.detailsOfLand.controls[i].get('tamilnilam').setValue(this.tamilNilam.aReg.extAres);
    //       }
    //     },
    //     () => {
    //       this.notificationService.alertError('Please provide valid survey and subdivision number','Error');
    //     }
    //   );
    // }
  }
  getTaluksAndViilages() {
    this.projectService.getTalukAndVillages().subscribe(
      (res: any) => {

        this.projectList = res.body.data;
      },
      () => {
        // this.onError();
      }
    );
  }
  getExtent(name) {
    if (name == 'projectName') {
      this.stage2SearchFilter.district = '',
        this.stage2SearchFilter.taluk = '',
        this.stage2SearchFilter.village = '',
        this.stage2SearchFilter.totalExtent = ''
    }
    else if (name == 'district') {
      this.stage2SearchFilter.taluk = '',
        this.stage2SearchFilter.village = '',
        this.stage2SearchFilter.totalExtent = ''
    } else if (name == 'taluk') {
      this.stage2SearchFilter.village = ''
      this.stage2SearchFilter.totalExtent = ''
    } else if (name == 'village') {
      this.stage2SearchFilter.totalExtent = ''
    }

    let filterQuery = {
      project: this.stage2SearchFilter.project.id,
      district: this.stage2SearchFilter.district.id,
      taluk: this.stage2SearchFilter.taluk.id,
      village: this.stage2SearchFilter.village.id,
    }
    if (filterQuery.project != undefined && filterQuery.district && filterQuery.taluk && filterQuery.village) {
      this.projectService.stage2Filter(filterQuery).subscribe((res: any) => {
        this.stage2SearchFilter.totalExtent = res.body.data.totalExtent
        this.isShowTable = true
      })
      this.projectService.stage2Remind(filterQuery).subscribe((res: any) => {
        this.RemainLand = res.body.data.alreadyTotalExtent
        this.RemainAmount = res.body.data.alreadyTotalCost
      })
    }

  }
  submit() {
    let beneficaryData = this.projectForm.value
    let landFrom = []
    beneficaryData.LandDetailsForm.forEach(element => {

      let data = {
        "projectId": this.projectId,
        "surveyNumber": element.surveyNo,
        "subDivisionNumber": element.subdivisionNo,
        "totalExtentAsPerTamilnilam": element.tamilnilam,
        "acquiredExtent": element.acquireAsAward,
        "asPerActExtent": element.asPerAct,
        "asPerPrivateNegotiation": element.asPerPrivateNego,
        "asPerActCost": element.asPerActCost,
        "asPerPrivateNegotiationCost": element.asPerPrivateNegoCost,
        "partSubDivisionNumber": element.partSubdivisionNo,
        "totalCompensation": Number(element.totalCompensation),
        "district": this.selectDistrictId,
        "taluk": this.talukId,
        "village": this.villageID
      }
      landFrom.push(data)
    });
    this.projectService.saveProjectSurvey(landFrom).subscribe(
      (res) => {
        this.notificationService.alertSuccess('Details of Determination of Compensation- Survey Number wise upated Sucessfully!', '');
        this.forms();
        this.addSkills();
        this.router.navigateByUrl('/stage2-view')
      },
      (err) => {
        this.notificationService.alertError('Error!', err.error.error);
        this.spinner.hide();
      }
    );

  }




  keyPressNumbersDecimal(event, lable) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      if (lable == 'land') {
        var charCode = event.which ? event.which : event.keyCode;
        // Only Numbers 0-9
        let text = event.target.value
        if (text.includes('.')) {
          let decimals = text.split('.');
          if (decimals[1].length >= 4) {
            event.preventDefault();
            return false;
          } else {
            return true;
          }
        }
      } else {
        var charCode = event.which ? event.which : event.keyCode;
        // Only Numbers 0-9
        let text = event.target.value
        if (text.includes('.')) {
          let decimals = text.split('.');
          if (decimals[1].length >= 2) {
            event.preventDefault();
            return false;
          } else {
            return true;
          }
        }
      }
    }
    return true;
  }



  SelectDistrict(e) {
    this.selectDistrictId = e.id
    this.selectDistrictCode = e.code;
    this.projectService.talukAndVillageforStage2(this.projectId, this.selectDistrictId, '', '').subscribe((res: any) => {
      this.taluk = res.body.data
    })
  }


  selectProject(e) {
    this.projectName = e.projectName
    this.projectId = e.id
    this.projectService.talukAndVillageforStage2(e.id, '', '', '').subscribe((res: any) => {
      this.districkList = res.body.data
    })
  }

  OnSelectTaluk(e) {
    this.talukId = e.id
    this.talukCode = e.code;
    this.projectService.talukAndVillageforStage2(this.projectId, this.selectDistrictId, this.talukId, '').subscribe((res: any) => {
      this.villages = res.body.data
    })

  }

  OnSelectVillage(e) {
    this.villageID = e.id
    this.villageCode = e.code


  }
  ngOnInit() {
    this.forms();
    this.addSkills();
    this.getProjects()
    // this.getTaluksAndViilages();

    this.projectService.getAgency().subscribe((res: any) => {
      this.AgencyLists = res.body.data;
    });
    if (this.router.url == '/project') {
      this.hamHeader = 'Projects';
      this.subHeader = 'Home  /   Projects';
    }
    // this.loginService.getuserDto().subscribe(
    //   (res) => {
    //     this.user = res.body;
    //   },
    //   (onError) => {}
    // );
    this.cols = [
      {
        field: 'projectName',
        header: 'Name of the project',
        isSortable: true,
        isFilterable: true,
        width: 10,
        isSelectcolumn: true,
      },
      {
        field: 'agencyId',
        header: 'Requesting Agency',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
        type: 'dropDowns',
      },
      {
        field: 'landUnitId',
        header: 'Lant Unit',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'type',
      },

      {
        field: 'tentativeLandCost',
        header: 'Project Amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'createdOn',
        header: 'Created On',
        jhiTranslate: 'tableHead.createdon',
        isSortable: true,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
  }
  removeSkill(i: number) {
    this.detailsOfLand.removeAt(i);
  }

  getUserrole() {

  }

  tamilnilam(e, val, i) {
    if (val == 'acquire') {
      let data = Number(e.target.value) * 10000
      let sample = this._decimalPipe.transform(data, "1.1-1")
      this.detailsOfLand.controls[i].get('acquireAsASquare').setValue(sample);
    }
    let tamilnilam = 0
    let extend = 0
    let asPerAct = 0
    let asPerPrivate = 0
    let asPerActCost = 0
    let asPerPrivateNegoe = 0
    let totalCompensation: number = 0


    this.detailsOfLand.value.forEach((element) => {
      console.log(element.totalCompensation);

      tamilnilam += Number(element.tamilnilam);
      extend += Number(element.acquireAsAward);
      asPerAct += Number(element.asPerAct);
      asPerPrivate += Number(element.asPerPrivateNego);
      asPerActCost += Number(element.asPerActCost);
      asPerPrivateNegoe += Number(element.asPerPrivateNegoCost);
      totalCompensation += Number(element.totalCompensation);
    });
    this.tamilnilams = tamilnilam
    this.extend = extend
    this.asPerAct = asPerAct
    this.asPerPrivate = asPerPrivate
    this.asPerActCost = asPerActCost
    this.asPerPrivateNegoe = asPerPrivateNegoe
    this.totalCompensation = Number(totalCompensation)
    // this.totalLandPrivate = totalLand
    // this.throughAct = act
  }

  detailsform(): FormGroup {
    return this.formBuilder.group({
      surveyNo: ['', Validators.required],
      subdivisionNo: ['', Validators.required],
      partSubdivisionNo: [''],
      tamilnilam: ['', Validators.required],
      acquireAsAward: ['', Validators.required],
      acquireAsASquare: ['', Validators.required],
      asPerAct: ['', Validators.required],
      asPerPrivateNego: ['', Validators.required],
      asPerActCost: ['', Validators.required],
      asPerPrivateNegoCost: ['', Validators.required],
      totalCompensation: ['', Validators.required]
    });
  }

  addSkill(i) {
    let totalLand: number = 0;
    let form = this.projectForm.valid
    if (form) {
      this.detailsOfLand.push(this.detailsform());
    }
  }

  forms() {
    this.projectForm = this.formBuilder.group({
      LandDetailsForm: this.formBuilder.array([]),
    })
  }

  getTotal(i) {
    let privateNegotitaion: any = Number(this.detailsOfLand.controls[i].get('asPerActCost').value);
    let throughAct: any = Number(this.detailsOfLand.controls[i].get('asPerPrivateNegoCost').value)
    let total: any = String(privateNegotitaion + throughAct)
    let format = ''
    if (privateNegotitaion != '' || throughAct != '') {
      if (total.includes('.')) {
        let split = total.split('.');
        let split1 = split[0];
        let split2 = split[1].slice(0, 2)
        let final = split1 + '.' + split2
        let finalValue = Number(final)
        this.detailsOfLand.controls[i].get('totalCompensation').setValue(finalValue);
      } else {
        this.detailsOfLand.controls[i].get('totalCompensation').setValue(Number(total));
      }
    }
  }

  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  addlandDetails() {
    let totalLand: number = 0;
    this.detailsOfLand.value.forEach((element) => {
      totalLand += Number(element.totalCompensation);
    });
    if (totalLand != 0) {
      if (totalLand == this.stage2SearchFilter.totalExtent) {
        this.notificationService.alertInfo(
          'Equal',
          'Acquired land in the district is equal to total amount of land'
        );
      } else {
        this.detailsOfLand.push(this.detailsform());
      }

    }
  }

  // getTotal(i) {
  //   console.log(i);
  //   let privateNegotitaion: any = Number(this.detailsOfLand.controls[i].get('privateNegotitaion').value);
  //   let throughAct: any = Number(this.detailsOfLand.controls[i].get('throughAct').value)
  //   let total: any = privateNegotitaion + throughAct
  //   if (privateNegotitaion != '' && throughAct != '') {
  //     console.log(privateNegotitaion)
  //     this.detailsOfLand.controls[i].get('total').setValue(total);

  //   }
  //   let totalLand: number = 0;
  //   let act: number = 0
  //   this.detailsOfLand.value.forEach((element) => {
  //     totalLand += Number(element.privateNegotitaion);
  //     act += Number(element.throughAct);
  //   });

  //   this.totalLandPrivate = totalLand
  //   this.throughAct = act
  // }


  get detailsOfLand(): FormArray {
    return this.projectForm.get('LandDetailsForm') as FormArray;
  }

  addSkills() {
    this.detailsOfLand.push(this.detailsform());
  }

  ngAfterViewInit() {
  }
  ngOnDestroy(): void {
    this.spinner.hide();

  }
}
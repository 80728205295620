<div class="hamburger">
    <h2>Status Request</h2>
</div>
<div class="container-fluid bg">
    <div class="tableDesign">
        <div class="row">
            <div class="col-md-12">
                <p-toolbar styleClass="toolbar">
                    <ng-template pTemplate="left">
                        <h5>
                            Status Request
                        </h5>
                    </ng-template>
                    <ng-template pTemplate="right">
                    </ng-template>
                </p-toolbar>
            </div>
        </div>
        <div class="row mt-3">
            <div #customerTable id="entities" class="col-md-12  px-5">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Application Number</th>
                            <th scope="col">Financial
                                year </th>
                            <th scope="col">Total funds requested
                                (In Rupees)</th>
                            <th scope="col">Status of Request</th>

                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of statusReq;let i =index">
                            <td>{{generateIndex(i)}}</td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                {{data.laoAllocateAmount}}
                            </td>
                            <td>
                                {{data.status}}
                            </td>
                        </tr>
                    </tbody>

                </table>
                <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)" [totalRecords]="totalCount" [rowsPerPageOptions]="[10,20,30]"></p-paginator>




            </div>
        </div>
    </div>


    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>
<div class="container">
    <div class="row mb-3 align-items-center">
        <div class="col-md-6">
            <h3>Return Payments</h3>
        </div>
        <div class="col-md-6 text-end d-flex gap-2">
            <input pInputText type="text" [(ngModel)]="searchVoucher" placeholder="Search Voucher No"
                class="p-inputtext p-component search-box" />
            <button pButton pRipple type="button" icon="pi pi-search" (click)="loadPayments()"></button>
            <button pButton pRipple type="button" icon="pi pi-times"
                (click)="searchVoucher = ''; loadPayments()"></button>
        </div>
    </div>
    <!-- (onLazyLoad)="loadPayments($event)"" -->
    <div class="row mt-4">
        <p-table #dt [value]="filteredPayments" [totalRecords]="totalRecords" stateStorage="local"
            stateKey="return-payments-table" [scrollable]="true" scrollHeight="500px" dataKey="id" editMode="row">
            <ng-template pTemplate="header">
                <tr>
                    <th>District</th>
                    <th>Taluk</th>
                    <th>Village</th>
                    <th>Project</th>
                    <th>Voucher No</th>
                    <th>Type</th>
                    <th>Beneficiary Name</th>

                    <th>Amount</th>
                    <th>Account No</th>
                    <th>IFSC Code</th>
                    <th>Payment Date</th>
                    <th>UTR No</th>
                    <th *ngIf = "roleId && enableStatepayment" >Actions</th>
                    <th>Status</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-payment let-i="rowIndex">
                <tr pEditableRow [pEditableRow]="payment">
                    <td>{{ payment.district }}</td>
                    <td>{{ payment.taluk }}</td>
                    <td>{{ payment.village }}</td>

                    <!-- Editable Fields -->
                    <td>{{ payment.projectName }}</td>
                    <td>{{ payment.voucherNumber }}</td>
                    <td>{{ payment.type }}</td>
                    <!-- <td> -->
                        <!-- <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="payment.beneficiaryName" />
                            </ng-template>
                            <ng-template pTemplate="output"> -->
                        <!-- {{ payment.beneficiaryName }} -->
                        <!-- </ng-template>
                        </p-cellEditor> -->
                    <!-- </td> -->

                    <td *ngIf="editfeild && roleId">
                        <input pInputText type="number" [(ngModel)]="payment.beneficiaryName" />
                    </td>
                    <td *ngIf="!editfeild"> {{ payment.beneficiaryName }} </td>


                    <td>
                        <!-- <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="number" [(ngModel)]="payment.beneficiaryAmount" />
                            </ng-template>
                            <ng-template pTemplate="output"> -->
                        ₹{{ payment.beneficiaryAmount |indianRupee }}
                        <!-- </ng-template>
                        </p-cellEditor> -->
                    </td>

                    <td *ngIf="editfeild && roleId">
                        <input pInputText type="number" [(ngModel)]="payment.accountNo" />
                    </td>
                    <td *ngIf="!editfeild"> {{ payment.accountNo }} </td>

                    <td *ngIf="editfeild && roleId">
                        <input pInputText type="text" [(ngModel)]="payment.ifscode" />
                    </td>
                    <td *ngIf="!editfeild"> {{ payment.ifscode }}</td>

                    <!-- <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="payment.ifscode" />
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{ payment.ifscode }}
                            </ng-template>
                        </p-cellEditor>
                    </td> -->

                    <td>{{ payment.transDate | date: 'yyyy-MM-dd' }}</td>
                    <td>{{ payment.utrNo }}</td>

                    <!-- Actions -->
                    <td *ngIf = "payment.returnStatus === 0 && roleId">
                        <button *ngIf="!editingRow[payment.id]" pButton pRipple type="button" icon="pi pi-pencil"
                            (click)="onRowEditInit(payment)" text rounded></button>

                        <button *ngIf="editingRow[payment.id]" pButton pRipple type="button" icon="pi pi-check"
                            (click)="onRowEditSave(payment, i)" text rounded></button>

                        <button *ngIf="editingRow[payment.id]" class="mx-3" pButton pRipple type="button"
                            icon="pi pi-times" (click)="onRowEditCancel(payment)" text rounded></button>
                        </td>
                        <td>

                            <button  *ngIf="payment.returnStatus === 0 && state"  class="btn btn-success p-2" (click)="onClickApprove(payment)">
                                Approve
                            </button>

                            <button  *ngIf="payment.returnStatus === 1 && maker"  class="btn btn-success p-2" (click)="onClickApprove(payment)">
                                Approve
                            </button>
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>

    <button *ngIf = "enablepayment" pButton pRipple type="button" icon="pi pi-check" (click)="OnSave()" text rounded>save</button>

    <p-dialog #dialog [(visible)]="signaturedialog" [modal]="true" styleClass="ib-dialog" [style]="{ width: '850px' }"
        (onHide)="hideDialog()">
        <p-header [ngClass]="fontService.headingClass">
            Implement FingurPrint
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <!-- src/app/biometric-registration/biometric-registration.component.html -->
                        <app-biometric-test [scannerReady]="scan" [returnPayment]="true"
                            [returnPaymentRequestDto]=filteredPayments [registrationStatusField]="figureData">
                            <!-- <div *ngIf="scannerReady">
          <h2  class="mb-2">Scanner is ready</h2>
          <button (click)="registerFingerprint()">Register Fingerprint</button>
          <div *ngIf="registrationStatus">
            <p>{{ registrationStatus }}</p>
          </div>
        </div>
        <div *ngIf="!scannerReady">
          <h2>Scanner is not ready</h2>
        </div> -->
                        </app-biometric-test>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button icon="pi pi-times" class="btn btn-primary" (click)="hideDialog()">
                <span class="button-label" [ngClass]="fontService.buttonClass" custom-button>Cancel
                </span>
            </button>
        </ng-template>
    </p-dialog>
</div>
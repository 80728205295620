import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class BankDetailsService {
  protected getBankdetailsUrl = environment.SERVER_API_URL + ('api/fetchBankInfoByIfscCode');
  constructor(protected http: HttpClient) { }
  getBankDetails(data): Observable<any> {
    // console.log("working")
    return this.http.get<any>(`${this.getBankdetailsUrl}?ifscCode=${data}`, { observe: 'response' })
  }
}

import { AfterViewInit, Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PersonalVendorService } from './personal-vendors.service';
import { PersonalVendors, PersonalVendorsDto, ValidationResponse } from './personal-vendors';
import { SearchResult } from "src/app/shared/models/search-result";
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-personal-vendors',
  templateUrl: './personal-vendors.component.html',
  styleUrls: ['./personal-vendors.component.scss']
})
export class PersonalVendorsComponent implements OnInit, AfterViewInit {

  @ViewChild('dialog') dialog: Dialog;
  @ViewChildren(NgSelectComponent) ngSelectComponents: QueryList<NgSelectComponent>;

  personalVendorsDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<PersonalVendors> = new SearchResult<PersonalVendors>();
  personalVendor?: PersonalVendorsDto;
  selectedPersonalVendors: PersonalVendors[] = [];
  submitted?: boolean;
  selectedMapping: string;
  checked: boolean;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  validationRes:ValidationResponse;

  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError=false;
  public showBMobile = false;
  public aadhaarError = false;
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  public verifyClicked:boolean;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showStaffError: string;
  showMobileUniError: string;
  emailUniError: string;
  aadhaarNoUniError: string;
  panNoUniError: string;
  accountNoUniError: any;
  bankList: any;
  bankBranchList: any;
  bankBranch: any;
  accountInvalidError:any;
  readonly:boolean;
  @HostListener('document:wheel', ['$event'])
  onWheel(event: WheelEvent) {
    const targetElement = event.target as HTMLElement;
    const isInsideNgSelect = targetElement.closest('.ng-option');
    if (!isInsideNgSelect) {
      this.ngSelectComponents.forEach((ngSelect: NgSelectComponent) => {
      ngSelect.close();
    });
  }
}


  constructor(
    private personalVendorService: PersonalVendorService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.loadPage(1)
    this.cols = [
      { field: 'name', header: 'Name', jhiTranslate: 'tableHead.name', isSortable: true, isFilterable: true, width: 10, isSelectcolumn: true },
      { field: 'designation', header: 'Designation',jhiTranslate: 'tableHead.designation', isSortable: true, isFilterable: true, width: 25, isSelectcolumn: true },
      { field: 'panNo', header: 'Pan No', jhiTranslate: 'tableHead.panno', isSortable: true, isFilterable: true, width: 15, isSelectcolumn: true },
      { field: 'accountNumber', header: 'Account No', jhiTranslate: 'tableHead.account.number', isSortable: false, isFilterable: true, width: 13, isSelectcolumn: true },
      { field: 'aadhaarNo', header: 'Aadhaar No', jhiTranslate: 'tableHead.aadhaarno', isSortable: false, isFilterable: true, width: 13, isSelectcolumn: true },
      { field: 'createdOn', header: 'Created On', jhiTranslate: 'tableHead.createdon', isSortable: true, isFilterable: true, width: 13, isSelectcolumn: true,type: 'date' },
    ];
    this.updateDialogFont();
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  openNew() {
    this.personalVendor = new PersonalVendorsDto();
    this.submitted = false;
    this.personalVendorsDialog = true;
    this.readonly=false;
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
        
      ];
      if (this.personalVendor.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.personalVendor.id,
        });
      }
      this.personalVendorService.validFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<PersonalVendors[]>) => {
          resolve(res.body);
        },
        () => {
          this.onError();
          resolve(null);
        }
      );
    });
  }

  addresschange(check?) {

    if (this.checked) {
      this.personalVendor.companyDoorNo = this.personalVendor.doorNo
      this.personalVendor.companyStreet = this.personalVendor.street
      this.personalVendor.companyArea = this.personalVendor.area
      this.personalVendor.companyCity = this.personalVendor.city
      this.personalVendor.companyDistrict = this.personalVendor.district
      this.personalVendor.companyState = this.personalVendor.state
      this.personalVendor.companyPinCode = this.personalVendor.pinCode
    }
    else {
      if (!check) {
        this.personalVendor.companyDoorNo = null
        this.personalVendor.companyStreet = null
        this.personalVendor.companyArea = null
        this.personalVendor.companyCity = null
        this.personalVendor.companyDistrict = null
        this.personalVendor.companyState = null
        this.personalVendor.companyPinCode = null
      }

    }
  }

  async checkName(){
    const filterData = await this.getfilterdata('name', this.personalVendor.name);
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }

  async checkstaffId(){
    const filterData = await this.getfilterdata('staffId', this.personalVendor.staffId);
    if (filterData.length !== 0) {
      this.showStaffError = 'Staff Already Exists';
    } else {
      this.showStaffError = null;
    }

  }

  async checkmobile(){
    const filterData = await this.getfilterdata('mobile', this.personalVendor.mobile);
    if (filterData.length !== 0) {
      this.showMobileUniError = 'Mobile No Already Exists';
    } else {
      this.showMobileUniError = null;
    }
  }

  async checkemail(){
    const filterData = await this.getfilterdata('email', this.personalVendor.email);
    if (filterData.length !== 0) {
      this.emailUniError = 'Email Already Exists';
    } else {
      this.emailUniError = null;
    }

  }

  async checkaadharNo(){
    const filterData = await this.getfilterdata('aadhaarNo', this.personalVendor.aadhaarNo);
    if (filterData.length !== 0) {
      this.aadhaarNoUniError = 'Aadhaar No Already Exists';
    } else {
      this.aadhaarNoUniError = null;
    }
  }

  async checkPanNo(){
    const filterData = await this.getfilterdata('panNo', this.personalVendor.panNo);
    if (filterData.length !== 0) {
      this.panNoUniError = 'Pan No Already Exists';
    } else {
      this.panNoUniError = null;
    }
  }

  async checkAccountNo(){
    const filterData = await this.getfilterdata('accountNumber', this.personalVendor.accountNumber);
    if (filterData.length !== 0) {
      this.accountNoUniError = 'account No Already Exists';
    } else {
      this.accountNoUniError = null;
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show()
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      sort: ['createdOn,asc']
    };
    this.filterProps = this.filterProps ? this.filterProps : []
    this.filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    })
    this.personalVendorService
      .filter({ ...filterQuery }, this.filterProps)
      .subscribe(
        (res: HttpResponse<PersonalVendors[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    
  }
  getBankBranchList(event?) {
    this.bankBranch=null;
    let filter = [];
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.personalVendor.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };
    if (event) {
      filter.push({
        key: 'bankBranch',
        operation: 'contains',
        value: event.term.toUpperCase(),
      });

    } else {
    
    }
  }

  getBankBranchdetais() {
    let filter = [];
    filter.push({
      key: 'bankIfsc',
      operation: 'equals',
      value: this.personalVendor.ifscCode.toUpperCase(),
    });
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.personalVendor.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };

    
  }

  getBankIfsc() {
    
    this.personalVendor.bankBranch = this.bankBranch.bankBranch;
   
  }

  accountValidation() {
    this.verifyClicked=true;
    this.spinner.show();
  
  }

  

  protected onSuccess(
    data: PersonalVendors[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide()
    this.page = page;
    if (navigate) {
      this.router.navigate(["/personal-vendors"], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,

        },
      });
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.panError = false;
    this.pinError = false;
    this.showBMobile = false;
    this.aadhaarError = false;
    this.tanError = false;
    this.tinError = false;
    this.gstError = false;
    this.ifscError = false;
    this.personalVendor = new PersonalVendorsDto();
    this.ngSelectInvalid = false;
    this.accountInvalidError=false;
    this.verifyClicked=true;
    this.personalVendorsDialog = false;
    this.submitted = false;
    this.bankBranch=null;
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(this.personalVendor.mobile);
    !this.personalVendor.mobile ? this.showMobileError = false : true;

    return !this.showMobileError && !!this.personalVendor.mobile;
  }

  validateEmail() {
    this.emailError = !this.validationService.isEmailValid(this.personalVendor.email)
    !this.personalVendor.email ? this.emailError = false : true;

    return !this.emailError && !!this.personalVendor.email;
  }

  validatePan() {
    this.panError = !this.validationService.isPanValid(this.personalVendor.panNo)
    !this.personalVendor.panNo ? this.panError = false : true;

    return !this.panError && !!this.personalVendor.panNo;
  }

  validateTan() {
    this.tanError = !this.validationService.isPanValid(this.personalVendor.tanNo)
    !this.personalVendor.tanNo ? this.tanError = false : true;

    return !this.tanError && !!this.personalVendor.tanNo;
  }

  validateTin() {
    this.tinError = !this.validationService.isPanValid(this.personalVendor.tinNo)
    !this.personalVendor.tinNo ? this.tinError = false : true;

    return !this.tinError && !!this.personalVendor.tinNo;
  }

  validatePin() {
    this.pinError = !this.validationService.isPincodeValid(this.personalVendor.pinCode)
    !this.personalVendor.pinCode ? this.pinError = false : true;

    return !this.pinError && !!this.personalVendor.pinCode;
  }

  validateCPin() {
    this.cPinError = !this.validationService.isPincodeValid(this.personalVendor.companyPinCode)
    !this.personalVendor.companyPinCode ? this.cPinError = false : true;

    return !this.cPinError && !!this.personalVendor.companyPinCode;
  }

  validateAadhaar() {
    this.aadhaarError = !this.validationService.isAadhaarValid(this.personalVendor.aadhaarNo)
    !this.personalVendor.aadhaarNo ? this.aadhaarError = false : true;

    return !this.aadhaarError && !!this.personalVendor.aadhaarNo;
  }

  validateGst() {
    this.gstError = !this.validationService.isPanValid(this.personalVendor.gstNo)
    !this.personalVendor.gstNo ? this.gstError = false : true;

    return !this.gstError && !!this.personalVendor.gstNo;
  }

  validateIfsc() {
    this.ifscError = !this.validationService.isIfscValid(this.personalVendor.ifscCode)
    !this.personalVendor.ifscCode ? this.ifscError = false : true;

    return !this.ifscError && !!this.personalVendor.ifscCode;
  }
  accountDetailsChanged(){
    this.verifyClicked=false;
  }

  savePersonalVendor() {
    const isFormValid =
      this.validateMobile() &&
      this.validatePin() &&
      this.validateAadhaar() &&
      this.validateIfsc() &&
      this.personalVendor.name &&
      this.personalVendor.designation &&
      // this.personalVendor.staffId &&
      this.personalVendor.doorNo &&
      this.personalVendor.street &&
      this.personalVendor.city &&
      this.personalVendor.district &&
      this.personalVendor.state &&
      this.personalVendor.companyDoorNo &&
      this.personalVendor.companyStreet &&
      this.personalVendor.companyDistrict &&
      this.personalVendor.companyCity &&
      this.personalVendor.companyState &&
      // this.personalVendor.bankId &&
      // this.personalVendor.bankBranch &&
      // this.personalVendor.bankName &&
      this.personalVendor.bankAccName &&
      this.personalVendor.accountNumber  &&
      this.personalVendor.ifscCode &&
      !this.accountInvalidError &&
      this.verifyClicked

    if (!isFormValid) {
      
      this.ngSelectInvalid = true;
      return;
    }

    this.ngSelectInvalid = false;
    this.personalVendorService
      .save(this.personalVendor)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.personalVendorsDialog = false;
          this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  onSelectRow($event) {
    if ($event.type === "select") {
      this.personalVendorService
        .getpersonalVendor($event.value.id)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.readonly=true;
            this.personalVendor = res.body
            this.personalVendorsDialog = true;
            if(this.personalVendor.bankName){
              this.getBankBranchList()
              this.getBankBranchdetais()
            }
          },
          () => {
            this.onError();
          }
        );
    }
    else {
      this.personalVendorService
        .delete($event.value.id)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.loadPage(this.page)
          },
          () => {
            this.onError();
          }
        );
    }
  }


}


import { NgModule } from '@angular/core';


import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { DividerModule } from 'primeng/divider';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
//import { EditorModule } from 'primeng/editor';
import { CheckboxModule } from 'primeng/checkbox';
import {BadgeModule} from 'primeng/badge';
import {RatingModule} from 'primeng/rating';
import {CardModule} from 'primeng/card';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';

import {RadioButtonModule} from 'primeng/radiobutton';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import { CustomFilterTableComponent } from './custom-filter-table/custom-filter-table.component';
import {StepsModule} from 'primeng/steps';
import {DockModule} from 'primeng/dock';
import {SlideMenuModule} from 'primeng/slidemenu';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CommonModule } from '@angular/common';
import { TranslateDirectiveDirective } from './language/translate-directive.directive';
import { ErrorComponent } from './error/error.component';
import { FormsModule } from '@angular/forms';
import { TabledatePipe } from './language/tabledate.pipe';
import { GenericErrorComponent } from './error/generic-error/generic-error.component';
import { IndianRupeePipe } from './language/indian-rupee.pipe';
import { NumberonlyDirective } from './directives/numberonly.directive';



// A theme is also required
@NgModule({
  declarations: [
    // shared pipe
    

    // shared component
    TabledatePipe,
    IndianRupeePipe,
    CustomFilterTableComponent,
    TranslateDirectiveDirective,
    ErrorComponent,
    GenericErrorComponent,
    NumberonlyDirective,
    
   
  ],
  imports: [
    FormsModule,
   
    // installed / primeng module
    ButtonModule,
    InputTextModule,
    TabViewModule,
    BreadcrumbModule,
    TableModule,
    OrderListModule,
    DividerModule,
    OverlayPanelModule,
    InputSwitchModule,
    SelectButtonModule,
    DialogModule,
    FileUploadModule,
    CheckboxModule,
    BadgeModule,
    RatingModule,
    CardModule,
    RadioButtonModule,
    CalendarModule,
    InputTextareaModule,
    DropdownModule,
    MultiSelectModule,
    InputNumberModule,
    StepsModule,
    DockModule,
    SlideMenuModule,
    ScrollPanelModule,
    CommonModule,
  ],
  exports: [

    // shared pipe

    // shared component
    CustomFilterTableComponent,
    IndianRupeePipe,
    // TranslateDirective,

    // installed / primeng module
    ButtonModule,
    TabledatePipe,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    TabViewModule,
    BreadcrumbModule,
    TableModule,
    OrderListModule,
    DividerModule,
    OverlayPanelModule,
    InputSwitchModule,
    SelectButtonModule,
    DialogModule,
    CheckboxModule,
    CardModule,
    RadioButtonModule,
    CalendarModule,
    DropdownModule,
    MultiSelectModule,
    StepsModule,
    DockModule,
    SlideMenuModule,
    ScrollPanelModule,
    CommonModule,
    TranslateDirectiveDirective,
  ],
  providers: []
 })
export class SharedModule {}

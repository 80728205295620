import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { Agency, AgencyDto } from '../agency/agency';

import { LevelType } from '../level-type/level-type';
import { LevelTypeService } from '../level-type/level-type.service';
import { ZonalService } from '../zonal/zonal.service';
import { AgencyService } from '../payment-status/payment-status.service';
import { PaymentService } from '../payment/payment.service';
import { stage3Service } from '../stage3/stage3.service';
import { EncryptService } from 'src/app/shared/encrypt.service';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-court-payment-status',
  templateUrl: './court-payment-status.component.html',
  styleUrls: ['./court-payment-status.component.scss']
})
export class CourtPaymentStatusComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;
  // @ViewChild('department', ) department: NgSelectComponent;
  agencyDialog: boolean = false;
  ddDialog:boolean =false;
  cols: any[];
  courtDDData:any=[];
  zonalDto: any;
  searchResult: SearchResult<Agency> = new SearchResult<Agency>();
  agency?: AgencyDto;
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  agencyList
  levelType: LevelType[];
  levelTypeDto: LevelType;
  levelMasterList: any;
  levelMasterDto: any;
  readonly:boolean=false;
  zonalList: any;
  fileUpload: any;
  today:Date=new Date();
  stage1Filter = {
    projectName: '',
    name: '',
    status:''

  }
ddDetails = {
    ddNumber: '',
    ddDate: '',
    depositedOn:'',
    courtName:'',
    fileName:''

  }
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public showGstError = false;
  public showIfscError = false;
  public showBMobile = false;
  //required fields property
  ngSelectInvalid = false;
  showNameError: any;
  showMobileNoError: string;
  showEmailError: string;
  showEmailUniError: string;
  paymentStatus=['Pending','Success']

  gstUniError: string;
  viewOnly: boolean;
  hamHeader: string;
  subHeader: string;
  beneficiary: any = {}
  beneficary: any;
  state: any[];
  district: any[];
  projectList: any;
  beneficiaryList: any[];
  taluk: AgencyDto[];
  villages: AgencyDto[];
  items: { label: string; command: (event: any) => void; }[];
  beneficaryForm: FormGroup;
  districtList: void;
  activeIndex: number;
  singleBeneficiaryList: any;
  label: any;
  splitDistirct: any[];
  fundFilter: any
  totalCount: any;
  pageCount: number = 0;
  paymentList: any;
  pageLength: any;
  index: number;
  sortDirection: any='asc';
  isTableShow: boolean =false;
  isFile: boolean;
  vFileExt: any;
  vFileUrl: string;

  viewDoc: boolean;
  roleCode: string;
  constructor(
    private agencyService: AgencyService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private levelTypeService: LevelTypeService,
    private zonalService: ZonalService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    public formBuilder: FormBuilder,
    protected paymentService:PaymentService,
    protected stageService:stage3Service,
    private encryptService:EncryptService
  ) {
  }
  ngOnInit() {
    this.hamHeader = "Court Payment Status"
    this.subHeader = "Payment  /  Court Payment Status"
    this.roleCode=  this.encryptService.decryptData(localStorage.getItem('roleCodeValue'))
    console.log(this.roleCode)
    this.getProjects()
    this.items = [{
      label: 'Details of acquired land',
      command: (event: any) => {
        this.activeIndex = 0;
        this.messageService.add({ severity: 'info', summary: 'First Step', detail: event.item.label });
      }
    },
    {
      label: 'Account Details',
      command: (event: any) => {
        this.activeIndex = 1;
        this.messageService.add({ severity: 'info', summary: 'Seat Selection', detail: event.item.label });
      }
    },];
   this.loadPage(1)
  }
  ngAfterViewInit() {
    this.updateDialogFont();
  }
  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;
    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  getProjects() {
    this.agencyService.getProjects().subscribe(
      (res: any) => {
        //console.log(res, 'res');

        this.projectList = res.body.data;
        // this.state = stateList.filter(res => res.levelTypeDto.id == 1)
        // this.district = stateList.filter(res => res.levelTypeDto.id == 2)
        // //console.log(stateList, ' this.stateList');
      },
      () => {
        this.onError();
      }
    );
  }

  loadPage(page?: number, params?): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query
    if (params) {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        project: this.stage1Filter.projectName ? this.stage1Filter.projectName:  '',
        sortBy:params,
        status:this.stage1Filter.status ?? '',
        sortDirection:this.sortDirection
      };
      if (this.sortDirection == 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    } else {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        project: this.stage1Filter.projectName ?? '',
        status:this.stage1Filter.status ?? '',
      };
    }

    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);
    this.agencyService.filterCourtDD({ ...query }).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad);
      },
      (err) => {
        this.notificationService.alertError('Error', err.error.error)
        this.onError();
      }
    );
  }
  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }

  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,

  ): void {
//console.log(data)
    this.isTableShow=true;
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.paymentList = data
    this.totalCount = Number(headers.get('X-Total-Count'));

  }

  onPageChange(event: PageEvent) {
    //console.log(event);
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    this.page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.loadPage(this.page);
  }

  protected onError(): void {
    this.spinner.hide();

  }


  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    // this.loadPage(event.page);
  }

  vendorFilter($event) {
    let filter = new filterQuery();
    filter.key = 'name';
    filter.operation = 'contains';
    filter.value = $event.term;
    let filterquery = [];
    filterquery.push(filter);
    this.zonalService.filter({ size: 10, page: 0 }, filterquery).subscribe(
      (res: HttpResponse<any>) => {
        this.zonalList = res.body;
      },
      () => { }
    );
  }
  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    var charLength = event.target.value.length
    //console.log(charLength);

    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      if (charLength == 10) {
        event.preventDefault();
        return true;
      }
    }
  }
  validateIfsc() {
    if (this.beneficiary.ifscCode) {
      this.showIfscError = !this.validationService.isIfscValid(
        this.beneficiary.ifscCode
      );
      return !this.showIfscError;
    } else {
      this.showIfscError = false;
      return true;
    }
  }


  downdloadPdf(data){
    this.paymentService.downloadPdf(data.voucherNumber).subscribe(res=>{
      const blob = new Blob([res.body], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'file.pdf';
      document.body.appendChild(link);
      window.open(url, '_blank');
      link.click();
      URL.revokeObjectURL(url);
      // this.spinner.hide();
      // this.router.navigate(['payment']);
      
      // this.notificationService.alertSuccess('Saved Successfully', '');
    },
    (err) => {
      // this.spinner.hide();
      this.notificationService.alertError('Error!', '');
    }
   )
 
  }
openDDdialog(data){
  //console.log(data)
this.courtDDData.push(data)
this.ddDialog=true;
}
 



  async checkName() {
    const filterData = await this.getfilterdata('name', this.agency.name);
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }

  async checkmobile() {
    const filterData = await this.getfilterdata('mobile', this.agency.mobile);
    if (filterData.length !== 0) {
      this.showMobileNoError = 'Phone Already Exists';
    } else {
      this.showMobileNoError = null;
    }
  }

  async checkemail() {
    const filterData = await this.getfilterdata('email', this.agency.email);
    if (filterData.length !== 0) {
      this.showEmailUniError = 'Email Already Exists';
    } else {
      this.showEmailUniError = null;
    }
  }

  async checkgstNo() {
    const filterData = await this.getfilterdata('gstNo', this.agency.gstNo);
    if (filterData.length !== 0) {
      this.gstUniError = 'GST Already Exists';
    } else {
      this.gstUniError = null;
    }
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
        {
          key: 'activeFlag',
          operation: 'equals',
          value: 'YES',
        },
      ];
      if (this.agency.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.agency.id,
        });
      }
      this.agencyService.validFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<Agency[]>) => {
          resolve(res.body);
        },
        () => {
          this.onError();
          resolve(null);
        }
      );
    });
  }
  removeDocument() {
    this.paymentService.deleteimage(this.ddDetails.fileName).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.isFile = false;
        //this.projectForm.get('fileGO').setValue('')
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }

  onUploadfile1($event) {
    const formData = new FormData();
    const i = $event.files.length - 1;
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }
    this.spinner.show()
    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.notificationService.alertSuccess('Image Upload SuccessFully', '');
        this.ddDetails.fileName= res.body.fileName;
        this.spinner.hide()
       // this.projectForm.get('fileGO').setValue(res.body.fileName)
       // this.project.fileUpload = res.body.fileName;
        this.isFile = true;
      },
      (err) => {
        this.spinner.hide()
      }
    );

  }
  viewDocument() {
    this.paymentService.viewimage( this.ddDetails.fileName).subscribe((res) => {
      const filename = res.body.fileName;
      this.vFileExt = filename.split('.').pop();
      this.vFileUrl =
        'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc = true;
    });
  }
  saveDD(){

    const isFormValid = this.ddDetails.ddNumber&&   this.ddDetails.ddDate && this.ddDetails.depositedOn && this.ddDetails.fileName && this.ddDetails.courtName
if(!isFormValid){
  this.ngSelectInvalid = true;
  this.notificationService.alertError('Please fill all the mandatory fileds marked with *','');
  return
}else{
  //console.log("working")

this.courtDDData[0].ddDate=this.ddDetails.ddDate;
this.courtDDData[0].ddNumber=this.ddDetails.ddNumber;
this.courtDDData[0].depositedOn=this.ddDetails.depositedOn;
this.courtDDData[0].courtName=this.ddDetails.courtName;
this.courtDDData[0].ddImage=this.ddDetails.fileName;
  //console.log(this.courtDDData)
  let val={requestPayload:this.encryptService.encryptData(JSON.stringify(this.courtDDData))}
  this.stageService.saveCourtDetails(val).subscribe(res=>{
this.notificationService.alertSuccess(res.body,'');
this.ddDialog=false;
  },(err=>{
    this.notificationService.alertError(err.error,'');
    this.ddDialog=false;
  }))
}
  }
  hideDialog(){
    this.ddDialog=false;
    this.ddDetails.courtName='';
    this.ddDetails.ddNumber='';
    this.ddDetails.depositedOn='';
    this.ddDetails.fileName='';
  }
}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { ReturnPaymentService } from '../return-payment/ReturnPaymentService';
import { ApiResponse, ReturnPayment } from './return';

@Component({
  selector: 'app-return-payment',
  templateUrl: './return-payment-view.component.html',
  styleUrls: ['./return-payment-view.component.scss'],
})
export class ReturnPaymentComponentView implements OnInit {
  payments: ReturnPayment[] = [];
  filteredPayments: ReturnPayment[] = [];
  totalRecords: number = 0;
  loading: boolean = false;
  editingRow: { [key: number]: boolean } = {};
  clonedRows: { [key: number]: ReturnPayment } = {};
  pageSize: number = 10;
  first: number = 0;
  searchVoucher: string = '';
  apiResponse: ApiResponse | null = null;

  constructor(
    private cd: ChangeDetectorRef,
    private paymentService: ReturnPaymentService
  ) {}

  ngOnInit(): void {
    this.loadPayments();
  }

  loadPayments(event?: LazyLoadEvent) {
    this.loading = true;
    
    const pageToLoad: number = event?.first ? Math.floor(event.first / (event.rows ?? this.pageSize)) : 0;
    const size: number = event?.rows ?? this.pageSize;
    let voucherNumber = this.searchVoucher ? this.searchVoucher : null;
  
    this.paymentService
      .fetchReturnpayment({ page: pageToLoad, size: size }, voucherNumber)
      .subscribe((data) => {
        let returnPayment = data.body;
        this.payments = returnPayment ?? [];
        this.filteredPayments = [...this.payments];
        this.totalRecords = Number(data.headers.get('X-Total-Count')) || 0;
        this.loading = false;
      });
  }
  

  onRowEditInit(payment: ReturnPayment) {
    if (!this.editingRow[payment.id]) {
      this.clonedRows[payment.id] = { ...payment };
      this.editingRow[payment.id] = true;
    }
    this.cd.detectChanges();
  }

  onRowEditSave(payment: ReturnPayment, index: number) {
    this.payments[index] = { ...payment };
    this.filteredPayments = [...this.payments];
    this.editingRow[payment.id] = false;
    delete this.clonedRows[payment.id];
    this.cd.detectChanges();
  }

  onRowEditCancel(payment: ReturnPayment) {
    if (this.clonedRows[payment.id]) {
      Object.assign(payment, this.clonedRows[payment.id]);
      delete this.clonedRows[payment.id];
    }
    this.editingRow[payment.id] = false;
  }
}

import { Component, Input } from '@angular/core';
import { RDService } from './biometric-test-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FontService } from 'src/app/shared/font-service/font.service';
@Component({
  selector: 'app-biometric-test',
  templateUrl: './biometric-test.component.html',
  styleUrls: ['./biometric-test.component.scss']
})
export class BiometricTestComponent {
  deviceInfo: string;
  response: string;
  aadhaarNo:string;
  enable: boolean = false;
  status: boolean = false;
  registration: boolean = false;
  // scannerReady: boolean;
  // registrationStatusField: any;
  @Input() scannerReady;
  @Input() registrationStatusField: string = 'please capture fingure print';
  @Input() registrationData = false;
  constructor(private rdService: RDService, private http: HttpClient,
    public fontService:FontService
  ) { }
  private baseUrl = 'https://localhost:11200';
  // private baseUrl = 'https://localhost:11102'; L1
  registerFingerprint(): void {
    this.rdService.captureBiometric().subscribe(
      data => {
        console.log('===========================================>>>>>>>>>>>>>>>>>>>>>>>', data);
        // this.captureSignature(data).subscribe((res: any) => {
        //   console.log('7777777777777777777777', res);
        //   if (this.registrationStatusField) {

        //   }
        // }, (error) => { });
        this.rdService.finguredata(data,this.aadhaarNo).subscribe(
          responseData => {
            console.log('0000000000000000000000000000', responseData.body);
            if(responseData){
              this.registrationStatusField = "fingure registered successfully with name" + responseData.body;
              this.rdService.updatebiometric(this.registrationStatusField);
            }
          },
          error => {
            console.error('Error occurred:', error);
          }
        );
      },
      error => console.error('Error: ', error)
    );
  }
  rdservice(): void {
    this.getDeviceInfo().subscribe(
      (data: any) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(data, 'application/xml');
        const obj = this.xmlToJsonHelper(xmlDoc.documentElement);
        console.log("Response: ", JSON.stringify(obj));
        this.scannerReady = true;
      },
      (error: any) => {
        console.log(error);
        console.log('dsadasdsadsa', error.text);
        console.error("Something went wrong: ", error);
      }
    );
  }

  getDeviceInfo(): Observable<any> {
    const url = `${this.baseUrl}/rd/info`;
    const headers = new HttpHeaders().set('Accept', 'application/json');

    return this.http.request('DEVICEINFO', url, { headers: headers, responseType: 'text' });
  }
  // captureSignature(pidOptions: any): Observable<any> {
  //   const parser = new DOMParser();
  //   const xmlDoc = parser.parseFromString(pidOptions, 'application/xml');
  //   const obj = this.xmlToJsonHelper(xmlDoc.documentElement);
  //   console.log("Response: ", JSON.stringify(obj));
  //   const url = `${environment.SERVER_API_URL}api/figurePrintStatus`;
  //   const headers = new HttpHeaders().set('Accept', 'application/json');
  //   return this.http.post(url, pidOptions, { headers: headers, responseType: 'text' });
  // }

  private handleError(error: any) {
    console.error('An error occurred', error);
    return throwError(error.message || 'Server error');
  }

  private xmlToJsonHelper(node: any): any {
    let obj: any = {};
    if (node.nodeType === 1) { // element
      if (node.attributes.length > 0) {
        obj['@attributes'] = {};
        for (let j = 0; j < node.attributes.length; j++) {
          const attribute = node.attributes.item(j);
          obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (node.nodeType === 3) { // text
      obj = node.nodeValue.trim();
    }
    if (node.hasChildNodes()) {
      for (let i = 0; i < node.childNodes.length; i++) {
        const item = node.childNodes.item(i);
        const nodeName = item.nodeName;
        if (typeof obj[nodeName] === 'undefined') {
          obj[nodeName] = this.xmlToJsonHelper(item);
        } else {
          if (typeof obj[nodeName].push === 'undefined') {
            const old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(this.xmlToJsonHelper(item));
        }
      }
    }
    return obj;
  }
}
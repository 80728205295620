import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AgencyService {
  constructor(private http: HttpClient) { }
  protected resourceDeleteUrl = environment.SERVER_API_URL + ('api/deleteImplementingAgency')
  protected getAgencyUrl = environment.SERVER_API_URL + ('api/benificiaryLandDetail')
  protected beneficiaryPayment = environment.SERVER_API_URL + ('api/beneficiaryPayment')


  getOneBeneficiary(id: number): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.getAgencyUrl}/${id}`, { observe: 'response' });
  }

  save(voucher?: any): Observable<any> {
    return this.http
      .post<any[]>(this.beneficiaryPayment, voucher, { observe: 'response' })
  }

  balanceAmount(id): Observable<any> {
    return this.http.get<any>(`${this.beneficiaryPayment}/balanceAmount/?beneficiaryLandOwnerId=${id}`, { observe: 'response' });

  }
  previousTranscation(id): Observable<any> {
    return this.http.get<any>(`${this.beneficiaryPayment}/getPreviousTransaction?benificiaryLandOwnerid=${id}`, { observe: 'response' });

  }
}


import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';

import { HttpResponse } from '@angular/common/http';

import { LocalStorageService } from 'ngx-webstorage';
import { User } from 'src/app/module/checker-project/checker';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';


class Masters {
 
  userDto: User;
  
}

interface MasterResponseModel {
    userDto: HttpResponse<User>,
  
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private masters = new BehaviorSubject<Masters>(new Masters());


  constructor(
    private spinner: NgxSpinnerService,
    public notificationService: NotificationService,
    protected localStorageService: LocalStorageService,
    private loginService: SigninService,
  ) {
    
  }

  public getMasters(): BehaviorSubject<Masters> {
    return this.masters;
  }

  public async getMasterService(): Promise<void> {
    try {
      this.spinner.show();
      const userDtoResponse = await this.loginService.getuserDto().toPromise();
      const responseData: Masters = {
        userDto: userDtoResponse.body,
      };
      this.masters.next(responseData);
      this.spinner.hide();
      //console.log('okijhgfdsfghjk', this.masters);
    } catch (error) {
      this.spinner.hide();
      // Handle errors here, e.g., show an error message.
    }
  }
}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReturnPaymentService } from './ReturnPaymentService';
import { LazyLoadEvent } from 'primeng/api';
import { RDService } from '../biometric-test/biometric-test-service';
import { NotificationService } from 'src/app/alert/notification.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SigninService } from 'src/app/auth/signin/signin.service';

@Component({
  selector: 'app-return-payment',
  templateUrl: './return-payment.component.html',
  styleUrls: ['./return-payment.component.scss']
})
export class ReturnPaymentComponent implements OnInit {

  payments: any[] = [];
  filteredPayments: any[] = [];
  totalRecords: number = 0;
  scan=false
  figureData:any;
  registrationStatusField: string = 'please capture fingure print';

  loading: boolean = false;
  editingRow: { [key: number]: boolean } = {};
  clonedRows: { [s: string]: any } = {};
  pageSize: number = 2;
  first: number = 0;
  searchVoucher: string = '';
  signaturedialog: boolean;
  editfeild: boolean = false;
  enablepayment: boolean = false;
  roleId: boolean = false;
  state: boolean = false;
  maker: boolean = false;
  enableStatepayment: boolean = false;

  constructor(private paymentService: ReturnPaymentService, public fontService: FontService
  ,protected notificationService: NotificationService, private fb: FormBuilder,
  private cd: ChangeDetectorRef, private rdService:RDService
  , private loginService:SigninService) {}

  ngOnInit(): void {
    // this.loadPayments({ first: 0, rows: this.pageSize });
    // this.loadPayments();
    this.rdService.updatebiometric(null);
    this.getUserrole();

  }

  getUserrole() {
    this.loginService.getuserDto().subscribe(
      (res) => {
        if (res.body.role.id == 2 ) {
          this.roleId = true
          this.state = true
          this.maker = false
        } else  if (res.body.role.id == 5 ) {
          this.roleId = false
          this.state = false
          this.maker = true
        }else {
          this.roleId = false
          this.state = false
          this.maker = false
        }
      },
      (onError) => { }
    );
  }

  // loadPayments(event: LazyLoadEvent) {
  //   this.loading = true;
  //   let page = Math.floor(event.first! / event.rows!);
  //   this.pageSize = event.rows!;
  //   this.first = event.first!;

  //   this.paymentService.getReturnPayments(page, this.pageSize).subscribe(data => {
  //     this.payments = data.content;
  //     this.filteredPayments = [...this.payments]; // Ensure filtering works
  //     this.totalRecords = data.totalElements;
  //     this.loading = false;
  //   });
  // }

  onClickApprove(payment: any) {
    this.paymentService.apiApprovePayment(payment).subscribe((res) => {
      this.notificationService.alertSuccess('Payment Approved Successfully', '');
      console.log('77777777777777777777777777777777777777',res);
      this.searchVoucher = res;
      this.loadPayments();
    })
  }

  loadPayments(event?: LazyLoadEvent) {
    this.loading = true;
    
    const pageToLoad: number = event?.first ? Math.floor(event.first / (event.rows ?? this.pageSize)) : 0;
    const size: number = event?.rows ?? this.pageSize;
    let voucherNumber = this.searchVoucher ? this.searchVoucher : null;
  
    if(voucherNumber == null || !voucherNumber){
        return;
    }

    this.paymentService
      .fetchReturnpayment({ page: pageToLoad, size: size }, voucherNumber)
      .subscribe((data) => {
        let returnPayment = data.body;
        this.payments = returnPayment ?? [];
        this.filteredPayments = [...this.payments];
        if(this.filteredPayments.length>0){
          if(this.filteredPayments[0].returnStatus === 2){
            this.enablepayment = true;
          }

          if(this.filteredPayments[0].returnStatus === 0){
            this.enableStatepayment = true;
          }
        }
        this.totalRecords = Number(data.headers.get('X-Total-Count')) || 0;
        this.loading = false;
      });
  }
  

  // Enable edit mode
  onRowEditInit(payment: any) {
    if (!this.editingRow[payment.id]) {
      this.clonedRows[payment.id] = { ...payment }; // Store original row
      this.editingRow[payment.id] = true;
      
      this.editfeild = true;
      console.log('Editing Row:', payment.id, 'Value:', payment.beneficiaryName);
    }

    this.cd.detectChanges();
  }

  onRowEditSave(payment: any, index: number) {
    this.filteredPayments[index] = { ...payment }; // Ensure data updates
    this.filteredPayments = [...this.filteredPayments]; // Trigger UI refresh
    this.editingRow[payment.id] = false;
    delete this.clonedRows[payment.id];
    this.editfeild = false;
    this.cd.detectChanges(); // Force UI refresh
}

  // Cancel editing and restore previous values
  onRowEditCancel(payment: any) {
    if (this.clonedRows[payment.id]) {
      Object.assign(payment, this.clonedRows[payment.id]); // Restore original values
      delete this.clonedRows[payment.id];
    }
    this.editingRow[payment.id] = false;
    this.editfeild = false;
  }

  // Search payments by voucher number
  // filterPayments() {
  //   if (!this.searchVoucher) {
  //     this.filteredPayments = []; // Reset search
  //   } else {
  //     this.loadPayments();
  //     console.log('5436yu43y6io4y6oi34uyo6y34o643')
  //     this.filteredPayments = this.payments.filter(payment =>
  //       payment.voucherNumber.toLowerCase().includes(this.searchVoucher.toLowerCase())
  //     );
  //   }
  // }

  hideDialog(){
    this.signaturedialog = false;
  }

  OnSave(){
    this.signaturedialog = true;
  this.rdService.fingureprint.subscribe( res => {
    this.figureData = res ? res : this.registrationStatusField;
    if(this.figureData.startsWith('fingure registered successfully')){
      //console.log('dsadsada'+ this.enableSignatureButton);
      this.signaturedialog = false;
      this.notificationService.alertSuccess(this.figureData,'');
    }
  }
);
}




}

<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Expenditure </h2>
</div>
<div class="container bg  mx-auto">
    <div class="row">
        <div class="col-lg-2">
            <div>
                <label class="form-control-label req" for="scheme">
                    From Date</label>
            </div>
            <p-calendar [readonlyInput]="true" dateFormat="dd/mm/yy" [maxDate]="maxDate" [(ngModel)]="fromDate"
                appendTo="body"></p-calendar>
        </div>
        <div class="col-lg-2">
            <div>
                <label class="form-control-label req" for="scheme">
                    To Date</label>
            </div>
            <p-calendar [readonlyInput]="true" [(ngModel)]="toDate" [maxDate]="maxDate" dateFormat="dd/mm/yy"
                appendTo="body"></p-calendar>
        </div>
        <div class="col-lg-2">
            <div>
                <label class="form-control-label req" for="scheme">
                    District Name</label>
            </div>
            <ng-select id="department"  appearance="outline" [(ngModel)]="districtName"
                [items]="districtNames" class="form-control" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-lg-2">
            <div>
                <label class="form-control-label req" for="scheme">
                    Agency Name</label>
            </div>
            <ng-select id="department"  appearance="outline" [(ngModel)]="agencyName"
                [items]="agencyNames" class="form-control" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-lg-2">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Project
                Name</label>
            <ng-select id="department"  appearance="outline" [(ngModel)]="projectName"
                [items]="projectList" class="form-control" appNgSelectInputRestriction>
            </ng-select>
        </div>
    </div>
    <div class="pt-4">
        <p-button label="Submit" class="submit" 
            (click)="getProjectsFilter(1,'action')"></p-button> &nbsp;
        <p-button label="Reset" class="submit"
            (click)="fromDate = '';
            toDate='' ; districtName='';agencyName=''; projectName='';getProjectsFilter(1,'first')"></p-button> &nbsp;
        <p-button class="submit"  label="Home" routerLink="/main-dashboard"></p-button>
    </div>
   
</div>
<div class="row tableDesign">
    <div class="col-lg-6">
        <h2>
            Department Wise Fund Expenditure
        </h2>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Department</th>
                    <th scope="col">Fund Received</th>
                    <th scope="col">Expenditure</th>
                    <th scope="col">Balance</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let data of depList;let i =index'>
                    <td>{{generateIndex(i)}}</td>
                    <td class="highlight" (click)="this.agencyName=data?.agency;getProjectsFilter(1,'action')">{{data?.agency}}</td>
                    <td>{{data?.fundReceived}}</td>
                    <td>{{data?.expenditure}}</td>
                    <td>{{data?.balance}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-lg-6">
        <h2>
            District Wise Fund Expenditure
        </h2>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">District</th>
                    <th scope="col">Fund Received</th>
                    <th scope="col">Expenditure</th>
                    <th scope="col">Balance</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let data of disList;let i =index'>
                    <td>{{generateIndex(i)}}</td>
                    <td class="highlight" (click)="this.districtName=data?.district;getProjectsFilter(1,'action')">{{data?.district}}</td>
                    <td>{{data?.fundReceived}}</td>
                    <td>{{data?.expenditure}}</td>
                    <td>{{data?.balance}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="hamburger">
    <h2>Profile Details</h2>
</div>
<div class="container bg">
    <div class="col-md-12">
        <div class="form-inner-panel" novalidate>
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="breadcrumb.profile.username" for="name">Name</label>
                            <input type="text" class="form-control" maxlength="50"
                                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" name="name"
                                autocomplete="off" id="name" [(ngModel)]="profile.name" readonly />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.dateOfBirth" for="dateOfBirth">Date of Birth</label>
                            <div>
                                <p-calendar [readonlyInput]="true" [(ngModel)]="profile.dateOfBirthUi" styleClass="datepicker"></p-calendar>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.gender" for="gender">Gender</label>
                            <input type="text" class="form-control" maxlength="10"
                                oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" name="name"
                                autocomplete="off" id="name" readonly />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.mobile" for="mobile">Mobile No</label>
                            <input type="text" class="form-control"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                name="mobile" autocomplete="off" [(ngModel)]="profile.mobileNo" id="email"
                                (input)="validateMobile()" id="mobile"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !profile.mobileNo }"
                                maxlength="10" />
                            <div *ngIf="showMobileError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter
                                    a valid Mobile
                                    Number.</small>
                            </div>
                            <div *ngIf="mobileNoError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.showMobileUniError"> {{
                                    mobileNoError }}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.email" for="email">Email</label>
                            <input type="text" class="form-control" name="email" autocomplete="off"
                                [(ngModel)]="profile.emailId" id="email" 
                                (input)="validateEmail()" maxlength="60" />
                            <div *ngIf="emailError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please enter a
                                    valid Email
                                    Address.</small>
                            </div>
                            <div *ngIf="emailIdError" class="text-danger">
                                <small [ngClass]="fontService.smallClass"
                                    jhiTranslate="error.showEmailUniError">{{emailIdError}}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.educationalQualification" for="educationalQualification">Education
                                Qualification</label>
                            <ng-select id="educationalQualification" [(ngModel)]="profile.educationalQualificationDto"
                                [items]="educationList" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !profile.educationalQualificationDto }"
                                [clearable]="true" class="form-control" appNgSelectInputRestriction>
                            </ng-select >
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.maritalStatus" for="maritalStatus">Marital Status</label>
                            <ng-select id="maritalStatus" [(ngModel)]="profile.maritalStatusDto" [items]="maritalList"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !profile.maritalStatusDto }"
                                bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                                class="form-control" appNgSelectInputRestriction>
                            </ng-select>
                        </div>
                        <div *ngIf="rolecode === 'DC2' || rolecode === 'BC2' || rolecode === 'VC2'  ||rolecode === 'SA'"
                            class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.categoryReservation" for="categoryReservation">Category
                                Reservation</label>
                            <ng-select id="categoryReservation" [(ngModel)]="profile.categoryReservationDto"
                                [items]="categoryReservationList" bindLabel="name" appearance="outline"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !profile.categoryReservationDto }"
                                [closeOnSelect]="true" [clearable]="true" class="form-control" appNgSelectInputRestriction>
                            </ng-select>
                        </div>
                        <div *ngIf="rolecode === 'DC2' || rolecode === 'BC2' || rolecode === 'VC2'  ||rolecode === 'SA'"
                            class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.community" for="community">Community</label>
                            <ng-select id="community" [(ngModel)]="profile.communityDto" [items]="community"
                                bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                                class="form-control" appNgSelectInputRestriction>
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.dateOfAssumingOffice" for="dateOfAssumingOffice">Date Of Assuming
                                Office</label>
                            <div>
                                <p-calendar [readonlyInput]="true" [(ngModel)]="profile.dateOfAssumingOffice" appendTo="body"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !profile.dateOfAssumingOffice }"
                                    [maxDate]="today"></p-calendar>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.termValidUpTo" for="termValidUpTo">Term Valid UpTo</label>
                                <div>
                                    <p-calendar [readonlyInput]="true" [(ngModel)]="profile.termValidUpTo" appendTo="body" [minDate]="today"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !profile.termValidUpTo }"></p-calendar>
                                </div>
                            </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.panno" for="pan">PAN No</label>
                            <input type="text" [(ngModel)]="profile.panNo" class="form-control"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase"
                                name="pan" autocomplete="off" id="pan" 
                                (input)="validatePan()" maxlength="10" />
                            <div *ngIf="panError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpan">Please enter a
                                    valid PAN
                                    Number.</small>
                            </div>
                            <div *ngIf="panNoError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.panNoUniError">{{
                                    panNoError }}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.buildingno" for="doorNo">Building No/Door No</label>
                            <input type="text" [(ngModel)]="profile.doorNo" class="form-control"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="20"
                                name="doorNo" autocomplete="off" id="doorNo" readonly />
                        </div>

                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.street" for="street">Street</label>
                            <input type="text" [(ngModel)]="profile.street" class="form-control"
                                oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="50"
                                name="street" autocomplete="off" id="street" readonly />
                        </div>

                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.landmark" for="landmark">Landmark</label>
                            <input type="text" [(ngModel)]="profile.landmark" class="form-control"
                                oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="50"
                                name="landmark" autocomplete="off" id="landmark" readonly />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.city" for="city">City</label>
                            <input type="text" [(ngModel)]="profile.city"
                                oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="50"
                                class="form-control" name="city" autocomplete="off" id="city" readonly />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.district" for="district">District</label>
                            <input type="text" [(ngModel)]="profile.district" class="form-control"
                                oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="20"
                                name="district" autocomplete="off" id="district" readonly />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.state" for="state">State</label>
                            <input type="text" [(ngModel)]="profile.state" class="form-control"
                                oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="20"
                                name="state" autocomplete="off" id="state" readonly />
                        </div>

                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass"
                                jhiTranslate="vendor.pincode" for="pinCode">Pin Code</label>
                            <input type="text" [(ngModel)]="profile.pinCode" class="form-control"
                                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                maxlength="6" name="pinCode" autocomplete="off" id="pinCode" readonly />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-3">
        <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span>
        </button>
        <button *ngIf="!profile.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button"
            (click)="saveProfile()">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
        </button>
        <button *ngIf="profile.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button"
            (click)="saveProfile()">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
        </button>
    </div>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>
<div class="hamburger">
  <h2>SNA</h2>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <p-toolbar styleClass="toolbar">
        <ng-template pTemplate="left">
        </ng-template>
        <ng-template pTemplate="right">
          <button class="btn btn-primary" styleClass="button" (click)="openNew()">
            Add
          </button>
        </ng-template>
      </p-toolbar>
    </div>
  </div>
  <div class="row">
    <div #customerTable id="entities" class="col-md-12  px-5">
      <table class="table mt-2">
        <thead>
          <tr>
            <th scope="col">Account No</th>
            <th scope="col">Name</th>
            <th scope="col">Amount </th>
            <th scope="col">IFSC Code</th>
            <th scope="col">Branch</th>
            <th scope="col">Short Name</th>
            <th scope="col">Code</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of snaAccount">
            <td>{{data.accountNumber}}</td>
            <td>{{data.name}}</td>
            <td>{{data.amount |indianRupee }}</td>
            <td class="text-uppercase">{{data.ifscCode}}</td>
            <td>{{data.branch}}</td>
            <td>{{data.shortName}}</td>
            <td>{{data.code}}</td>
            <td>
              <a (click)="delete(data.id)">Delete</a>
            </td>
          </tr>
        </tbody>
      </table>
      <!--
       <div class="text-center" *ngIf="totalCounts == 0">
          <img src="../../../assets/img/notFound.png" width="419px" alt="">
        </div>
        <p-paginator [rows]="itemsPerPage" (onPageChange)="onPageChange($event)" [totalRecords]="totalCounts"
              [rowsPerPageOptions]="[10,20,30,50,100]">
        </p-paginator> 
        -->
    </div>
  </div>
  <p-dialog #dialog [(visible)]="accountSnaDialog" [modal]="true" styleClass="ib-dialog" [style]="{ width: '850px' }"
    (onHide)="hideDialog()">
    <p-header [ngClass]="fontService.headingClass">
      {{ "sna.detail.name" | translate }}
    </p-header>
    <ng-template pTemplate="content">
      <div class="col-12">
        <div class="form-inner-panel">

          <div class="row gy-2">
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.name.name"
                for="snaName">SNA Name</label>
              <input type="text" id="snaName" name="snaName" autocomplete="off" maxlength="80"
                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" style="text-transform:uppercase" class="form-control"
                [(ngModel)]="accountSna.name" [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.name }"
                [readonly]="readonly" />
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.code.name"
                for="snaCode">SNA Code</label>
              <input type="text" id="snaCode" name="snaCode" autocomplete="off" class="form-control" maxlength="20"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" style="text-transform:uppercase" [(ngModel)]="accountSna.code"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.code }" [readonly]="readonly" />
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.short.name"
                for="shortName">Short Name</label>
              <input type="text" class="form-control"
                oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="20"
                [(ngModel)]="accountSna.shortName" name="snaCode" autocomplete="off" id="shortName"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.shortName }" [readonly]="readonly" />
            </div>
            <div class="col-sm-12 col-md-8">
              <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="sna.description.name"
                for="snaDescription">SNA Description</label>
              <textarea name="snaDescription" [readonly]="readonly" [(ngModel)]="accountSna.snaDesc" id="snaDescription"
                maxlength="200" cols="30" rows="2"></textarea>
            </div>
          </div>
          <div class="row gy-2 mt-3">
            <div class="col-12">
              <h6 [ngClass]="fontService.headingClass" jhiTranslate="sna.account.details">
                Account Details
              </h6>
            </div>

            <!-- <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.ifscode.name"
                for="ifsc">IFSC Code</label>
              <input type="text" class="form-control"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase" maxlength="11"
                [(ngModel)]="accountSna.ifscCode" name="ifsc" autocomplete="off" id="ifsc"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.ifscCode }" (input)="validateIfsc()" [readonly]="readonly" />
              <div *ngIf="ifscError" class="text-danger">
                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                  IFSCode.</small>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.branch.name"
                for="branch">Branch</label>
              <input type="text" class="form-control"
                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" style="text-transform:uppercase" maxlength="60"
                [(ngModel)]="accountSna.branch" name="branch" autocomplete="off" id="branch"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.branch }"  [readonly]="readonly"/>
            </div> -->
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode"
                for=" ifsc">IFSC Code</label>
              <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11"
                oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" uppercase [(ngModel)]="accountSna.ifscCode"
                [readonly]="readonly" id="ifsc" [ngClass]="{
  'is-invalid': ngSelectInvalid && !accountSna.ifscCode
}" maxlength="11" (input)="validateIfsc()" />
              <div *ngIf="ifscError" class="text-danger">
                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please
                  enter a valid
                  IFSCode.</small>
              </div>
            </div>

            <div class="col-sm-6 col-md-4">
              <label class="form-control-label" [ngClass]="fontService.labelClass" for="bankName">Bank
                Name</label>
              <p>{{accountSna.bankName}}</p>
            </div>

            <div class="col-sm-6 col-md-4">
              <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="agency.branch.name"
                for=" branchName">Branch Name</label>

              <p>{{accountSna.branch}}</p>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass"
                jhiTranslate="sna.account.no.name" for="bankAccountNo">Account No</label>
              <input
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" maxlength="17" class="form-control"
                [(ngModel)]="accountSna.accountNumber" name="bankAccountNo" autocomplete="off" id="bankAccountNo"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.accountNumber }" [readonly]="readonly" />
            </div>
            <!-- <div class="col-sm-6 col-md-4" *ngIf="accountChange">
            <label class="form-control-label req" for="retypeAccountNumber">Retype Account
                Number</label> (change)="accountChange = true" [type]="accountChange ? 'password' : 'text'"  
            <input type="text" class="form-control" required name="retypeAccountNumber"  id="retypeAccountNumber"  maxlength="17" autocomplete="off" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" [(ngModel)]="accountSna.retypebankAccountNo" [disabled]="readonly"  />
        </div> -->
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.address.name"
                for="branchAddress">Address</label>
              <input type="text" class="form-control" name="branchAddress" autocomplete="off" maxlength="300"
                [(ngModel)]="accountSna.address" id="branchAddress"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.address }" [readonly]="readonly" />
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass"
                jhiTranslate="sna.branch.manager.no.name" for="branchMangerNo">Branch Manager No</label>
              <input type="text" class="form-control" name="branchMangerNo" autocomplete="off"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" inputmode="numeric" pattern="\d*" maxlength="10"
                [(ngModel)]="accountSna.managerMobNo" id="branchMangerNo" (input)="validateMobile()"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.managerMobNo }" [readonly]="readonly" />
              <div *ngIf="showMobileError" class="text-danger">
                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter a valid Mobile
                  Number.</small>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="sna.manager.name"
                for="managerName">Manager Name</label>
              <input type="text" class="form-control"
                oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" style="text-transform:uppercase" [readonly]="readonly"
                maxlength="45" name="managerName" autocomplete="off" [(ngModel)]="accountSna.managerName"
                id="managerName" [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.managerName }" />
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label req" [ngClass]="fontService.labelClass"
                jhiTranslate="sna.branch.email.name" for="branchEmail">Branch E-mail</label>
              <input type="text" class="form-control" name="branchEmail" autocomplete="off"
                [(ngModel)]="accountSna.branchEmail" id="branchEmail" (input)="validateEmail()"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !accountSna.branchEmail }" maxlength="60" />
              <div *ngIf="emailError" class="text-danger">
                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please enter a valid Email
                  Address.</small>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="sna.amount.name"
                for="amount">Amount</label>
              <input type="text" class="form-control" name="amount" autocomplete="off"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                onblur="this.value =this.value.trim();" [(ngModel)]="accountSna.amount" id="amount"
                [readonly]="readonly" />
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button icon="pi pi-times" class="btn btn-primary" (click)="hideDialog()">
        <span class="button-label" [ngClass]="fontService.buttonClass" custom-button jhiTranslate="common.cancelbutton">
        </span>
      </button>
      <button *ngIf="!readonly" icon="pi pi-check" class="btn btn-primary" (click)="saveSna()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
      </button>
    </ng-template>
  </p-dialog>
  <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>
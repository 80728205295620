import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AgencyService } from './agency.service';
import { Agency, AgencyDto } from './agency';
import { SearchResult } from 'src/app/shared/models/search-result';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelType } from '../level-type/level-type';
import { ZonalService } from '../zonal/zonal.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { Dialog } from 'primeng/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss'],
})
export class AgencyComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;
  // @ViewChild('department', ) department: NgSelectComponent;

  agencyDialog: boolean = false;
  cols: any[];
  zonalDto: any;
  searchResult: SearchResult<Agency> = new SearchResult<Agency>();
  agency?: AgencyDto;
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  levelType: LevelType[];
  levelTypeDto: LevelType;
  levelMasterList: any;
  sortDirection = 'asc'
  levelMasterDto: any;
  zonalList: any;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public showGstError = false;
  public showIfscError = false;
  public showBMobile = false;
  //required fields property
  ngSelectInvalid = false;
  showNameError: any;
  showMobileNoError: string;
  showEmailError: string;
  showEmailUniError: string;
  gstUniError: string;
  viewOnly: boolean;
  hamHeader: string;
  subHeader: string;
  agencyList: any;
  stateList: any;
  state: any[];
  district: any;
  totalCount: any;
  pageCount: number = 0;
  index: number;
  roleId: boolean = false;
  pageLength: number;

  filterQuery: any = { agencyName: '', page: '', size: '' };
  params: any;
  dir: any;

  constructor(
    private agencyService: AgencyService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private loginService: SigninService,
    private levelTypeService: LevelTypeService,
    private zonalService: ZonalService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
  ) {
    this.getUserrole()
  }
  filter = {
    agencyName: '',
  }
  submitForFilter(params, dir) {
    this.params = params,
      this.dir = dir
    const pageToLoad: number = this.page ?? this.page ?? 1;
    if (params) {
      this.filterQuery = {
        agencyName: this.filter.agencyName,
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        sortBy: params,
        sortDirection: dir
      }
      if (this.sortDirection == 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    } else {
      this.filterQuery = {
        agencyName: this.filter.agencyName,
        page: pageToLoad - 1,
        size: this.itemsPerPage,
      };
    }
    this.spinner.show()
    this.agencyService.filters(this.filterQuery).subscribe((res: any) => {
      this.agencyList = res.body.data
      this.spinner.hide()
      this.totalCount = res.body.totalCount
    })
  }
  clear(data) {
    if (data == 'agencyName') {
      this.filter.agencyName = ''
      this.filterQuery.agencyName = ''
    }
    if (this.filter.agencyName == '') {
      this.loadPage(this.page)
    } else {
      this.submitForFilter('', '')
    }
  }

  ngOnInit() {

    if (this.router.url == '/agency') {
      this.hamHeader = 'Requisitioning Departments';
      this.subHeader = 'Home  /   Requisitioning Departments';
    }
    // this.AgencyService.getAgencys().then(data => this.agencys = data);
    this.loadPage(1);
    this.cols = [
      {
        field: 'agencyName',
        header: 'Department Name',
        // jhiTranslate: 'tableHead.department.name',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
        // type: 'dropDown',
      },
      {
        field: 'districtLgdCode',
        header: 'District LGD Code',
        // jhiTranslate: 'tableHead.scheme.name',
        isSortable: true,
        isFilterable: false,
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'districtName',
        header: 'District Name',
        // jhiTranslate: 'tableHead.agency.name',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'authorizedPersonMobileNumber',
        header: '',
        jhiTranslate: 'tableHead.mobile.number',
        isSortable: false,
        isFilterable: false,
        width: 13,
        isSelectcolumn: true,
      },
    ];

  }

  getUserrole() {
    this.loginService.getuserDto().subscribe(
      (res) => {
        if (res.body.role.id != 1 && res.body.role.id != 8 && res.body.role.id != 5 && res.body.role.id != 4 && res.body.role.id != 3) {
          this.roleId = true
        } else {
          this.roleId = false
        }
      },
      (onError) => { }
    );
  }
  ngAfterViewInit() {
  }
  govermentType = [
    {
      name: 'CENTRAL_GOVERNMENT',
    },
    {
      name: 'STATE_GOVERNMENT',
    },
  ];
  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);
    this.agencyService.filter({ ...query }, this.filterProps).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (err) => {
        this.spinner.hide();
        this.notificationService.alertError('Error!', err.error.error);
      }
    );
  }

  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    // this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data.data ?? [];
    this.agencyList = data.data;
    ////console.log( this.agencyList,' this.agencyList');
    this.totalCount = data.totalCount;
  }

  protected onError(): void { }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  vendorFilter($event) {
    let filter = new filterQuery();
    filter.key = 'name';
    filter.operation = 'contains';
    filter.value = $event.term;
    let filterquery = [];
    filterquery.push(filter);
    this.zonalService.filter({ size: 10, page: 0 }, filterquery).subscribe(
      (res: HttpResponse<any>) => {
        this.zonalList = res.body;
      },
      () => { }
    );
  }

  deleteAgency(data) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the agency?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.spinner.show();
        this.agencyService.deleteAgency(data.id).subscribe(
          (res: HttpResponse<any[]>) => {
            this.spinner.hide();
            this.loadPage();
          },
          () => {
            this.onError();
          }
        );
      },
    });
  }

  openNew(data, content?: any) {
    if (data == 'edit') {
      this.viewOnly = false;
      this.agency = new AgencyDto();
      this.submitted = false;
      // this.spinner.show()
      this.agencyService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          // this.spinner.hide()
          let stateList = res.body;
          this.state = stateList.filter((res) => res.levelTypeDto.id == 1);
          this.district = stateList.filter((res) => res.levelTypeDto.id == 2);
        },
        () => {
          this.spinner.hide()
          this.onError();
        }
      );
      this.agencyService.getOneAgency(content.RequestingAgencyId).subscribe(
        (res: any) => {
          let data: any = res.body.data;
          this.agency.id = data.id;
          this.agency.name = data.agencyName;
          this.agency.govermentType = data.governmentType;
          this.agency.address1 = data.address1;
          this.agency.address2 = data.address2;
          this.agency.street = data.street;
          this.agency.city = data.city;
          this.agency.district = data.districtName;
          this.agency.state = data.state;
          this.agency.pincode = data.pinCode;
          this.agency.agency = data.authorizedPersonName;
          this.agency.designation = data.authorizedPersonDesignation;
          this.agency.mobile = data.authorizedPersonMobileNumber;
          this.agency.email = data.authorizedPersonEmailId;
          this.agency.offliceMobile = data.officePhoneNumber;
          this.agency.ooficeemail = data.officeEmailId;
        },
        () => {
          this.onError();
        }
      );
      this.agencyDialog = true;
    } else {
      this.viewOnly = true;
      this.agency = new AgencyDto();
      this.submitted = false;
      this.spinner.show()
      this.agencyService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          this.spinner.hide()
          let stateList = res.body;
          this.state = stateList.filter((res) => res.levelTypeDto.id == 1);
          this.district = stateList.filter((res) => res.levelTypeDto.id == 2);
          ////console.log(this.stateList, ' this.stateList');
        },
        () => {
          this.spinner.hide()
          this.onError();
        }
      );
      this.agencyService.getOneAgency(content.RequestingAgencyId).subscribe(
        (res: any) => {
          let data: any = res.body.data;
          this.agency.name = data.agencyName;
          this.agency.govermentType = data.governmentType;
          this.agency.address1 = data.address1;
          this.agency.address2 = data.address2;
          this.agency.street = data.street;
          this.agency.city = data.city;
          this.agency.district = data.districtName;
          this.agency.state = data.state;
          this.agency.pincode = data.pinCode;
          this.agency.agency = data.authorizedPersonName;
          this.agency.designation = data.authorizedPersonDesignation;
          this.agency.mobile = data.authorizedPersonMobileNumber;
          this.agency.email = data.authorizedPersonEmailId;
          this.agency.offliceMobile = data.officePhoneNumber;
          this.agency.ooficeemail = data.officeEmailId;
        },
        () => {
          this.onError();
        }
      );
      this.agencyDialog = true;
    }
  }


  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        // this.levelType = res.body;
        this.levelType = res.body.filter(
          (item) => item.name === 'State' || item.name === 'District'
        );
      },
      () => { }
    );
  }

  onLevelTypeChange($event?) {
    this.levelMasterDto = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };

    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };

    }
  }

  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.showGstError = false;
    this.showIfscError = false;
    this.showBMobile = false;
    this.ngSelectInvalid = false;
    this.agency = new AgencyDto();
    this.levelTypeDto = new LevelType();
    this.levelTypeDto = null;
    this.levelType = null;
    this.levelMasterDto = null;
    this.levelMasterList = null;
    this.agencyDialog = false;
    this.showNameError = null;
    this.showMobileNoError = null;
    this.showEmailError = null;
    this.showEmailUniError = null;
    this.gstUniError = null;
    // this.viewOnly = false;
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(
      this.agency.mobile
    );
    !this.agency.mobile ? (this.showMobileError = false) : true;
    return !this.showMobileError && !!this.agency.mobile;
  }

  validateEmail() {
    this.emailError = !this.validationService.isEmailValid(this.agency.email);
    !this.agency.email ? (this.emailError = false) : true;
    return !this.emailError && !!this.agency.email;
  }

  validateGst() {
    this.showGstError = !this.validationService.isGstValid(this.agency.gstNo);
    !this.agency.gstNo ? (this.showGstError = false) : true;
    return !this.showGstError && !!this.agency.gstNo;
  }

  validateIfsc() {
    if (this.agency.ifscCode) {
      this.showIfscError = !this.validationService.isIfscValid(
        this.agency.ifscCode
      );
      return !this.showIfscError;
    } else {
      this.showIfscError = false;
      return true;
    }
  }

  validateBmobile() {
    if (this.agency.branchMobNo) {
      this.showBMobile = !this.validationService.isMobileValid(
        this.agency.branchMobNo
      );
      return !this.showBMobile;
    } else {
      this.showBMobile = false;
      return true;
    }
  }

  validateBemail() {
    if (this.agency.branchEmail) {
      this.emailBranchError = !this.validationService.isEmailValid(
        this.agency.branchEmail
      );
      return !this.emailBranchError;
    } else {
      this.emailBranchError = false;
      return true;
    }
  }

  saveAgency(data) {
    if (data == 'add') {
      const isFormValid = this.validateMobile() && this.validateEmail();

      if (!isFormValid) {
        this.ngSelectInvalid = true;
        return;
      }
      if (
        this.showNameError ||
        this.showMobileNoError ||
        this.showEmailUniError ||
        this.gstUniError
      ) {
        return;
      }

      this.ngSelectInvalid = false;
      this.agency.leveltype = this.levelTypeDto?.id;
      this.agency.levelMaster = this.levelMasterDto?.id;

      let payload = {
        agencyName: this.agency.name,
        governmentType: this.agency.govermentType.name,
        address1: this.agency.address1,
        address2: this.agency.address2,
        street: this.agency.street,
        city: this.agency.city,
        districtLgdCode: this.agency.district,
        state: this.agency.state.id,
        pinCode: this.agency.pincode,
        authorizedPersonName: this.agency.agency,
        authorizedPersonDesignation: this.agency.designation,
        authorizedPersonMobileNumber: this.agency.mobile,
        authorizedPersonEmailId: this.agency.email,
        officePhoneNumber: this.agency.offliceMobile,
        officeEmailId: this.agency.ooficeemail,
      };

      this.agencyService.save(payload).subscribe(
        (res: HttpResponse<Agency[]>) => {
          this.agencyDialog = false;
          this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
          this.hideDialog();
        },
        (err) => {
          this.onError();
          this.notificationService.alertError('Error!', err.error.error);
          this.spinner.hide();
        }
      );
    } else {
      const isFormValid = this.validateMobile() && this.validateEmail();

      if (!isFormValid) {
        this.ngSelectInvalid = true;
        return;
      }
      if (
        this.showNameError ||
        this.showMobileNoError ||
        this.showEmailUniError ||
        this.gstUniError
      ) {
        return;
      }
      this.ngSelectInvalid = false;
      this.agency.leveltype = this.levelTypeDto?.id;
      this.agency.levelMaster = this.levelMasterDto?.id;
      let payload = {
        agencyName: this.agency.name,
        governmentType: this.agency.govermentType.name,
        address1: this.agency.address1,
        address2: this.agency.address2,
        street: this.agency.street,
        city: this.agency.city,
        districtLgdCode: this.agency.district,
        state: this.agency.state.id,
        pinCode: this.agency.pincode,
        authorizedPersonName: this.agency.agency,
        authorizedPersonDesignation: this.agency.designation,
        authorizedPersonMobileNumber: this.agency.mobile,
        authorizedPersonEmailId: this.agency.email,
        officePhoneNumber: this.agency.offliceMobile,
        officeEmailId: this.agency.ooficeemail,
      };
      this.agencyService.edit(payload, this.agency.id).subscribe(
        (res: HttpResponse<Agency[]>) => {
          this.agencyDialog = false;
          this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
          this.hideDialog();
        },
        (err) => {
          this.onError();
          this.notificationService.alertError('Error!', err.error.error);
          this.spinner.hide();
        }
      );
    }
  }

  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }
  onPageChange(event: PageEvent) {
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    this.page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.submitForFilter(this.params, this.dir);
  }

  async checkName() {
    const filterData = await this.getfilterdata('name', this.agency.name);
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }

  async checkmobile() {
    const filterData = await this.getfilterdata('mobile', this.agency.mobile);
    if (filterData.length !== 0) {
      this.showMobileNoError = 'Phone Already Exists';
    } else {
      this.showMobileNoError = null;
    }
  }

  async checkemail() {
    const filterData = await this.getfilterdata('email', this.agency.email);
    if (filterData.length !== 0) {
      this.showEmailUniError = 'Email Already Exists';
    } else {
      this.showEmailUniError = null;
    }
  }

  async checkgstNo() {
    const filterData = await this.getfilterdata('gstNo', this.agency.gstNo);
    if (filterData.length !== 0) {
      this.gstUniError = 'GST Already Exists';
    } else {
      this.gstUniError = null;
    }
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
        {
          key: 'activeFlag',
          operation: 'equals',
          value: 'YES',
        },
      ];
      if (this.agency.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.agency.id,
        });
      }
      this.agencyService.validFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<Agency[]>) => {
          resolve(res.body);
        },
        () => {
          this.onError();
          resolve(null);
        }
      );
    });
  }

  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'view') {
      if ($event.type === 'view') {
        this.viewOnly = true;
      }
      this.agencyService.getAgency($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.agency = res.body;
          this.agencyDialog = true;

          this.onchangeSchema();
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.agencyService.delete($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }
}

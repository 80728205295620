import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AgencyService } from './lao.service';
import { Agency, AgencyDto } from './lao';
import { SearchResult } from 'src/app/shared/models/search-result';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelType } from '../level-type/level-type';
import { ZonalService } from '../zonal/zonal.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Dialog } from 'primeng/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-list-fund-allocation',
  templateUrl: './lao-list.component.html',
  styleUrls: ['./lao-list.component.scss']
})
export class ListFundAllocationComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;
  // @ViewChild('department', ) department: NgSelectComponent;

  agencyDialog: boolean = false;
  cols: any[];
  zonalDto: any;
  searchResult: SearchResult<Agency> = new SearchResult<Agency>();
  agency?: AgencyDto;
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: any = 10;
  page: number = 1;
  filterProps: any;
  levelType: LevelType[];
  levelTypeDto: LevelType;
  levelMasterList: any;
  levelMasterDto: any;

  zonalList: any;
  // validation fields properties
  filter = {
    userName: '',
    mobile: '',
    district: '',
    designaiton: ''
  }
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public showGstError = false;
  public showIfscError = false;
  public showBMobile = false;
  //required fields property
  ngSelectInvalid = false;
  showNameError: any;
  showMobileNoError: string;
  showEmailError: string;
  showEmailUniError: string;
  gstUniError: string;
  viewOnly: boolean;
  hamHeader: string;
  subHeader: string;
  agencyList: any;
  stateList: any;
  state: any[];
  district: any;
  projectList: any;

  fund: any = {
    category: '',
    name: ''
  }
  totalCount: any;
  pageCount: number = 0;
  index: number;
  roleId: boolean = false;
  pageLength: number;
  categoryLAO: any;
  districtId: any;
  selectCategory: any;
  filterQuery: any = { userName: '', mobile: '', district: '', designation: '', page: '', size: '' };
  sortDirection: any ='asc';
  constructor(
    private agencyService: AgencyService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private loginService: SigninService,
    private levelTypeService: LevelTypeService,
    private zonalService: ZonalService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,

  ) {
  }

  ngOnInit() {
    this.getProjects()
    if (this.router.url == '/agency') {
      this.hamHeader = 'Requisitioning Departments';
      this.subHeader = 'Home  /   Requisitioning Departments';
    }
    this.loadPage(this.page);

  }

  getUserrole() {
    this.loginService.getuserDto().subscribe(
      (res) => {
        if (res.body.role.id != 1 && res.body.role.id != 8 && res.body.role.id != 5 && res.body.role.id != 4 && res.body.role.id != 3) {
          this.roleId = true
        } else {
          this.roleId = false
        }
      },
      (onError) => { }
    );
  }
  ngAfterViewInit() {
    // this.updateDialogFont();
  }
  govermentType = [
    {
      name: 'CENTRAL_GOVERNMENT',
    },
    {
      name: 'STATE_GOVERNMENT',
    },
  ];
  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;
    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }
  
  loadPage(page?: number,params?): void {
    this.spinner.show();
    let query 
    const pageToLoad: number = page ?? this.page ?? 1;
    if (params) {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        sortBy:params,
        sortDirection:this.sortDirection
      };
      if (this.sortDirection == 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    } else {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
      };
    }
   
    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);
    this.agencyService.filter({ ...query }, this.filterProps).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad);
      },
      (err) => {
        this.spinner.hide();
        this.notificationService.alertError('Error!', err.error.error);
      }
    );
  }

  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,
  ): void {
    // this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data.data ?? [];
    this.agencyList = data.data;
    //console.log(this.agencyList, ' this.agencyList');
    this.totalCount = data.totalCount;
  }

  protected onError(): void {
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  vendorFilter($event) {
    let filter = new filterQuery();
    filter.key = 'name';
    filter.operation = 'contains';
    filter.value = $event.term;
    let filterquery = [];
    filterquery.push(filter);
    this.zonalService.filter({ size: 10, page: 0 }, filterquery).subscribe(
      (res: HttpResponse<any>) => {
        this.zonalList = res.body;
      },
      () => { }
    );
  }



  openNew(data, content?: any) {
    this.spinner.show();
    if (data == 'edit') {
      this.viewOnly = false;
      this.agencyService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          let stateList = res.body;
          this.spinner.hide();
          this.state = stateList.filter((res) => res.levelTypeDto.id == 1);
          this.district = stateList.filter((res) => res.levelTypeDto.id == 2);
        },
        () => {
          this.onError();
        }
      );
      this.agency = new AgencyDto();
      this.submitted = false;
      this.fund.id = content.id;
      this.fund.category = content.laoCategory != null ? content.laoCategory.id : '';
      this.fund.designaiton = content.designation;
      this.fund.mobile = content.mobile;
      this.fund.email = content.email;
      this.fund.district = content.levelMasterId.id
      this.agencyDialog = true;
    } else {
      this.viewOnly = true;
      this.agencyService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          this.spinner.hide();
          let stateList = res.body;
          this.state = stateList.filter((res) => res.levelTypeDto.id == 1);
          this.district = stateList.filter((res) => res.levelTypeDto.id == 2);
        },
        () => {
          this.onError();
        }
      );
      this.agency = new AgencyDto();
      this.submitted = false;
      this.fund.category = content.laoCategory != null ? content.laoCategory.id : '';
      this.fund.designaiton = content.designation;
      this.fund.mobile = content.mobile;
      this.fund.email = content.email;
      this.fund.district = content.levelMasterId.id

      this.agencyDialog = true;
    }
  }

  getDeActivate(id) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.agencyService.profileDeactivate(id).subscribe(res => {
          this.notificationService.alertSuccess("User Deactivated", '')
        }, (err) => {
          console.log(err.error.error);
          this.notificationService.alertError(err.error.error, '')
        })
      },
    });
  }


  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        // this.levelType = res.body;
        this.levelType = res.body.filter(
          (item) => item.name === 'State' || item.name === 'District'
        );
      },
      () => { }
    );
  }


  onProjectChange(e) {
    let districtList = e.projectLocation
    districtList.forEach(element => {
    });

  }

  keyPressNumberForMobile(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) || event.target.value.length >= 10) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  getProjects() {
    this.agencyService.getCategoryLao().subscribe((res: any) => {
      this.categoryLAO = res.body.data;
    });
  }
  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.showGstError = false;
    this.showIfscError = false;
    this.showBMobile = false;
    this.ngSelectInvalid = false;
    this.agency = new AgencyDto();
    this.levelTypeDto = new LevelType();
    this.levelTypeDto = null;
    this.levelType = null;
    this.levelMasterDto = null;
    this.levelMasterList = null;
    this.agencyDialog = false;
    this.showNameError = null;
    this.showMobileNoError = null;
    this.showEmailError = null;
    this.showEmailUniError = null;
    this.gstUniError = null;
  }

  submitForFilter() {
    const pageToLoad: number = this.page ?? this.page ?? 1;

    this.filterQuery = {
      userName: this.filter.userName,
      mobile: this.filter.mobile,
      district: this.filter.district,
      designation: this.filter.designaiton,
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.agencyService.filters(this.filterQuery).subscribe((res: any) => {
      this.agencyList = res.body.data
      this.totalCount = res.body.totalCount
    })
  }
  clear(data) {
    if (data == 'userName') {
      this.filter.userName = ''
      this.filterQuery.userName = ''
    } else if (data == 'mobile') {
      this.filter.mobile = ''
      this.filterQuery.mobile = ''
    } else if (data == 'district') {
      this.filter.district = ''
      this.filterQuery.district = ''
    } else if (data == 'designation') {
      this.filterQuery.designation = ''
      this.filter.designaiton = ''
    }

    if (this.filter.userName == '' && this.filter.mobile == '' && this.filter.district == '' && this.filter.designaiton == '') {
      this.loadPage(this.page)
    } else {
      this.submitForFilter()
    }

  }

  onDistrict(e) {
    this.districtId = e.id

  }

  onLAO(e) {
    this.selectCategory = e.id
  }

  saveAgency(data) {

    this.ngSelectInvalid = false;

    let payload = {
      "mobile": this.fund.mobile,
      "email": this.fund.email,
      "zonalName": null,
      "userActiveFlag": "Yes",
      "resetPassword": null,
      "loginAttempt": null,
      "resetDate": null,
      "implementingAgencyId": null,
      "designation": this.fund.designaiton,
      "levelMasterId": this.districtId,
      "profileStatus": "Yes",
      "keyCloakUuid": "null",
      "laoCategoryId": this.selectCategory,
    };
    this.agencyService.edit(payload, this.fund.id).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.agencyDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
        this.hideDialog();
      },
      (err) => {
        this.onError();
        this.notificationService.alertError('Error!', err.error.error);
        this.spinner.hide();
      }
    );

  }

  generateIndex(i) {
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }
  onPageChange(event: PageEvent) {
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    this.page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    if (this.filterQuery.userName == '' && this.filterQuery.mobile == '' && this.filterQuery.district == '' && this.filterQuery.designation == '') {
      this.loadPage(this.page);

    } else {
      this.submitForFilter()
    }
  }

  async checkName() {
    const filterData = await this.getfilterdata('name', this.agency.name);
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }
  async checkmobile() {
    const filterData = await this.getfilterdata('mobile', this.agency.mobile);
    if (filterData.length !== 0) {
      this.showMobileNoError = 'Phone Already Exists';
    } else {
      this.showMobileNoError = null;
    }
  }

  async checkemail() {
    const filterData = await this.getfilterdata('email', this.agency.email);
    if (filterData.length !== 0) {
      this.showEmailUniError = 'Email Already Exists';
    } else {
      this.showEmailUniError = null;
    }
  }

  async checkgstNo() {
    const filterData = await this.getfilterdata('gstNo', this.agency.gstNo);
    if (filterData.length !== 0) {
      this.gstUniError = 'GST Already Exists';
    } else {
      this.gstUniError = null;
    }
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
        {
          key: 'activeFlag',
          operation: 'equals',
          value: 'YES',
        },
      ];
      if (this.agency.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.agency.id,
        });
      }
      this.agencyService.validFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<Agency[]>) => {
          resolve(res.body);
        },
        () => {
          this.onError();
          resolve(null);
        }
      );
    });
  }

}

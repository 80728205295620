export class FilterTable {
    district: string;
    block?: string;
    village?: string;
    districtId?: string;
    blockId?: string;
    villageId?: string;
    finyearId?:number;
    iaId?:number;
  
}
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tabledate'
})
export class TabledatePipe implements PipeTransform {

  transform(value: string):string {
    if(!value){
      return ''
    }
    var datePipe = new DatePipe("en-US");
     value = datePipe.transform(value, 'dd-MM-yyyy');
     return value || '';
 }
}

<div class="hamburger" *ngIf="router.url != '/home'">
    <h2>Recommend for DD Payment</h2>
</div>
<div class="container-fluid bg">
    <div class="container row mx-auto bgFilter">
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Project
                Name</label>
            <ng-select id="department" bindLabel="projectName" appearance="outline"
                [(ngModel)]="stage2SearchFilter.project" [items]="projectList" class="form-control"
                (change)="getExtent('projectName')" (change)="selectProject($event)" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Districts</label>
            <ng-select id="department" bindLabel="name" appearance="outline" (change)="SelectDistrict($event)"
                (change)="getExtent('district')" [(ngModel)]="stage2SearchFilter.district" [items]="districkList"
                class="form-control" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Taluk</label>
            <ng-select id="type" [items]="taluk" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                [clearable]="true" class="form-control" (change)="OnSelectTaluk($event)" (change)="getExtent('taluk')"
                [(ngModel)]="stage2SearchFilter.taluk" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }"
                appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="type" [items]="villages" bindLabel="name" (change)="OnSelectVillage($event)"
                (change)="getExtent('village')" appearance="outline" [closeOnSelect]="true" [clearable]="true"
                class="form-control" [(ngModel)]="stage2SearchFilter.village"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }" appNgSelectInputRestriction>
            </ng-select>
        </div>
        <div class="col-sm-3 col-md-3">
            <label class="form-control-label req" [ngClass]="fontService.labelClass">Total extent Acquired(In
                hectares)</label>
            <input type="text" class="form-control" name="name" readonly [(ngModel)]="stage2SearchFilter.totalExtent"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" />
        </div>
        <div class="col-sm-3 col-md-3 mt-3">
            <p-button label="Submit" class="" (click)="getSurvey()"></p-button>
        </div>
    </div>
    <div class="container tableDesign mt-3" *ngIf="isShowTable">
        <p-table [value]="products" [tableStyle]="{ 'width': '100px' ,margin:'auto'}" class="mt-4">
            <ng-template pTemplate="header">
                <tr>
                    <th style="background-color: #017DC3;">Total Amount allotted
                        for Project</th>
                    <th style="background-color: #017DC3;">Total Amount Already
                        Paid</th>
                    <th style="background-color: #017DC3;">Total amount for which
                        fund Transfer is
                        Pending</th>
                    <th style="background-color: #017DC3;">Balance amount</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body">
                <tr>
                    <td>
                        {{amountDetails.totalAmountAllocatedForProject |currency:'INR'}}
                    </td>
                    <td>
                        {{amountDetails.totalAmountAlreadyPaid |currency:'INR'}}
                    </td>
                    <td>
                        {{amountDetails.totalAmountForWhichFundTransferIsPending |currency:'INR'}}
                    </td>
                    <td>
                        {{amountDetails.balanceAmount |currency:'INR'}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class=" mt-3" *ngIf="isShowTable">
        <div #customerTable id="entities" class="col-md-12 mt-3">
            <p-table [value]="beneficiaryData" dataKey="id">
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th scope="col" style="background-color: #017DC3;">Beneficiary Name
                        </th>
                        <th scope="col" style="background-color: #017DC3;">Survey
                            Number</th>
                        <th scope="col" style="background-color: #017DC3;">Subdivision
                            Number</th>
                        <th scope="col" style="background-color: #017DC3;">Mobile
                            Number
                        </th>
                        <th scope="col" style="background-color: #017DC3;">Bank Name
                        <th scope="col" style="background-color: #017DC3;">Account Number
                        </th>
                        <th scope="col" style="background-color: #017DC3;">IFSC Code
                        <th scope="col" style="background-color: #017DC3;">Branch
                        </th>
                        <th scope="col" style="background-color: #017DC3;">Aadhaar Number
                        </th>
                        <th scope="col" style="background-color: #017DC3;">Amount
                        </th>
                        <th scope="col" style="background-color: #017DC3;">Recommended to
                            LAO for
                            Payment of Compensation</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-beneficary let-expanded="expanded" let-rowIndex="rowIndex">
                    <tr>
                        <td>

                            <button type="button" *ngIf="beneficary.beneficiaryLandOwnerCustomLegalDto.length != 0"
                                pButton pRipple [pRowToggler]="beneficary"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                        </td>
                        <td>{{beneficary.benificiaryLandOwnerName}}</td>
                        <td>{{beneficary.surveyNumber}}</td>
                        <td>{{beneficary.subDivisionNumber}}</td>
                        <td>{{beneficary.mobileNumber}}</td>
                        <td>{{beneficary.nameOfBank}}</td>
                        <td>{{beneficary.accountNumber}}</td>
                        <td class="text-uppercase">{{beneficary.ifscCode}}</td>
                        <td>{{beneficary.branchName}}</td>
                        <td>{{beneficary.aadharNumber}}</td>
                        <td>{{beneficary.totalAmount |indianRupee  }}</td>
                        <td>
                            <p-checkbox [binary]="true" inputId="binary"
                                (onChange)="generateVoucher($event,beneficary)"></p-checkbox>
                        </td>
                    </tr>

                </ng-template>
                <ng-template pTemplate="footer">
                    <tr style="background: rgba(1, 125, 195, 0.05) !important;" *ngIf="totalCount != 0">
                        <th colspan="10" class="footer">
                            <h5 class="total">Total</h5>
                        </th>
                        <th class="footer">
                            {{totalAmount |indianRupee }}
                        </th>
                        <th>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-beneficary>
                    <tr>
                        <td colspan="12">
                            <div class="p-3">
                                <p-table [value]="beneficary.beneficiaryLandOwnerCustomLegalDto" dataKey="id">
                                    <ng-template pTemplate="header">
                    <tr>
                        <th scope="col" style="background-color: #017DC3;">Legal heir Name </th>
                        <th scope="col" style="background-color: #017DC3;">Mobile No </th>
                        <th scope="col" style="background-color: #017DC3;">Name of Bank </th>
                        <th scope="col" style="background-color: #017DC3;">Account No </th>
                        <th scope="col" style="background-color: #017DC3;">Aadhaar No </th>
                        <th scope="col" style="background-color: #017DC3;">Amount </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-order>
                    <tr>
                        <td>{{ order.benificiaryLandOwnerName }}</td>
                        <td>{{ order.mobileNumber }}</td>
                        <td>{{ order.nameOfBank }}</td>
                        <td>{{ order.accountNumber }}</td>
                        <td>{{ order.aadharNumber }}</td>
                        <td>{{ order.compensationAmount | currency: 'INR' }}</td>
                    </tr>
                </ng-template>

            </p-table>
        </div>
        </td>
        </tr>
        </ng-template>
        </p-table>
        <div class="text-center" *ngIf="totalCount == 0">
            <img src="../../../assets/img/notFound.png" width="419px" alt="">
        </div>
        <div class="flex mt-3" *ngIf="totalCount != 0">
            <p-button label="Recommended for payment" [disabled]="enableGenerateVoucher" class="submit"
                (click)="recommended()"></p-button>
        </div>
        <form ngNoForm action="https://emgateway.emsigner.com/eMsecure/V3_0/Index" method="POST">
            <input id="Parameter1" name="Parameter1" type="hidden" [value]='param1' />
            <input id="Parameter2" name="Parameter2" type="hidden" [value]='param2' />
            <input id="Parameter3" name="Parameter3" type="hidden" [value]='param3' />



            <button *ngIf="enableSignButton" pButton pRipple onclick="submit()" type="submit"
                class="p-button-success custom-button approve_btn">
                <span class="button-label" [ngClass]="fontService.buttonClass">perform E-sign</span>
            </button>

        </form>
    </div>
</div>

<p-dialog header="Payment Details" [(visible)]="visible" (onHide)="hide()" [modal]="true" [style]="{ width: '50vw' }"
    [draggable]="false" [resizable]="false">
    <div class="row payment">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Bank</th>
                    <th scope="col">Account Number</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of availableAmount">
                    <td>{{data.name}}</td>
                    <td>{{data.bankName}}</td>
                    <td>{{data.accountNumber}}</td>
                </tr>

            </tbody>
        </table>
    </div>
    <div>
        <p-button label="Generate Voucher" class="submit mx-auto" (click)="submit()"></p-button>
    </div>
</p-dialog>

</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AgencyService } from './stage4.service';
import { Agency, AgencyDto } from './stage4';
import { SearchResult } from 'src/app/shared/models/search-result';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelType } from '../level-type/level-type';
import { ZonalService } from '../zonal/zonal.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { Dialog } from 'primeng/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/alert/notification.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/project/project.service';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'app-stage4-view',
  templateUrl: './stage4-view.component.html',
  styleUrls: ['./stage4-view.component.scss']
})
export class Stage4ViewComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;
  // @ViewChild('department', ) department: NgSelectComponent;
  agencyDialog: boolean = false;
  cols: any[];
  zonalDto: any;
  searchResult: SearchResult<Agency> = new SearchResult<Agency>();
  agency?: AgencyDto;
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  agencyList
  levelType: LevelType[];
  levelTypeDto: LevelType;
  levelMasterList: any;
  levelMasterDto: any;
  zonalList: any;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  stage1Filter = {
    projectId: '',
    name:'',
    mobile:'',
    aadhaar:''
  }
  public showGstError = false;
  public showIfscError = false;
  public showBMobile = false;
  //required fields property
  ngSelectInvalid = false;
  showNameError: any;
  showMobileNoError: string;
  showEmailError: string;
  showEmailUniError: string;
  gstUniError: string;
  viewOnly: boolean;
  hamHeader: string;
  subHeader: string;
  beneficiary: any = {}
  beneficary: any;
  state: any[];
  district: any[];
  projectList: any;
  beneficiaryList: any[];
  taluk: AgencyDto[];
  villages: AgencyDto[];
  items: { label: string; command: (event: any) => void; }[];
  beneficaryForm: FormGroup;
  districtList: void;
  activeIndex: number;
  singleBeneficiaryList: any;
  label: any;
  splitDistirct: any[];
  totalCount: any;
  pageCount: number = 0;
  index: any;
  pageLength: any;
  sortDirection: string ='asc';
param1: any;
param2: any;
param3: any;
  enableSignButton: boolean;
  constructor(
    private agencyService: AgencyService,
    private projectService: ProjectService,

    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private levelTypeService: LevelTypeService,
    private zonalService: ZonalService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    public formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.hamHeader = "Payment status"
    this.subHeader = "Payment  /  Payment status"
    this.forms()
    this.items = [{
      label: 'Details of acquired land',
      command: (event: any) => {
        this.activeIndex = 0;
        this.messageService.add({ severity: 'info', summary: 'First Step', detail: event.item.label });
      }
    },
    {
      label: 'Account Details',
      command: (event: any) => {
        this.activeIndex = 1;
        this.messageService.add({ severity: 'info', summary: 'Seat Selection', detail: event.item.label });
      }
    },];
    this.loadPage()
    this.getProjects()
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }


  forms() {
    this.beneficaryForm = this.formBuilder.group({
      disTrictName: ['', Validators.required],
      taluk: ['', Validators.required],
      village: ['', Validators.required],
      projectName: ['', Validators.required],
      surveyNo: ['', Validators.required],
      landAcquire: ['', Validators.required],
      totalCost: ['', Validators.required],
      AccountDetailsForm: this.formBuilder.array([])
    })
  }


  detailsform(): FormGroup {
    return this.formBuilder.group({
      landOwneName: ['', Validators.required],
      aadhaarNo: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      accountHolderName: ['', Validators.required],
      bankName: ['', Validators.required],
      bankAccountNo: ['', Validators.required],
      ifscCode: ['', Validators.required],
      branchName: ['', Validators.required],
      amount: ['', Validators.required],
    })
  }

  removeSkill(i: number) {
    this.detailsOfAccount.removeAt(i);
  }

  get detailsOfAccount(): FormArray {
    return this.beneficaryForm.get("AccountDetailsForm") as FormArray
  }

  addSkill() {
    this.detailsOfAccount.push(this.detailsform());

  }
  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  getProjects() {
    this.projectService.getProjectListforStage4().subscribe(
      (res: any) => {

        this.projectList = res.body.data;

      },
      () => {
        // this.onError();
      }
    );
  }
  loadPage(page?: number,param?): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query 
    if (param) {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        projectId: this.stage1Filter.projectId ?? '',
        mobileNumber: this.stage1Filter.mobile ?? '',
        name: this.stage1Filter.name ?? '',
        aadharNumber: this.stage1Filter.aadhaar ?? '',
        sortBy:param,
        sortDirection:this.sortDirection
      };
      if (this.sortDirection == 'asc') {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
    } else {
      query = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        projectId: this.stage1Filter.projectId ?? '',
        mobileNumber: this.stage1Filter.mobile ?? '',
        name: this.stage1Filter.name ?? '',
        aadharNumber: this.stage1Filter.aadhaar ?? '',
      };
    }
   
    this.filterProps = this.filterProps ?? [];
    this.filterProps = this.filterProps ? this.filterProps : [];
    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);
    this.agencyService.filter({ ...query }).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad);
      },
      () => {
        this.onError();
      }
    );
  }
  protected onSuccess(
    data: any,
    headers: HttpHeaders,
    page: number,
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.beneficary = data.data
    this.totalCount = data.totalCount;

    //console.log(this.beneficary, 'this.beneficary');

    // this.searchResult.items = data ?? [];
    // this.searchResult = { ...this.searchResult };
  }


  onPageChange(event: PageEvent) {
    //console.log(event);
    this.itemsPerPage = event.rows;
    this.pageCount = Number(event.page);
    let page = Number(event.page) + 1;
    this.pageLength = Number(event.page)
    this.loadPage(page);
  }
  
  protected onError(): void { 
    this.spinner.hide()
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    // this.loadPage(event.page);
  }

  vendorFilter($event) {
    let filter = new filterQuery();
    filter.key = 'name';
    filter.operation = 'contains';
    filter.value = $event.term;
    let filterquery = [];
    filterquery.push(filter);
    this.zonalService.filter({ size: 10, page: 0 }, filterquery).subscribe(
      (res: HttpResponse<any>) => {
        this.zonalList = res.body;
      },
      () => { }
    );
  }

  openNew(data, beneficaryData?: any) {
    this.label = data
    this.activeIndex = 0;
    this.forms()
    this.addSkill()
    if (data == 'new') {
      this.viewOnly = false
      this.beneficiary = []
      this.agency = new AgencyDto();
      this.submitted = false;
      this.getProjects()
      this.agencyDialog = true;
      this.agencyService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          let stateList = res.body;
          this.state = stateList.filter(res => res.levelTypeDto.id == 1)
          this.district = stateList.filter(res => res.levelTypeDto.id == 2)
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.viewOnly = true
      this.beneficiary = []
      this.agency = new AgencyDto();
      this.submitted = false;
      this.getProjects()
      this.agencyDialog = true;
      this.agencyService.getLevelMaster().subscribe(
        (res: HttpResponse<any[]>) => {
          let stateList = res.body;
          this.state = stateList.filter(res => res.levelTypeDto.id == 1)
          this.district = stateList.filter(res => res.levelTypeDto.id == 2)
        },
        () => {
          this.onError();
        }
      );
      this.agencyService.beneficiary(beneficaryData.id).subscribe(
        (res: HttpResponse<any[]>) => {
          this.singleBeneficiaryList = res.body;
          //console.log(this.singleBeneficiaryList);
          this.beneficaryForm.patchValue({
            disTrictName: this.singleBeneficiaryList.districtLGDCode.name,
            taluk: this.singleBeneficiaryList.talukLGDCode.name,
            village: this.singleBeneficiaryList.revenueLGDCode.name,
            projectName: this.singleBeneficiaryList.projectID.projectName,
            surveyNo: this.singleBeneficiaryList.surveyNumber,
            landAcquire: this.singleBeneficiaryList.extendToBeAcquired,
            totalCost: this.singleBeneficiaryList.totalCost,
          })
          this.displayItems()
        },
        () => {
          this.onError();
        }
      );
    }
  }
  createItem(item: any) {
    return this.formBuilder.group({
      landOwneName: [item.benificiaryLandOwnerName, [Validators.required]],
      aadhaarNo: [item.aadharNumber, [Validators.required]],
      mobileNumber: [item.mobileNumber, [Validators.required]],
      accountHolderName: [item.nameOfAccountHolder, [Validators.required]],
      bankName: [item.nameOfBank, [Validators.required]],
      bankAccountNo: [item.accountNumber, [Validators.required]],
      ifscCode: [item.ifscCode, [Validators.required]],
      branchName: [item.branchName, [Validators.required]],
      amount: [item.compensationAmount, [Validators.required]],
    });
  }



  displayItems() {
    let transformedItems = this.singleBeneficiaryList.landOwners.map((item: any) =>
      this.createItem(item)
    );
    this.beneficaryForm.setControl('AccountDetailsForm', this.formBuilder.array(transformedItems));
    this.beneficaryForm.disable()
  }

  onSelectDistrict(e) {
    this.agencyService.talukAndVillages(e.id, 3).subscribe(res => {
      this.taluk = res
    })
    this.beneficaryForm.get('taluk').setValue('')
    this.beneficaryForm.get('village').setValue('')

  }

  OnSelectTaluk(e) {
    this.agencyService.talukAndVillages(e.id, 4).subscribe(res => {
      this.villages = res
    })
    this.beneficaryForm.get('village').setValue('')

  }
  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        // this.levelType = res.body;
        this.levelType = res.body.filter(item => item.name === 'State' || item.name === 'District');

      },
      () => { }
    );
  }

  generateIndex(i) {
    
    this.index = i;
    if (this.pageCount == 0) {
      return this.index + 1;
    } else {
      return (this.index + 1) + (this.itemsPerPage * this.pageLength);
    }
  }
  onProjectChange(e) {
    let districtList = e.projectLocations
    this.splitDistirct = []
    districtList.forEach(element => {
      this.splitDistirct.push(element.districtLGDCode)
    });
    this.beneficaryForm.get('disTrictName').setValue('')
    this.beneficaryForm.get('taluk').setValue('')
    this.beneficaryForm.get('village').setValue('')
  }


  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.showGstError = false;
    this.showIfscError = false;
    this.showBMobile = false;
    this.ngSelectInvalid = false;
    this.agency = new AgencyDto();
    this.levelTypeDto = new LevelType();
    this.levelTypeDto = null;
    this.levelType = null;
    this.levelMasterDto = null;
    this.levelMasterList = null;
    this.agencyDialog = false;
    this.showNameError = null;
    this.showMobileNoError = null;
    this.showEmailError = null;
    this.showEmailUniError = null;
    this.gstUniError = null;
    this.viewOnly = false;
  }
 
  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    var charLength = event.target.value.length
    //console.log(charLength);

    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      if (charLength == 10) {
        event.preventDefault();
        return true;
      }
    }
  }


  saveAgency() {

    let beneficaryData = this.beneficaryForm.value

    let landFrom = []
    beneficaryData.AccountDetailsForm.forEach(element => {
      let data = {
        "benificiaryLandOwnerName": element.landOwneName,
        "aadharNumber": element.aadhaarNo,
        "mobileNumber": element.mobileNumber,
        "nameOfAccountHolder": element.accountHolderName,
        "nameOfBank": element.bankName,
        "accountNumber": element.bankAccountNo,
        "branchName": element.branchName,
        "ifscCode": element.ifscCode,
        "compensationAmount": element.amount,
      }
      landFrom.push(data)
    });

    let Payload = {
      "projectID": beneficaryData.projectName.id,
      "districtLGDCode": beneficaryData.disTrictName.id,
      "talukLGDCode": beneficaryData.taluk.id,
      "revenueLGDCode": beneficaryData.village.id,
      "surveyNumber": beneficaryData.surveyNo,
      "totalCost": beneficaryData.totalCost,
      "extendToBeAcquired": beneficaryData.landAcquire,
      "benificiardLandOwnerDto": landFrom
    }

    this.ngSelectInvalid = false;
    this.agency.leveltype = this.levelTypeDto?.id;
    this.agency.levelMaster = this.levelMasterDto?.id;
    this.spinner.show()
    this.agencyService.save(Payload).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.agencyDialog = false;
        this.spinner.hide()

        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
        this.hideDialog();
      },
      () => {
        this.onError();
        this.notificationService.alertError('Error!', '');
      }
    );
  }

  enable(data:any){
    //console.log('77777777777777777777777',data);
    this.getfile(data.voucherNumber);
  }

  getfile(VoucherNo:string) {
    this.projectService
      .getEmudrarequest(VoucherNo)
      .subscribe(
        (res: HttpResponse<any>) => {
       
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          this.enableSignButton = true;
          // if(!this.voucherGenerationDto.vendorTransactionDto.id){
          //   this.router.navigate(['/PaymentVoucher'], {
          //     queryParams: {
          //       id: res.body.paramFour
          //     },
          //   });
          // }
          // add more parameters as needed
        },
        () => {}
      );
  }

}
